import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { I18nService } from '@sl/common/services/i18n/I18nService.service';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
/**
 * Date pipe which uses selected current language
 */
export class LocalizedDatePipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  transform(value: any, pattern: string = 'mediumDate'): string {
    let datePipe;
    try {
      // Current language may not work if it's a custom language from a theme
      datePipe = new DatePipe(this.i18nService.getCurrentLanguage());
      return datePipe.transform(value, pattern);
    } catch (e) {
      try {
        datePipe = new DatePipe(this.i18nService.getDefaultLanguage());
        return datePipe.transform(value, pattern);
      } catch (e) {
        SLLogger.warn("DatePipe couldn't be initialized!");
        return String(value);
      }
    }
  }
}
