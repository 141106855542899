import { Pipe, PipeTransform } from '@angular/core';
import { ContactInfoOverview } from '@sl/common/model/ContactInfoOverview';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { ContactInfo } from '@sl/common/model';
import { SLUtils } from '@sl/common/utils/SLUtils';

@Pipe({ name: 'fullname' })
export class ContactInfoFullNamePipe implements PipeTransform {
  transform(ci: ContactInfoOverview): string {
    if (ci != null) {
      const fullContactInfo = ci.hasOwnProperty('emailAddresses') ? (ci as ContactInfo) : null;
      let text = '';

      if (fullContactInfo != null && !TextUtils.isNullOrEmpty(fullContactInfo.namePrefix)) {
        text += fullContactInfo.namePrefix + ' ';
      }
      if (!TextUtils.isNullOrEmpty(ci.firstName)) {
        text += ci.firstName + ' ';
      }
      if (!TextUtils.isNullOrEmpty(ci.lastName)) {
        text += ci.lastName;
      }
      if (fullContactInfo && !TextUtils.isNullOrEmpty(fullContactInfo.namePostfix)) {
        text += ' ' + fullContactInfo.namePostfix;
      }

      if (fullContactInfo && TextUtils.isNullOrEmpty(text) && SLUtils.checkContactInfoHasEmail(fullContactInfo)) {
        text = fullContactInfo.emailAddresses[0].emailAddress;
      }

      return text.trim();
    }
    return '';
  }
}
