import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { KeyboardUtil } from '@sl/common/utils/KeyboardUtil';

@Component({
  selector: 'sl-answer-filter',
  templateUrl: './answer-filter.component.html',
  styleUrls: ['./answer-filter.component.scss'],
})
export class AnswerFilterComponent {
  searchText: string;

  @Output()
  onSearchChanged = new EventEmitter<string>();

  onTextChanged() {
    this.onSearchChanged.emit(this.searchText);
  }

  onKeyPressed(event: KeyboardEvent) {
    if (KeyboardUtil.isEscapeKeyPress(event)) {
      this.clearSearch();
    }
  }

  clearSearch() {
    this.searchText = null;
    this.onTextChanged();
  }
}
