import { BaseModel } from '@sl/common/model/BaseModel';
import { VotingAnswerOption } from '@sl/common/model/VotingAnswerOption';

export class Voting extends BaseModel {
  public id: string;
  public questionText: string;
  public pollOverviewImage?: string;
  public type: VotingType;
  public state: VotingState;
  public answerImageStyle: VotingAnswerImageStyle;
  public startedAt: number;
  public durationSeconds: number;
  public endedAt: number;
  public resultChartType: VotingResultChartType;
  public answerOptions: Array<VotingAnswerOption>;
  public isQuiz: boolean;
  public isVotingAnonymous: boolean;
  public quizPoints?: number;
  public includeElapsedTimeInQuizPointsCalculation: boolean;
  public answerRestrictionRegex?: string; // Only set for open text votings
  public maxAnswersPerAttendee?: number; // Only set for open text votings
  public maxVotes?: number;
  public votesLeft?: number;

  constructor(json: any = null, id: string = '', questionText: string = '', type: VotingType = VotingType.SingleChoice, answerImageStyle: VotingAnswerImageStyle = VotingAnswerImageStyle.Small) {
    super(json);
    this.id = id;
    this.questionText = questionText;
    this.type = type;
    this.answerImageStyle = answerImageStyle;
  }
}

export enum VotingState {
  Prepared = 'prepared',
  Running = 'running',
  Finished = 'finished',
}

export enum VotingType {
  SingleChoice = 'single',
  MultipleChoice = 'multiple',
  OpenText = 'openText',
  Ranking = 'ranking',
  Photo = 'photo',
  PointsDistribution = 'pointsDistribution',
}

export enum VotingAnswerImageStyle {
  Large = 'large',
  Small = 'small',
}

export enum VotingResultChartType {
  Bar = 'bar',
  Column = 'column',
  PhotoGrid = 'photoGrid',
  Pie = 'pie',
  Grid = 'grid',
}
