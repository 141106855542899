import { Component } from '@angular/core';
import { SLConfig } from '@sl/config/SLConfig';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';
import { SLLogger } from '@sl/common/utils/SLLogger';

@Component({
  selector: 'debug-settings',
  templateUrl: 'debug-settings.component.html',
})
export class DebugSettingsComponent {
  readonly env = SLConfig.getEnvironment();
  readonly isProduction = this.env.production;

  constructor(private translateService: TranslateService) {}

  get fakePresentationRunning(): boolean {
    return SLConfig.getDebugConfig().fakePresentationRunning || false;
  }

  set fakePresentationRunning(value: boolean) {
    const debugConfig = SLConfig.getDebugConfig();
    debugConfig.fakePresentationRunning = value;
    SLConfig.updateDebugConfig(debugConfig);

    location.reload();
  }

  getCurrentWebservice() {
    return this.env.webservice.baseUri;
  }

  onWebserviceBaseUriChanged(data: MatSelectChange) {
    if (!SLConfig.getEnvironment().production) {
      const debugConfig = SLConfig.getDebugConfig();

      if (!debugConfig.environment) {
        debugConfig.environment = {};
      }

      if (!debugConfig.environment.webservice) {
        debugConfig.environment.webservice = {};
      }
      debugConfig.environment.webservice.baseUri = data.value;

      try {
        localStorage.clear();
      } catch (e) {
        SLLogger.warn("Couldn't clear local storage!");
      }
      SLConfig.updateDebugConfig(debugConfig);
      location.reload();
    }
  }
}
