import { Environment, IEnvironment } from './IEnvironment';
import { SlideLizardTheme } from '@sl/config/themes/SlideLizardTheme';
import { getFallbackTheme } from '@sl/config/themes/FallbackTheme';
import { getNeutralTheme } from '@sl/config/themes/NeutralTheme';
import { SlideLizardDarkTheme } from '@sl/config/themes/SlideLizardDarkTheme';
import { ThemeAnalytics } from '@sl/common/model/Theme';

export const ENVIRONMENT: IEnvironment = {
  name: Environment.Production,
  production: true,
  appVersion: '2.6.6',
  appVersionCode: 266,
  buildTimestamp: 0,
  webservice: {
    baseUri: 'https://service.slidelizard.at/api',
    apikey: 'web',
    contactGuesserServiceBaseUri: 'https://ciguesser.slidelizard.at/api',
    photoUploadBaseUri: 'https://slidelizardimagefunctions.azurewebsites.net/api/upload?code=yHXi42NyVvFpzbz8OqyXgK/6tPUGsS2bKig4J95snP24L2kgMp7JqQ==',
    avatarBaseUri: 'https://slidelizarddata.blob.core.windows.net/configuration/avatars/',
  },
  i18n: {
    defaultLanguage: 'en',
    supportedLanguages: ['en', 'de', 'fr', 'it', 'es', 'sk', 'hu', 'ro'],
  },
  theme: {
    default: SlideLizardTheme,
    defaultDark: SlideLizardDarkTheme,
    fallback: getFallbackTheme([{ name: ThemeAnalytics.GOOGLE_ANALYTICS, id: 'G-RV2DLQD96R' }]),
    neutral: getNeutralTheme([{ name: ThemeAnalytics.GOOGLE_ANALYTICS, id: 'G-RV2DLQD96R' }]),
  },
  authServerConfig: {
    issuer: 'https://auth.slidelizard.at',
    redirectUri: 'https://app.slidelizard.com/assets/oauth-redirect.html',
    silentRefreshRedirectUri: 'https://app.slidelizard.com/assets/oauth-redirect.html',
    clientId: 'web',
    scope: 'openid profile email',
    requireHttps: false,
  },
  homepageURL: 'https://slidelizard.com',
  audienceParticipationURL: 'attend.sl',
  baseDeepLinkURL: 'https://app.slidelizard.com',
  tracking: {
    enableRequestTimeTracking: false,
    allowedAnalyticsDomains: ['app.slidelizard.com', 'attend.sl'],
    error: [],
  },
  pushMessageServer: {
    baseUri: 'https://push.slidelizard.at',
    attendeeHubName: 'attendeehub',
  },
  liveStreaming: {
    jitsi: {
      domain: 'meet.jit.si',
    },
  },
  notSupportedBrowsers: {
    mobile: {
      safari: '<10',
      'android browser': '<64',
      samsung_internet: '<7',
      opera: '<41',
    },
    android: {
      firefox: '<68',
    },
    iOS: {
      firefox: '<20',
    },
    'internet explorer': '<=11',
    edge: '<18',
    firefox: '<60',
    chrome: '<64',
  },
  helpPages: {
    msTeams: {
      en: 'https://help.slidelizard.com/d/en-microsoft-teams-addin',
      de: 'https://help.slidelizard.com/d/de-microsoft-teams-addin',
    },
  },
};

export default ENVIRONMENT;
