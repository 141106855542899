<form #form="ngForm" fxLayout="column" fxLayoutAlign="center start">
  <ng-template ngFor let-answer [ngForOf]="answers">
    <p class="previousAnswer foreground-text1-color-primary background-color-primary" [@anim]="answer">{{ answer }}</p>
  </ng-template>

  <mat-form-field *ngIf="canAddAnswer(); else maxAnswersExceededInfo" class="foreground-text1-color-background" appearance="outline">
    <mat-label class="foreground-text2-color-background">{{ 'presentation.votings.open-text.add-answer' | translate }} </mat-label>
    <input
      type="text"
      matInput
      [(ngModel)]="newAnswer"
      name="newAnswer"
      #answerTextInput="ngModel"
      (keyup.enter)="save()"
      autofocus
      [disabled]="disabled || sendVoteState == ProgressState.Loading || sendVoteState == ProgressState.Empty"
      autocapitalize="sentences"
      autocomplete="off"
      maxlength="25"
      [errorStateMatcher]="errorMatcher"
      [pattern]="activeVoting.answerRestrictionRegex"
      [allowedInputPattern]="activeVoting.answerRestrictionRegex"
    />
    <mat-error *ngIf="answerTextInput.invalid">
      {{ 'presentation.votings.open-text.error-invalid-input' | translate }}
    </mat-error>

    <mat-hint *ngIf="!disabled" class="foreground-text2-color-background" align="start">
      <ng-template [ngIf]="activeVoting?.maxAnswersPerAttendee" [ngIfElse]="addUnlimitedAnswers">
        <translate-count-string
          *ngIf="previousVotingAnswer?.answers?.length > 0; else noAnswerSentYet"
          [count]="activeVoting?.maxAnswersPerAttendee - previousVotingAnswer?.answers?.length"
          count1Text="presentation.votings.open-text.add-1-more-answer"
          countMultipleText="presentation.votings.open-text.add-x-more-answers"
        >
        </translate-count-string>

        <ng-template #noAnswerSentYet>
          <translate-count-string [count]="activeVoting?.maxAnswersPerAttendee" count1Text="presentation.votings.open-text.1-answer-possible" countMultipleText="presentation.votings.open-text.x-answers-possible"> </translate-count-string>
        </ng-template>
      </ng-template>
      <ng-template #addUnlimitedAnswers>
        {{ 'presentation.votings.open-text.add-unlimited-answers' | translate }}
      </ng-template>
    </mat-hint>
  </mat-form-field>

  <ng-template #maxAnswersExceededInfo>
    <p class="foreground-text2-color-background" style="font-style: italic;">
      {{ 'presentation.votings.open-text.max-answers-exceeded' | translate: { maxAnswers: activeVoting?.maxAnswersPerAttendee } }}
    </p>
  </ng-template>
</form>
