import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss'],
})
export class HorizontalBarChartComponent {
  @HostBinding('class.verticalCenterItems')
  verticallyCenterItems = true;

  _chartData: Array<ChartValue>;

  @Input()
  set chartData(value: Array<ChartValue>) {
    this._chartData = value;
    this.verticallyCenterItems = this._chartData?.length < 5;
  }

  get chartData() {
    return this._chartData;
  }
}
