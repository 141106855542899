import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorTrackerService } from '@sl/common/services/error-handling/ErrorTrackerService';

@Injectable()
export class SlideLizardErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any): void {
    const trackingService = this.injector.get(ErrorTrackerService);
    trackingService.trackError(error);

    console.error(error);
  }
}
