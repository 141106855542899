export enum ExternalContentAvailability {
  BEFORE_PRESENTATION = 'beforePresentation',
  BEGIN_PRESENTATION = 'beginPresentation',
  AFTER_PRESENTATION = 'afterPresentation',
}

export enum Accessibility {
  UNRESTRICTED = 'unrestricted',
  EMAIL_REQUIRED = 'emailRequired',
}
