import { Voting, VotingState } from '@sl/common/model/Voting';
import { PresentationInstanceState, AskQuestionData } from '@sl/common/model';
import { VotingResult } from '@sl/common/model/VotingResult';
import { BlockingState } from '@sl/common/services/endpoint/dto/AttendanceInfo';

export enum ShowHideAction {
  Show,
  Hide,
}

export enum QuizResultAnswerState {
  Correct = 'correct',
  Incorrect = 'incorrect',
}

export class VotingStateChangedEvent {
  votingId: string;
  newState: VotingState;
  voting?: Voting;

  constructor(votingId: string, newState: VotingState, voting?: Voting) {
    this.votingId = votingId;
    this.newState = newState;
    this.voting = voting;
  }
}

export class PresentationInstanceStateChangedEvent {
  state: PresentationInstanceState;

  constructor(newState: PresentationInstanceState) {
    this.state = newState;
  }
}

export class SlideChangedEvent {
  slideNumber: number;
  animationNumber: number;

  constructor(slideNumber: number, animationNumber: number) {
    this.slideNumber = slideNumber;
    this.animationNumber = animationNumber;
  }
}

export class LastAttendeeMessageChangedEvent {
  lastAttendeeMessageId: string;

  constructor(lastAttendeeMessageId: string) {
    this.lastAttendeeMessageId = lastAttendeeMessageId;
  }
}

export class NewInstanceAvailableEvent {
  newInstanceId: string;

  constructor(newInstanceId: string) {
    this.newInstanceId = newInstanceId;
  }
}

export class ExternalContentVisibilityChangedEvent {
  constructor(public externalContentId: string, public action: ShowHideAction) {}
}

export class VotingResultVisibilityChangedEvent {
  constructor(public votingResult: VotingResult, public action: ShowHideAction) {}
}

export class QuestionVisibilityChangedEvent {
  constructor(public question: AskQuestionData, public action: ShowHideAction) {}
}

export class BlockingStateChangedEvent {
  constructor(public blockingStates: BlockingState[]) {}
}

export class MaxReachableQuizPointsChangedEvent {
  constructor(public maxReachableQuizPoints: number) {}
}

export class QuizResultChangedEvent {
  constructor(public voting: Voting, public gainedQuizPoints: number, public answerState: QuizResultAnswerState) {}
}

export class QuestionRankingChangedEvent {
  constructor(public questionId: string, public upVotes: number) {}
}
