import { IAnalyticsTracker } from './IAnalyticsTracker';

declare const fbq: Function;

export class FacebookPixelTracker implements IAnalyticsTracker {
  constructor(private trackingId: string) {}

  init(): void {
    fbq('init', this.trackingId);
  }

  trackPageView(page: string): void {
    fbq('trackSingle', this.trackingId, 'PageView', { name: page });
  }

  trackEvent(event: string, param: string | number | object): void {
    fbq('trackSingleCustom', this.trackingId, event, param);
  }
}
