import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'app/AppModule';
import { SLConfig } from '@sl/config/SLConfig';

if (SLConfig.getEnvironment().production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
