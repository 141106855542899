<div style="width: 100vw; height: 100vh;" fxLayout="column" fxLayoutAlign="center center">
  <!--  <img src="/assets/img/app_icon.svg" class="loading-rotate"/>-->
  <img *ngIf="imageUrl" class="image" [src]="imageUrl"/>

  <mat-progress-spinner
    color="accent"
    diameter="96"
    strokeWidth="10"
    mode="indeterminate"></mat-progress-spinner>
</div>
