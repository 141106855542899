import { Theme, ThemeAnalytics } from '@sl/common/model/Theme';
import { AuthProviderKey } from '@sl/common/services/endpoint/dto/LoginPayload';

export function getNeutralTheme(analytics?: ThemeAnalytics[]): Theme {
  return {
    colors: {
      primaryColor: 'grey',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'grey',
      text1OnSecondaryColor: '#FFF',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#424242',
      text2OnSurfaceColor: '#7A7A7A',

      backgroundColor: '#F5F5F5',
      text1OnBackgroundColor: 'var(--text1OnSurfaceColor)',
      text2OnBackgroundColor: 'var(--text2OnSurfaceColor)',

      dialogBackgroundColor: 'var(--backgroundColor)',
      text1OnDialogBackgroundColor: 'var(--text1OnBackgroundColor)',
      text2OnDialogBackgroundColor: 'var(--text2OnBackgroundColor)',

      errorColor: '#f44336',
      text1OnErrorColor: 'white',

      toolbarBackground: 'var(--primaryColor)',
      text1OnToolbarColor: 'var(--text1OnPrimaryColor)',

      headerBackground: 'var(--primaryColor)',
      text1OnHeaderColor: 'var(--text1OnPrimaryColor)',

      highlightColor: '#DEDEDE',
      dividerColor: 'var(--text2OnSurfaceColor)',
    },
    fonts: [],
    logoUrl: '',
    analytics,
    removeSlideLizardBranding: false,
    showHelpOthersConnect: true,
    allowAnonymousQuestions: true,
    sendVoteOnlyAfterConfirmation: false,
    availableAuthProviders: [AuthProviderKey.Google, AuthProviderKey.Facebook, AuthProviderKey.SlideLizard],
  };
}
