import { Component } from '@angular/core';
import { DeepLinkUtils } from '@sl/common/utils/DeepLinkUtils';
import { BaseDialogComponent } from '@sl/common/components/BaseDialogComponent';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'share-connection-code-dialog',
  templateUrl: 'share-connection-code-dialog.component.html',
})
export class ShareConnectionCodeDialogComponent extends BaseDialogComponent<ShareConnectionCodeDialogComponent> {
  qrCodeURL = '';
  participationURL = '';
  qrCodeSize = '200';

  constructor(dialogRef: MatDialogRef<ShareConnectionCodeDialogComponent>) {
    super(dialogRef);
  }

  set connectionCode(connectionCode: string) {
    this.participationURL = DeepLinkUtils.createAudienceParticipationURL(connectionCode);

    let qrCodeParticipationURL;
    if (!this.participationURL.startsWith('http')) {
      // add http so all qr code readers interpret it as URL
      qrCodeParticipationURL = 'http://' + this.participationURL;
    } else {
      qrCodeParticipationURL = this.participationURL;
    }

    this.setQRCodeUrl(qrCodeParticipationURL);
  }

  set conferenceId(conferenceId: string) {
    this.setQRCodeUrl(DeepLinkUtils.createConferenceDeepLink(conferenceId));
  }

  private setQRCodeUrl(url: string) {
    this.qrCodeURL = 'https://qrcodesvc.slidelizard.at/api/v1/qrcode?content='+url+'&pointSizeInPx=10';
  }
}
