import { Component, HostBinding, Input } from '@angular/core';
import { VotingResult } from '@sl/common/model/VotingResult';
import { VotingResultChartType, VotingType } from '@sl/common/model/Voting';
import { MultiLanguageStringPipe } from '@sl/common/pipes/MultiLanguageStringPipe';
import { IWorkflowStepComponent } from '@sl/common/components/IWorkflowStepComponent';

@Component({
  selector: 'sl-voting-result',
  templateUrl: './voting-result.component.html',
  styleUrls: ['./voting-result.component.scss'],
  host: { class: 'background-color-background' },
})
export class VotingResultComponent implements IWorkflowStepComponent {
  VotingResultChartType = VotingResultChartType;

  @HostBinding('class.background-color-dialogBackground')
  hostBackground = true;

  @Input()
  showVotingTitle = true;

  private _result: VotingResult;

  @Input()
  set result(value: VotingResult) {
    this._result = value;
    this.calc();
  }

  get result() {
    return this._result;
  }

  chartData: Array<ChartValue>;

  constructor(private multiLangStringPipe: MultiLanguageStringPipe) {}

  calc() {
    if (this.result && this.result.voting) {
      if (this.result.voting.type === VotingType.SingleChoice || this.result.voting.type === VotingType.PointsDistribution || this.result.voting.type === VotingType.MultipleChoice) {
        const sumVotes = this.result.statistic.votes.reduce((sum, vote) => sum + vote.voteCount, 0);
        const totalVoters = this.result.statistic.totalVoters;
        const isMultipleChoice = this.result.voting.type === VotingType.MultipleChoice;

        this.chartData = this.result.statistic.votes
          .map((vote) => {
            const answerOption = this.result.voting.answerOptions.find((answerOption) => answerOption.id == vote.answerOptions_id);

            return answerOption
              ? {
                  name: this.multiLangStringPipe.transform(answerOption.text),
                  value: this.calcVotePercentage(vote.voteCount, isMultipleChoice ? totalVoters : sumVotes),
                  imageUrl: answerOption.imageUrl,
                  isCorrectAnswer: this.result.voting.isQuiz ? answerOption.isCorrectAnswer : undefined,
                }
              : null;
          })
          .filter((chartValue) => chartValue != null);
      } else if (this.result.voting.type === VotingType.OpenText) {
        this.chartData = [];
        for (const openTextVote of this.result.statistic.openTextVotes) {
          const value = this.chartData.find((cv) => cv.name.toLowerCase() === openTextVote.answer.toLowerCase().trim());
          if (value) {
            value.value += 1;
          } else {
            this.chartData.push({ value: 1, name: openTextVote.answer.trim() });
          }
        }
      } else if (this.result.voting.type === VotingType.Photo) {
        this.chartData = this.result.statistic.photoVotes.map((pv) => ({
          name: '',
          value: 1,
          imageUrl: pv.imageUrl,
        }));
      }
    }
  }

  private calcVotePercentage(voteCount: number, total: number) {
    return total > 0 ? Math.round((voteCount / total) * 100) : 0;
  }

  canSkip(): boolean {
    return true;
  }
}
