import { Component, OnInit } from '@angular/core';
import { BaseVideoPlayerComponent } from '@sl/common/components/video/base-video-player.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';

@Component({
  selector: 'sl-youtube-video-player',
  templateUrl: './youtube-video-player.component.html',
  styleUrls: ['./youtube-video-player.component.scss'],
})
export class YoutubeVideoPlayerComponent extends BaseVideoPlayerComponent {
  public static readonly VIDEO_URL_REGEXES = [new RegExp(/https:\/\/www\.youtube\.com\/watch?.*v=([^?&\/]*).*/), new RegExp(/https:\/\/youtu\.be\/([^?\/]*)[\/?]?.*/), new RegExp(/https:\/\/www\.youtube\.com\/embed\/([^?\/]*)[\/?]?.*/)];

  saveLiveStreamUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  protected onSourceSet() {
    super.onSourceSet();

    for (let regex of YoutubeVideoPlayerComponent.VIDEO_URL_REGEXES) {
      const match = regex.exec(this.source);
      if (match) {
        const videoId = match[1];
        const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&showinfo=0`;
        this.saveLiveStreamUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
        return;
      }
    }

    this.saveLiveStreamUrl = null;
  }
}
