import { IAnalyticsTracker } from '@sl/common/services/analytics/IAnalyticsTracker';
import { Injectable } from '@angular/core';
import { SLAnalyticsService } from '@sl/common/services/analytics';
import { PresenterAnalyticsService } from '@sl/pres/services/PresenterAnalyticsService';

/**
 * Use for combined (INTERNAL & EXTERNAL) analytics reporting. All events are sent to SLAnalyticsService and PresenterAnalyticsService.
 */
@Injectable({ providedIn: 'root' })
export class PresentationAnalyticsService implements IAnalyticsTracker {
  constructor(private slAnalyticsService: SLAnalyticsService, private userAnalyticsService: PresenterAnalyticsService) {}

  init(): void {
    this.slAnalyticsService.init();
    this.userAnalyticsService.init();
  }

  trackEvent(event: string, param?: number | string | object, label?: string): void {
    this.slAnalyticsService.trackEvent(event, param);
    this.userAnalyticsService.trackEvent(event, param);
  }

  trackPageView(page: string): void {
    this.slAnalyticsService.trackPageView(page);
    this.userAnalyticsService.trackPageView(page);
  }
}
