import { AdvancedHttpClient, ParamMap } from '@sl/common/services/httpclient/AdvancedHttpClient';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { SLConfig } from '@sl/config/SLConfig';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError as _throw } from 'rxjs';
import { Environment } from '@sl/config/environments/IEnvironment';

export abstract class BaseDataService extends AdvancedHttpClient {
  protected constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected get baseUri(): string {
    return SLConfig.getEnvironment().webservice.baseUri;
  }

  protected get defaultHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  protected get defaultQueryParams(): HttpParams {
    return new HttpParams().set('apikey', SLConfig.getEnvironment().webservice.apikey);
  }

  protected get defaultVersion(): string {
    return 'v16';
  }

  protected async getDynamicDefaultHeaders(): Promise<HttpHeaders> {
    return new HttpHeaders();
  }

  protected request<T>(method: string, url: string, body?: any, headers?: ParamMap, queryParams: ParamMap = {}, version?: string): Observable<T> {
    return super.request(method, url, body, headers, queryParams, version).pipe(
      map((value: T) => value),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 503) {
          // Indicates that server is currently under maintenance => dispatch event to show error message
          window.dispatchEvent(new CustomEvent('server-maintenance-error'));
        }
        return _throw(error);
      })
    );
  }

  protected isMockingEnabled() {
    return false;//SLConfig.getEnvironment().name === Environment.Local;
  }
}
