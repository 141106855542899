import { Injectable, OnDestroy } from '@angular/core';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { SLUtils } from '@sl/common/utils/SLUtils';
import { UserDataService } from '@sl/common/services/endpoint/UserDataService';
import { ContactInfo, EmailAddress } from '@sl/common/model';
import { QUERY_PARAMS } from '../../../RouteConfig';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Utils } from '@sl/common/utils/Utils';

export interface MSTeamsTabSettings {
  url: string;
  websiteUrl?: string;
}

@Injectable({ providedIn: 'root' })
export class MSTeamsService implements OnDestroy {
  private microsoftTeams: typeof microsoftTeams;

  private initialized$ = new ReplaySubject<boolean>(1);
  private initialized = false;

  private unsubscribe$ = new Subject<void>();
  private context?: microsoftTeams.Context;

  constructor(private authService: AuthService, private userDataService: UserDataService) {}

  public init(callback?: () => void) {
    if (!this.initialized) {
      import('@microsoft/teams-js').then((microsoftTeams) => {
        this.microsoftTeams = microsoftTeams;

        this.microsoftTeams.initialize(async () => {
          this.initialized = true;
          this.initialized$.next(true);
          this.context = await this.getContext();

          this.unsubscribe$.next();
          callback?.();

          this.syncUserEmail(this.context);
        });
      });
    } else {
      callback();
    }
  }

  public login(options: { url: string; successCallback?: (result?: string) => void; failureCallback?: (reason?: string) => void }) {
    if (this.initialized) {
      this.microsoftTeams.authentication.authenticate({
        url: options.url,
        width: 600,
        height: 535,
        successCallback: (result?: string) => {
          options.successCallback?.(result);
        },
        failureCallback: (reason?: string) => {
          options.failureCallback?.(reason);
        },
      });
    }
  }

  public notifyAuthSuccess(result?: string) {
    if (this.initialized) {
      this.microsoftTeams.authentication.notifySuccess(result);
    }
  }

  public notifyAuthFailure(reason?: string) {
    if (this.initialized) {
      this.microsoftTeams.authentication.notifyFailure(reason);
    }
  }

  public getContext() {
    if (this.initialized) {
      return new Promise<microsoftTeams.Context>((resolve) => {
        this.microsoftTeams.getContext((context) => resolve(context));
      });
    }
    return null;
  }

  public setConfigurationPageSaveHandler(getSettings: () => MSTeamsTabSettings): void {
    if (this.initialized) {
      this.microsoftTeams.settings.registerOnSaveHandler((evt) => {
        this.setSettings(getSettings());
        evt.notifySuccess();
      });
    }
  }

  public setSettings(settings: MSTeamsTabSettings) {
    if (this.initialized) {
      const url = Utils.ensureHttps(settings.url);
      const contentUrl = `${url}?${QUERY_PARAMS.IS_IN_MS_TEAMS}=true&${QUERY_PARAMS.LANG}={locale}`;
      const websiteUrl = Utils.ensureHttps(settings.websiteUrl ?? url);

      this.microsoftTeams.settings.setSettings(
        {
          websiteUrl: websiteUrl,
          contentUrl: contentUrl,
          entityId: 'slidelizard',
          suggestedDisplayName: 'SlideLizard',
        },
        (status, reason) => SLLogger.log(`Saving settings: ${status} (${reason})`)
      );
    }
  }

  public getSettings(callback: (settings: microsoftTeams.settings.Settings) => void) {
    if (this.initialized) {
      this.microsoftTeams.settings.getSettings(callback);
    }
  }

  public setTabUrl(url: string) {
    // if (this.initialized) {
    //   const contentUrl = `${url}?${QUERY_PARAMS.IS_IN_MS_TEAMS}=true&${QUERY_PARAMS.LANG}={locale}`;
    //   const websiteUrl = `${url}`;
    //
    //   this.microsoftTeams.setFrameContext({
    //     websiteUrl: websiteUrl,
    //     contentUrl: contentUrl,
    //   });
    // }
  }

  public showDialog() {
    if (this.initialized) {
      let taskInfo: any = {};
      taskInfo.url = `${location.href}`;
      taskInfo.title = 'SlideLizard';
      taskInfo.height = 600;
      taskInfo.width = 700;
      const submitHandler = (err: any, result: any) => {
        console.log(`Submit handler - err: ${err}`);
        console.log(`Submit handler - result\rName: ${result.name}\rEmail: ${result.email}\rFavorite book: ${result.favoriteBook}`);
      };
      this.microsoftTeams.tasks.startTask(taskInfo, submitHandler);
    }
  }

  public setConfigurationPageValidity(valid: boolean): void {
    if (this.initialized) {
      this.microsoftTeams.settings.setValidityState(valid);
    }
  }

  public isInMSTeams() {
    return this.initialized;
  }

  public isInitialized$() {
    return this.initialized$.asObservable();
  }

  private syncUserEmail(context: microsoftTeams.Context) {
    const msTeamsUserEmail = context.loginHint;

    if (msTeamsUserEmail) {
      this.authService.user.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
        if (user?.isAnonymous) {
          this.updateUserEmail(msTeamsUserEmail);
        }
      });
    }
  }

  private updateUserEmail(newEmail: string) {
    const existingEmail = this.authService.currentUser?.contactInfo?.emailAddresses?.[0]?.emailAddress;

    if (existingEmail !== newEmail) {
      const newContactInfo = SLUtils.clone(this.authService.currentUser.contactInfo) || new ContactInfo();

      if (newContactInfo.emailAddresses == null || newContactInfo.emailAddresses.length === 0) {
        newContactInfo.emailAddresses = [];
        newContactInfo.emailAddresses.push(new EmailAddress('work', ''));
      }
      newContactInfo.emailAddresses[0].emailAddress = newEmail;

      this.userDataService.updateUserMeContactInfo(newContactInfo).subscribe(
        (_) => this.authService.loadUserMeDelayed(),
        (_) => SLLogger.warn('Changing user email failed (triggered MS Teams integration)!')
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isDarkTheme() {
    return this.isInMSTeams() && this.context?.theme === 'dark';
  }
}
