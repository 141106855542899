<form *ngIf="newCI" #form="ngForm" fxLayout="column" fxLayoutAlign="start center">
  <mat-progress-bar *ngIf="loadingState == ProgressState.Loading" mode="query" style="margin-top: 12px;"></mat-progress-bar>

  <ng-container *ngIf="loadingState == ProgressState.Content">
    <login *ngIf="!currentUser || currentUser.isAnonymous" [displayStyle]="forceEditForm || hasUserData || (currentUser && !currentUser.isAnonymous) ? 'small' : 'large'" (onLoginSuccessfulEvent)="onLoginSuccessful($event)"></login>

    <ng-container *ngIf="currentUser?.isAnonymous">
      <hr *ngIf="themeService.currentTheme.availableAuthProviders?.length > 0" style="width: 80%;" />

      <div class="foreground-text2-color-surface" fxLayout="row" fxLayoutAlign="center center" style="margin: 12px 0 16px;">
        <p class="mat-h3" style="margin: 0;" [innerHTML]="'profile.enter-data' | translate"></p>
        <div *ngIf="isDesktop" inlineSVG="assets/img/ic_information_outline.svg" style="margin: 4px 0 0 4px;" [matTooltip]="'profile.enter-data-info' | translate"></div>
      </div>
    </ng-container>

    <div class="input-row" fxLayout="row" fxLayoutAlign="start center" *ngIf="enableSelectableContactInfo === true && !!selectableContactInfos && selectableContactInfos.length > 0">
      <div class="input-icon" inlineSVG="/assets/img/payment-details-form/name.svg"></div>
      <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
        <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.selectci' | translate }}</mat-placeholder>
        <mat-select
          matInput
          [value]="newCI"
          [ngModel]="newCI"
          (ngModelChange)="fullNameChanged($event)"
          name="fullname"
          #fullname="ngModel"
          [errorStateMatcher]="matcher"
          [required]="false"
          [disabled]="submitState == ProgressState.Loading"
          (focus)="onInputFocused($event)">
          <mat-option *ngFor="let selectableCi of selectableContactInfos" [value]="selectableCi.id">{{selectableCi.lastName}} {{selectableCi.firstName}}</mat-option>
          <mat-option [value]="null">{{ 'profile.other' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <ng-container *ngFor="let contactField of contactInfoFields">
      <div *ngIf="contactField.name == ContactInfoField.ImageUrl" class="input-row" fxLayout="column" fxLayoutAlign="start center">
        <ng-container>
          <img *ngIf="newCI?.imageUrl" class="avatar" [src]="newCI.imageUrl" />

          <button mat-raised-button color="primary" (click)="openAvatarGrid()" [disabled]="disabled || submitState == ProgressState.Loading || submitState == ProgressState.Empty">{{ 'profile.pick-avatar' | translate }}</button>
        </ng-container>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.Firstname" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/name.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.firstname' | translate }}</mat-placeholder>
          <input
            matInput
            [(ngModel)]="newCI.firstName"
            name="firstName"
            #firstName="ngModel"
            (focus)="onInputFocused($event)"
            [disabled]="submitState == ProgressState.Loading || (fullname != null && fullname.value == null)"
            capitalize
            pattern="^[^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]*$"
            [errorStateMatcher]="matcher"
            minlength="2"
            maxlength="64"
            [required]="contactField.required"
          />
          <mat-error *ngIf="firstName.invalid">
            {{ 'profile.firstname-invalid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.Lastname" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/name.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.lastname' | translate }}</mat-placeholder>
          <input
            matInput
            [(ngModel)]="newCI.lastName"
            name="lastName"
            #lastName="ngModel"
            (focus)="onInputFocused($event)"
            [disabled]="submitState == ProgressState.Loading || (fullname != null && fullname.value == null)"
            capitalize
            pattern="^[^0-9_!¡?÷?¿\\+=@#$%ˆ&*(){}|~<>;:[\]]*$"
            [errorStateMatcher]="matcher"
            minlength="2"
            maxlength="64"
            [required]="contactField.required"
          />
          <mat-error *ngIf="lastName.invalid">
            {{ 'profile.lastname-invalid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.Email" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/email.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.email' | translate }}</mat-placeholder>
          <input
            matInput
            type="email"
            [(ngModel)]="newCI.emailAddresses[0].emailAddress"
            name="email"
            #email="ngModel"
            pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$"
            emailValid
            maxlength="320"
            (focus)="onInputFocused($event)"
            [disabled]="!currentUser?.isAnonymous || submitState == ProgressState.Loading || (fullname != null && fullname.value == null)"
            [errorStateMatcher]="matcher"
            [required]="contactField.required"
          />
          <mat-progress-spinner *ngIf="email.pending" matSuffix diameter="22" strokeWidth="3" mode="indeterminate"></mat-progress-spinner>
          <mat-error *ngIf="email.invalid && email.errors?.pattern">
            {{ 'profile.email-invalid' | translate }}
          </mat-error>
          <mat-error *ngIf="email.invalid && email.errors?.emailInvalid">
            {{ 'profile.email-invalid-provider' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.Company" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/company.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.company' | translate }}</mat-placeholder>
          <input matInput [(ngModel)]="newCI.company" name="company" maxlength="320" (focus)="onInputFocused($event)" [disabled]="submitState == ProgressState.Loading" [errorStateMatcher]="matcher" [required]="contactField.required" />
        </mat-form-field>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.Position" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/company.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.position' | translate }}</mat-placeholder>
          <input matInput [(ngModel)]="newCI.position" name="position" maxlength="320" (focus)="onInputFocused($event)" [disabled]="submitState == ProgressState.Loading" [errorStateMatcher]="matcher" [required]="contactField.required" />
        </mat-form-field>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.City" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/address.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.city' | translate }}</mat-placeholder>
          <input matInput [(ngModel)]="newCI.addresses[0].city" name="city" maxlength="320" (focus)="onInputFocused($event)" [disabled]="submitState == ProgressState.Loading" [errorStateMatcher]="matcher" [required]="contactField.required" />
        </mat-form-field>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.Street" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/address.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.street' | translate }}</mat-placeholder>
          <input matInput [(ngModel)]="newCI.addresses[0].street" name="street" maxlength="320" (focus)="onInputFocused($event)" [disabled]="submitState == ProgressState.Loading" [errorStateMatcher]="matcher" [required]="contactField.required" />
        </mat-form-field>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.Zip" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/address.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.zip' | translate }}</mat-placeholder>
          <input matInput [(ngModel)]="newCI.addresses[0].zip" name="zip" maxlength="320" (focus)="onInputFocused($event)" [disabled]="submitState == ProgressState.Loading" [errorStateMatcher]="matcher" [required]="contactField.required" />
        </mat-form-field>
      </div>

      <div *ngIf="contactField.name == ContactInfoField.PhoneNumber" class="input-row" fxLayout="row" fxLayoutAlign="start center">
        <div class="input-icon" inlineSVG="/assets/img/payment-details-form/name.svg"></div>
        <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
          <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.phone-number' | translate }}</mat-placeholder>
          <input matInput [(ngModel)]="newCI.phoneNumbers[0].phoneNumber" name="phoneNumber" maxlength="320" (focus)="onInputFocused($event)" [disabled]="submitState == ProgressState.Loading" [errorStateMatcher]="matcher" [required]="contactField.required" />
        </mat-form-field>
      </div>

      <ng-container *ngFor="let i of additionalInformation">
        <div class="input-row" fxLayout="row" fxLayoutAlign="start center" *ngIf="contactField.name === 'additionalInformation' + i">
          <div class="input-icon" inlineSVG="/assets/img/payment-details-form/name.svg"></div>
          <mat-form-field class="full-width-input foreground-text1-color-dialogBackground">
            <mat-placeholder class="foreground-text2-color-dialogBackground">{{ 'profile.additionalInformation.' + i | translate }}</mat-placeholder>
            <input matInput [(ngModel)]="newCI['additionalInformation' + i]" [name]="'additionalInformation' + i" maxlength="500" (focus)="onInputFocused($event)" [required]="contactField.required" />

            <mat-hint>
              <ng-container *ngIf="hasAdditionalInformationDescription(i)">
                {{ 'profile.additionalInformation.' + i + '-description' | translate }}
              </ng-container>
            </mat-hint>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>

    <accept-terms #acceptTerms [style.opacity]="form.dirty ? 1 : 0" textClass="foreground-text1-color-dialogBackground" [disabled]="submitState == ProgressState.Loading"></accept-terms>

    <mat-card fxFlexAlign="end" fxLayout="row" fxLayoutAlign="start center" class="bottom-bar-container">
      <div class="bottom-bar-text-container">
        <p *ngIf="submitState == ProgressState.Error" class="foreground-color-error">{{ 'profile.error-save-failed' | translate }}</p>
      </div>

      <mat-progress-spinner *ngIf="submitState == ProgressState.Loading" class="inline-progress" diameter="26" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>

      <button *ngIf="submitState != ProgressState.Loading" class="send-button" mat-button [disabled]="(!form.dirty || !form.valid) && !hasUnsavedData()" (click)="acceptTerms.hasAcceptedTerms ? save() : acceptTerms.showRequired()">{{ (submitState == ProgressState.Error ? 'retry' : 'save') | translate }}</button>
    </mat-card>
  </ng-container>
</form>
