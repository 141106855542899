import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Path } from '@sl/common/utils/Path';
import { map, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';

export type ParamMap = { [s: string]: string };

export abstract class AdvancedHttpClient {
  constructor(protected httpClient: HttpClient) {}

  protected get baseUri(): string {
    throw new Error('No base URI specified for the HttpClient! Please use the BaseUri decorator');
  }

  protected get defaultHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  protected abstract async getDynamicDefaultHeaders(): Promise<HttpHeaders>;

  protected get defaultQueryParams(): HttpParams {
    return new HttpParams();
  }

  protected get defaultVersion(): string {
    throw new Error('No default version specified for the HttpClient!');
  }

  protected get<T>(url: string, headers?: ParamMap, queryParams?: ParamMap, version?: string) {
    return this.request<T>('GET', url, null, headers, queryParams, version);
  }

  protected put<T>(url: string, body: any, headers?: ParamMap, queryParams?: ParamMap, version?: string) {
    return this.request<T>('PUT', url, body, headers, queryParams, version);
  }

  protected post<T>(url: string, body: any, headers?: ParamMap, queryParams?: ParamMap, version?: string) {
    return this.request<T>('POST', url, body, headers, queryParams, version);
  }

  protected delete<T>(url: string, headers?: ParamMap, queryParams?: ParamMap, version?: string) {
    return this.request<T>('DELETE', url, null, headers, queryParams, version);
  }

  protected request<T>(method: string, url: string, body?: any, headers?: ParamMap, queryParams: ParamMap = {}, version?: string) {
    return from(this.getDynamicDefaultHeaders()).pipe(
      mergeMap((dynamicDefaultHeaders) => {
        const finalVersion: string = version ? version : this.defaultVersion;
        const finalUrl: Path = this.buildUrl(finalVersion, url);

        let finalHeaders;
        if (headers) {
          finalHeaders = this.mergeHeaders(new HttpHeaders(headers), this.mergeHeaders(dynamicDefaultHeaders, this.defaultHeaders));
        } else {
          finalHeaders = this.mergeHeaders(dynamicDefaultHeaders, this.defaultHeaders);
        }
        const finalQueryParams = this.mergeQueryParams(new HttpParams({ fromObject: queryParams }), this.defaultQueryParams);

        return this.httpClient
          .request<T>(method, finalUrl.toString(), {
            headers: finalHeaders,
            params: finalQueryParams,
            body: body,
            responseType: 'json',
            observe: 'response',
          })
          .pipe(map((event) => event.body));
      })
    );
  }

  protected buildUrl(version: string, url: string) {
    if (version) {
      return new Path(this.baseUri).combine(version, url);
    }
    return new Path(this.baseUri).combine(url);
  }

  private mergeHeaders(overridingHeaders: HttpHeaders, baseHeaders: HttpHeaders) {
    let mergedHeaders = new HttpHeaders();

    if (baseHeaders) {
      for (const h of baseHeaders.keys()) {
        mergedHeaders = mergedHeaders.append(h, baseHeaders.get(h));
      }
    }

    if (overridingHeaders) {
      for (const h of overridingHeaders.keys()) {
        if (mergedHeaders.has(h)) {
          const value = overridingHeaders.get(h);
          if (value != undefined && value !== '') {
            mergedHeaders = mergedHeaders.set(h, value);
          } else {
            mergedHeaders = mergedHeaders.delete(h);
          }
        } else {
          mergedHeaders = mergedHeaders.append(h, overridingHeaders.get(h));
        }
      }
    }

    return mergedHeaders;
  }

  private mergeQueryParams(overridingQueryParams: HttpParams, baseQueryParams: HttpParams) {
    let mergedQueryParams = new HttpParams();

    if (baseQueryParams) {
      for (const qp of baseQueryParams.keys()) {
        mergedQueryParams = mergedQueryParams.append(qp, baseQueryParams.get(qp));
      }
    }

    if (overridingQueryParams) {
      for (const qp of overridingQueryParams.keys()) {
        if (mergedQueryParams.has(qp)) {
          mergedQueryParams = mergedQueryParams.set(qp, overridingQueryParams.get(qp));
        } else {
          mergedQueryParams = mergedQueryParams.append(qp, overridingQueryParams.get(qp));
        }
      }
    }

    return mergedQueryParams;
  }
}
