import { BaseAnalyticsService } from '@sl/common/services/analytics/BaseAnalyticsService';
import { IAnalyticsTracker } from '@sl/common/services/analytics/IAnalyticsTracker';
import { Injectable } from '@angular/core';

/**
 * Use for EXTERNAL analytics reporting, so the presenter can track his audience. Configured via Theme.
 */
@Injectable({ providedIn: 'root' })
export class PresenterAnalyticsService extends BaseAnalyticsService {
  private trackers: IAnalyticsTracker[];

  initWithTrackers(trackers: IAnalyticsTracker[]) {
    this.trackers = trackers;
    this.init();
  }

  public reset() {
    super.reset();
    this.trackers = null;
  }

  protected getTrackers(): IAnalyticsTracker[] {
    return this.trackers;
  }
}
