import { BaseModel } from '@sl/common/model/BaseModel';

export class AttendeeMessage extends BaseModel {
  public type: AttendeeMessageType = AttendeeMessageType.Text;
  public displayType: AttendeeMessageDisplayType = AttendeeMessageDisplayType.Dialog;
  public text: string;
}

export enum AttendeeMessageDisplayType {
  SlideOverlay = 'slideOverlay',
  Dialog = 'dialog',
}

export enum AttendeeMessageType {
  Text = 'text',
  Image = 'image',
}
