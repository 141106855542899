import { Component, ViewChild } from '@angular/core';
import { BaseOverlayComponent } from '@sl/common/components/BaseOverlayComponent';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData } from '@sl/common/components/confirm-dialog/ConfirmDialogComponent';
import { EditContactInfoComponent } from '@sl/common/components/edit-contact-info/edit-contact-info.component';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { ContactInfo } from '@sl/common/model';
import { SLUtils } from '@sl/common/utils/SLUtils';

@Component({
  selector: 'sl-edit-contact-info-dialog',
  styleUrls: ['edit-contact-info-dialog.component.scss'],
  templateUrl: 'edit-contact-info-dialog.component.html',
})
export class EditContactInfoDialogComponent extends BaseOverlayComponent {
  @ViewChild(EditContactInfoComponent)
  editContactInfo: EditContactInfoComponent;

  constructor(public authService: AuthService, dialog: MatDialog) {
    super(dialog);
  }

  onLoginSuccessful() {
    // Don't trigger unsaved changes detection
    this.close(false);
  }

  onContactInfoSaved() {
    this.close(true);
  }

  checkHasUserData(ci: ContactInfo) {
    return SLUtils.checkHasUserData(ci);
  }
  protected hasUnsavedData(): boolean {
    return this.editContactInfo.hasUnsavedData();
  }

  protected getDiscardUnsavedChangesMessage(): ConfirmDialogData {
    const msg = super.getDiscardUnsavedChangesMessage();
    msg.titleRes = 'profile.unsaved-data-title';
    msg.messageRes = 'profile.unsaved-data-message';
    return msg;
  }

  public save() {
    this.editContactInfo?.save();
  }
}
