import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from '@sl/common/services/endpoint/BaseDataService';
import { ClientVersionInfo } from '@sl/common/services/endpoint/dto/ClientVersionInfo';

@Injectable({ providedIn: 'root' })
export class ClientVersionDataService extends BaseDataService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public getAvailableClientVersions() {
    const applicationId = 'web';
    // if (this.isMockingEnabled()) {
    //   return of([new ClientVersionInfo(21)]);
    // }
    return this.get<ClientVersionInfo[]>(`clientversion/${applicationId}`);
  }
}
