import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { VideoPlayerComponent } from '@sl/common/components/video/video-player.component';
import { BaseVideoPlayerComponent } from '@sl/common/components/video/base-video-player.component';

@Component({
  selector: 'html5-video-player',
  templateUrl: 'html5-video-player.component.html',
  styleUrls: ['html5-video-player.component.scss'],
})
export class Html5VideoPlayerComponent extends BaseVideoPlayerComponent implements AfterViewInit {
  public static readonly VIDEO_URL_REGEXES = [new RegExp(/http.*\.mp4/), new RegExp(/http.*\.webm/)];

  @ViewChild('video')
  videoElement: ElementRef;

  bufferPercentage = 0;

  ngAfterViewInit(): void {
    this.videoTag?.addEventListener('progress', () => this.updateBufferPercentage());
    this.videoTag?.addEventListener('canplaythrough', () => this.updateBufferPercentage());

    this.videoTag?.addEventListener('canplay', () => this.onReady.emit());
    this.videoTag?.addEventListener('error', () => this.onError.emit());
  }

  loadVideo(event: MouseEvent) {
    this.videoTag?.load();
    event.stopPropagation();
  }

  public play() {
    this.videoTag?.play();
  }

  public pause() {
    this.videoTag?.pause();
  }

  public togglePlayPause() {
    if (this.isPlaying()) {
      this.pause();
    } else {
      this.play();
    }
  }

  onPlayPauseButtonClicked(e: Event) {
    this.togglePlayPause();
    e.stopPropagation();
  }

  public isPlaying() {
    return !this.videoTag?.paused;
  }

  public isFinished() {
    return this.videoTag?.ended;
  }

  public isReadyToPlay() {
    return this.videoTag?.readyState === 4;
  }

  public getError() {
    return this.videoTag?.error;
  }

  private updateBufferPercentage() {
    this.bufferPercentage = Math.round(this.getLargestBufferTime() / this.videoTag?.duration) * 100;
  }

  private getLargestBufferTime() {
    let largestBufferValue = 0;
    const videoTag = this.videoTag;

    if (videoTag?.buffered.length > 0) {
      for (let i = 0; i < videoTag.buffered.length; i++) {
        const start = videoTag.buffered.start(i);
        const end = videoTag.buffered.end(i);
        largestBufferValue += end - start;
      }
    }
    return largestBufferValue;
  }

  get videoTag(): HTMLVideoElement | undefined {
    return this.videoElement?.nativeElement;
  }
}
