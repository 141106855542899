<div
  fxLayout="row"
  fxLayoutAlign="start center"
  [style.opacity]="disabled ? 0.7 : 1"
>
  <div
    *ngFor="let n of numbers; let i = index"
    (mouseenter)="onHover(i + 1)"
    (click)="onClick(i + 1)"
    style="cursor: pointer; margin-right: 4px;"
    [style.width]="size"
    [style.height]="size"
    [ngClass]="i < shownRating ? selectedClass : notSelectedClass"
    [inlineSVG]="
      'assets/img/ic_' + (i < shownRating ? 'star' : 'star_outline') + '.svg'
    "
  >
</div>
</div>
<div
  style="margin-top: -7pt;"
  fxLayout="row"
  fxLayoutAlign="start center"
>
<p style="overflow-wrap: break-word; text-align: center; font-size: x-small; line-height: normal; height: 60px; width: 60px;" *ngFor="let item of answerOptions">{{item.text}}</p>
</div>
