import { Pipe, PipeTransform } from '@angular/core';
import { LocalizedDatePipe } from '@sl/common/pipes/LocalizedDatePipe';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'fromToDate',
})
export class FromToDatePipe implements PipeTransform {
  constructor(private datePipe: LocalizedDatePipe, private translateService: TranslateService) {}

  transform(fromTimestamp: number, toTimestamp?: number): string {
    let text = '';
    if (fromTimestamp && fromTimestamp > 0) {
      const from = new Date(fromTimestamp);
      if (this.isSameCalendarDay(from, new Date())) {
        text += this.translateService.instant('date-time.today');
      } else {
        text += this.datePipe.transform(from, 'shortDate');
      }
      text += ' ';
      text += this.datePipe.transform(from, 'shortTime');

      if (toTimestamp && toTimestamp > 0) {
        const to = new Date(toTimestamp);
        text += ' - ';
        if (!this.isSameCalendarDay(to, from)) {
          // From and to are NOT the same day
          text += this.datePipe.transform(to, 'shortDate') + ' ';
        }
        text += this.datePipe.transform(to, 'shortTime');
      }
    }
    return text;
  }

  private isSameCalendarDay(to: Date, from: Date) {
    return to.getFullYear() === from.getFullYear() && to.getMonth() === from.getMonth() && to.getDay() === from.getDay();
  }
}
