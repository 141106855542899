<div *ngIf="currentUser"
     fxLayout="row" fxLayoutAlign="end center"
     style="cursor: pointer;"
     [matTooltip]="(currentUser.isAnonymous ? 'profile.edit-long' : 'profile.view') | translate"
     (click)="showUser()">

  <contact-image [contactInfoOverview]="currentUser.contactInfo"
                 [imageSize]="imageSize" noNameImageColor="currentColor"></contact-image>

  <ng-template [ngIf]="currentUser">
    <h6 *ifMediaQuery="'(min-width: 960px)'" style="margin-left: 16px; color: currentColor;">{{currentUser.contactInfo ?
      currentUser.contactInfo.firstName : ('auth.login' | translate)}}</h6>
  </ng-template>
</div>
