import { Pipe, PipeTransform } from '@angular/core';
import { PredefinedSocialNetwork, SocialNetwork } from '@sl/common/model';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'socialNetworkName' })
export class SocialNetworkNamePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(sn: SocialNetwork): string {
    let displayName = sn.name;
    const predefinedSocialNetwork = PredefinedSocialNetwork.getPrefinedSocialNetwork(sn);
    if (predefinedSocialNetwork) {
      displayName = predefinedSocialNetwork.displayName + ' ' + this.translate.instant('contact-detail.profile');
    }
    return displayName;
  }
}
