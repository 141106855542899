import { BaseModel } from '@sl/common/model';

export class WorkflowStep extends BaseModel<WorkflowStep> {
  name?: string;
  order: number;
  type: WorkflowStepType;
  accessType: WorkflowStepAccessType;
  isActivated: boolean;
  targetId: string;
}

export enum WorkflowStepType {
  ContactInfo = 'contactInfo',
  Voting = 'voting',
  CustomForm = 'customForm',
}

export enum WorkflowStepAccessType {
  Interaction = 'interaction',
  View = 'view',
}
