import { Component, Input, OnDestroy } from '@angular/core';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { Subscription } from 'rxjs';
import { User } from '@sl/common/model';
import { SLAnalyticsService, TrackingKey } from '@sl/common/services/analytics';
import { EditContactInfoOverlayService } from '@sl/common/components/edit-contact-info-dialog/EditContactInfoOverlayService';
import { ViewContactInfoOverlayService } from '@sl/common/components/view-contact-info-dialog/ViewContactInfoOverlayService';

@Component({
  selector: 'profile',
  templateUrl: 'profile.component.html',
})
export class ProfileComponent implements OnDestroy {
  @Input() imageSize = '32px';

  currentUser: User;
  userSubscription: Subscription;

  constructor(private contactDetailOverlayService: ViewContactInfoOverlayService, private userDetailOverlayService: EditContactInfoOverlayService, private authService: AuthService, private trackingService: SLAnalyticsService) {
    this.userSubscription = authService.user.subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  showUser() {
    if (this.currentUser) {
      if (this.currentUser.isAnonymous) {
        this.userDetailOverlayService.open();
      } else {
        this.showContactInfo();
      }
    }
  }

  showContactInfo() {
    this.trackingService.trackPageView(TrackingKey.PageView.PROFILE);
    this.contactDetailOverlayService.open(this.currentUser.contactInfo);
  }
}
