import { AdvancedHttpClient } from 'app/common/services/httpclient/AdvancedHttpClient';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SLConfig } from 'config/SLConfig';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ContactInfoGuesserDataService extends AdvancedHttpClient {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected get baseUri(): string {
    return SLConfig.getEnvironment().webservice.contactGuesserServiceBaseUri;
  }

  protected get defaultHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }

  protected get defaultQueryParams(): HttpParams {
    return new HttpParams();
  }

  protected get defaultVersion(): string {
    return 'v1';
  }

  protected async getDynamicDefaultHeaders(): Promise<HttpHeaders> {
    return new HttpHeaders();
  }

  public getEMailValid(email: string) {
    return this.get<{ valid: boolean }>(`emailcheck`, null, { email: email });
  }
}
