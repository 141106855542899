import { Component, Input } from '@angular/core';
import { AttendedPresentationInfo } from '@sl/common/services/endpoint/dto/AttendedPresentationInfo';

@Component({
  selector: 'presentation-entry',
  templateUrl: 'presentation-entry.component.html',
  styleUrls: ['presentation-entry.component.scss'],
})
export class PresentationEntryComponent {
  @Input() presentationHistoryEntry: AttendedPresentationInfo;
}
