import { IAnalyticsTracker } from './IAnalyticsTracker';
import { SLLogger } from '@sl/common/utils/SLLogger';

export abstract class BaseAnalyticsService implements IAnalyticsTracker {
  protected initialized = false;

  protected abstract getTrackers(): IAnalyticsTracker[];

  public init(): void {
    this.tryInitAll();
  }

  public trackPageView(page: string): void {
    this.tryInitAll();
    this.execTrackers((t) => t.trackPageView(page));
    SLLogger.log('TrackingService', 'pageview: ' + page);
  }

  public trackEvent(event: string, param?: string | number | object, label?: string): void {
    this.tryInitAll();
    this.execTrackers((t) => t.trackEvent(event, param, label));
    SLLogger.log('TrackingService', 'event: ' + event + (param !== undefined ? ' (' + param + ')' : '') + (label ? ', label: ' + label : ''));
  }

  public reset() {
    this.initialized = false;
  }

  private tryInitAll(): void {
    if (!this.hasTrackers() || this.initialized) {
      return;
    }
    try {
      this.execTrackers((t) => t.init());
    } finally {
      this.initialized = true;
    }
  }

  private hasTrackers() {
    return this.getTrackers() && this.getTrackers().length > 0;
  }

  protected execTrackers(action: (tracker: IAnalyticsTracker) => void) {
    if (this.hasTrackers()) {
      for (const tracker of this.getTrackers()) {
        action(tracker);
      }
    }
  }
}
