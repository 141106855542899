export class DeviceDetect {
  private static platform: Platform;
  private static deviceType: DeviceType;
  private static browser: Browser;

  public static getDevicePlatform() {
    if (this.platform == null) {
      this.platform = this.calcDevicePlatform();
    }
    return this.platform;
  }

  private static calcDevicePlatform() {
    const userAgent = navigator.userAgent,
      platform = navigator.platform;

    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (windowsPlatforms.indexOf(platform) !== -1) {
      return Platform.Windows;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return Platform.iOS;
    } else if (/Android/.test(userAgent)) {
      return Platform.Android;
    } else if (macosPlatforms.indexOf(platform) !== -1) {
      if (navigator.maxTouchPoints > 1) {
        // iPad OS disguises as Mac
        return Platform.iOS;
      }
      return Platform.MacOS;
    } else if (/Linux/.test(platform)) {
      return Platform.Linux;
    } else {
      return Platform.Other;
    }
  }

  public static getDeviceType() {
    if (this.deviceType == null) {
      this.deviceType = this.calcDeviceType();
    }
    return this.deviceType;
  }

  private static calcDeviceType() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/(msie|trident)/.test(userAgent)) {
      // Internet Explorer
      return DeviceType.Desktop;
    } else if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent)) {
      return DeviceType.Tablet;
    } else if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      // iPad OS disguises as Mac
      return DeviceType.Tablet;
    } else if (/(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(userAgent)) {
      return DeviceType.Phone;
    } else {
      return DeviceType.Desktop;
    }
  }

  public static getBrowser() {
    if (this.browser == null) {
      this.browser = this.calcBrowser();
    }
    return this.browser;
  }

  private static calcBrowser() {
    const win = <any>window;

    if (!!win.chrome && (!!win.chrome.webstore || !!win.chrome.runtime)) {
      return Browser.Chrome;
    } else if (!!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)) {
      return Browser.Safari;
    }

    return Browser.Other;
  }

  public static isDesktop() {
    return DeviceDetect.getDeviceType() === DeviceType.Desktop;
  }

  public static isTablet() {
    return DeviceDetect.getDeviceType() === DeviceType.Tablet;
  }

  public static isPhone() {
    return DeviceDetect.getDeviceType() === DeviceType.Phone;
  }
}

export enum Platform {
  Android = 'android',
  iOS = 'ios',
  Windows = 'windows',
  MacOS = 'mac',
  Linux = 'linux',
  Other = 'other',
}

export enum DeviceType {
  Phone = 'phone',
  Tablet = 'tablet',
  Desktop = 'desktop',
}

export enum Browser {
  Chrome = 'chrome',
  Safari = 'safari',
  Firefox = 'firefox',
  Edge = 'edge',
  Other = 'other',
}
