export interface StateChangedEvent {
  oldState: string;
  newState: string;
  originalEvent: Event;
}

interface Lifecycle {
  state: string;
  pageWasDiscarded: boolean;

  addEventListener(event: string, listener: (event: StateChangedEvent) => void): void;

  removeEventListener(event: string, listener: (event: StateChangedEvent) => void): void;
}

declare var lifecycle: Lifecycle;

/**
 * Observe the page's lifecycle. See https://developers.google.com/web/updates/2018/07/page-lifecycle-api
 */
export class LifecycleUtils {
  static readonly STATE_CHANGED_EVENT = 'statechange';

  static addStateChangedListener(listener: (event: StateChangedEvent) => void) {
    try {
      lifecycle.addEventListener(LifecycleUtils.STATE_CHANGED_EVENT, listener);
    } catch (e) {
      console.warn(e);
    }
  }

  static removeStateChangedListener(listener: (event: StateChangedEvent) => void) {
    try {
      lifecycle.removeEventListener(LifecycleUtils.STATE_CHANGED_EVENT, listener);
    } catch (e) {
      console.warn(e);
    }
  }
}
