import { Component, Input, OnInit } from '@angular/core';
import { HelpPage, HelpPagesService } from '@sl/common/services/util/HelpPagesService';

@Component({
  selector: 'sl-help-link',
  templateUrl: './help-link.component.html',
  styleUrls: ['./help-link.component.scss'],
})
export class HelpLinkComponent {
  @Input()
  set helpPage(value: HelpPage) {
    this.href = this.helpPageService.getHelpPage(value);
  }

  href?: string;

  constructor(private helpPageService: HelpPagesService) {}
}
