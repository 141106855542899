<div
  class="container"
  fxLayout="row"
  fxLayoutAlign="start center"
  [style.opacity]="disabled ? 0.7 : 1"
>
  <div
    *ngFor="let n of numbers; let i = index"
    (mouseenter)="onHover(i + 1)"
    (click)="onClick(i + 1)"
    class="numberInputOption"
    [ngClass]="
      i == shownRating - 1
        ? ['selected', selectedClass]
        : ['notSelected', notSelectedClass]
    "
  >
    {{ i + 1 }}
  </div>
</div>
<div
  *ngIf="indicatorTextLeft || indicatorTextRight"
  fxLayout="row"
  class="indicatorContainer"
  [ngClass]="notSelectedClass"
></div>
<div
  style="margin-left: -8px; margin-top: 2px;"
  fxLayout="row"
  fxLayoutAlign="start center"
>
<p style="overflow-wrap: break-word; margin-right: 4px; text-align: center; font-size: x-small; line-height: normal;" [style.height]="size" [style.width]="size" *ngFor="let item of answerOptions">{{item.text}}</p>
</div>
