import { Pipe, PipeTransform } from '@angular/core';
import { ContactInfoFullNamePipe } from '@sl/common/pipes/ContactInfoFullNamePipe';
import { ContactInfoOverview } from '@sl/common/model/ContactInfoOverview';

@Pipe({ name: 'speakers' })
export class SpeakersPipe implements PipeTransform {
  transform(speakers: ContactInfoOverview[]): string {
    let text = '';
    const fullNamePipe = new ContactInfoFullNamePipe();
    let i = 0;
    for (const speaker of speakers) {
      text += fullNamePipe.transform(speaker);
      if (i < speakers.length - 1) {
        text += ', ';
      }
      i++;
    }
    return text;
  }
}
