import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import localeDE from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { SLConfig } from '@sl/config/SLConfig';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { ErrorTrackerService } from '@sl/common/services/error-handling/ErrorTrackerService';
import { LifecycleUtils } from '@sl/common/utils/LifecycleUtils';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '@sl/common/services/theme/ThemeService';
import { ServerInfoDataService } from '@sl/common/services/endpoint/ServerInfoDataService';
import { ServerTimeStorageService } from '@sl/common/services/localstorage/ServerTimeStorageService';
import { UpdateService } from '@sl/common/services/util/UpdateService';
import { BrowserDetect } from '@sl/common/utils/BrowserDetect';
import { SLAnalyticsService } from '@sl/common/services/analytics';
import { QUERY_PARAMS } from './RouteConfig';
import { Utils } from '@sl/common/utils/Utils';
import { MSTeamsService } from '@sl/common/services/ms-teams/MSTeamsService';
import { PreferredLanguageStorageService } from '@sl/common/services/i18n/PreferredLanguageStorageService';
import { I18nService } from '@sl/common/services/i18n/I18nService.service';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  constructor(
    oauthService: OAuthService,
    translateService: TranslateService,
    i18nService: I18nService,
    authService: AuthService,
    analyticsService: SLAnalyticsService,
    themeService: ThemeService,
    errorTrackerService: ErrorTrackerService,
    router: Router,
    updateService: UpdateService,
    serverInfoDataService: ServerInfoDataService,
    serverTimeStorageService: ServerTimeStorageService,
    preferredLanguageStorageService: PreferredLanguageStorageService,
    msTeamsService: MSTeamsService
  ) {
    errorTrackerService.init();

    this.setupMaintenanceErrorHandler(translateService);

    this.setupRouteErrorHandler(router, errorTrackerService);

    this.setupI18N(preferredLanguageStorageService);

    this.setupAuth(oauthService, authService);

    themeService.resetTheme();

    // Set after theme was set!
    errorTrackerService.setTag('browser-lang', translateService.getBrowserCultureLang());
    errorTrackerService.setTag('ui-lang', i18nService.getCurrentLanguage());
    errorTrackerService.setTag('build-timestamp', String(SLConfig.getEnvironment().buildTimestamp));

    this.setupLifecycleLogger();

    this.syncServerTime(serverInfoDataService, serverTimeStorageService);

    this.checkBrowserSupported(errorTrackerService, analyticsService, translateService);
    this.setupMSTeams(msTeamsService, themeService);
  }

  private setupMSTeams(msTeamsService: MSTeamsService, themeService: ThemeService) {
    const isInMSTeams = Utils.getQueryParam(QUERY_PARAMS.IS_IN_MS_TEAMS) === 'true';
    if (isInMSTeams) {
      msTeamsService.init(() => themeService.resetTheme());
    }
  }

  private setupMaintenanceErrorHandler(translateService: TranslateService) {
    window.addEventListener('server-maintenance-error', () => {
      SLLogger.warn('Got 503 maintenance error from server!');
      translateService.get('error-maintenance').subscribe((text) => alert(text));
    });
  }

  private setupRouteErrorHandler(router: Router, errorTrackerService: ErrorTrackerService) {
    router.errorHandler = function (err: any) {
      SLLogger.warn('Router error: %o', err);
      // Current 'hack' to fix the problem, needs further investigation!
      if (confirm('Please reload the site!')) {
        location.reload();
      }
      errorTrackerService.trackError(err);
    };
  }

  private setupI18N(preferredLanguageStorageService: PreferredLanguageStorageService) {
    try {
      const preferredLanguageParam = Utils.getQueryParam(QUERY_PARAMS.LANG);
      if (preferredLanguageParam) {
        const preferredLanguage = preferredLanguageParam.split('-')[0].toLowerCase();
        preferredLanguageStorageService.setPreferredLanguage(preferredLanguage);
      }
    } catch (e) {
      SLLogger.warn(e);
    }
  }

  private setupLifecycleLogger() {
    LifecycleUtils.addStateChangedListener(function (event) {
      SLLogger.log('Changed state (trigger: ' + event.originalEvent.type + '): ' + event.oldState + ' => ' + event.newState);
    });
  }

  private setupAuth(oauthService: OAuthService, authService: AuthService) {
    oauthService.configure(SLConfig.getEnvironment().authServerConfig);
    oauthService.tokenValidationHandler = new JwksValidationHandler();

    authService.ensureLoggedIn();
  }

  private syncServerTime(serverInfoDataService: ServerInfoDataService, serverTimeStorageService: ServerTimeStorageService) {
    const serverInfoFetchStartTime = new Date().getTime();
    serverInfoDataService.getServerInfo().subscribe(
      (info) => {
        const currentTime = new Date().getTime();

        const fetchDuration = currentTime - serverInfoFetchStartTime;
        SLLogger.log('Fetching server info took ' + fetchDuration);

        SLLogger.log('Current time: ' + currentTime + ' server time: ' + info.currentTimestamp);
        const serverTimeDiff = info.currentTimestamp - currentTime;
        SLLogger.log('Server time differs about ' + serverTimeDiff);
        serverTimeStorageService.saveServerTimeDifference(serverTimeDiff);
      },
      () => SLLogger.warn("Couldn't fetch server info!")
    );
  }

  private checkBrowserSupported(errorTrackerService: ErrorTrackerService, analyticsService: SLAnalyticsService, translateService: TranslateService) {
    const isBrowserSupported = !BrowserDetect.checkIsNotSupported();
    errorTrackerService.setTag('browser-supported', String(isBrowserSupported));
    analyticsService.trackEvent('browser-supported', String(isBrowserSupported));

    if (!isBrowserSupported) {
      SLLogger.log('Browser not supported!');

      const browserInfo = BrowserDetect.getBrowser();
      const browserName = browserInfo.getBrowserName() + ' ' + browserInfo.getBrowserVersion();
      alert(translateService.instant('browser-not-supported.message', { name: browserName }));
    }
  }
}
