import { LoginProvider } from '@sl/common/services/auth/LoginProvider';
import { ContactInfo } from '@sl/common/model';
import { Subject } from 'rxjs';
import { AuthProviderKey } from '@sl/common/services/endpoint/dto/LoginPayload';

export class FacebookLoginProvider implements LoginProvider {
  constructor(private accessToken: string) {}

  getKey(): AuthProviderKey {
    return AuthProviderKey.Facebook;
  }

  getToken(): string {
    return this.accessToken;
  }

  getProfileData(): Promise<ContactInfo> {
    const profile = new Subject<ContactInfo>();
    FB.api('/me', { fields: 'id,first_name,last_name,email,about,website,picture.width(300).height(300)' }, (response: any) => {
      if (!response || response.error) {
        profile.error(response.error);
        profile.complete();
        return;
      }

      const ci = new ContactInfo();

      ci.firstName = response.first_name;
      ci.lastName = response.last_name;

      ci.emailAddresses = [];
      ci.emailAddresses[0] = { type: 'private', emailAddress: response.email };

      ci.website = response.website;
      ci.selfDescription = response.about;

      const picture = response.picture.data;
      if (picture != null && !picture.is_silhouette) {
        ci.imageUrl = picture.url;
      }

      profile.next(ci);
      profile.complete();
    });
    return profile.toPromise();
  }
}
