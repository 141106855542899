import { LoginPayload } from './dto/LoginPayload';
import { RegisterPayload } from './dto/RegisterPayload';
import { BaseDataService } from '@sl/common/services/endpoint/BaseDataService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthResponse } from '@sl/common/services/endpoint/dto/AuthResponse';
import { RegisterAnonymousPayload } from '@sl/common/services/endpoint/dto/RegisterAnonymousPayload';

@Injectable({ providedIn: 'root' })
export class AuthDataService extends BaseDataService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public login(auth: LoginPayload) {
    return this.put<AuthResponse>(`user/me/login`, auth);
  }

  public register(auth: RegisterPayload) {
    return this.post<AuthResponse>(`user/me/register`, auth);
  }

  public registerAnonymous(auth: RegisterAnonymousPayload) {
    return this.post<AuthResponse>(`user/me/register/anonymous`, auth);
  }

  public exchangeLimitedLifetimeToken(token: string) {
    // if (this.isMockingEnabled()) {
    //   const res: AuthResponse = {
    //     id: '60e59ad0eb0257acb7eebabe',
    //     sessionToken: '2fb5941aece147599df091e1934f371c8933a8adda3e441f94ddb56766fb5cc7c880195ff3854e8cab43335490425b3ff83aabe2f1a04ee69120173e8f5c5677',
    //   };
    //   return of(res);
    // }
    return this.put<AuthResponse>(`user/me/login/attendeeinvitationtoken/${token}`, {});
  }
}
