import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { BaseOverlayService } from '@sl/common/services/BaseOverlayService';
import { EditContactInfoDialogComponent } from '@sl/common/components/edit-contact-info-dialog/EditContactInfoDialogComponent';

@Injectable()
export class EditContactInfoOverlayService extends BaseOverlayService<EditContactInfoDialogComponent> {
  constructor(overlay: Overlay) {
    super(overlay);
  }

  protected getComponent(): ComponentType<EditContactInfoDialogComponent> {
    return EditContactInfoDialogComponent;
  }

  open(forceEditForm: boolean = false) {
    const instance = this.createOverlay();
    instance.overlayRef = this.overlayRef;
    // instance.forceEditForm = forceEditForm;

    return this.resultSubject;
  }
}
