import { Injectable } from '@angular/core';
import { LocalStorageService } from '@sl/common/services/storageaccess/LocalStorageService';

@Injectable({ providedIn: 'root' })
export class AuthLocalStorageService extends LocalStorageService<string> {
  public static readonly SESSION_TOKEN_KEY = 'sessiontoken';
  public static readonly SESSION_ROLE_KEY = 'sessionrole';

  setSessionToken(token: string): void {
    this.setValue(AuthLocalStorageService.SESSION_TOKEN_KEY, token);
  }

  getSessionToken(): string {
    return this.getValue(AuthLocalStorageService.SESSION_TOKEN_KEY);
  }

  setSessionRole(role: string): void {
    this.setValue(AuthLocalStorageService.SESSION_ROLE_KEY, role);
  }

  getSessionRole(): string {
    return this.getValue(AuthLocalStorageService.SESSION_ROLE_KEY);
  }
}
