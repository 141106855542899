import { LoginProvider } from '@sl/common/services/auth/LoginProvider';
import { ContactInfo } from '@sl/common/model';
import { Subject } from 'rxjs';
import { AuthProviderKey } from '@sl/common/services/endpoint/dto/LoginPayload';
import { OAuthService } from 'angular-oauth2-oidc';
import { SLUtils } from '@sl/common/utils/SLUtils';

export class SlideLizardLoginProvider implements LoginProvider {
  constructor(private oauthService: OAuthService) {}

  getKey(): AuthProviderKey {
    return AuthProviderKey.SlideLizard;
  }

  getToken(): string {
    return this.oauthService.getIdToken();
  }

  getProfileData(): Promise<ContactInfo> {
    const profile = new Subject<ContactInfo>();

    const ci = new ContactInfo();
    const claims = <any>this.oauthService.getIdentityClaims();
    ci.firstName = claims.firstName;
    ci.lastName = claims.lastName;

    ci.emailAddresses = [];
    ci.emailAddresses[0] = { type: 'private', emailAddress: claims.email };

    SLUtils.completeSubjectDelayed(profile, ci);

    return profile.toPromise();
  }
}
