import { ExternalContent, ExternalContentMimeType } from '@sl/common/model/ExternalContent';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mimeTypeIcon' })
export class ExternalContentMimeTypeIconPipe implements PipeTransform {
  transform(externalContent: ExternalContent): string {
    if (externalContent.representationString?.includes('teams.microsoft.com')) {
      return this.getUrl('ms-teams');
    }

    let mimeType = ExternalContentMimeType.getMimeType(externalContent.mimeType);
    if (!mimeType) {
      mimeType = ExternalContentMimeType.Other;
    }
    return this.getUrl(mimeType.iconName);
  }

  getUrl(iconName: string) {
    return '/assets/img/external-content-type/' + iconName + '.svg';
  }
}
