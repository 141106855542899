import { Injectable } from '@angular/core';
import { BaseAuthorizedDataService } from '@sl/common/services/endpoint/BaseAuthorizedDataService';
import { HttpClient } from '@angular/common/http';
import { SessionService } from '@sl/common/services/auth/SessionService';
import { map, tap } from 'rxjs/operators';
import { Workflow, WorkflowState } from '../model/Workflow';
import { of } from 'rxjs';
import { Theme } from '@sl/common/model/Theme';
import { WorkflowStep, WorkflowStepAccessType, WorkflowStepType } from '../model/WorkflowStep';
import { VotingResultChartType } from '@sl/common/model/Voting';
import { FakeDataProvider, FakeThemes } from '@sl/common/services/endpoint/FakeDataProvider';
import { VotingResult } from '@sl/common/model/VotingResult';
import { SLConfig } from '@sl/config/SLConfig';
import { CustomForm, CustomFormElement, CustomFormElementType } from '@sl/common/model/CustomForm';
import { AttendancePayload } from '@sl/common/services/endpoint/dto/AttendancePayload';
import { AttendanceInfo } from '@sl/common/services/endpoint/dto/AttendanceInfo';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { WorkflowStateInfo } from '@sl/common/services/endpoint/dto/WorklfowStateInfo';

@Injectable({
  providedIn: 'root',
})
export class WorkflowDataService extends BaseAuthorizedDataService {
  constructor(protected httpClient: HttpClient, protected sessionService: SessionService) {
    super(httpClient, sessionService);
  }

  public postAttendance(workflowId: string, deviceInfo: AttendancePayload) {
    if (this.fakePresentationRunning()) {
      // return of(new AttendanceInfo(GDPRState.NotSeen, null));
    }
    return this.post<AttendanceInfo>(`workflow/${workflowId}/attendance`, deviceInfo);
  }

  public getCurrentState(workflowStepId: string) {
    SLLogger.log('Get current state');
    return this.get<WorkflowStateInfo>(`workflow/${workflowStepId}/stateinfo`).pipe(
      map((pi) => {
        if (this.fakePresentationRunning()) {
          if (this.isMockingEnabled()) {
            // pi.lastAttendeeMessageId = "5";
            // pi.currentSlideNumber = 3;
            // pi.runningVotingId = "1234";
          }
        }

        return pi;
      })
    );
  }

  public getWorkflow(workflowId: string) {
    const mapWorkflow = (workflow: Workflow) => {
      workflow.steps = workflow.steps.filter((w) => w.isActivated != false).sort((a, b) => a.order - b.order);
      return workflow;
    };

    if (this.isMockingEnabled()) {
      const workflow = new Workflow({
        id: 'w-1',
        name: 'Test workflow',
        state: WorkflowState.Running,
        steps: [
          new WorkflowStep({ id: 'html', name: 'Beenden', type: WorkflowStepType.CustomForm, accessType: WorkflowStepAccessType.View }),
          // new WorkflowStep({ id: 'ws-2', name: 'Vote!', type: WorkflowStepType.Voting, accessType: WorkflowStepAccessType.Interaction }),
          // new WorkflowStep({ id: 'ws-1', name: 'Identify yourself', type: WorkflowStepType.ContactInfo, accessType: WorkflowStepAccessType.View }),
          // new WorkflowStep({ id: 'ws-3', name: 'View voting', type: WorkflowStepType.Voting, accessType: WorkflowStepAccessType.View }),
        ],
      });
      return FakeDataProvider.createDelayedResult(workflow).pipe(map(mapWorkflow));
    }

    return this.get<Workflow>(`workflow/${workflowId}`).pipe(
      map((workflow) => {
        if (this.fakePresentationRunning()) {
          workflow.state = WorkflowState.Running;
        }
        return workflow;
      }),
      map(mapWorkflow)
    );
  }

  public getWorkflowStepData<T>(workflowStepId: string, workflowStepType: WorkflowStepType, workflowStepAccessType: WorkflowStepAccessType, targetId: string) {
    if (this.isMockingEnabled()) {
      let result: any;
      switch (workflowStepType) {
        case WorkflowStepType.ContactInfo:
          result = null;
          break;
        case WorkflowStepType.Voting: {
          if (workflowStepAccessType === WorkflowStepAccessType.Interaction) {
            result = FakeDataProvider.createVoting();
          } else {
            result = FakeDataProvider.createVotingResult(FakeDataProvider.createVoting({ resultChartType: VotingResultChartType.PhotoGrid }));
          }
          break;
        }
        case WorkflowStepType.CustomForm:
          result = new CustomForm({ name: 'Beenden', formElements: [new CustomFormElement({ type: CustomFormElementType.Label, title: "Danke für Ihre Abstimmung!<br/><a href='close'>Zurück</a>" })] });
          break;
      }
      return FakeDataProvider.createDelayedResult(result);
    }

    return this.get<T>(`workflow/step/${workflowStepId}/${workflowStepType}/${targetId}/${workflowStepAccessType}`).pipe(
      tap((data: any) => {
        // TODO remove and find better solution
        if (workflowStepType === WorkflowStepType.Voting && workflowStepAccessType === WorkflowStepAccessType.View && data.voting && data as VotingResult) {
          (data as VotingResult).voting.resultChartType = VotingResultChartType.PhotoGrid;
        }
      })
    );
  }

  public getTheme(workflowId: string) {
    if (this.isMockingEnabled()) {
      return of(null);
    }
    return this.get<Theme>(`workflow/${workflowId}/theme`);
  }

  private fakePresentationRunning() {
    return SLConfig.getDebugConfig().fakePresentationRunning;
  }
}
