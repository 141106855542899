import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { Voting, VotingState, VotingType } from '@sl/common/model/Voting';
import { VotingAnswerEntry } from '@sl/common/model/local/VotingAnswerEntry';
import { PresentationQuizPointsService, QuizCalculationResult, QuizResult } from '@sl/pres/services/PresentationQuizPointsService';
import { Subject, Subscription, timer } from 'rxjs';
import { LifecycleUtils, StateChangedEvent } from '@sl/common/utils/LifecycleUtils';
import { VotingAnswersLocalStorageService } from '@sl/pres/services/storage/VotingAnswersLocalStorageService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PresentationAnalyticsService } from '@sl/pres/services/PresentationAnalyticsService';
import { TranslateService } from '@ngx-translate/core';
import { ServerTimeStorageService } from '@sl/common/services/localstorage/ServerTimeStorageService';
import { SlideLizardMetaService } from '@sl/common/services/util/SlideLizardMetaService';
import { takeUntil } from 'rxjs/operators';
import { TrackingKey } from '@sl/common/services/analytics';
import { NGUtils } from '@sl/common/utils/NGUtils';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { OpenTextAnswerPayload } from '@sl/common/services/endpoint/dto/OpenTextAnswerPayload';
import { QuizResultChangedEvent } from '@sl/pres/services/Events';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { BaseVotingAnswerContainerComponent } from './answer-types/BaseVotingAnswerContainerComponent';
import { InteractionDataService } from '@sl/common/services/endpoint/InteractionDataService';
import { PhotoAnswerPayload } from '@sl/common/services/endpoint/dto/PhotoAnswerPayload';
import { IWorkflowStepComponent } from '@sl/common/components/IWorkflowStepComponent';
import { WorkflowQuizPointsService } from 'app/worflow/services/WorkflowQuizPointsService';

@Component({
  selector: 'sl-voting',
  templateUrl: './voting.component.html',
  styleUrls: ['./voting.component.scss']
})
export class VotingComponent implements OnInit, AfterViewChecked, OnDestroy, IWorkflowStepComponent {
  private static readonly QUIZ_RESULT_SHOWN_CLOSE_DELAY = 10000;
  private static readonly COUNTDOWN_LAST_SECONDS_START = 10;
  private static readonly COUNTDOWN_PROGRESS_BAR_ANIMATED_CLASS = 'countdown-progress-bar-animated';
  private static readonly ERROR_TOO_LATE_CLOSE_DELAY = 6000;

  VotingType = VotingType;
  QuizResult = QuizResult;
  ProgressState = ProgressState;

  sendVoteState = ProgressState.Content;

  activeVoting: Voting;

  @Input()
  set voting(voting: Voting) {
    this.onVotingLoaded(voting);
  }

  @Input()
  sourceId: string;

  @Input()
  isWorkflow: boolean;

  @Input()
  canVote?: boolean;

  @Output()
  onVoteSent = new EventEmitter<any>();

  @Output()
  onShouldHide = new EventEmitter<void>();

  previousVotingAnswer: VotingAnswerEntry = null;

  tooLateErrorShown = false;
  quizResultShown = false;
  quizResult: QuizCalculationResult;

  answerFilter: string;

  // Countdown
  countdownSubscription: Subscription;
  countdownRunning = false;
  countdownLastSeconds = false;
  countdownTotalSeconds: number;
  countdownInitialLeftSeconds: number;
  countdownCurrentLeftSeconds: number;
  stateChangedListener: (event: StateChangedEvent) => void;
  lastSecondsCountdownElementsSetupDone = false;

  @ViewChild('scrollContainer', { read: ElementRef })
  scrollContainer: ElementRef;
  hasShownScrollIndicator = true;
  scrollIndicatorVisible = false;

  @ViewChild('scrollContainer')
  answerContainerComponent: BaseVotingAnswerContainerComponent;

  @ViewChild('countdownProgressBar')
  countdownProgressBar: ElementRef;

  @ViewChild('countdownText1')
  countdownText1: ElementRef;

  @ViewChild('countdownText2')
  countdownText2: ElementRef;

  protected unsubscribe$ = new Subject<void>();

  constructor(
    private interactionDataService: InteractionDataService,
    private votingsStorageService: VotingAnswersLocalStorageService,
    private snackBar: MatSnackBar,
    private trackingService: PresentationAnalyticsService,
    private translateService: TranslateService,
    private serverTimeStorageService: ServerTimeStorageService,
    private metaService: SlideLizardMetaService,
    private dialogContainerElement: ElementRef,
    private zone: NgZone,
    @Optional() public workflowQuizPointsService: WorkflowQuizPointsService,
    @Optional() public presentationQuizPointsService: PresentationQuizPointsService
  ) {
  }
  ngOnInit(): void {
    this.trackingService.trackPageView(TrackingKey.PageView.VOTINGS);
  }

  ngAfterViewChecked() {
    // this.setScrollContainerHeight(this.dialogContainerElement, this.toolbar, this.scrollContainer);

    if (this.answerContainerComponent?.shouldShowScrollIndicator() && this.scrollContainer) {
      if (!this.hasShownScrollIndicator && this.scrollContainer.nativeElement.clientHeight < this.scrollContainer.nativeElement.scrollHeight - 40) {
        this.hasShownScrollIndicator = true;
        NGUtils.deferChange(() => (this.scrollIndicatorVisible = true));
      } else if (this.hasShownScrollIndicator && this.scrollContainer.nativeElement.clientHeight >= this.scrollContainer.nativeElement.scrollHeight - 40) {
        this.hasShownScrollIndicator = false;
        NGUtils.deferChange(() => (this.scrollIndicatorVisible = false));
      }
    }
  }

  onVotingFinished() {
    if (this.activeVoting) {
      if (this.activeVoting.isQuiz) {
        this.showQuizResult();
        this.resetCountdown();
        return true;
      } else if (!this.hasSentVote()) {
        // Vote not sent yet
        this.resetCountdown();
        this.showTooLateError();
        return true;
      }
    }
    return false;
  }

  private resetView() {
    this.tooLateErrorShown = false;
    this.quizResultShown = false;
    this.quizResult = null;
    this.resetCountdown();
  }

  private onVotingLoaded(voting: Voting) {
    this.resetView();
    this.activeVoting = voting;

    this.previousVotingAnswer = this.votingsStorageService.getPreviousVotingAnswer(this.activeVoting.id);
    if (this.canSendAnotherAnswer()) {
      this.hasShownScrollIndicator = false;
      this.sendVoteState = this.ProgressState.Content;
      this.showCountdown();

      this.translateService.get('presentation.votings.voting-now-title').subscribe((value) => this.metaService.startAlternatingTitle(value));
    } else {
      this.sendVoteState = this.ProgressState.Empty;
    }
  }

  // region Countdown
  private showCountdown() {
    if (this.activeVoting.durationSeconds && this.activeVoting.startedAt) {
      this.countdownTotalSeconds = this.activeVoting.durationSeconds;
      this.countdownInitialLeftSeconds = this.calculateCountdownLeftSeconds();
      this.countdownCurrentLeftSeconds = Math.floor(this.countdownInitialLeftSeconds);

      if (this.countdownTotalSeconds > 0 && this.countdownCurrentLeftSeconds > 0) {
        this.setupCountdownProgressBar();

        if (this.countdownSubscription && !this.countdownSubscription.closed) {
          this.countdownSubscription.unsubscribe();
        }

        const delay = this.countdownInitialLeftSeconds - this.countdownCurrentLeftSeconds;
        // console.log("delay: " + delay);
        this.countdownSubscription = timer(delay * 1000, 1000)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(() => this.onCountdownTick());
        this.onCountdownTick();

        this.stateChangedListener = (event: StateChangedEvent) => {
          this.zone.run(() => {
            if (event.newState === 'passive' && event.oldState === 'hidden') {
              LifecycleUtils.removeStateChangedListener(this.stateChangedListener);
              this.showCountdown();
            }
          });
        };
        LifecycleUtils.addStateChangedListener(this.stateChangedListener);

        this.countdownRunning = true;
      }
    }
  }

  private finishCountdown() {
    this.resetCountdown();

    if (!this.hasSentVote()) {
      // Vote not sent yet
      this.showTooLateError();
    }

    this.activeVoting.state = VotingState.Finished;
  }

  private resetCountdown() {
    if (this.countdownSubscription && !this.countdownSubscription.closed) {
      this.countdownSubscription.unsubscribe();
    }

    if (this.stateChangedListener) {
      LifecycleUtils.removeStateChangedListener(this.stateChangedListener);
    }

    this.countdownRunning = false;
  }

  private calculateCountdownLeftSeconds() {
    if (this.activeVoting) {
      const serverTime = this.serverTimeStorageService.getServerTimeDifference() || 0;
      const votingRunningSinceSeconds = (new Date().getTime() - this.activeVoting.startedAt + serverTime) / 1000;

      // SLLogger.log("voting started at: " + this.activeVoting.startedAt);
      // SLLogger.log("voting running since: " + votingRunningSinceSeconds);

      return this.activeVoting.durationSeconds - votingRunningSinceSeconds;
    } else {
      return 0;
    }
  }

  private onCountdownTick() {
    this.countdownCurrentLeftSeconds = this.calculateCountdownLeftSeconds();
    // console.log("tick: " + this.countdownCurrentLeftSeconds);

    this.checkCountdownLastSeconds();

    if (!this.countdownLastSeconds) {
      this.setCountdownText(this.countdownText1, this.countdownCurrentLeftSeconds);
    }

    if (this.countdownCurrentLeftSeconds <= 0) {
      this.finishCountdown();
    }
  }

  private checkCountdownLastSeconds() {
    if (this.countdownCurrentLeftSeconds <= VotingComponent.COUNTDOWN_LAST_SECONDS_START) {
      this.countdownLastSeconds = true;
      if (!this.lastSecondsCountdownElementsSetupDone && this.countdownText1 && this.countdownText2) {
        this.setupCountdownTextElement(this.countdownText1);
        this.setupCountdownTextElement(this.countdownText2, -1);
        this.lastSecondsCountdownElementsSetupDone = true;
      } else {
        NGUtils.runAfterNextRender(() => this.checkCountdownLastSeconds());
      }
    } else {
      this.countdownLastSeconds = false;
    }
  }

  private setupCountdownProgressBar() {
    if (this.countdownProgressBar) {
      this.countdownProgressBar.nativeElement.classList.remove(VotingComponent.COUNTDOWN_PROGRESS_BAR_ANIMATED_CLASS);
      // tslint:disable-next-line:no-unused-expression
      void this.countdownProgressBar.nativeElement.offsetWidth; // Important line to make restarting the animation work, see https://css-tricks.com/restart-css-animation/
      this.countdownProgressBar.nativeElement.classList.add(VotingComponent.COUNTDOWN_PROGRESS_BAR_ANIMATED_CLASS);
    } else {
      NGUtils.runAfterNextRender(() => this.setupCountdownProgressBar());
    }
  }

  private setupCountdownTextElement(countdownText: ElementRef, correction: number = 0) {
    this.setCountdownText(countdownText, this.countdownCurrentLeftSeconds + correction);

    countdownText.nativeElement.addEventListener('animationiteration', () => {
      this.setCountdownText(countdownText, this.countdownCurrentLeftSeconds);
    });
  }

  private setCountdownText(countdownText: ElementRef, secondsLeft: number) {
    if (countdownText) {
      countdownText.nativeElement.innerText = Math.round(secondsLeft) + 's';
    }
  }

  // endregion

  protected hasUnsavedData() {
    return this.activeVoting && this.activeVoting.state !== VotingState.Finished && this.previousVotingAnswer == null && this.hasAnswerToSend();
  }

  async sendVote() {
    this.sendVoteState = this.ProgressState.Loading;
    let votingPayload: any;
    try {
      votingPayload = await this.answerContainerComponent?.getVotePayload();
      votingPayload.remainingSecondsInQuizAfterVote = this.countdownCurrentLeftSeconds;
    } catch (e) {
      this.sendVoteState = ProgressState.Error;
      this.showSnackbar('presentation.votings.error-send-answer');
      this.trackingService.trackEvent(TrackingKey.Event.VOTING_ANSWERED, { successful: false });
      return;
    }

    if (votingPayload) {
      SLLogger.log('sending %o', votingPayload);

      this.interactionDataService.postVotingAnswers(this.sourceId, this.activeVoting.id, this.activeVoting.type, this.activeVoting.isQuiz, votingPayload).subscribe(
        (result) => {
          if (this.activeVoting.votesLeft != null) {
            this.activeVoting.votesLeft--;
          }

          this.saveVotingAnswer(votingPayload);
          this.translateService.get('presentation.votings.vote-sent-successfully').subscribe((text) => this.snackBar.open(text, null, { duration: 3000 }));

          this.trackingService.trackEvent(TrackingKey.Event.VOTING_ANSWERED, { successful: true });

          this.onVoteSent.emit(votingPayload);

          if(this.activeVoting.isQuiz && this.isWorkflow)
            this.showQuizResult();

          if (this.canSendAnotherAnswer()) {
            this.sendVoteState = this.ProgressState.Content;
            if (this.activeVoting.type === VotingType.OpenText || this.activeVoting.type === VotingType.Photo) {
              try {
                this.scrollContainer.nativeElement.scrollTo(0, this.scrollContainer.nativeElement.scrollHeight);
              } catch (e) {
              }
            }
          } else {
            this.sendVoteState = this.ProgressState.Empty;
            this.metaService.stopAlternatingTitle();

            if (!this.activeVoting.isQuiz) {
              this.close();
            }
          }
        },
        (error) => {
          this.sendVoteState = this.ProgressState.Error;
          if (error && error.error && error.error.errorCode === '409/69') {
            // Voting already finished
            this.showSnackbar('presentation.votings.error-send-answer-voting-already-finished');
          } else {
            this.showSnackbar('presentation.votings.error-send-answer');
          }
          this.trackingService.trackEvent(TrackingKey.Event.VOTING_ANSWERED, { successful: false });
        }
      );
    } else {
      this.sendVoteState = this.ProgressState.Content;
    }
  }

  private saveVotingAnswer(votingPayload: any) {
    let savePayload = votingPayload;
    let remainingSecondsInQuizAfterVote = undefined;

    if(!!savePayload?.remainingSecondsInQuizAfterVote) {
      remainingSecondsInQuizAfterVote = savePayload.remainingSecondsInQuizAfterVote;
    }

    // TODO extract to voting components
    if (this.activeVoting.type === VotingType.OpenText) {
      const answerText = (votingPayload as OpenTextAnswerPayload).answerText;

      if (this.previousVotingAnswer) {
        savePayload = (this.previousVotingAnswer.answers as Array<string>).concat(answerText);
      } else {
        savePayload = [answerText];
      }
    } else if (this.activeVoting.type === VotingType.Photo) {
      const imageUrl = (votingPayload as PhotoAnswerPayload).imageUrl;

      if (this.previousVotingAnswer) {
        savePayload = (this.previousVotingAnswer.answers as Array<string>).concat(imageUrl);
      } else {
        savePayload = [imageUrl];
      }
    }

    this.previousVotingAnswer = this.votingsStorageService.saveVote(this.activeVoting.id, savePayload, remainingSecondsInQuizAfterVote);
  }

  onSearchChanged() {
  }

  onAnswerContainerScrolled() {
    this.scrollIndicatorVisible = false;
  }

  onScrollIndicatorClicked() {
    this.scrollIndicatorVisible = false;
    if (this.scrollContainer) {
      try {
        const element = this.scrollContainer.nativeElement;
        element.scrollTo(0, element.scrollHeight - element.clientHeight);
      } catch (e) {
        SLLogger.warn(e);
      }
    }
  }

  private showSnackbar(translationKey: string) {
    this.translateService.get(translationKey).subscribe((text) => this.snackBar.open(text, null, { duration: 4000 }));
  }

  hasAnswerToSend() {
    return this.answerContainerComponent ? this.answerContainerComponent.hasChosenAnswer() : false;
  }

  private canSendAnotherAnswer() {
    if (this.previousVotingAnswer && this.activeVoting) {
      if (this.activeVoting.type === VotingType.OpenText || this.activeVoting.type === VotingType.Photo) {
        return this.activeVoting.maxAnswersPerAttendee == null || (this.previousVotingAnswer.answers as Array<any>).length < this.activeVoting.maxAnswersPerAttendee;
      } else {
        return this.activeVoting.votesLeft != null && this.activeVoting.votesLeft > 0;
      }
    }
    return true;
  }

  private hasSentVote() {
    return this.previousVotingAnswer != null;
  }

  showTooLateError() {
    if (!this.tooLateErrorShown) {
      this.tooLateErrorShown = true;
      this.sendVoteState = ProgressState.Empty;
      this.metaService.stopAlternatingTitle();

      this.scrollIndicatorVisible = false;
      this.hasShownScrollIndicator = true;

      setTimeout(() => {
        // Check again, because if it's not shown anymore, a new voting was loaded
        if (this.tooLateErrorShown) {
          this.close();
        }
      }, VotingComponent.ERROR_TOO_LATE_CLOSE_DELAY);
    }
  }

  showQuizResult(quizResultData?: QuizResultChangedEvent) {
    if (!this.quizResultShown || !this.quizResult?.calculationFinished) {
      if(this.isWorkflow)
        this.quizResult = this.workflowQuizPointsService?.calculateQuizResult(this.activeVoting, quizResultData);
      else
        this.quizResult = this.presentationQuizPointsService?.calculateQuizResult(this.activeVoting, quizResultData);

      if (this.quizResult) {
        this.quizResultShown = true;
        this.sendVoteState = ProgressState.Empty;
        this.metaService.stopAlternatingTitle();

        this.scrollIndicatorVisible = false;
        this.hasShownScrollIndicator = true;

        if (this.quizResult.calculationFinished) {
          setTimeout(() => {
            // Check again, because if it's not shown anymore, a new voting was loaded
            if (this.quizResultShown) {
              this.close();
            }
          }, VotingComponent.QUIZ_RESULT_SHOWN_CLOSE_DELAY);
        }
      }
    }
  }

  close() {
    this.onShouldHide.emit();
  }

  ngOnDestroy(): void {
    this.metaService.stopAlternatingTitle();
  }

  canSkip(): boolean {
    return this.previousVotingAnswer != null || (this.activeVoting.votesLeft != null && this.activeVoting.votesLeft <= 0);
  }

  isSendButtonRequired() {
    return this.answerContainerComponent?.shouldShowSendButton() ?? true;
  }
}
