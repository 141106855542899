import { BaseModel } from '@sl/common/model';
import { FeedbackAnswerOption } from './FeedbackAnswerOption';

export class FeedbackValue {
  public feedbackOptionId: string;
  public value: any;

  constructor(feedbackOptionId: string, value: any) {
    this.feedbackOptionId = feedbackOptionId;
    this.value = value;
  }
}

export class FeedbackOption extends BaseModel {
  public title: string;
  public type: FeedbackOptionType;
  public order: number;
  public required: boolean;
  public answerOptions: FeedbackAnswerOption[];

  constructor(id: string, title: string, type: FeedbackOptionType, required: boolean = false, order: number = 0, answerOptions: FeedbackAnswerOption[] = []) {
    super(null);
    this.id = id;
    this.title = title;
    this.type = type;
    this.order = order;
    this.required = required;
    this.answerOptions = answerOptions;
  }
}

export enum FeedbackOptionType {
  StarRating5 = 'rating5',
  StarRating6 = 'rating6',
  Text = 'text',
  NumberRating5 = 'points5',
  NumberRating6 = 'points6',
  YesNo = 'yesNo',
  SingleChoice = 'singleChoice'
}
