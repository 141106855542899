import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SLConfig } from '@sl/config/SLConfig';
import { I18nService } from '@sl/common/services/i18n/I18nService.service';

@Pipe({
  name: 'multiLang',
  pure: false,
})
export class MultiLanguageStringPipe implements PipeTransform, OnDestroy {
  private prevInputValue: string;
  private prevReturnValue: string;
  private subscription: Subscription;

  constructor(private i18nService: I18nService, private changeDetectorRef: ChangeDetectorRef) {}

  transform(value?: string): string {
    if (value != this.prevInputValue) {
      this.prevInputValue = value;

      this.dispose();
      this.subscription = this.i18nService.onLangChanged$.subscribe(() => {
        const newReturnValue = this.parseMultiLanguageString(value);

        if (this.prevReturnValue !== newReturnValue) {
          this.prevReturnValue = newReturnValue;
          this.changeDetectorRef.markForCheck();
        }
      });

      this.prevReturnValue = this.parseMultiLanguageString(value);
    }
    return this.prevReturnValue;
  }

  parseMultiLanguageString(value?: string) {
    if (value) {
      try {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(value, 'text/xml');
        const rootNode = xmlDoc.getElementsByTagName('Translations')[0];
        const translationNodes = rootNode.getElementsByTagName('Translation');

        if (translationNodes?.length > 0) {
          const defaultLanguage = rootNode.getAttribute('default');
          let defaultTranslation: string = translationNodes[0].childNodes[0].nodeValue;

          const preferredLanguages = this.i18nService.getLanguagePriorities();
          const translations = this.getTranslations(translationNodes);
          translations.forEach((t) => this.i18nService.addLanguage(t.lang));

          for (let preferredLang of preferredLanguages) {
            for (let translation of translations) {
              if (translation.lang === preferredLang) {
                return translation.text;
              } else if (translation.lang === defaultLanguage) {
                defaultTranslation = translation.text;
              }
            }
          }

          return defaultTranslation;
        }
      } catch (e) {}
    }
    return value;
  }

  dispose() {
    this.subscription?.unsubscribe();
    this.subscription = undefined;
  }

  ngOnDestroy(): void {
    this.dispose();
  }

  private getTranslations(translationNodes: HTMLCollectionOf<Element>) {
    const translations: { lang: string; text: string }[] = [];
    for (let i = 0; i < translationNodes.length; i++) {
      const translation = translationNodes[i];
      const lang = translation.getAttribute('Lang') || translation.getAttribute('lang');
      const text = translation.childNodes[0].nodeValue;
      translations.push({ lang, text });
    }
    return translations;
  }
}
