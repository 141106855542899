<form #form="ngForm" fxLayout="column" fxLayoutAlign="start center">
  <div *ngIf="answers" class="previousAnswerImageContainer">
    <img *ngFor="let answer of answers" [src]="answer | safe: 'url'" [@anim]="answer" />
  </div>

  <ng-container *ngIf="canAddAnswer(); else maxAnswersExceededInfo">
    <img *ngIf="newAnswer?.previewImageUrl" class="previewImage" [src]="newAnswer.previewImageUrl | safe: 'url'" />

    <button mat-raised-button color="primary" (click)="fileInput.click()" [disabled]="disabled || sendVoteState == ProgressState.Loading || sendVoteState == ProgressState.Empty">{{ 'presentation.votings.photo.add-answer' | translate }}</button>

    <input hidden #fileInput type="file" accept="image/png, image/jpeg" (change)="onPhotoSelected(fileInput.files)" />
  </ng-container>

  <mat-hint *ngIf="!disabled" class="foreground-text2-color-background">
    <ng-template [ngIf]="maxAnswers" [ngIfElse]="addUnlimitedAnswers">
      <translate-count-string *ngIf="activeVoting.votesLeft > 0; else noAnswerSentYet" [count]="activeVoting.votesLeft" count1Text="presentation.votings.photo.add-1-more-answer" countMultipleText="presentation.votings.photo.add-x-more-answers"> </translate-count-string>

      <ng-template #noAnswerSentYet>
        <translate-count-string [count]="maxAnswers" count1Text="presentation.votings.photo.1-answer-possible" countMultipleText="presentation.votings.photo.x-answers-possible"> </translate-count-string>
      </ng-template>
    </ng-template>
    <ng-template #addUnlimitedAnswers>
      {{ 'presentation.votings.photo.add-unlimited-answers' | translate }}
    </ng-template>
  </mat-hint>

  <ng-template #maxAnswersExceededInfo>
    <p class="foreground-text2-color-background" style="font-style: italic;">
      {{ 'presentation.votings.photo.max-answers-exceeded' | translate: { maxAnswers: maxAnswers } }}
    </p>
  </ng-template>
</form>
