import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[ngModel][capitalize]',
})
export class CapitalizeInputDirective {
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  @HostListener('input', ['$event'])
  onInputChange($event: any) {
    this.value = this.capitalize($event.target.value);
    this.ngModelChange.emit(this.value);
  }

  capitalize(value: string): string {
    if (value) {
      return value.charAt(0).toUpperCase() + value.substr(1);
    }
    return value;
  }
}
