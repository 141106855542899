import { BaseDataService } from '@sl/common/services/endpoint/BaseDataService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { filter, take } from 'rxjs/operators';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { SessionService } from '@sl/common/services/auth/SessionService';

export abstract class BaseAuthorizedDataService extends BaseDataService {
  protected constructor(protected httpClient: HttpClient, protected sessionService: SessionService) {
    super(httpClient);
  }

  protected async getDynamicDefaultHeaders(): Promise<HttpHeaders> {
    try {
      if (TextUtils.isNullOrEmpty(this.sessionService.sessionToken.value)) {
        await this.sessionService.sessionToken
          .pipe(
            filter((token) => !TextUtils.isNullOrEmpty(token)),
            take(1)
          )
          .toPromise();
      }

      return new HttpHeaders({ auth: this.sessionService.sessionToken.value });
    } catch (err) {
      SLLogger.warn(err);
    }

    return new HttpHeaders();
  }
}
