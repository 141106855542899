import { Injectable } from '@angular/core';
import { LocalStorageService } from '@sl/common/services/storageaccess/LocalStorageService';
import { VotingAnswerEntry } from '@sl/common/model/local/VotingAnswerEntry';
import { QuizResult } from '@sl/pres/services/PresentationQuizPointsService';

export class SavedQuizResult {
  public votingId: string;
  public result: QuizResult;
  public points: number;

  constructor(votingId: string, result: QuizResult, points: number) {
    this.votingId = votingId;
    this.result = result;
    this.points = points;
  }
}

@Injectable({ providedIn: 'root' })
export class QuizResultCalculatedLocalStorageService extends LocalStorageService<SavedQuizResult[]> {
  private static readonly QUIZ_RESULT_CALCULATED = 'quiz-result-calculated';

  saveQuizResult(votingId: string, result: QuizResult, points: number) {
    let calculatedQuizResults = this.getValue(QuizResultCalculatedLocalStorageService.QUIZ_RESULT_CALCULATED);
    const quizResultCalculation = new SavedQuizResult(votingId, result, points);
    if (!calculatedQuizResults) {
      this.setValue(QuizResultCalculatedLocalStorageService.QUIZ_RESULT_CALCULATED, [quizResultCalculation]);
      return quizResultCalculation;
    }

    calculatedQuizResults = calculatedQuizResults.filter((value) => value.votingId !== votingId);
    calculatedQuizResults.push(quizResultCalculation);

    this.setValue(QuizResultCalculatedLocalStorageService.QUIZ_RESULT_CALCULATED, calculatedQuizResults);
    return quizResultCalculation;
  }

  getQuizResult(votingId: string): SavedQuizResult {
    const calculatedQuizResults = this.getValue(QuizResultCalculatedLocalStorageService.QUIZ_RESULT_CALCULATED);
    if (!calculatedQuizResults) {
      return undefined;
    }
    return calculatedQuizResults.find((value) => value.votingId === votingId);
  }
}
