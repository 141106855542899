<ng-template [ngIf]="activeVoting">
  <mat-card *ngFor="let answer of activeVoting.answerOptions; let i=index" class="answerContainer"
            fxFlex="100%"
            [title]="canMoveAnswers() ? ('presentation.votings.ranking.guidance.desktop' | translate) : ''"
            [ngClass]="{'enabled': canMoveAnswers()}"
            cdkDrag [cdkDragDisabled]="!canMoveAnswers()" [cdkDragData]="answer" cdkDragBoundary=".answersContainer"
            [style.cursor]="canMoveAnswers() ? 'move': 'auto'">

    <div fxLayout="column">
      <div *ngIf="(activeVoting.answerImageStyle == 'large' || !answer.text) && answer.imageUrl"
           class="largeAnswerImage">
        <img [src]="answer.imageUrl" class="imageContent selectedImage"
             [style.object-fit]="answer.imageUrl.includes('emoji') ? 'contain' : 'cover'"/>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="smallAnswerImageAndTextContainer">
        <p class="positionText foreground-text1-color-surface">{{i + 1}}.</p>
        <img *ngIf="(activeVoting.answerImageStyle == 'small' && answer.text) && answer.imageUrl"
             [src]="answer.imageUrl" class="smallAnswerImage"/>
        <p *ngIf="answer.text" class="answerText foreground-text1-color-surface" [innerHTML]="answer.text | multiLang"></p>

        <ng-template [ngIf]="canMoveAnswers()">
          <div *ngIf="showUpDownButtons" fxLayout="column"
               class="drag-buttons-container foreground-text2-color-surface">
            <button mat-button disableRipple [disabled]="i <= 0" (click)="moveAnswer(answer, -1)"
                    [title]="'presentation.votings.ranking.move-up' | translate">
              <svg viewBox="0 0 24 24">
                <path d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"/>
              </svg>
            </button>
            <button mat-button disableRipple [disabled]="i >= activeVoting.answerOptions.length - 1"
                    (click)="moveAnswer(answer, +1)" [title]="'presentation.votings.ranking.move-down' | translate">
              <svg viewBox="0 0 24 24">
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
              </svg>
            </button>
          </div>

          <svg *ngIf="!showUpDownButtons" cdkDragHandle class="drag-handle foreground-text2-color-surface"
               viewBox="0 0 24 24">
            <path d="M21 11H3V9H21V11M21 13H3V15H21V13Z"/>
          </svg>
        </ng-template>

      </div>
    </div>

  </mat-card>

  <div *ngIf="canMoveAnswers()" fxLayout="row" class="info" fxLayoutAlign="center" fxFlex="100%">
    <div class="foreground-text2-color-background" inlineSVG="assets/img/ic_information_outline.svg"></div>
    <p
      class="foreground-text2-color-background">{{ ('presentation.votings.ranking.guidance.' + (isDesktop ? 'desktop' : 'mobile')) | translate }}</p>
  </div>
</ng-template>
