import { Component, OnInit } from '@angular/core';
import { BaseVideoPlayerComponent } from '@sl/common/components/video/base-video-player.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'sl-vimeo-video-player',
  templateUrl: './vimeo-video-player.component.html',
  styleUrls: ['./vimeo-video-player.component.scss'],
})
export class VimeoVideoPlayerComponent extends BaseVideoPlayerComponent {
  public static readonly VIDEO_URL_REGEXES = [new RegExp(/https:\/\/player\.vimeo\.com\/video\/([^?\/]*)[\/?]?.*/), new RegExp(/https:\/\/vimeo\.com\/event\/([^?\/]*)\/embed/), new RegExp(/https:\/\/vimeo\.com\/([^?\/]*)[\/?]?.*/)];

  saveLiveStreamUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  protected onSourceSet() {
    super.onSourceSet();

    for (let regex of VimeoVideoPlayerComponent.VIDEO_URL_REGEXES) {
      const match = regex.exec(this.source);
      if (match) {
        const videoId = match[1];
        const embedUrl = `https://player.vimeo.com/video/${videoId}`;
        this.saveLiveStreamUrl = this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
        return;
      }
    }

    this.saveLiveStreamUrl = null;
  }
}
