<mat-card class="history-item">
  <img #coverImage [sl-image]="presentationHistoryEntry" class="image" (error)="coverImage.src = '/assets/img/presentation_image_placeholder.png'" />
  <h5 id="overlay" class="image" style="position: absolute; top: 0; background-color: black; transition: opacity 0.3s ease; color: white; text-align: center; line-height: 200px;">
    {{ 'attendance-history.view-presentation' | translate }}
  </h5>

  <h5 style="margin: 8px 16px 0; overflow: hidden;">{{ presentationHistoryEntry.name | multiLang }}</h5>
  <p class="foreground-text2-color-surface" style="margin: 0 16px 16px;">{{ presentationHistoryEntry.attendedAt | date: 'longDate' }}</p>

  <div *ngIf="presentationHistoryEntry.speakers && presentationHistoryEntry.speakers.length > 0" fxLayout="row" style="margin: 0 16px;" fxLayoutAlign="start center">
    <div style="position: relative; height: 44px;">
      <img #img *ngFor="let speaker of presentationHistoryEntry.speakers; let i = index" [style.left]="i * 24 + 'px'" [sl-image]="speaker" [matTooltip]="speaker | fullname" matTooltipShowDelay="100" class="speaker-image fit-image" (error)="img.src = '/assets/img/person_image_empty.png'" />
    </div>
    <p style="line-height: 20px;" [style.margin-left]="(presentationHistoryEntry.speakers.length - 1) * 24 + 44 + 16 + 'px'">
      {{ presentationHistoryEntry.speakers | speakers }}
    </p>
  </div>
</mat-card>
