import { LoginProvider } from '@sl/common/services/auth/LoginProvider';
import { ContactInfo } from '@sl/common/model';
import { Subject } from 'rxjs';
import { AuthProviderKey } from '@sl/common/services/endpoint/dto/LoginPayload';
import { SLUtils } from '@sl/common/utils/SLUtils';

export class GoogleLoginProvider implements LoginProvider {
  getKey(): AuthProviderKey {
    return AuthProviderKey.Google;
  }

  getToken(): string {
    return this.getCurrentUser().getAuthResponse().id_token;
  }

  getProfileData(): Promise<ContactInfo> {
    const profile = new Subject<ContactInfo>();

    const ci = new ContactInfo();
    const googleProfile = this.getCurrentUser().getBasicProfile();
    ci.firstName = googleProfile.getGivenName();
    ci.lastName = googleProfile.getFamilyName();

    ci.emailAddresses = [];
    ci.emailAddresses[0] = { type: 'private', emailAddress: googleProfile.getEmail() };

    ci.imageUrl = googleProfile.getImageUrl();

    SLUtils.completeSubjectDelayed(profile, ci);

    return profile.toPromise();
  }

  private getCurrentUser() {
    return gapi.auth2.getAuthInstance().currentUser.get();
  }
}
