import { Component, Input } from '@angular/core';
import { TextUtils } from 'app/common/utils/TextUtils';
import { ContactInfoOverview } from '@sl/common/model';
import { ContactInfoFullNamePipe } from '@sl/common/pipes/ContactInfoFullNamePipe';

@Component({
  selector: 'contact-image',
  templateUrl: 'contact-image.component.html',
})
export class ContactImageComponent {
  static readonly COLORS = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'];

  @Input() contactInfoOverview: ContactInfoOverview;
  @Input() imageSize = '28px';
  @Input() fontSize = '16px';
  @Input() noNameImageColor = 'white';

  constructor(private fullNamePipe: ContactInfoFullNamePipe) {}

  hasImage() {
    if (this.contactInfoOverview) {
      return !TextUtils.isNullOrEmpty(this.contactInfoOverview.imageBlobId) || !TextUtils.isNullOrEmpty(this.contactInfoOverview.imageUrl);
    }
    return false;
  }

  getFirstCharOfName() {
    if (this.contactInfoOverview) {
      const name = this.fullNamePipe.transform(this.contactInfoOverview);

      if (name) {
        return name.charAt(0).toUpperCase();
      }
    }
    return null;
  }

  getColorForChar() {
    const char = this.getFirstCharOfName();
    if (!TextUtils.isNullOrEmpty(char)) {
      return ContactImageComponent.COLORS[char.charCodeAt(0) % ContactImageComponent.COLORS.length];
    } else {
      return ContactImageComponent.COLORS[0];
    }
  }
}
