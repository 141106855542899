import { Pipe, PipeTransform } from '@angular/core';
import { ContactInfoOverview } from '@sl/common/model/ContactInfoOverview';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { MultiLanguageStringPipe } from '@sl/common/pipes/MultiLanguageStringPipe';

@Pipe({ name: 'job' })
export class ContactInfoJobNamePipe implements PipeTransform {
  constructor(private multilangPipe: MultiLanguageStringPipe) {}

  transform(ci: ContactInfoOverview): string {
    const position = this.multilangPipe.transform(ci.position);
    return TextUtils.isNullOrEmpty(position) ? ci.company : TextUtils.isNullOrEmpty(ci.company) ? position : position + ' @ ' + ci.company;
  }
}
