import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { VotingState } from '@sl/common/model/Voting';
import {
  BlockingStateChangedEvent,
  MaxReachableQuizPointsChangedEvent,
  QuizResultChangedEvent
} from '@sl/pres/services/Events';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { PushConnectionState } from '@sl/common/services/BasePushMessageService';
import { NGUtils } from '@sl/common/utils/NGUtils';
import { AttendeeWorkflowPushService } from './AttendeeWorkflowPushService';
import { WorkflowDataService } from './workflow-data.service';
import { Workflow } from '../model/Workflow';

export enum LiveConnectionState {
  Disconnected,
  ConnectedPush,
  ConnectedPolling,
}

enum PushMessages {
  BlockingStateChanged = 'blocking-states-changed',
  QuizResultChanged = 'quiz-result-changed'
}

@Injectable()
export class WorkflowStateHub {
  private static readonly POLLING_DURATION = 3000;

  private pollingSubscription: Subscription;
  private isStateUpdateRunning: boolean;

  private attendeePushConnectionStateSubscription: Subscription;

  public blockingStateChanged: Subject<BlockingStateChangedEvent>;
  public maxReachableQuizPointsChanged: Subject<MaxReachableQuizPointsChangedEvent>;
  public quizResultChanged: Subject<QuizResultChangedEvent>;


  workflow: Workflow;
  currentStateSubscription: Subscription;

  constructor(private attendeeWorkflowPushService: AttendeeWorkflowPushService, private workflowDataService: WorkflowDataService) {
    this.initSubjects();
  }

  public init(workflow: Workflow) {
    if (!this.workflow || this.workflow.id !== workflow.id) {
      this.workflow = workflow;
      this.isStateUpdateRunning = false;

      this.attendeeWorkflowPushService.init(workflow);
      this.attachPushEventListeners();

      this.attendeePushConnectionStateSubscription = this.attendeeWorkflowPushService.connectionState.pipe(distinctUntilChanged()).subscribe((pushConnectionState) => {
        if (pushConnectionState === PushConnectionState.Connected) {
          this.stopStatePolling();
        } else {
          this.forceStateUpdate();
          this.startStatePolling();
        }
      });
      return true;
    } else {
      // Update reference to presentationInstance object, since it can be new after loading again from backend
      this.workflow = workflow;

      return false;
    }
  }

  private initSubjects() {
    this.blockingStateChanged = new ReplaySubject<BlockingStateChangedEvent>(1);
    this.maxReachableQuizPointsChanged = new ReplaySubject<MaxReachableQuizPointsChangedEvent>(1);
    this.quizResultChanged = new ReplaySubject<QuizResultChangedEvent>(1);
  }

  private attachPushEventListeners() {
    this.attendeeWorkflowPushService.listen(PushMessages.BlockingStateChanged, (event) => this.blockingStateChanged.next(new BlockingStateChangedEvent(event.blockingStates)));
    this.attendeeWorkflowPushService.listen(PushMessages.QuizResultChanged, (event) => this.quizResultChanged.next(new QuizResultChangedEvent(event.voting, event.gainedQuizPoints, event.answerState)));
  }

  public forceStateUpdate() {
    if (this.isStateUpdateRunning || !this.workflow || !this.workflow.id) {
      return;
    }

    this.isStateUpdateRunning = true;
    this.currentStateSubscription = this.workflowDataService.getCurrentState(this.workflow.id).subscribe(
      (state) => {
        this.isStateUpdateRunning = false;

        this.maxReachableQuizPointsChanged.next(new MaxReachableQuizPointsChangedEvent(state.maxReachableQuizPoints ?? 0));
      },
      (error) => {
        SLLogger.warn('Updating state failed %o', error);
        this.isStateUpdateRunning = false;
      }
    );
  }

  public disconnect() {
    this.unsubscribeAll();
    if (this.attendeePushConnectionStateSubscription && !this.attendeePushConnectionStateSubscription.closed) {
      this.attendeePushConnectionStateSubscription.unsubscribe();
    }

    if (this.currentStateSubscription && !this.currentStateSubscription.closed) {
      this.currentStateSubscription.unsubscribe();
    }
    this.stopStatePolling();

    this.initSubjects();
  }

  private startStatePolling() {
    if (!this.pollingSubscription || this.pollingSubscription.closed) {
      SLLogger.log('Starting polling');
      this.pollingSubscription = timer(WorkflowStateHub.POLLING_DURATION, WorkflowStateHub.POLLING_DURATION)
        .pipe(filter((_) => !this.isStateUpdateRunning && (document.visibilityState ? document.visibilityState === 'visible' : true)))
        .subscribe((_) => this.forceStateUpdate());
    }
  }

  private stopStatePolling() {
    if (this.pollingSubscription && !this.pollingSubscription.closed) {
      SLLogger.log('Stopping polling');
      this.pollingSubscription.unsubscribe();
    }
  }

  public unsubscribeAll() {
    NGUtils.unsubscribeAllSubscribers(
      this.blockingStateChanged,
      this.maxReachableQuizPointsChanged
    );
  }
}
