import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { BaseOverlayComponent } from '@sl/common/components/BaseOverlayComponent';
import { Subject } from 'rxjs';

export abstract class BaseOverlayService<T extends BaseOverlayComponent> {
  protected overlayRef: OverlayRef;
  protected resultSubject: Subject<any>;

  constructor(private overlay: Overlay) {}

  createOverlay() {
    this.resultSubject = new Subject();
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    const portal = new ComponentPortal(this.getComponent());
    const containerRef = this.overlayRef.attach(portal);

    this.overlayRef.detachments().subscribe((_) => {
      this.resultSubject.next(containerRef.instance.result);
      this.resultSubject.complete();
    });
    return containerRef.instance;
  }

  public isOverlayOpen() {
    return this.overlayRef && this.overlayRef.hasAttached();
  }

  public closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  protected abstract getComponent(): ComponentType<T>;

  protected getOverlayConfig(): OverlayConfig {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'dark-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.getPositionStrategy(),
    });

    if (!this.isLargeScreen()) {
      overlayConfig.height = '100%';
    }
    return overlayConfig;
  }

  protected getPositionStrategy() {
    const strategy = this.overlay.position().global().centerHorizontally();

    if (this.isLargeScreen()) {
      strategy.centerVertically();
    } else {
      strategy.top('0px');
    }

    return strategy;
  }

  protected isLargeScreen() {
    return window.matchMedia('screen and (min-width: 700px) and (min-height: 400px)').matches;
  }
}
