<ng-template [ngIf]="activeVoting">
  <mat-card *ngFor="let answer of getFilteredAnswerOptions(); let i = index" class="answerContainer" [ngClass]="{ clickable: canSelectAnswers(), selected: isAnswerSelected(answer) }" (click)="onAnswerSelected(answer)" fxFlex="100%" fxFlex.gt-xs="45%">
    <div fxLayout="column">
      <div *ngIf="(activeVoting.answerImageStyle == 'large' || !answer.text) && answer.imageUrl" class="largeAnswerImage">
        <img [src]="answer.imageUrl" class="imageContent selectedImage" />
        <div class="zoomButton foreground-text1-color-surface" (click)="$event.stopPropagation(); showAnswerImage(answer)" inlineSVG="assets/img/ic_fullscreen.svg"></div>
        <div *ngIf="isAnswerSelected(answer)" class="selectedImage selectedImageOverlay" fxLayoutAlign="center center">
          <div class="answerSelectedIndicatorLarge foreground-color-secondary" inlineSVG="/assets/img/ic_voting_answer_selected_indicator.svg"></div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="smallAnswerImageAndTextContainer">
        <img *ngIf="activeVoting.answerImageStyle == 'small' && answer.text && answer.imageUrl" [src]="answer.imageUrl" class="smallAnswerImage" />
        <p *ngIf="answer.text" class="answerText foreground-text1-color-surface" [innerHTML]="answer.text | multiLang"></p>
        <div *ngIf="activeVoting.answerImageStyle == 'small' && answer.text && isAnswerSelected(answer)" class="answerSelectedIndicatorSmall foreground-color-secondary" inlineSVG="/assets/img/ic_voting_answer_selected_indicator.svg"></div>
      </div>
    </div>
  </mat-card>
</ng-template>
