import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAuthorizedDataService } from '@sl/common/services/endpoint/BaseAuthorizedDataService';
import { SessionService } from '@sl/common/services/auth/SessionService';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { VotingType } from '@sl/common/model/Voting';
import { ChoiceAnswerPayload } from '@sl/common/services/endpoint/dto/ChoiceAnswerPayload';
import { OpenTextAnswerPayload } from '@sl/common/services/endpoint/dto/OpenTextAnswerPayload';

@Injectable({ providedIn: 'root' })
export class InteractionDataService extends BaseAuthorizedDataService {
  constructor(protected httpClient: HttpClient, protected sessionService: SessionService) {
    super(httpClient, sessionService);
  }

  public postVotingAnswers(sourceId: string, votingId: string, votingType: VotingType, isQuiz: boolean, answers: ChoiceAnswerPayload | OpenTextAnswerPayload) {
    if (this.isMockingEnabled()) {
      return of('test').pipe(delay(1000));
    }

    let votePath: string;
    switch (votingType) {
      case VotingType.SingleChoice:
      case VotingType.MultipleChoice:
      case VotingType.PointsDistribution:
        votePath = isQuiz ? 'quiz' : 'poll';
        break;
      case VotingType.OpenText:
        votePath = 'opentext';
        break;
      case VotingType.Ranking:
        votePath = isQuiz ? 'quiz' : 'ranking';
        break;
      case VotingType.Photo:
        votePath = 'photo';
        break;
    }

    return this.post<any>(`voting/${votingId}/source/${sourceId}/vote/${votePath}`, answers);
  }
}
