import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'sl-boolean-input',
  templateUrl: './boolean-input.component.html',
  styleUrls: ['./boolean-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanInputComponent),
      multi: true,
    },
  ],
})
export class BooleanInputComponent implements ControlValueAccessor {
  @Input()
  disabled = false;

  _value?: boolean;

  set value(val: string) {
    this._value = val === 'true';
    this.propagateChanged(this._value);
  }

  get value() {
    return this._value != null ? String(this._value) : undefined;
  }

  propagateChanged = (_: any) => {};

  writeValue(value: boolean): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  registerOnTouched(fn: any): void {}
}
