export class VotingStatistic {
  totalVoters: number;
  votes?: Array<AnswerOptionVoteCount>;
  openTextVotes?: Array<OpenTextVote>;
  photoVotes?: Array<PhotoVote>;
  lastVotedAt?: number;
}

export class OpenTextVote {
  createdAt: number;
  answer: string;
}

export class PhotoVote {
  createdAt: number;
  imageUrl: string;
  isApproved: boolean;
}

export class AnswerOptionVoteCount {
  answerOptions_id: string;
  voteCount: number;

  constructor(answerOptions_id: string, voteCount: number) {
    this.answerOptions_id = answerOptions_id;
    this.voteCount = voteCount;
  }
}
