import { Theme, ThemeAnalytics, ThemeContactInfoField, ThemeFont } from '@sl/common/model/Theme';
import { AuthProviderKey } from '@sl/common/services/endpoint/dto/LoginPayload';

export function getFallbackTheme(analytics?: ThemeAnalytics[]): Theme {
  return {
    colors: {
      primaryColor: '#673AB7',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: '#A1416E',
      text1OnSecondaryColor: '#FFF',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#424242',
      text2OnSurfaceColor: 'rgba(0, 0, 0, 0.54)',

      backgroundColor: '#F5F5F5',
      text1OnBackgroundColor: 'var(--text1OnSurfaceColor)',
      text2OnBackgroundColor: 'var(--text2OnSurfaceColor)',

      dialogBackgroundColor: 'var(--backgroundColor)',
      text1OnDialogBackgroundColor: 'var(--text1OnBackgroundColor)',
      text2OnDialogBackgroundColor: 'var(--text2OnBackgroundColor)',

      footerBackground: 'var(--backgroundColor)',
      text1OnFooterColor: 'var(--text1OnBackgroundColor)',
      text2OnFooterColor: 'var(--text2OnBackgroundColor)',

      errorColor: '#f44336',
      text1OnErrorColor: 'white',

      // Other colors
      toolbarBackground: 'linear-gradient(-135deg, #A64269 0%, #8E3F87 100%)',
      headerBackground: 'linear-gradient(-135deg, #BF454A 0%, #A64269 30%, #8E3F87 60%, #673AB7 100%)',
      highlightColor: 'rgba(0, 0, 0, 0.13)',
      dividerColor: 'lightgrey',
    },
    fonts: [new ThemeFont('Lato', 'https://fonts.gstatic.com/s/lato/v15/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2', 'woff2', 500)],
    logoUrl: '',
    analytics,
    removeSlideLizardBranding: false,
    showHelpOthersConnect: true,
    allowAnonymousQuestions: true,
    availableAuthProviders: [AuthProviderKey.Google, AuthProviderKey.Facebook, AuthProviderKey.SlideLizard],
    contactInfoFields: [
      { name: ThemeContactInfoField.Firstname, order: 0 },
      { name: ThemeContactInfoField.Lastname, order: 1 },
      { name: ThemeContactInfoField.Email, order: 2 },
    ],
  };
}
