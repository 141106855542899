import { Address, BaseModel, PresentationInstanceState } from '@sl/common/model';
import { Accessibility, ExternalContentAvailability } from '@sl/common/model/ContentRestrictions';

export class PresentationInstance extends BaseModel {
  public state: PresentationInstanceState;
  public startedAt: number;
  public endedAt: number;
  public isQuestionsEnabled: boolean;
  public isFeedbackEnabled: boolean;
  public address: Address;
  public connectionCode: string;
  public exportedSlidesDocument: ExportedSlidesDocument;
  public settings: PresentationInstanceSettings;
}

export class PresentationInstanceSettings {
  gdpr: GDPRInfo;
  slideNotesEnabled: boolean;
  slideStarringEnabled: boolean;
}

export class GDPRInfo {
  isActivated: boolean;
  text: String;
}

export class ExportedSlidesDocument {
  url: string;
  availability: ExternalContentAvailability;
  accessibility: Accessibility;
}

export enum SlideAvailability {
  BEGIN_PRESENTATION = 'beginPresentation',
  AFTER_PRESENTATION = 'afterPresentation',
  UNTIL_CURRENT_SLIDE = 'untilCurrentSlide',
  ONLY_CURRENT_SLIDE = 'onlyCurrentSlide',
}
