<div overlayContainer fxLayout="column" fxFlexAlign="center center">
  <mat-toolbar class="background-color-dialogBackground">
    <mat-toolbar-row>
      <div class="clickable-round toolbar-button foreground-text1-color-dialogBackground" (click)="close(false)" inlineSVG="assets/img/ic_close.svg"></div>
      <span class="foreground-text1-color-dialogBackground">{{ (!authService.currentUser || !checkHasUserData(authService.currentUser.contactInfo) ? 'auth.login' : 'profile.edit-long') | translate }}</span>
    </mat-toolbar-row>
  </mat-toolbar>

  <sl-edit-contact-info (onContactInfoSaved)="onContactInfoSaved()" (onLoggedIn)="onLoginSuccessful()"></sl-edit-contact-info>
</div>
