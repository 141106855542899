import { Injectable } from '@angular/core';
import { LocalStorageService } from '@sl/common/services/storageaccess/LocalStorageService';

@Injectable({ providedIn: 'root' })
export class PreferredLanguageStorageService extends LocalStorageService<string> {
  private static readonly LANGUAGE = 'language';

  getPreferredLanguage() {
    return super.getValue(PreferredLanguageStorageService.LANGUAGE);
  }

  setPreferredLanguage(value: string) {
    return super.setValue(PreferredLanguageStorageService.LANGUAGE, value);
  }
}
