import { BaseModel } from '@sl/common/model/BaseModel';

export class VotingAnswerOption extends BaseModel {
  public id: string;
  public text: string;
  public imageUrl: string;
  public order: number;
  public isCorrectAnswer: boolean;

  constructor(json: any = null, id: string = '', text: string = '', imageUrl: string = '', order: number = 1, isCorrectAnswer: boolean = false) {
    super(json);
    this.id = id;
    this.text = text;
    this.imageUrl = imageUrl;
    this.order = order;
    this.isCorrectAnswer = isCorrectAnswer;
  }
}
