import { Component, Input } from '@angular/core';
import { Address } from '@sl/common/model/Address';
import { TranslateService } from '@ngx-translate/core';
import { WindowUtils } from '@sl/common/utils/WindowUtils';

@Component({
  selector: 'location-item',
  templateUrl: 'location-item.component.html',
})
export class LocationItemComponent {
  @Input() address: Address;

  constructor(private translateService: TranslateService) {}

  onClick() {
    WindowUtils.openSafely('http://maps.google.com/?q=' + this.computeAddressString().replace(' ', '+'));
    // ga('send', 'event', 'presentation_interaction', 'clicked', 'location');
  }

  computeFirstLine(): string {
    if (!this.address) {
      return '';
    }
    if (this.address.locationName) {
      return this.address.locationName;
    }
    return this.computeAddressString();
  }

  computeSecondLine(): string {
    if (!this.address) {
      return '';
    }
    if (this.address.locationName) {
      return this.computeAddressString();
    }
    if (this.address.type) {
      return this.translateService.instant('contact-detail.type.' + this.address.type);
    }
    return '';
  }

  computeAddressString(): string {
    if (!this.address) {
      return '';
    }
    let text = '';
    if (this.address.street) {
      text += this.address.street;
    }
    if (this.address.zip) {
      text += ', ' + this.address.zip;
    }
    if (this.address.city) {
      text += ' ' + this.address.city;
    }
    if (this.address.country) {
      text += ' ' + this.address.country;
    }
    return text;
  }
}
