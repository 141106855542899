import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { BaseVideoPlayerComponent } from '@sl/common/components/video/base-video-player.component';
import { AzureVideoPlayerComponent } from '@sl/common/components/video/azure-video-player/azure-video-player.component';
import { Html5VideoPlayerComponent } from '@sl/common/components/video/html5-video-player/html5-video-player.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { YoutubeVideoPlayerComponent } from '@sl/common/components/video/youtube-video-player/youtube-video-player.component';
import { VimeoVideoPlayerComponent } from '@sl/common/components/video/vimeo-video-player/vimeo-video-player.component';
import { NGUtils } from '@sl/common/utils/NGUtils';

@Component({
  selector: 'sl-video-player',
  template: '<ng-template #videoPlayerContainer></ng-template>',
})
export class VideoPlayerComponent extends BaseVideoPlayerComponent implements AfterViewInit, OnDestroy {
  private static readonly VIDEO_PLAYERS = new Map<any, RegExp[]>([
    [AzureVideoPlayerComponent, AzureVideoPlayerComponent.VIDEO_URL_REGEXES],
    [Html5VideoPlayerComponent, Html5VideoPlayerComponent.VIDEO_URL_REGEXES],
    [YoutubeVideoPlayerComponent, YoutubeVideoPlayerComponent.VIDEO_URL_REGEXES],
    [VimeoVideoPlayerComponent, VimeoVideoPlayerComponent.VIDEO_URL_REGEXES],
  ]);

  @ViewChild('videoPlayerContainer', { read: ViewContainerRef })
  videoPlayerContainer?: ViewContainerRef;

  private videoPlayerRef?: ComponentRef<BaseVideoPlayerComponent>;
  private unsubscribe$ = new Subject<void>();

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    super();
  }

  ngAfterViewInit(): void {
    NGUtils.deferChange(() => this.loadVideoPlayerComponent());
  }

  protected onSourceSet() {
    this.loadVideoPlayerComponent();
  }

  private loadVideoPlayerComponent() {
    if (this.videoPlayerContainer) {
      this.videoPlayerContainer.clear();
      this.unsubscribe$.next();

      if (this.source) {
        const matchingVideoPlayerComponent = VideoPlayerComponent.findMatchingVideoPlayerComponent(this.source);
        if (matchingVideoPlayerComponent) {
          this.videoPlayerRef = this.videoPlayerContainer.createComponent<BaseVideoPlayerComponent>(this.componentFactoryResolver.resolveComponentFactory(matchingVideoPlayerComponent));
          this.setupVideoPlayerInstance(this.videoPlayerRef.instance);
        } else {
          SLLogger.warn(`Couldn't find video player for url: ${this.source}`);
        }
      }
    }
  }

  private static findMatchingVideoPlayerComponent(source: string) {
    for (let [key, regexes] of Array.from(VideoPlayerComponent.VIDEO_PLAYERS.entries())) {
      const isFittingPlayer = regexes.some((regex) => regex.exec(source) != null);

      if (isFittingPlayer) {
        return key;
      }
    }
    return null;
  }

  private setupVideoPlayerInstance(instance: BaseVideoPlayerComponent) {
    instance.source = this.source;
    instance.controlsShown = true;

    instance.onReady
      .asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onReady.emit());
    instance.onError
      .asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => this.onError.emit(error));
  }

  play() {
    this.videoPlayerRef?.instance.play();
  }

  pause() {
    this.videoPlayerRef?.instance.pause();
  }

  isPlaying(): boolean {
    return this.videoPlayerRef?.instance.isPlaying();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public static canPlaySource(source: string) {
    return this.findMatchingVideoPlayerComponent(source) != null;
  }
}
