import { BaseModel } from '@sl/common/model/BaseModel';
import { Accessibility, ExternalContentAvailability } from '@sl/common/model/ContentRestrictions';

export class ExternalContent extends BaseModel {
  public id: string;
  public representationString: string;
  public name: string;
  public order: number;
  public description: string;
  public mimeType: string;
  public availability: ExternalContentAvailability;
  public accessibility: Accessibility;

  constructor(json: any = null, name: string = '', description: string = '', mimeType: string = 'website', availability: ExternalContentAvailability = ExternalContentAvailability.BEGIN_PRESENTATION, accessibility: Accessibility = Accessibility.UNRESTRICTED, representationString: string = null) {
    super(json);
    this.name = name;
    this.description = description;
    this.mimeType = mimeType;
    this.availability = availability;
    this.accessibility = accessibility;
    this.representationString = representationString;
  }
}

export class ExternalContentMimeType {
  static readonly Picture = new ExternalContentMimeType('image', 'Image');
  static readonly Website = new ExternalContentMimeType('website', 'Website');
  static readonly TextDocument = new ExternalContentMimeType('textDoc', 'Text document', 'text-doc');
  static readonly CalculationDocument = new ExternalContentMimeType('calcDoc', 'Calculation document', 'calc-doc');
  static readonly SlideDocument = new ExternalContentMimeType('presentationDoc', 'Slides', 'slide-doc');
  static readonly Video = new ExternalContentMimeType('video', 'Video');
  static readonly Music = new ExternalContentMimeType('audio', 'Audio');
  static readonly Book = new ExternalContentMimeType('book', 'Book');
  static readonly Other = new ExternalContentMimeType('other', 'Other');
  // Virtual mimetype used only identify the manually added external content for synced slides
  static readonly SyncedSlides = new ExternalContentMimeType('syncedSlides', 'Slides', 'slide-doc');
  // Virtual mimetype used only identify live streams
  static readonly Live = new ExternalContentMimeType('live', 'Live-Stream', 'live');

  static readonly values = [
    ExternalContentMimeType.Picture,
    ExternalContentMimeType.Website,
    ExternalContentMimeType.TextDocument,
    ExternalContentMimeType.CalculationDocument,
    ExternalContentMimeType.SlideDocument,
    ExternalContentMimeType.Video,
    ExternalContentMimeType.Music,
    ExternalContentMimeType.Book,
    ExternalContentMimeType.Other,
    ExternalContentMimeType.SyncedSlides,
    ExternalContentMimeType.Live,
  ];

  constructor(public name: string, public displayName = name, public iconName: string = name) {}

  static getMimeType(mimeTypeString: string): ExternalContentMimeType {
    for (const mimeType of ExternalContentMimeType.values) {
      if (mimeType.name === mimeTypeString) {
        return mimeType;
      }
    }
    return null;
  }

  toString() {
    return this.name;
  }
}
