import { Component, Input } from '@angular/core';

@Component({
  selector: 'word-grid',
  templateUrl: './word-grid.component.html',
  styleUrls: ['./word-grid.component.scss'],
})
export class WordGridComponent {
  _chartData: Array<ChartValue>;

  @Input()
  set chartData(value: Array<ChartValue>) {
    this._chartData = value;
  }

  get chartData() {
    return this._chartData;
  }
}
