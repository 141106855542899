import { BaseModel } from '@sl/common/model/BaseModel';

export class FeedbackAnswerOption extends BaseModel {
  public id: string;
  public text: string;
  public order: number;

  constructor(json: any = null, id: string = '', text: string = '', order: number = 1) {
    super(json);
    this.id = id;
    this.text = text;
    this.order = order;
  }
}
