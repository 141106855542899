import { BaseModel } from '@sl/common/model';
import { WorkflowStep } from './WorkflowStep';

export class Workflow extends BaseModel<Workflow> {
  name: string;
  state: WorkflowState;

  steps: WorkflowStep[];
}

export enum WorkflowState {
  Running = 'running',
  Finished = 'finished',
}
