<div *ngIf="!isProduction" fxLayout="column" style="max-height: 300px;">
  <div style="height: 1px; width: 100%; background-color: var(--dividerColor); margin: 12px 0;"></div>
  <p class="foreground-text1-color-background" style="margin-left: 16px;">Debug settings</p>

  <div fxLayout="row wrap" fxLayoutAlign="space-between" style="padding: 0 16px 16px;">
    <mat-checkbox [(ngModel)]="fakePresentationRunning" [ngModelOptions]="{ standalone: true }" style="font-size: 0.875rem; margin-top: 16px;"><span class="foreground-text1-color-background">Fake Running</span> </mat-checkbox>

    <mat-select style="width: 40%; margin-top: 16px;" [value]="getCurrentWebservice()" (selectionChange)="onWebserviceBaseUriChanged($event)">
      <mat-option value="https://develop.slidelizard.at/api">Develop</mat-option>
      <mat-option value="https://servicetest.slidelizard.at/api">Test</mat-option>
      <mat-option value="https://service.slidelizard.at/api">Production</mat-option>
    </mat-select>
  </div>
</div>
