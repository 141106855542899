import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Directive } from '@angular/core';
import { Observable, timer, of } from 'rxjs';
import { ContactInfoGuesserDataService } from '@sl/common/services/endpoint/contactInfoGuesser/ContactInfoGuesserDataService';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { SLLogger } from '@sl/common/utils/SLLogger';

@Directive({
  selector: '[emailValid][formControlName], [emailValid][formControl], [emailValid][ngModel]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: EMailValidDirective, multi: true }],
})
export class EMailValidDirective implements AsyncValidator {
  constructor(private contactInfoGuesserDataService: ContactInfoGuesserDataService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (TextUtils.isNullOrEmpty(control.value)) {
      return of(null);
    }

    return timer(400).pipe(
      switchMap(() => this.contactInfoGuesserDataService.getEMailValid(control.value)),
      map((result) => {
        return result.valid ? null : { emailInvalid: true };
      }),
      catchError((e) => {
        SLLogger.warn(e);
        return of(null);
      }),
      first()
    );
  }
}
