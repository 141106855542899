import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { I18nService } from '@sl/common/services/i18n/I18nService.service';
import { ISO_LANGUAGE_CODES } from '@sl/common/services/i18n/LanguageCodes';

@Component({
  selector: 'sl-language-chooser',
  templateUrl: './language-chooser.component.html',
  styleUrls: ['./language-chooser.component.scss'],
})
export class LanguageChooserComponent {
  constructor(public i18nService: I18nService) {}

  onLanguageChanged(data: MatSelectChange) {
    const newLang = data.value;

    this.i18nService.setLanguage(newLang);
    this.i18nService.setPreferredLanguage(newLang);
  }

  getLanguageName(key: string) {
    return ISO_LANGUAGE_CODES[key]?.nativeName ?? key;
  }
}
