import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FeedbackOptionType } from '@sl/common/model';
import { CustomFormElement, CustomFormElementType, CustomFormElementValue } from '@sl/common/model/CustomForm';
import { NgForm } from '@angular/forms';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { AcceptTermsComponent } from '@sl/common/components/accept-terms/AcceptTermsComponent';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { NGUtils } from '@sl/common/utils/NGUtils';

type ElementType = FeedbackOptionType | CustomFormElementType;

@Component({
  selector: 'sl-custom-form',
  templateUrl: './custom-form.component.html',
  styleUrls: ['./custom-form.component.scss'],
})
export class CustomFormComponent implements AfterViewInit {
  ElementType = { ...FeedbackOptionType, ...CustomFormElementType };

  _elements: CustomFormElement<ElementType>[];

  @Input()
  set elements(value: CustomFormElement<ElementType>[]) {
    this._elements = value;
    NGUtils.runAfterNextRender(() => this.configureSpezialHTMLTriggers());
  }

  get elements() {
    return this._elements;
  }

  @Input()
  values: CustomFormElementValue[];

  @Input()
  disabled: boolean;

  @Output()
  onValuesChanged = new EventEmitter<CustomFormElementValue[]>();

  @Output()
  onValidChanged = new EventEmitter<boolean>();

  @ViewChild('form', { static: true })
  form: NgForm;

  @ViewChild('form', { read: ElementRef })
  formElement: ElementRef;

  @ViewChild('acceptTerms')
  acceptTerms: AcceptTermsComponent;

  @ViewChild('acceptTerms', { read: ElementRef })
  acceptTermsElement: ElementRef;

  getValue(formElementId: string) {
    return this.values?.find((value) => value.formElementId === formElementId)?.value;
  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.onValidChanged.emit(this.form.valid);
      this.onValuesChanged.emit(this.getFormValues());
    });
  }

  getSortedElements() {
    return this.elements?.sort((a, b) => a.order - b.order);
  }

  isValid() {
    return this.form.valid;
  }

  getFormValues() {
    const values = [];
    if (this.elements) {
      for (const option of this.elements) {
        let value = this.form.value[option.id];
        if (option.type === this.ElementType.Text && !TextUtils.isNullOrEmpty(value)) {
          value = value.trim();
        }
        if (value == null) {
          // Ignore empty values
          continue;
        }
        values.push(new CustomFormElementValue(option.id, value));
      }
    }

    return values;
  }

  hasEnteredText() {
    if (this.elements) {
      for (const option of this.elements) {
        if (option.type === this.ElementType.Text && !TextUtils.isNullOrEmptyTrimmed(this.form.value[option.id])) {
          return true;
        }
      }
    }
    return false;
  }

  showAcceptTermsRequired() {
    try {
      this.acceptTermsElement.nativeElement.scrollIntoView();
    } catch (e) {
      SLLogger.warn(e);
    }
    this.acceptTerms.showRequired();
  }

  configureSpezialHTMLTriggers() {
    const closeElements = (this.formElement.nativeElement as HTMLElement).querySelectorAll('[href=close]');
    closeElements?.forEach((element) =>
      element.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();

        const w = window.open(window.location.href, '_self');
        w.close();
      })
    );
  }
}
