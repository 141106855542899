import { BaseModel } from '@sl/common/model/BaseModel';

export class Address extends BaseModel {
  public locationName: string;
  public type: string;
  public street: string;
  public zip: string;
  public city: string;
  public country: string;
}
