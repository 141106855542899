import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAuthorizedDataService } from '@sl/common/services/endpoint/BaseAuthorizedDataService';
import { SessionService } from '@sl/common/services/auth/SessionService';
import { ContactInfo, ContactInfoOverview, User } from '@sl/common/model';
import { RegisterPayload } from '@sl/common/services/endpoint/dto/RegisterPayload';
import { AuthResponse } from '@sl/common/services/endpoint/dto/AuthResponse';
import { AttendedPresentationInfo } from '@sl/common/services/endpoint/dto/AttendedPresentationInfo';
import { SubscriptionInfo } from '@sl/common/services/endpoint/dto/SubscriptionInfo';
import { AttendanceInfo } from '@sl/common/services/endpoint/dto/AttendanceInfo';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserDataService extends BaseAuthorizedDataService {
  constructor(protected httpClient: HttpClient, protected sessionService: SessionService) {
    super(httpClient, sessionService);
  }

  public getUserMe() {
    return this.get<User>(`user/me`);
  }

  public getUserMeSubscriptionInfo() {
    // if(this.isMockingEnabled()){
    //   const subscriptionInfo = new SubscriptionInfo();
    //   subscriptionInfo.billingPeriod = BillingPeriod.Yearly;
    //   subscriptionInfo.plan = Plan.Basic;
    //   return of(subscriptionInfo);
    // }
    return this.get<SubscriptionInfo>(`user/me/subscriptioninfo`);
  }

  public updateUserMeContactInfo(contactInfo: ContactInfo) {
    return this.put<any>(`user/me/contactinfo`, contactInfo);
  }

  public upgradeAnonymous(auth: RegisterPayload) {
    return this.put<AuthResponse>(`user/me/upgrade/anonymous`, auth);
  }

  public getAttendedPresentations() {
    return this.get<AttendedPresentationInfo[]>(`user/me/attendance/presentationinfo`);
  }

  public getAttendanceInfo(presentationInstanceId: string) {
    if (this.isMockingEnabled()) {
      return of(new AttendanceInfo(null, null));
    }
    return this.get<AttendanceInfo>(`user/me/presentationinstance/${presentationInstanceId}/attendance`).pipe(
      map((attendanceInfo) => {
        // if (this.isMockingEnabled()) {
        //   attendanceInfo.slideNotes = null;
        // }
        return attendanceInfo;
      })
    );
  }

  public getWorkflowAttendanceInfo(workflowId: string) {
    if (this.isMockingEnabled()) {
      return of(new AttendanceInfo(null, null));
    }
    return this.get<AttendanceInfo>(`user/me/workflow/${workflowId}/attendance`).pipe(
      map((attendanceInfo) => {
        // if (this.isMockingEnabled()) {
        //   attendanceInfo.slideNotes = null;
        // }
        return attendanceInfo;
      })
    );
  }

  public getTenantUsers(tenantId: string): Observable<ContactInfoOverview[]> {
    if(this.isMockingEnabled()) {
      var arr: ContactInfoOverview[] = [];
      return of(arr);
    }
    return this.get<ContactInfoOverview[]>(`user/tenant/${tenantId}/users`);
  }

  public updateAttendanceInfo(presentationInstanceId: string, attendanceInfo: AttendanceInfo) {
    return this.put<any>(`user/me/presentationinstance/${presentationInstanceId}/attendance`, attendanceInfo);
  }


  public updateWorkflowAttendanceInfo(workflowId: string, attendanceInfo: AttendanceInfo) {
    return this.put<any>(`user/me/workflow/${workflowId}/attendance`, attendanceInfo);
  }

  public triggerSummaryEmail(presentationInstanceId: string) {
    return this.get<any>(`user/me/presentationinstance/${presentationInstanceId}/attendance/sendsummarymail`);
  }
}
