import { Component, Input } from '@angular/core';

@Component({
  selector: 'vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss'],
})
export class VerticalBarChartComponent {
  _chartData: Array<ChartValue>;

  @Input()
  set chartData(value: Array<ChartValue>) {
    this._chartData = value;
    this.maxValue = Math.max(...value.map((chartValue) => chartValue.value));
  }

  get chartData() {
    return this._chartData;
  }

  maxValue: number;
}
