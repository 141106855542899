<mat-list-item [ngClass]="{ clickable: clickable }" style="max-width: 100vw; box-sizing: border-box; height: unset; min-height: 48px; padding: 6px 0;">
  <img *ngIf="imageBlobId && !imageUrl" mat-list-avatar [sl-image]="{ imageBlobId: imageBlobId, imageUrl: null }" [style.borderRadius]="borderRadius" class="fit-image" style="width: 40px; height: 40px; background: darkgrey;" />
  <img *ngIf="imageUrl" mat-list-avatar [src]="imageUrl" style="height: 40px; border-radius: 0;" [ngClass]="imageClass" [style.borderRadius]="borderRadius" />
  <div *ngIf="imageSVG" mat-list-avatar [inlineSVG]="imageSVG" [ngClass]="imageClass" [style.borderRadius]="borderRadius"></div>

  <div class="mat-list-text">
    <h6 matLine [ngClass]="[firstLineTextClass ? firstLineTextClass : 'foreground-text1-color-surface', selectable ? 'selectable' : '']" [style.white-space]="multiline ? 'normal' : 'nowrap'" style="text-align: left;" [innerHTML]="firstLine"></h6>
    <p matLine *ngIf="secondLine" [ngClass]="[secondLineTextClass ? secondLineTextClass : 'foreground-text2-color-surface']" style="white-space: normal; text-align: left;" [innerHTML]="secondLine"></p>
  </div>
</mat-list-item>
