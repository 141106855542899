import { Component, Input } from '@angular/core';

@Component({
  selector: 'item',
  templateUrl: 'item.component.html',
})
export class ItemComponent {
  @Input() firstLine: string;
  @Input() firstLineTextClass: string;
  @Input() multiline = false;

  @Input() secondLine: string;
  @Input() secondLineTextClass: string;

  // Use either imageBlobId or imageUrl!
  @Input() imageBlobId: string;
  @Input() imageUrl: string;
  @Input() imageSVG: string;
  @Input() imageClass: string;

  @Input() selectable = false;
  @Input() clickable = true;
  @Input() borderRadius = '';
}
