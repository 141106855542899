import { SLLogger } from '@sl/common/utils/SLLogger';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';

export class KeyboardUtil {
  public static isEscapeKeyPress(event: KeyboardEvent) {
    return event.key === 'Escape' || event.key === 'Esc';
  }

  public static isValidKeyPress(event: KeyboardEvent, key: string) {
    if (!(document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLTextAreaElement)) {
      return event.key === key;
    }
    return false;
  }

  public static hideKeyboard() {
    if (document.activeElement) {
      try {
        (<any>document.activeElement).blur();
      } catch (e) {
        SLLogger.warn(e);
      }
    }
  }

  private static resizeEventListener: any;
  private static resizeTimer: any;

  public static setOnScreenKeyboardShownListener(listener: (keyboardShown: boolean) => void) {
    if (listener && DeviceDetect.isPhone()) {
      const originalHeight = window.innerHeight;
      this.resizeEventListener = (event: any) => this.checkKeyboardShown(originalHeight, listener);
      window.addEventListener('resize', this.resizeEventListener);
    }
  }

  private static checkKeyboardShown(originalHeight: number, listener: (keyboardShown: boolean) => void) {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      const keyboardShown = window.innerHeight <= originalHeight - 150;
      listener(keyboardShown);
    }, 250);
  }

  public static removeOnScreenKeyboardShownListener() {
    if (this.resizeEventListener) {
      window.removeEventListener('resize', this.resizeEventListener);
    }
  }
}
