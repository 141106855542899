import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';

@Directive({
  selector: '[overlayContainer]',
})
export class OverlayContainerDirective {
  constructor(private renderer: Renderer2, hostElement: ElementRef) {
    renderer.addClass(hostElement.nativeElement, 'overlay-container');
    renderer.addClass(hostElement.nativeElement, 'mat-elevation-z24');
    renderer.setStyle(hostElement.nativeElement, 'height', !DeviceDetect.isPhone() ? '100vh' : '');
  }
}
