import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SLConfig } from '@sl/config/SLConfig';


@Component({
  selector: 'avatar-grid-dialog',
  templateUrl: 'avatar-grid-dialog.component.html',
})
export class AvatarGridDialogComponent{
  constructor() {}

  selectedImageURL: string;

  avartarImages = [
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'cat.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'elephant.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'fox.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'zebra.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'lion.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'tiger.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'panda.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'giraffe.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'owl.png',
    SLConfig.getEnvironment().webservice.avatarBaseUri + 'koala.png',
  ];

  setImageUrl(imageURL: string) {
    this.selectedImageURL = imageURL;
  }
}
