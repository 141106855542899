export let langDE = {
  "site-title": "An Präsentation teilnehmen",
  "site-description": "Geben Sie den Präsentations-Code ein, um an Live-Umfragen teilzunehmen, Fragen zu stellen, Folien anzuzeigen und Feedback zu geben. Kein Download erforderlich.",
  "ok": "OK",
  "cancel": "Abbrechen",
  "retry": "Erneut probieren",
  "send": "Senden",
  "save": "Speichern",
  "yes": "Ja",
  "already-sent": "Schon gesendet",
  "check-again": "Nochmal versuchen",
  "loading": "Laden",
  "discard": "Verwerfen",
  "no-connection": "Keine Internet Verbindung",
  "error-maintenance": "SlideLizard wird gerade gewartet! Der Service wird in kürze wieder online sein!",
  "language": "Sprache",
  "help": "Hilfe",
  "search": "Suchen",
  "browser-not-supported": {
    "message": "Ihr Browser ({{name}}) ist relativ alt und wird daher nicht mehr offiziell unterstützt. Probleme können auftreten - bitte installieren Sie einen aktuellen Browser!"
  },
  "name": {
    "branded": "SlideLizard",
    "neutral": "dieser App"
  },
  "legal": {
    "accept-terms-active": "Ich akzeptiere die <a href='https://slidelizard.com/de/terms' target='_blank'>Nutzungsbedingungen</a> und <a href='https://slidelizard.com/de/privacy' target='_blank'>Datenschutzbestimmungen</a>.",
    "accept-terms-passive": "Durch die Benutzung von {{appName}} akzeptieren Sie die <a href='https://slidelizard.com/de/terms' target='_blank'>Nutzungsbedingungen</a> und <a href='https://slidelizard.com/de/privacy' target='_blank'>Datenschutzbestimmungen</a>.",
    "accept-terms-login": "Durch das Anmelden bei {{appName}} akzeptieren Sie die <a href='https://slidelizard.com/de/terms' target='_blank'>Nutzungsbedingungen</a> und <a href='https://slidelizard.com/de/privacy' target='_blank'>Datenschutzbestimmungen</a>.",
  },
  "auth": {
    "login": "Anmelden",
    "login-with": "Anmelden mit",
    "login-google": "Mit Google anmelden",
    "login-google-short": "Google",
    "login-facebook": "Mit Facebook anmelden",
    "login-facebook-short": "Facebook",
    "login-slidelizard": "Mit SlideLizard anmelden",
    "login-slidelizard-short": "SlideLizard",
    "login-in-progress": "Anmeldung wird durchgeführt...",
    "logout": "Abmelden",
    "error-login-failed": "Beim Anmelden ist ein Fehler aufgetreten!",
    "error-google-login": "Beim Anmelden mit Google ist ein Fehler aufgetreten!",
    "error-google-login-check-thirdparty-cookies": "Beim Anmelden mit Google ist ein Fehler aufgetreten! <br>Stellen Sie sicher, dass Cookies Dritter <a href='https://www.google.de/search?q=cookies+dritter+zulassen' target='_blank'>zugelassen</a> sind.",
    "error-facebook-login": "Beim Anmelden mit Facebook ist ein Fehler aufgetreten!",
  },
  "profile": {
    "edit-short": "Bearbeiten",
    "edit-long": "Profil bearbeiten",
    "title": "Ihr Profil",
    "enter-data": "oder bearbeiten Sie Ihre Daten",
    "enter-data-info": "Ihre Daten werden gespeichert, aber es wird kein Benutzerkonto erstellt.",
    "email": "Ihre E-Mail Adresse",
    "email-invalid": "Ungültige E-Mail Adresse",
    "email-invalid-provider": "E-Mail Anbieter nicht erlaubt",
    "firstname": "Ihr Vorname",
    "firstname-invalid": "Ungültiger Vorname",
    "lastname": "Ihr Nachname",
    "lastname-invalid": "Ungültiger Nachname",
    "phone-number": "Telefonnummer",
    "zip": "PLZ",
    "city": "Ort",
    "street": "Straße",
    "company": "Unternehmen",
    "position": "Position",
    "update-successful": "Profil wurde aktualisiert",
    "unsaved-data-title": "Wollen Sie Ihr Profil speichern?",
    "unsaved-data-message": "Sie haben Ihre Daten noch nicht gespeichert.",
    "error-save-failed": "Profil konnte nicht gespeichert werden!",
    "selectci": "Wie heißen Sie?",
    "other": "Anders",
    "pick-avatar": "Avatar auswählen",
  },
  "connect": {
    "join": "Teilnehmen",
    "enter-presentation-code": "Präsentations-Code",
    "enter-access-key": "Passwort",
    "private-presentation-info": "Diese Präsentation ist <b>privat</b>.",
    "private-presentation-access-key-info": "Bitte fragen Sie den Vortragenden nach dem Passwort.",
    "private-presentation-specific-user-info": "Nur ausgewählte Personen können teilnehmen.",
    "error-no-presentation": "Falscher Präsentations-Code!",
    "error-wrong-access-key": "Falsches Passwort!",
    "error-wrong-user": "Die Präsentation ist für Ihren Benutzer nicht freigegeben. Bitte melden Sie sich mit einem anderen an!",
    "error-generic": "Irgendetwas ist schiefgelaufen, bitte versuchen Sie es erneut!"
  },
  "attendance-history": {
    "title": "Teilgenommene Präsentationen & Konferenzen",
    "view-presentation": "Präsentation ansehen",
    "view-conference": "Konferenz ansehen",
    "loading": "Teilgenommene Präsentationen werden geladen...",
    "error": "Teilgenommene Präsentationen konnten nicht geladen werden!"
  },
  "contact-detail": {
    "company": "Unternehmen",
    "phone-numbers": "Telefonnummern",
    "email-addresses": "Email Adressen",
    "addresses": "Adressen",
    "social-networks": "Soziale Netzwerke",
    "website": "Webseite",
    "profile": "Profil",
    "type": {
      "home": "Zuhause",
      "private": "Privat",
      "work_mobile": "Arbeit Mobil",
      "office": "Büro",
      "work": "Arbeit",
      "mobile": "Mobil"
    }
  },
  "share-connection": {
    "share": "Teilen",
    "description": "Teile den Teilnehmer-Link um andere teilnehmen zu lassen!"
  },
  "conference": {
    "error-not-found": "Wir konnten die Konferenz leider nicht finden!",
    "connect-with-code": "Verbinde dich mit einem Konferenz-Code"
  },
  "presentation": {
    "info": "Info",
    "details": "Details",
    "starting-soon": "Präsentation startet bald",
    "main-view": {
      "switch": "{{first}} & {{second}} tauschen",
      "fullscreen-show": "Vollbild aktivieren",
      "fullscreen-hide": "Vollbild deaktivieren",
      "small-view-hide": "{{view}} ausblenden",
      "small-view-show": "{{view}} einblenden",
      "slides": "Folien"
    },
    "live-stream": {
        "name": "Live Stream",
      "error-zoom-cant-be-shown-mobile": {
        "text": "Diese Präsentation verwendet Zoom zum Videotelefonieren. </br>Wechseln Sie zwischen Zoom & SlideLizard um an Umfragen teilzunehmen.",
        "cta-open-external": "In Zoom App öffnen"
      },
      "error-zoom-cant-be-shown": {
        "text": "Diese Präsentation verwendet Zoom zum Videotelefonieren. </br><b>Klicken Sie um Zoom und SlideLizard Seite an Seite zu öffnen</b>.",
        "cta-open-external": "Zoom öffnen",
        "cta-resize-window": "SlideLizard Fenster neben Zoom platzieren",
      }
    },
    "external-content": {
      "title": "Geteilte Inhalte",
      "available-after-presentation": "Verfügbar nach der Präsentation",
      "available-after-presentation-info": "Dieser Inhalt ist erst nach der Präsentation verfügbar.",
      "now-available": "Jetzt verfügbar",
      "receive-by-email": "Geteilte Inhalte per E-Mail zusenden",
      "receive-by-email-afterwards": "Geteilte Inhalte nach der Präsentation per E-Mail erhalten",
      "will-be-received-by-email-afterwards": "Inhalte werden nach der Präsentation an {{email}} gesendet",
    },
    "email-required": {
      "info": "Um '{{title}}' zu <b>öffnen</b> verlangt der Vortragende Ihre <b>E-Mail Adresse</b>.",
      "option-enter-email": "Oder Ihre E-Mail Adresse",
      "unsaved-data-title": "Wollen Sie Ihre E-Mail speichern?",
      "unsaved-data-message": "Sie haben Ihre E-Mail Adresse noch nicht gespeichert.",
      "open": "Öffnen",
      "error-send-failed": "E-Mail konnte nicht gespeichert werden!"
    },
    "help-others-connect": "Hilf anderen sich zu verbinden",
    "error-not-found": "Wir konnten die Präsentation leider nicht finden!",
    "connect-with-code": "Verbinde dich mit einem Präsentations-Code",
    "speaker": "Vortragende",
    "receive-content-via-email": {
      "title-now": "Geben Sie Ihre E-Mail Adresse an um <b>{{content}}</b> per E-Mail zugesendet zu bekommen.",
      "cta-now": "Jetzt senden",
      "title-afterwards": "Geben Sie Ihre E-Mail Adresse an um <b>{{content}}</b> nach der Präsentation per E-Mail zugesendet zu bekommen.",
      "cta-afterwards": "Speichern",
      "slides": "die Folien",
      "notes": "private Notizen",
      "starred-slides": "markierte Folien",
      "speaker-contact-info": "Daten des Vortragenden",
      "external-content": "geteilte Dokumente"
    },
    "summary-email": {
      "successful": "Die E-Mail ist auf dem Weg!",
      "error": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut!"
    },
    "votings": {
      "title": "Abstimmungen",
      "vote": "An Abstimmung teilnehmen",
      "active": "Jetzt abstimmen",
      "no-active": "Aktuell ist keine Abstimmung aktiv",
      "type": {
        "single": "Single Choice",
        "multiple": "Multiple Choice",
        "openText": "Freitext",
        "ranking": "Sortierungs",
        "photo": "Foto",
        "pointsDistribution": "Punkteverteilung"
      },
      "poll": "{{type}} Umfrage",
      "quiz": "{{type}} Quiz ({{points}} Punkte)",
      "anonymous-poll": "Anonyme {{type}} Umfrage",
      "anonymous-quiz": "Anonymes {{type}} Quiz ({{points}} Punkte)",
      "quiz-correct-answer": "Gratulation, <br/><b>richtige Antwort</b>",
      "quiz-wrong-answer": "Leider <br/><b>falsche Antwort</b>",
      "quiz-no-answer": "<b>Keine</b> <br/>Antwort abgegeben",
      "quiz-waiting-for-result": "Antwort wird ausgewertet...",
      "quiz-gained-no-points":"Keine Punkte",
      "quiz-gained-1-point":"+{{count}} Punkt",
      "quiz-gained-x-points":"+{{count}} Punkte",
      "quiz-total-points":"Gesamt {{currentPoints}} / {{maxReachablePoints}}",
      "quiz-points-info":"Derzeit {{currentPoints}} / {{maxReachablePoints}} erreichbaren Quiz-Punkten",
      "send-vote": "Senden",
      "already-voted": "Antwort bereits gesendet",
      "vote-sent-successfully": "Ihre Antwort wurde gesendet!",
      "more-answers": "Weitere Antworten",
      "unsaved-data-title": "Wollen Sie eine Antwort senden?",
      "unsaved-data-message": "Sie haben noch nicht abgestimmt.",
      "voting-now-title": "Jetzt abstimmen!",
      "open-text": {
        "add-answer": "Antwort hinzufügen",
        "x-answers-possible": "{{count}} Antworten möglich",
        "1-answer-possible": "1 Antwort möglich",
        "add-x-more-answers": "{{count}} weitere Antworten möglich",
        "add-1-more-answer": "1 weitere Antwort möglich",
        "add-unlimited-answers": "Sie können beliebig viele Antworten senden",
        "max-answers-exceeded": "Maximale Anzahl an Antworten erreicht ({{maxAnswers}})",
        "error-invalid-input": "Eingabe nicht gültig"
      },
      "photo": {
        "add-answer": "Foto auswählen",
        "x-answers-possible": "Sie können {{count}} Fotos hochladen",
        "1-answer-possible": "",
        "add-x-more-answers": "{{count}} weitere Fotos möglich",
        "add-1-more-answer": "1 weiteres Foto möglich",
        "add-unlimited-answers": "Sie können beliebig viele Fotos hochladen",
        "max-answers-exceeded": "Maximale Anzahl an Foto erreicht ({{maxAnswers}})",
        "error-file-too-large": "Das Bild ist zu groß. Bitte laden Sie eine Datei mit maximal {{maxFileSizeMb}}mb hoch."
      },
      "ranking": {
        "move-up": "Nach oben verschieben",
        "move-down": "Nach unten verschieben",
        "guidance": {
          "desktop": "Klicken und ziehen Sie Einträge um sie nach Priorität zu sortieren",
          "mobile": "Verschieben Sie Einträge mit dem Griff auf der rechten Seite um sie zu sortieren"
        }
      },
      "points-distribution": {
        "points-left":"<b>{{points}}</b> Punkte verbleibend"
      },
      "error-send-answer": "Beim Senden der Antwort ist ein Fehler aufgetreten!",
      "error-send-answer-voting-already-finished": "Leider zu spät!<br> Abstimmung bereits beendet.",
      "error-load-failed": "Abstimmung konnten nicht geladen werden!",
      "blocked":"Sie sind für Abstimmungen nicht zugelassen"
    },
    "feedback": {
      "title": "Feedback abgeben",
      "number-rating": {
        "indicator-left": "",
        "indicator-right": ""
      },
      "yes-no-rating": {
        "yes": "Ja",
        "no": "Nein",
      },
      "anonymous": "Feedback ist anonym",
      "already-left": "Feedback schon gesendet",
      "sent-successfully": "Ihr Feedback wurde gesendet!",
      "unsaved-data-title": "Wollen Sie Ihr Feedback senden?",
      "unsaved-data-message": "Sie haben Ihr Feedback noch nicht gesendet.",
      "error-send-failed": "Beim Senden des Feedbacks ist ein Fehler aufgetreten!",
      "error-load-failed": "Feedback konnte nicht geladen werden!",
    },
    "question": {
      "ask": "Frage stellen",
      "send-as": "Senden als",
      "anonymous": "Anonym",
      "identify": "Name / E-Mail angeben",
      "placeholder": "Frage",
      "sent-successfully": "Ihre Frage wurde gesendet!",
      "unsaved-data-title": "Wollen Sie Ihre Frage senden?",
      "unsaved-data-message": "Sie haben Ihre Frage noch nicht gesendet.",
      "error-send-failed": "Beim Senden der Frage ist ein Fehler aufgetreten!",
      "anonymous-info": "Wenn Sie eine Frage anonym stellen, kann der Vortragende diese nach der Präsentation nicht mehr beantworten!",
      "error-identification-needed": "Name oder E-Mail muss angegeben werden um die Frage zu senden (anonyme Fragen wurden vom Vortragenden deaktiviert).",
      "blocked":"Sie sind für Fragen nicht zugelassen"
    },
    "slides": {
      "title": "Präsentationsfolien",
      "available-after-presentation": "Folien sind nach der Präsentation verfügbar",
      "sent-via-email-after-presentation": "Sie bekommen die Folien via E-Mail nach der Präsentation zugesendet.",
      "receive-via-email-after-presentation": "Geben Sie Ihre E-Mail Adresse an um die Folien nach der Präsentation zugesendet zu bekommen.",
      "receive-via-email-now": "Geben Sie Ihre E-Mail Adresse an um die Folien zugesendet zu bekommen.",
      "download-now": "Sie müssen Ihre E-Mail Adresse angeben um die Folien herunterzuladen.",
      "choose-download-or-receive-via-email": "Sie können sich die Folien entweder via E-Mail zusenden lassen, oder herunterladen und direkt auf diesem Gerät ansehen.",
      "choose-download-or-receive-via-email-short": "Jetzt herunterladen oder per E-Mail bekommen",
      "click-to-view": "Klicken um anzusehen",
      "download": "Herunterladen",
      "receive-via-email": "Als E-Mail bekommen",
      "receive-via-email-afterwards": "Klicken um via E-Mail zu bekommen",
      "will-be-received-by-email-afterwards": "Folien werden nach der Präsentation an {{email}} gesendet",
      "slide-animation-in-progress": "Die Folie wird sichtbar nachdem alle Elemente angezeigt werden",
      "slide-shown-on-large-screen": "Folie nur am Beamer sichtbar",
      "slide-not-shared": "Folie wurde nicht geteilt",
      "live-short": "Live",
      "live-long": "Aktuelle Folie",
      "toggle-fullscreen": "Vollbildansicht umschalten",
      "go-live-short": "Zu Live",
      "go-live-long": "Zur aktuellen Folie",
      "rotate-device": "Bitte drehen Sie Ihr Telefon!"
    },
    "live": {
      "connection-push": "Live",
      "connection-polling": "Schlechte Verbindung",
      "no-connection": "Keine Verbindung",
    },
    "attendee-message": {
      "new": "Neue Nachricht"
    },
    "notes": {
      "title": "Notizen",
      "add": "Private Notizen hinzufügen",
      "click-to-add": "Klicke um private Notizen hinzuzufügen",
      "click-to-add-for-slide": "Klicke um private Notizen zu Folie {{slideNumber}} hinzuzufügen",
      "not-editable": "Für diese Folie können keine Notizen hinzugfügt  werden",
      "no-notes": "Keine privaten Notizen",
      "receive-by-email": "Notizen per E-Mail zusenden",
      "receive-by-email-afterwards": "Notizen per E-Mail nach der Präsentation erhalten",
      "will-be-received-by-email-afterwards": "Notizen werden nach der Präsentation an {{email}} gesendet",
      "not-saved": "Ihre privaten Notizen wurden noch nicht gespeichert! Wollen Sie sie jetzt speichern?",
      "error-not-saved": "Ihre Notizen & markierten Folien konnten nicht gespeichert werden!"
    },
    "stars": {
      "star": "Folie markieren",
      "unstar": "Folie nicht mehr markieren",
      "receive-via-email-afterwards": "Wollen Sie Ihre markierten Folien nach der Präsentation per E-Mail bekommen?",
      "will-be-received-by-email-afterwards": "Sie bekommen Ihre markierten Folien nach der Präsentation per E-Mail."
    }
  },
  "plan-activation": {
    "title": "Verwalten Sie Ihren Plan",
    "login": "Anmelden",
    "logout": "Abmelden",
    "confirm": "Bestätigen",
    "current-plan": "Aktueller Plan",
    "change-plan": "Ändern",
    "cancel-plan": "Beenden",
    "next": "Weiter",
    "active-since": "Aktiv seit",
    "invoice-address": "Rechnungsadresse",
    "plan": {
      "PLAN_FREE": "FREE",
      "PLAN_TRIAL": "PRO Probephase",
      "PLAN_BASIC": "BASIC",
      "PLAN_PRO": "PRO",
      "PLAN_ENTERPRISE": "ENTERPRISE",
      "PLAN_SMALL_BUSINESS": "SMALL BUSINESS",
      "PLAN_BUSINESS": "BUSINESS",
      "PLAN_ENTERPRISE_NEW": "ENTERPRISE",
    },
    "site-title": "Plan ändern",
    "upgrade-plan": "Upgrade zu {{plan}}",
    "choose-plan": {
      "title": "Plan wählen",
      "period-monthly": "Monat",
      "period-yearly": "Jahr"
    },
    "cancel-email": {
      "subject": "SlideLizard Abo beenden",
      "body": "Bitte beenden Sie das Abo ({{plan}}) für {{email}}."
    },
    "payment-details": {
      "title": "Zahlungsinformationen",
      "period": {
        "title": "Zahlungsperiode",
        "yearly": "Jährlich",
        "monthly": "Monatlich"
      },
      "christmas-offer":"🎄 Weihnachtsangebot 🎄",
      "info": "Wir senden Ihnen eine Rechnung per E-Mail. <br><b>Sie können jederzeit kündigen.</b>",
      "firstname": "Vorname",
      "firstname-required": "Vorname ist erforderlich",
      "lastname": "Nachname",
      "lastname-required": "Nachname ist erforderlich",
      "email": "E-Mail Adresse",
      "email-required": "Bitte geben Sie eine gültige E-Mail Adresse ein",
      "street": "Straße",
      "street-required": "Straße ist erforderlich",
      "city": "Ort",
      "city-required": "Ort ist erforderlich",
      "zip": "PLZ",
      "zip-required": "PLZ ist erforderlich",
      "country": "Land",
      "country-required": "Land ist erforderlich",
      "company": "Firma (optional)",
      "vat-reg-no": "UID (optional)"
    },
    "summary": {
      "title": "Bestätigen Sie Ihre Eingaben",
      "chosen-plan": "Plan",
      "amount": "Preis",
      "exclusive-vat": "exkl. MwSt.",
      "payment-period": "Zahlungsperiode",
      "name": "Name",
      "company": "Firma",
      "vat-reg-no": "UID",
      "email": "E-Mail",
      "address": "Adresse",
      "subscribe": "Kostenpflichtig bestätigen",
      "invoice-info": "Sie bekommen eine Rechnung per E-Mail zugeschickt,<br> die Sie dann mit Kreditkarte bezahlen können.",
      "error": "Tut uns Leid, irgendetwas ist schief gelaufen!",
      "error-plan-already-activated": "Sie haben diesen Plan bereits gekauft!<br>Bitte wählen Sie einen anderen Plan aus."
    },
    "finish": {
      "title": "Gratulation!",
      "text": "Sie haben sich soeben dem Club von erfolgreichen Vortragenden angeschlossen, die SlideLizard verwenden!",
      "ppt-restart-info": "Bitte starten Sie PowerPoint neu, um Ihren neuen Plan zu aktivieren."
    }
  },
  "video-player": {
    "buffer-info": "Video lädt {{bufferPercentage}}%",
    "error": "Ein Fehler ist aufgetreten. Video konnte nicht geladen werden."
  },
  "ms-teams": {
    "enter-code-or-link": "Präsentations-Code oder Link eingeben",
    "error-wrong-code": "Keine Präsentation gefunden!",
    "setup-help": "Finden Sie den Code im SlideLizard-Tab in PowerPoint",
    "setup-help-info": "<a href='https://help.slidelizard.com/d/installation-2' target='_blank'>So verwenden Sie SlideLizard</a>",
    "setup-help-image": "/assets/img/ms-teams/tutorial-copy-presentation-code.de.png",
    "setup-finished": "Konfiguration fertig",
    "save-info": "Klicken Sie auf ‘Speichern’ um die SlideLizard Präsentation zu dem Termin hinzuzufügen.",
    "meeting-tab-info": "Während des Termins können Teilnehmer SlideLizard durch Klicken auf das Logo klicken."
  },
  "workflow": {
    "current-step": "Schritt {{currentStep}} von {{totalSteps}}",
    "next": "Weiter",
    "previous": "Zurück",
    "finished": {
      "title": "Die Abstimmung ist beendet",
      "text": ""
    },
    "error-not-found": "Die Abstimmung konnte nicht gefunden werden!"
  },
  "about": {
    "title": "About",
    "copyright": "© 2024 {{appName}}",
    "follow-facebook": "Folge uns auf Facebook",
    "visit-website": "Besuche unsere Webseite",
    "address": "<b>SlideLizard Software GmbH</b><br>Semmelweisstraße 34<br>4020 Linz / Österreich<br>",
    "imprint": "Impressum",
    "send-logs": "Protokolle senden"
  },
  "date-time": {
    "today": "Heute"
  }
};
