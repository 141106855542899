import { AfterViewChecked, Component, Input } from '@angular/core';
import { BaseVotingAnswerContainerComponent } from '@sl/common/components/voting/answer-types/BaseVotingAnswerContainerComponent';
import { Voting, VotingAnswerImageStyle, VotingType } from '@sl/common/model/Voting';
import { VotingAnswerEntry } from '@sl/common/model/local/VotingAnswerEntry';
import { ThemeService } from '@sl/common/services/theme/ThemeService';
import { FullscreenImageOverlayService } from '@sl/common/components/voting/answer-types/choice-answer/fullscreen-image-dialog/FullscreenImageOverlayService';
import { ChoiceAnswerPayload } from '@sl/common/services/endpoint/dto/ChoiceAnswerPayload';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { VotingAnswerOption } from '@sl/common/model/VotingAnswerOption';

@Component({
  selector: 'points-distribution-answer',
  templateUrl: './points-distribution-answer.component.html',
  styleUrls: ['./points-distribution-answer.component.scss'],
})
export class PointsDistributionAnswerComponent extends BaseVotingAnswerContainerComponent implements AfterViewChecked {
  @Input()
  set activeVoting(voting: Voting) {
    this._activeVoting = voting;
    this.populateSelectedChoiceOptions();
  }

  get activeVoting() {
    return this._activeVoting;
  }

  @Input()
  set previousVotingAnswer(value: VotingAnswerEntry) {
    this._previousVotingAnswer = value;
    this.populateSelectedChoiceOptions();
  }

  get previousVotingAnswer() {
    return this._previousVotingAnswer;
  }

  @Input()
  answerFilter?: string;

  selectedAnswersIds: string[];

  constructor(private themeService: ThemeService, private fullscreenImageOverlayService: FullscreenImageOverlayService) {
    super();
  }
  
  ngAfterViewChecked(){}

  populateSelectedChoiceOptions() {
    if (this.activeVoting) {
      if (!this.activeVoting.votesLeft && this.previousVotingAnswer?.answers) {
        let previousVotingAnswers: string[];
        previousVotingAnswers = (this.previousVotingAnswer.answers as ChoiceAnswerPayload).answerOptionIds;
        this.selectedAnswersIds = previousVotingAnswers;
      } else {
        this.selectedAnswersIds = [];
      }
    }
  }

  canSelectAnswers() {
    return !this.disabled && (this.sendVoteState === ProgressState.Content || this.sendVoteState === ProgressState.Error) && (this.activeVoting.maxVotes != null ? this.activeVoting.maxVotes > this.selectedAnswersIds.length : this.previousVotingAnswer == null);
  }

  getAnswerSelectedCount(answer: VotingAnswerOption) {
    return this.selectedAnswersIds.filter((answerId) => answer.id === answerId).length;
  }

  addPointToAnswer(answer: VotingAnswerOption) {
    if (!this.canSelectAnswers()) {
      return;
    }

    this.selectedAnswersIds.push(answer.id);
  }

  removePoint(answer: VotingAnswerOption) {
    this.selectedAnswersIds.splice(this.selectedAnswersIds.indexOf(answer.id), 1);
  }

  async getVotePayload(): Promise<ChoiceAnswerPayload> {
    //const answers = this.activeVoting.answerOptions.filter((item, index) => this.selectedAnswersIds[index]).map((item) => item.id);
    return { answerOptionIds: this.selectedAnswersIds };
  }

  hasChosenAnswer(): boolean {
    return this.selectedAnswersIds.length > 0;
  }

  shouldShowSendButton(): boolean {
    return true;
  }

  getFilteredAnswerOptions() {
    if (this.answerFilter) {
      const normalizedFilter = this.answerFilter.trim().toLowerCase();
      return this.activeVoting.answerOptions.filter((ao) => ao.text.toLowerCase().includes(normalizedFilter));
    }
    return this.activeVoting.answerOptions;
  }

  showAnswerImage(answer: VotingAnswerOption) {
    if (this._activeVoting.answerImageStyle === VotingAnswerImageStyle.Large && answer.imageUrl) {
      this.fullscreenImageOverlayService.open(answer.imageUrl);
      return;
    }
  }
}
