import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { BaseOverlayService } from '@sl/common/services/BaseOverlayService';
import { ContactInfoOverview } from '@sl/common/model';
import { ViewContactInfoDialogComponent } from '@sl/common/components/view-contact-info-dialog/ViewContactInfoDialogComponent';

@Injectable()
export class ViewContactInfoOverlayService extends BaseOverlayService<ViewContactInfoDialogComponent> {
  constructor(overlay: Overlay) {
    super(overlay);
  }

  protected getComponent(): ComponentType<ViewContactInfoDialogComponent> {
    return ViewContactInfoDialogComponent;
  }

  open(contactInfoOverview: ContactInfoOverview) {
    const instance = this.createOverlay();
    instance.overlayRef = this.overlayRef;
    instance.contactInfoOverview = contactInfoOverview;

    return this.overlayRef;
  }
}
