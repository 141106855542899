import { Input } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'translate-count-string',
  templateUrl: 'translate-count-string.component.html',
})
export class TranslateCountStringComponent {
  @Input()
  count: number;

  @Input()
  count1Text: string;

  @Input()
  countMultipleText: string;
}
