import { Injectable } from '@angular/core';
import { LocalStorageService } from '@sl/common/services/storageaccess/LocalStorageService';

@Injectable({ providedIn: 'root' })
export class AcceptedTermsStorageService extends LocalStorageService<any> {
  private static readonly HAS_ACCEPTED_TERMS = 'has_accepted_terms';
  private static readonly ACCEPTED_TERMS_DATETIME = 'accepted_terms_datetime';

  getHasAcceptedTerms() {
    return <boolean>super.getValue(AcceptedTermsStorageService.HAS_ACCEPTED_TERMS);
  }

  setHasAcceptedTerms(value: boolean) {
    if (value) {
      this.setAcceptedTermsDateTime(new Date());
    } else {
      this.setAcceptedTermsDateTime(null);
    }
    return super.setValue(AcceptedTermsStorageService.HAS_ACCEPTED_TERMS, value);
  }

  getAcceptedTermsDateTime() {
    return <Date>super.getValue(AcceptedTermsStorageService.HAS_ACCEPTED_TERMS);
  }

  private setAcceptedTermsDateTime(value: Date) {
    return super.setValue(AcceptedTermsStorageService.ACCEPTED_TERMS_DATETIME, value);
  }
}
