import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[allowedInputPattern]',
})
export class AllowedInputPatternDirective {
  @Input()
  allowedInputPattern: string;

  @HostListener('keypress', ['$event'])
  keypress(event: KeyboardEvent) {
    console.log(event);
    if (this.allowedInputPattern) {
      if (event.key === 'Backspace' || event.isComposing || event.metaKey) {
        return true;
      }

      const text = (<any>event.target).value + event.key;

      if (!text.match(this.allowedInputPattern)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
    return true;
  }
}
