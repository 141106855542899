export class Slide {
  public id: string;
  public slideNumber: number;
  public imageUrl: string;
  public animationCount: number;

  constructor(id: string, slideNumber: number, imageUrl: string) {
    this.id = id;
    this.slideNumber = slideNumber;
    this.imageUrl = imageUrl;
    this.animationCount = 0;
  }
}
