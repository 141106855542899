import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { PresentationDataService } from '@sl/common/services/endpoint/PresentationDataService';
import { VotingState } from '@sl/common/model/Voting';
import {
  LastAttendeeMessageChangedEvent,
  NewInstanceAvailableEvent,
  PresentationInstanceStateChangedEvent,
  ShowHideAction,
  ExternalContentVisibilityChangedEvent,
  VotingResultVisibilityChangedEvent,
  SlideChangedEvent,
  VotingStateChangedEvent,
  BlockingStateChangedEvent,
  MaxReachableQuizPointsChangedEvent,
  QuizResultChangedEvent,
  QuestionVisibilityChangedEvent,
  QuestionRankingChangedEvent,
} from '@sl/pres/services/Events';
import { AttendeePushService } from '@sl/pres/services/AttendeePushService';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { PushConnectionState } from '@sl/common/services/BasePushMessageService';
import { NGUtils } from '@sl/common/utils/NGUtils';
import { PresentationInstance, PresentationInstanceState } from '@sl/common/model';
import { VotingResult } from '@sl/common/model/VotingResult';

export enum LiveConnectionState {
  Disconnected,
  ConnectedPush,
  ConnectedPolling,
}

enum PushMessages {
  VotingStateChanged = 'voting-state-changed',
  NewInstanceAvailable = 'new-instance-available',
  AttendeeMessageReceived = 'attendee-message-received',
  ShowExternalContent = 'show-external-content',
  HideExternalContent = 'hide-external-content',
  ShowVotingResult = 'show-voting-result',
  HideVotingResult = 'hide-voting-result',
  ShowQuestion = 'show-question-text',
  HideQuestion = 'hide-question-text',
  SlideChanged = 'slide-changed',
  PresentationInstanceStateChanged = 'presentation-instance-state-changed',
  BlockingStateChanged = 'blocking-states-changed',
  QuizResultChanged = 'quiz-result-changed',
  QuestionRankingChanged = 'question-upvoted',
}

@Injectable()
export class PresentationStateHub {
  private static readonly POLLING_DURATION = 3000;

  private pollingSubscription: Subscription;
  private isStateUpdateRunning: boolean;

  public liveConnectionState: BehaviorSubject<LiveConnectionState>;
  private attendeePushConnectionStateSubscription: Subscription;

  public votingStateChanged: Subject<VotingStateChangedEvent>;
  public presentationInstanceStateStateChanged: Subject<PresentationInstanceStateChangedEvent>;
  public slideChanged: Subject<SlideChangedEvent>;
  public lastAttendeeMessageChanged: Subject<LastAttendeeMessageChangedEvent>;
  public newInstanceAvailable: Subject<NewInstanceAvailableEvent>;
  public externalContentVisibilityChanged: Subject<ExternalContentVisibilityChangedEvent>;
  public votingResultVisibilityChanged: Subject<VotingResultVisibilityChangedEvent>;
  public questionVisibilityChanged: Subject<QuestionVisibilityChangedEvent>;
  public blockingStateChanged: Subject<BlockingStateChangedEvent>;
  public maxReachableQuizPointsChanged: Subject<MaxReachableQuizPointsChangedEvent>;
  public quizResultChanged: Subject<QuizResultChangedEvent>;
  public questionRankingChanged: Subject<QuestionRankingChangedEvent>;

  presentationInstance: PresentationInstance;
  currentStateSubscription: Subscription;

  constructor(private attendeePushService: AttendeePushService, private presDataService: PresentationDataService) {
    this.initSubjects();
  }

  public init(presentationInstance: PresentationInstance) {
    if (!this.presentationInstance || this.presentationInstance.id !== presentationInstance.id) {
      this.presentationInstance = presentationInstance;
      this.isStateUpdateRunning = false;

      this.attendeePushService.init(presentationInstance);
      this.attachPushEventListeners();

      this.attendeePushConnectionStateSubscription = this.attendeePushService.connectionState.pipe(distinctUntilChanged()).subscribe((pushConnectionState) => {
        if (pushConnectionState === PushConnectionState.Connected) {
          this.liveConnectionState.next(LiveConnectionState.ConnectedPush);
          this.stopStatePolling();
        } else {
          this.forceStateUpdate();
          this.liveConnectionState.next(LiveConnectionState.ConnectedPolling);
          this.startStatePolling();
        }
      });
      return true;
    } else {
      // Update reference to presentationInstance object, since it can be new after loading again from backend
      this.presentationInstance = presentationInstance;

      return false;
    }
  }

  private initSubjects() {
    this.liveConnectionState = new BehaviorSubject<LiveConnectionState>(LiveConnectionState.Disconnected);
    this.newInstanceAvailable = new ReplaySubject<NewInstanceAvailableEvent>(1);
    this.votingStateChanged = new ReplaySubject<VotingStateChangedEvent>(1);
    this.presentationInstanceStateStateChanged = new ReplaySubject<PresentationInstanceStateChangedEvent>(1);
    this.slideChanged = new ReplaySubject<SlideChangedEvent>(1);
    this.lastAttendeeMessageChanged = new ReplaySubject<LastAttendeeMessageChangedEvent>(1);
    this.externalContentVisibilityChanged = new ReplaySubject<ExternalContentVisibilityChangedEvent>(1);
    this.votingResultVisibilityChanged = new ReplaySubject<VotingResultVisibilityChangedEvent>(1);
    this.blockingStateChanged = new ReplaySubject<BlockingStateChangedEvent>(1);
    this.maxReachableQuizPointsChanged = new ReplaySubject<MaxReachableQuizPointsChangedEvent>(1);
    this.quizResultChanged = new ReplaySubject<QuizResultChangedEvent>(1);
    this.questionVisibilityChanged = new ReplaySubject<QuestionVisibilityChangedEvent>(1);
    this.questionRankingChanged = new ReplaySubject<QuestionRankingChangedEvent>(1);
  }

  private attachPushEventListeners() {
    this.attendeePushService.listen(PushMessages.VotingStateChanged, (event) => this.votingStateChanged.next(new VotingStateChangedEvent(event.votingId, event.newState, event.voting)));
    this.attendeePushService.listen(PushMessages.NewInstanceAvailable, (event) => this.newInstanceAvailable.next(new NewInstanceAvailableEvent(event.newInstanceId)));
    this.attendeePushService.listen(PushMessages.AttendeeMessageReceived, () => this.forceStateUpdate());

    this.attendeePushService.listen(PushMessages.ShowExternalContent, (event) => this.externalContentVisibilityChanged.next(new ExternalContentVisibilityChangedEvent(event.externalContentId, ShowHideAction.Show)));
    this.attendeePushService.listen(PushMessages.HideExternalContent, (event) => this.externalContentVisibilityChanged.next(new ExternalContentVisibilityChangedEvent(event.externalContentId, ShowHideAction.Hide)));

    this.attendeePushService.listen(PushMessages.ShowVotingResult, (event) => this.votingResultVisibilityChanged.next(new VotingResultVisibilityChangedEvent(new VotingResult(event.voting, event.statistic), ShowHideAction.Show)));
    this.attendeePushService.listen(PushMessages.HideVotingResult, (event) => this.votingResultVisibilityChanged.next(new VotingResultVisibilityChangedEvent(new VotingResult(event.voting, null), ShowHideAction.Hide)));

    this.attendeePushService.listen(PushMessages.ShowQuestion, (event) => this.questionVisibilityChanged.next(new QuestionVisibilityChangedEvent(event, ShowHideAction.Show)));
    this.attendeePushService.listen(PushMessages.HideQuestion, (event) => this.questionVisibilityChanged.next(new QuestionVisibilityChangedEvent(event, ShowHideAction.Hide)));

    this.attendeePushService.listen(PushMessages.SlideChanged, (event) => {
      this.slideChanged.next(new SlideChangedEvent(Math.max(1, event.slideNumber), Math.max(0, event.animationNumber)));

      if (event.action) {
        switch (event.action.name) {
          case PushMessages.ShowExternalContent: {
            this.externalContentVisibilityChanged.next(new ExternalContentVisibilityChangedEvent(event.action.data.externalContentId, ShowHideAction.Show));
            break;
          }
          case PushMessages.ShowVotingResult: {
            this.votingResultVisibilityChanged.next(new VotingResultVisibilityChangedEvent(new VotingResult(event.action.data.voting, event.action.data.statistic), ShowHideAction.Show));
            break;
          }
        }
      }
    });
    this.attendeePushService.listen(PushMessages.PresentationInstanceStateChanged, (event) => {
      if (this.presentationInstance.state !== event.newState) {
        SLLogger.log("Instance state changed from '" + this.presentationInstance.state + "' to '" + event.newState + "'");
        this.presentationInstance.state = event.newState;
        if (event.state === PresentationInstanceState.Finished || event.state === PresentationInstanceState.Error) {
          this.presentationInstance.endedAt = new Date().getTime();
        }

        this.presentationInstanceStateStateChanged.next(new PresentationInstanceStateChangedEvent(event.newState));
      }
    });

    this.attendeePushService.listen(PushMessages.BlockingStateChanged, (event) => this.blockingStateChanged.next(new BlockingStateChangedEvent(event.blockingStates)));
    this.attendeePushService.listen(PushMessages.QuizResultChanged, (event) => this.quizResultChanged.next(new QuizResultChangedEvent(event.voting, event.gainedQuizPoints, event.answerState)));
    this.attendeePushService.listen(PushMessages.QuestionRankingChanged, (event) => this.questionRankingChanged.next(new QuestionRankingChangedEvent(event.questionId, event.upvotes)));
  }

  public forceStateUpdate() {
    if (this.isStateUpdateRunning || !this.presentationInstance || !this.presentationInstance.id) {
      return;
    }

    this.isStateUpdateRunning = true;
    this.currentStateSubscription = this.presDataService.getCurrentState(this.presentationInstance.id).subscribe(
      (state) => {
        this.isStateUpdateRunning = false;
        this.liveConnectionState.next(this.attendeePushService.connectionState.value === PushConnectionState.Connected ? LiveConnectionState.ConnectedPush : LiveConnectionState.ConnectedPolling);

        if (state.newInstanceId) {
          this.newInstanceAvailable.next(new NewInstanceAvailableEvent(state.newInstanceId));
          return;
        }

        if (this.presentationInstance && this.presentationInstance.state !== state.presentationInstanceState) {
          this.presentationInstanceStateStateChanged.next(new PresentationInstanceStateChangedEvent(state.presentationInstanceState));
        }

        this.slideChanged.next(new SlideChangedEvent(state.currentSlideNumber, state.currentAnimationNumber));

        if (state.runningVotingId) {
          this.votingStateChanged.next(new VotingStateChangedEvent(state.runningVotingId, VotingState.Running));
        } else {
          this.votingStateChanged.next(new VotingStateChangedEvent(null, VotingState.Finished));
        }

        if (state.lastAttendeeMessageId) {
          this.lastAttendeeMessageChanged.next(new LastAttendeeMessageChangedEvent(state.lastAttendeeMessageId));
        }

        this.maxReachableQuizPointsChanged.next(new MaxReachableQuizPointsChangedEvent(state.maxReachableQuizPoints ?? 0));
      },
      (error) => {
        SLLogger.warn('Updating state failed %o', error);
        this.isStateUpdateRunning = false;
        this.liveConnectionState.next(LiveConnectionState.Disconnected);
      }
    );
  }

  public disconnect() {
    this.unsubscribeAll();
    if (this.attendeePushConnectionStateSubscription && !this.attendeePushConnectionStateSubscription.closed) {
      this.attendeePushConnectionStateSubscription.unsubscribe();
    }

    if (this.currentStateSubscription && !this.currentStateSubscription.closed) {
      this.currentStateSubscription.unsubscribe();
    }
    this.stopStatePolling();
    this.attendeePushService.disconnect();
    this.liveConnectionState.next(LiveConnectionState.Disconnected);
    this.presentationInstance = null;

    this.initSubjects();
  }

  private startStatePolling() {
    if (!this.pollingSubscription || this.pollingSubscription.closed) {
      SLLogger.log('Starting polling');
      this.pollingSubscription = timer(PresentationStateHub.POLLING_DURATION, PresentationStateHub.POLLING_DURATION)
        .pipe(filter((_) => !this.isStateUpdateRunning && (document.visibilityState ? document.visibilityState === 'visible' : true)))
        .subscribe((_) => this.forceStateUpdate());
    }
  }

  private stopStatePolling() {
    if (this.pollingSubscription && !this.pollingSubscription.closed) {
      SLLogger.log('Stopping polling');
      this.pollingSubscription.unsubscribe();
    }
  }

  public unsubscribeAll() {
    NGUtils.unsubscribeAllSubscribers(
      this.votingStateChanged,
      this.presentationInstanceStateStateChanged,
      this.slideChanged,
      this.lastAttendeeMessageChanged,
      this.liveConnectionState,
      this.newInstanceAvailable,
      this.externalContentVisibilityChanged,
      this.votingResultVisibilityChanged,
      this.questionVisibilityChanged,
      this.blockingStateChanged,
      this.maxReachableQuizPointsChanged
    );
  }
}
