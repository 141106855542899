import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ROUTES } from './RouteConfig';
import { AppComponent } from './AppComponent'; // needs to be relative for the app component
import { IndividualModulePreloader } from './IndividualModulePreloader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { SessionService } from '@sl/common/services/auth/SessionService';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { ScriptService } from '@sl/common/services/ScriptService';
import { SlideLizardErrorHandler } from '@sl/common/services/error-handling/SlideLizardErrorHandler';
import { ConnectModule } from '@sl/connect/connect.module';
import { HttpClient } from '@angular/common/http';
import { SLTranslateHttpLoader } from '@sl/common/utils/SLTranslateHttpLoader';

export function createTranslateLoader(http: HttpClient) {
  return new SLTranslateHttpLoader(http, '/assets/lang/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(ROUTES, { preloadingStrategy: IndividualModulePreloader, relativeLinkResolution: 'legacy' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ConnectModule,
    OAuthModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [IndividualModulePreloader, AuthService, SessionService, ScriptService, { provide: ErrorHandler, useClass: SlideLizardErrorHandler }],
})
export class AppModule {}
