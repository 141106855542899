import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'progress-bar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input()
  primaryProgressClass: string;

  @Input()
  primaryProgress = 0;

  @Input()
  secondaryProgress = 0;

  @Input()
  secondaryProgressClass: string;

  constructor(private sanitizer: DomSanitizer) {}

  getScaleX(progress: number) {
    return this.sanitizer.bypassSecurityTrustStyle(`scaleX(${progress})`);
  }
}
