<img *ngIf="hasImage(); else imagePlaceholder"
     [sl-image]="contactInfoOverview"
     fallback-image="/assets/img/person_image_empty.png"
     class="round-image fit-image"
     style="vertical-align: middle;"
     [style.min-width]="imageSize"
     [style.min-height]="imageSize"
     [style.width]="imageSize"
     [style.height]="imageSize"/>

<ng-template #imagePlaceholder>

  <div *ngIf="getFirstCharOfName() != null; else noNameIcon"
       class="round-image"
       style="vertical-align: middle; text-align: center; font-family: Roboto, 'Helvetica Neue', sans-serif; font-weight: 500; color: white;"
       [style.fontSize]="fontSize"
       [style.backgroundColor]="getColorForChar()"
       [style.lineHeight]="imageSize"
       [style.min-width]="imageSize"
       [style.min-height]="imageSize"
       [style.width]="imageSize"
       [style.height]="imageSize">{{getFirstCharOfName()}}
  </div>
  <ng-template #noNameIcon>
    <div style="vertical-align: middle; box-sizing: border-box; padding: 4px;"
         [style.fill]="noNameImageColor"
         [style.min-width]="imageSize"
         [style.min-height]="imageSize"
         [style.width]="imageSize"
         [style.height]="imageSize"
         inlineSVG="/assets/img/ic_account.svg">
    </div>
  </ng-template>
</ng-template>
