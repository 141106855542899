import { AuthProviderKey } from '@sl/common/services/endpoint/dto/LoginPayload';

export class Theme {
  public fonts?: ThemeFont[];
  public colors?: ThemeColors;
  public logoUrl?: string;
  public translation?: ThemeLanguageOverride;
  public analytics?: ThemeAnalytics[];
  public removeSlideLizardBranding?: boolean;
  public showHelpOthersConnect?: boolean;
  public allowAnonymousQuestions?: boolean;
  public availableAuthProviders?: AuthProviderKey[];
  public executableJavaScript?: string;
  public sendVoteOnlyAfterConfirmation?: boolean;
  public contactInfoFields?: ThemeContactInfoFieldConfiguration[];
}

export enum ThemeContactInfoField {
  Firstname = 'firstName',
  Lastname = 'lastName',
  Company = 'company',
  Position = 'position',
  ImageUrl = 'imageUrl',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Street = 'street',
  Zip = 'zip',
  City = 'city',
  AdditionalInformation1 = 'additionalInformation1',
  AdditionalInformation2 = 'additionalInformation2',
  AdditionalInformation3 = 'additionalInformation3',
  AdditionalInformation4 = 'additionalInformation4',
  AdditionalInformation5 = 'additionalInformation5',
  SelectPredefinedContactInfo = 'selectPredefinedContactInfo'
}

export class ThemeContactInfoFieldConfiguration {
  name: ThemeContactInfoField;
  order?: number;
  required?: boolean;
}

export class ThemeFont {
  public name?: string;
  public url: string;
  public format: string;
  public weight?: number;
  public style?: string;

  constructor(name: string, url: string, format: string, weight: number = 500, style: string = 'normal') {
    this.name = name;
    this.url = url;
    this.format = format;
    this.weight = weight;
    this.style = style;
  }
}

export class ThemeColors {
  public primaryColor?: string;
  public text1OnPrimaryColor?: string;

  public secondaryColor?: string;
  public text1OnSecondaryColor?: string;

  public surfaceColor?: string;
  public text1OnSurfaceColor?: string;
  public text2OnSurfaceColor?: string;

  public backgroundColor?: string;
  public text1OnBackgroundColor?: string;
  public text2OnBackgroundColor?: string;

  public dialogBackgroundColor?: string;
  public text1OnDialogBackgroundColor?: string;
  public text2OnDialogBackgroundColor?: string;

  public errorColor?: string;
  public text1OnErrorColor?: string;

  public toolbarBackground?: string;
  public text1OnToolbarColor?: string;

  public headerBackground?: string;
  public text1OnHeaderColor?: string;

  public footerBackground?: string;
  public text1OnFooterColor?: string;
  public text2OnFooterColor?: string;

  public highlightColor?: string;
  public dividerColor?: string;
}

export class ThemeLanguageOverride {
  public fallbackLanguage: string;
  public url: string;

  constructor(fallbackLanguage: string, url: string) {
    this.fallbackLanguage = fallbackLanguage;
    this.url = url;
  }
}

export class ThemeAnalytics {
  static readonly FACEBOOK_PIXEL = 'facbeookPixel';
  static readonly GOOGLE_ANALYTICS = 'googleAnalytics';

  public name: string;
  public id: string;
}
