import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { fadeAndSlideOnAddOrRemoveAnimation, fadeOnAddOrRemoveAnimation } from '@sl/common/animations/CommonAnimations';
import { ActivatedRoute, Router } from '@angular/router';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { PresentationDataService } from '@sl/common/services/endpoint/PresentationDataService';
import { PresentationInstance } from '@sl/common/model';
import { SlideLizardMetaService } from '@sl/common/services/util/SlideLizardMetaService';
import { SLAnalyticsService, TrackingKey } from '@sl/common/services/analytics';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AccessProtectionOverlayService } from '@sl/connect/components/access_protection/AccessProtectionOverlayService';
import { Location } from '@angular/common';
import { SLLogger } from '@sl/common/utils/SLLogger';

@Component({
  selector: 'connect',
  templateUrl: 'connect.component.html',
  styleUrls: ['connect.component.scss'],
  animations: [fadeAndSlideOnAddOrRemoveAnimation, fadeOnAddOrRemoveAnimation],
})
export class ConnectComponent implements OnInit {
  ProgressState = ProgressState;
  initialCodeLoadingState = ProgressState.Content;
  state: ProgressState;

  errorMessage: string;

  @ViewChild('codeInput', { static: true })
  codeInput: ElementRef;

  hasPreEnteredCode = false;

  _code = '';
  get code() {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
    if (this.state === ProgressState.Error) {
      this.state = ProgressState.Content;
    }
  }

  accessKey = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private presDataService: PresentationDataService,
    private accessProtectionOverlayService: AccessProtectionOverlayService,
    private translateService: TranslateService,
    private slMetaService: SlideLizardMetaService,
    private trackingService: SLAnalyticsService
  ) {}

  ngOnInit() {
    const preCodeInput = (<any>window).preCodeInput;
    if (!TextUtils.isNullOrEmpty(preCodeInput)) {
      this.code = preCodeInput;
      if ((<any>window).connectClicked) {
        this.hasPreEnteredCode = true;
        this.onConnectClicked();
      }
    }

    const accessKeyQueryParam = this.route.snapshot.queryParams['pw'];

    const segments = this.route.snapshot.url;
    if (segments != null && segments.length > 0 && segments[0].path !== 'connect') {
      this.hasPreEnteredCode = true;
      this.initialCodeLoadingState = ProgressState.Loading;
      this.connectTo(segments[0].path, accessKeyQueryParam);
    }

    const codeQueryParam = this.route.snapshot.queryParams['code'];

    if (!TextUtils.isNullOrEmpty(codeQueryParam)) {
      this.hasPreEnteredCode = true;
      this.initialCodeLoadingState = ProgressState.Loading;
      this.connectTo(codeQueryParam, accessKeyQueryParam);
    }

    this.translateService.get(['site-title', 'site-description']).subscribe((translations) => this.slMetaService.setMetaData(translations['site-title'], translations['site-description']));

    setTimeout(() => {
      if (this.codeInput && this.codeInput.nativeElement) {
        this.codeInput.nativeElement.select();
      }
    }, 500);

    this.trackingService.trackPageView(TrackingKey.PageView.CONNECT);
  }

  private connectTo(connectionCode: string, accessKey?: string) {
    if (!TextUtils.isNullOrEmpty(connectionCode)) {
      if (!TextUtils.isNullOrEmpty(accessKey)) {
        this.accessKey = accessKey;
      }
      this.code = connectionCode;
      this.onConnectClicked();
    }
  }

  onConnectClicked() {
    if (this.code && this.code.length >= 4) {
      this.state = ProgressState.Loading;
      this.checkPresentationExists();
    } else {
      this.initialCodeLoadingState = ProgressState.Empty;
    }
  }

  stopLoading() {
    this.showError();
  }

  private checkPresentationExists() {
    this.resetConnectionCodeInURL();
    this.presDataService.getPresentationInstanceByCode(this.code.toLowerCase(), this.accessKey).subscribe(
      (presentationInstance: PresentationInstance) => {
        if (presentationInstance) {
          this.state = ProgressState.Content;
          this.trackingService.trackEvent(TrackingKey.Event.CONNECT_PRESENTATION, { successful: true, type: 'pres' });
          this.router.navigate(['presentation', presentationInstance.id]);
        } else {
          this.onPresentationLoadError(null);
        }
      },
      (error: HttpErrorResponse) => this.onPresentationLoadError(error)
    );
  }

  private onPresentationLoadError(error: HttpErrorResponse) {
    if (error && error.status === 403) {
      this.accessProtectionOverlayService.open(this.code, false, true);
      this.state = ProgressState.Content;
      return;
    } else if (error && error.status === 412) {
      this.accessProtectionOverlayService.open(this.code, true, false);
      this.state = ProgressState.Content;
      return;
    } else if (error && error.status === 404) {
      // Instance doesn't exist
      this.showError();
    } else {
      // Other error
      this.showError('connect.error-generic');
    }

    this.initialCodeLoadingState = ProgressState.Empty;
  }

  onConnectInput(event: any) {
    if (event.charCode === 0) {
      // Firefox bugfix for deleting chars
      return true;
    }

    const pattern = /[0-9A-Za-z\.\-]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
    return true;
  }

  showError(msg: string = 'connect.error-no-presentation') {
    this.hasPreEnteredCode = false;
    this.errorMessage = msg;
    this.state = ProgressState.Error;
    this.trackingService.trackEvent(TrackingKey.Event.CONNECT_PRESENTATION, { successful: false });

    if (this.codeInput && this.codeInput.nativeElement) {
      // Set the input to disabled, because the binding to the state takes a few ms
      this.codeInput.nativeElement.disabled = false;
      this.codeInput.nativeElement.select();
    }
  }

  private resetConnectionCodeInURL() {
    try {
      this.location.replaceState('connect');
    } catch (e) {
      SLLogger.warn("Failed to set 'connect' in url, %o", e);
    }
  }
}
