import { Note } from '@sl/common/model/Note';

export class AttendanceInfo {
  public gdprState: GDPRState = GDPRState.NotSeen;
  public slideNotes: Array<Note>;
  public currentTotalQuizPoints?: number;
  public blockingStates: BlockingState[];

  constructor(gdprState: GDPRState, notes: Array<Note> = undefined) {
    this.gdprState = gdprState;
    this.slideNotes = notes;
  }
}

export enum BlockingState {
  Voting = 'voting',
}

export enum GDPRState {
  NotSeen = 'notSeen',
  Unaccepted = 'unaccepted',
  Accepted = 'accepted',
}
