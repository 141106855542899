import { SLUtils } from '@sl/common/utils/SLUtils';

export class OverlayCloseHelper {
  private closedByGoingBack = false;
  private popstateRef: any;

  public blockHistoryBack = false;

  constructor(private name: string, private closeCallback: () => any) {
    let newState = SLUtils.clone(history.state);
    if (newState === null) {
      newState = {};
    }

    if (newState.slOverlayStack == null) {
      newState.slOverlayStack = [];
    }
    newState.slOverlayStack.push(this.name);

    history.pushState(newState, '', window.location.pathname);

    this.popstateRef = (event: any) => this.onPopstate(event); // Weird way to keep 'this' correct in function
    window.addEventListener('popstate', this.popstateRef);
  }

  private onPopstate(event: any) {
    if (event.state == null || event.state.slOverlayStack == null || event.state.slOverlayStack.indexOf(this.name) === -1) {
      this.closedByGoingBack = true;
      this.closeCallback();
    }
  }

  public onDestroy() {
    window.removeEventListener('popstate', this.popstateRef);
    if (!this.blockHistoryBack && !this.closedByGoingBack) {
      history.back();
    }
  }

  public static isOverlayOpen(name: string) {
    return history.state != null && history.state.slOverlayStack != null && history.state.slOverlayStack.indexOf(name) !== -1;
  }

  public static isHighestOverlay(name: string) {
    const overlayStack = history.state?.slOverlayStack;
    return overlayStack?.indexOf(name) === overlayStack?.length - 1;
  }
}
