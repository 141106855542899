import { IEnvironment } from './environments/IEnvironment';
import ENVIRONMENT from '@sl/config/environments/environment';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { IDebugConfig } from '@sl/config/IDebugConfig';

declare var BUILD_TIMESTAMP: number;

export class SLConfig {
  private static readonly DEBUG_CONFIG_KEY = 'sl_debug_config';

  private static environment: IEnvironment;
  private static debugConfig: IDebugConfig;

  public static getEnvironment(): IEnvironment {
    if (!this.environment) {
      this.loadEnvironment();
    }

    return this.environment;
  }

  private static loadEnvironment() {
    this.environment = ENVIRONMENT;
    this.environment.buildTimestamp = BUILD_TIMESTAMP;

    if (!this.environment.production) {
      this.nestedAssign(this.environment, this.getDebugConfig().environment);
    }
  }

  public static getDebugConfig(): IDebugConfig {
    if (!this.environment.production) {
      try {
        const debugConfig = localStorage.getItem(this.DEBUG_CONFIG_KEY);
        if (debugConfig) {
          return JSON.parse(debugConfig);
        }
      } catch (e) {
        SLLogger.warn(e);
      }
    }
    return {};
  }

  public static updateDebugConfig(newDebugConfig: IDebugConfig) {
    if (!this.environment.production) {
      try {
        this.debugConfig = newDebugConfig;
        localStorage.setItem(this.DEBUG_CONFIG_KEY, JSON.stringify(this.debugConfig));
        this.loadEnvironment();
      } catch (e) {
        SLLogger.warn(e);
      }
    }
  }

  private static nestedAssign(target: any, source: any) {
    if (source) {
      Object.keys(source).forEach((sourceKey) => {
        if (Object.keys(source).find((targetKey) => targetKey === sourceKey) !== undefined && typeof source[sourceKey] === 'object') {
          target[sourceKey] = this.nestedAssign(target[sourceKey], source[sourceKey]);
        } else {
          target[sourceKey] = source[sourceKey];
        }
      });
    }
    return target;
  }
}
