export namespace TrackingKey {
  export namespace PageView {
    export const CONNECT = 'connect';
    export const ACCESS_PROTECTION = 'access-protection';
    export const PRESENTATION = 'presentation-info';
    export const FEEDBACK = 'feedback';
    export const QUESTION = 'question';
    export const SPEAKER_SHOWN = 'speaker';
    export const PROFILE = 'profile';
    export const SLIDE_OVERVIEW = 'slide-overview';
    export const VOTINGS = 'voting';
    export const ABOUT = 'about';
    export const NOTES = 'notes';
  }

  export namespace Event {
    export const SIGNUP = 'sign_up';
    export const LOGIN = 'login';
    export const LOGOUT = 'logout';
    export const HISTORY_PRESENTATION_ITEM_CLICKED = 'history-presentation-item-clicked';
    export const CONNECT_PRESENTATION = 'connect-presentation';
    export const QUESTION_ASKED = 'presentation-question-asked';
    export const FEEDBACK_SENT = 'presentation-feedback-sent';
    export const VOTING_ANSWERED = 'presentation-voting-answer-sent';
    export const EXTERNAL_CONTENT_CLICKED = 'presentation-shared-content-clicked';
    export const NOTES_AND_STARS_SAVED = 'presentation-notes-and-stars-saved';
    export const NOTES_AND_STARS_SAVE_FAILED = 'presentation-notes-and-stars-save-failed';
    export const SLIDE_STARRED = 'presentation-slide-starred';
    export const SLIDE_UNSTARRED = 'presentation-slide-unstarred';
    export const TRIGGERED_SUMMARY_EMAIL = 'presentation-triggered-summary-email';
    export const JUMP_TO_CURRENT_SLIDE_CLICKED = 'presentation-jump-to-current-slide-clicked';

    export namespace Payment {
      export const CONTACT_DETAILS_ENTERED = 'payment-contact-details-entered';
      export const SUBSCRIBE_CLICKED = 'payment-subscribe-clicked';
      export const SUBSCRIBE_FAILED = 'payment-subscribe-failed';
      export const SUBSCRIBE_SUCCEEDED = 'payment-subscribe-succeeded';
      export const CHANGE_PLAN_CLICKED = 'payment-change-plan-clicked';
    }
  }
}
