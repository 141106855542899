import { Injectable } from '@angular/core';
import { LocalStorageService } from '@sl/common/services/storageaccess/LocalStorageService';
import { FeedbackValue } from '@sl/common/model';

type FeedbackValueStorage = {
  presentationInstanceId: string;
  feedbacks: FeedbackValue[];
};

@Injectable({ providedIn: 'root' })
export class FeedbackLocalStorageService extends LocalStorageService<FeedbackValueStorage[]> {
  private static readonly FEEDBACK_KEY = 'feedbacks';

  saveFeedback(presentationInstanceId: string, feedbackValues: FeedbackValue[]): void {
    let feedbacks = this.getValue(FeedbackLocalStorageService.FEEDBACK_KEY);
    const appendingFeedback: FeedbackValueStorage = {
      presentationInstanceId,
      feedbacks: feedbackValues,
    };

    if (!feedbacks) {
      this.setValue(FeedbackLocalStorageService.FEEDBACK_KEY, [appendingFeedback]);
      return;
    }

    feedbacks = feedbacks.filter((fb) => fb.presentationInstanceId !== presentationInstanceId);
    feedbacks.push(appendingFeedback);

    this.setValue(FeedbackLocalStorageService.FEEDBACK_KEY, feedbacks);
  }

  tryGetFeedback(presentationInstanceId: string): FeedbackValue[] {
    const feedbacks = this.getValue(FeedbackLocalStorageService.FEEDBACK_KEY);

    if (!feedbacks) {
      return undefined;
    }
    const fbForPresentation = feedbacks.filter((f) => f.presentationInstanceId === presentationInstanceId).pop();
    if (!fbForPresentation) {
      return undefined;
    }

    return fbForPresentation.feedbacks;
  }
}
