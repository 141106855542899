import { Voting, VotingAnswerImageStyle, VotingType } from '@sl/common/model/Voting';
import { VotingAnswerOption } from '@sl/common/model/VotingAnswerOption';
import { VotingResult } from '@sl/common/model/VotingResult';
import { AnswerOptionVoteCount, VotingStatistic } from '@sl/common/model/VotingStatistic';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Theme, ThemeAnalytics, ThemeContactInfoField, ThemeFont, ThemeLanguageOverride } from '@sl/common/model/Theme';
import { Question } from '@sl/common/model/Question';

export class FakeDataProvider {
  public static createId() {
    return (Math.random() * 10000).toString();
  }

  public static createVoting(override?: Partial<Voting>): Voting {
    const voting = new Voting(null, this.createId(), this.createMultilangString({ lang: 'en', text: 'What presentation software are you using?' }, { lang: 'de', text: 'Welche Präsentationssoftware benutzen Sie?' }), VotingType.SingleChoice, VotingAnswerImageStyle.Small);

    voting.answerOptions = [
      new VotingAnswerOption(null, 'answer1', 'Snail 1', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f40c.png', 1, true),
      new VotingAnswerOption(null, 'answer2', 'Dolphin 2', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f42c.png'),
      new VotingAnswerOption(null, 'answer3', 'Owl 3', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f989.png'),
      new VotingAnswerOption(null, 'answer4', 'Snail 4', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f40c.png', 1, true),
      new VotingAnswerOption(null, 'answer5', 'Dolphin 5', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f42c.png'),
      new VotingAnswerOption(null, 'answer6', 'Owl 6', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f989.png'),
      new VotingAnswerOption(null, 'answer7', 'Snail 7', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f40c.png', 1, true),
      new VotingAnswerOption(null, 'answer8', 'Dolphin 8', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f42c.png'),
      new VotingAnswerOption(null, 'answer9', 'Owl 9', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f989.png'),
      new VotingAnswerOption(null, 'answer10', 'Snail 10', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f40c.png', 1, true),
      new VotingAnswerOption(null, 'answer11', 'Dolphin 11', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f42c.png'),
      new VotingAnswerOption(null, 'answer12', 'Owl 12', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f989.png'),
      new VotingAnswerOption(null, 'answer13', 'Bat 13', 'https://slidelizarddata.blob.core.windows.net/emojis/img-apple-160/1f987.png'),
    ];

    Object.assign(voting, override);
    return voting;
  }

  static createVotingResult(voting: Voting, voteCount = 100): VotingResult {
    const votingStatistic = new VotingStatistic();
    votingStatistic.totalVoters = voteCount;
    votingStatistic.lastVotedAt = this.createFakeDate();
    votingStatistic.votes = voting.answerOptions.map((answerOption) => new AnswerOptionVoteCount(answerOption.id, voteCount / voting.answerOptions.length));

    return new VotingResult(voting, votingStatistic);
  }

  static createQuestion(override?: Partial<Question>) {
    const q = new Question();
    q.id = this.createId();
    q.isAnonymous = true;
    q.createdAt = this.createFakeDate();
    q.text = 'How does this work?';
    q.upVotes = 1;

    Object.assign(q, override);
    return q;
  }

  static createFakeDate(date: Date = new Date()) {
    return date.getTime() + Number(localStorage.getItem('sl_st'));
  }

  static createDelayedResult<T>(result: T) {
    return of(result).pipe(delay(Math.random() * 1000));
  }

  static createMultilangString(...translations: { lang: string; text: string }[]) {
    const value = translations.map((t) => `<Translation lang="${t.lang}">${t.text}</Translation>`).join(' ');
    return `<Translations>${value}</Translations>`;
  }
}

export class FakeThemes {
  public static DenkPharmaTheme: Theme = {
    colors: {
      primaryColor: '#fff200',
      text1OnPrimaryColor: 'black',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: 'var(--text1OnPrimaryColor)',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: 'var(--text1OnPrimaryColor)',
      text2OnSurfaceColor: '#333',

      toolbarBackground: 'var(--primaryColor)',
      text1OnToolbarColor: 'var(--text1OnPrimaryColor)',

      footerBackground: 'var(--primaryColor)',
      text1OnFooterColor: 'var(--text1OnPrimaryColor)',
      text2OnFooterColor: 'rgba(0,0,0,0.8)',

      backgroundColor: '#f4f4f4',
      text1OnBackgroundColor: 'black',
      headerBackground: 'var(--toolbarBackground)',
      text1OnHeaderColor: 'var(--text1OnToolbarColor)',
    },
    logoUrl: 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/DenkPharma/logo/denk-pharma.jpg',
    fonts: [
      { name: 'Futura', url: 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/DenkPharma/fonts/Futura-thin.woff', weight: 300, format: 'woff' },
      { name: 'Futura', url: 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/DenkPharma/fonts/Futura-regular.woff', weight: 400, format: 'woff' },
      { name: 'Futura', url: 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/DenkPharma/fonts/Futura-bold.woff', weight: 800, format: 'woff' },
    ],
  };

  public static RaiffeisenSumsiTheme: Theme = {
    colors: {
      primaryColor: '#FFF101',
      text1OnPrimaryColor: '#373737',

      secondaryColor: '#21517a',
      text1OnSecondaryColor: '#fff',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: 'var(--text1OnPrimaryColor)',
      text2OnSurfaceColor: 'rgba(0, 0, 0, 0.54)',

      toolbarBackground: 'var(--primaryColor)',
      text1OnToolbarColor: 'var(--text1OnPrimaryColor)',

      backgroundColor: '#f4f4f4',
      text1OnBackgroundColor: 'black',
      headerBackground: 'var(--toolbarBackground)',
      text1OnHeaderColor: 'var(--text1OnToolbarColor)',

      footerBackground: '#666',
      text1OnFooterColor: 'white',
      text2OnFooterColor: 'rgba(255,255,255,0.8)',
    },
    logoUrl: 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/RLBOOE/RaiffeisenSumsi/logo.png',
    availableAuthProviders: [],
    fonts: [
      { name: 'Open Sans', url: 'https://fonts.gstatic.com/s/opensans/v23/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2', weight: 300, format: 'woff2' },
      { name: 'Open Sans', url: 'https://fonts.gstatic.com/s/opensans/v23/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2', weight: 400, format: 'woff2' },
    ],
    translation: new ThemeLanguageOverride('de', 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/RLBOOE/RaiffeisenSumsi/lang-override-de.json'),
    sendVoteOnlyAfterConfirmation: true,
    contactInfoFields: [
      { name: ThemeContactInfoField.AdditionalInformation2, order: -1 },
      { name: ThemeContactInfoField.AdditionalInformation3, order: -1 },
      { name: ThemeContactInfoField.Street, order: -1 },
      { name: ThemeContactInfoField.City, order: -1 },
      { name: ThemeContactInfoField.Zip, order: -1 },
      { name: ThemeContactInfoField.PhoneNumber, order: -1 },
      { name: ThemeContactInfoField.Email, order: -1 },
      { name: ThemeContactInfoField.Firstname, order: -1 },
      { name: ThemeContactInfoField.Lastname, order: -1 },
      { name: ThemeContactInfoField.AdditionalInformation1, order: -1 },
    ],
    executableJavaScript:
      "var footer = document.getElementById('footer-privacy-text'); var cookieSettingsButton = document.createElement('button'); cookieSettingsButton.id = 'footer-privacy-text'; cookieSettingsButton.className = 'ot-sdk-show-settings'; cookieSettingsButton.textContent = 'Cookie-Einstellungen'; footer.append(document.createElement('br'));footer.append(document.createElement('br'));footer.append(cookieSettingsButton);(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-NF94V78');" +
      "footer.append(document.createElement('br'));var cookieInfoLink = document.createElement('a');cookieInfoLink.textContent='RLB OOE Datenschutz'; cookieInfoLink.href='https://www.raiffeisen.at/ooe/de/meine-bank/datenschutz.html#:~:text=COOKIES'; cookieInfoLink.target='_blank'; footer.append(cookieInfoLink);",
  };

  public static ZebraTheme: Theme = {
    translation: new ThemeLanguageOverride('de', 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/Zebra/de_override.json'),
  };

  public static EngelTheme: Theme = {
    colors: {
      primaryColor: 'rgb(166, 201, 87)',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: 'var(--text1OnPrimaryColor)',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#222',
      text2OnSurfaceColor: '#aaa',

      backgroundColor: '#F1F1F1',
      headerBackground: '#FFF',
      text1OnHeaderColor: 'var(--text1OnSurfaceColor)',

      toolbarBackground: '#FFF',
      text1OnToolbarColor: 'var(--primaryColor)',
    },
    fonts: [{ name: 'Helvetica Neue', url: 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/Engel/Helvetica-Neue.woff', format: 'woff' }],
    logoUrl: 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/Engel/logo.svg',
  };

  public static KTMTheme: Theme = {
    colors: {
      primaryColor: '#000',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: '#FFF',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#000',
      text2OnSurfaceColor: '#000',

      toolbarBackground: '#fff',
      text1OnToolbarColor: '#000',

      backgroundColor: 'url(https://slidelizarddata.blob.core.windows.net/configuration/custombranding/KTM/background.jpg)',
      text1OnBackgroundColor: '#fff',
      text2OnBackgroundColor: '#fff',

      dialogBackgroundColor: '#f1f1f1',
      text1OnDialogBackgroundColor: '#000',
      text2OnDialogBackgroundColor: '#000',

      headerBackground: 'rgba(0,0,0,0)',
      text1OnHeaderColor: 'var(--text1OnSurfaceColor)',
    },
    fonts: [
      new ThemeFont('TradeGothic', 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/KTM/TradeGothic-Bold.woff2', 'woff2', 500),
      new ThemeFont('TradeGothic', 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/KTM/TradeGothic-Light.woff', 'woff', 400),
    ],
    logoUrl: 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/KTM/ktm-logo.png',
    availableAuthProviders: [],
  };

  public static SimpleCustomThemeLight: Theme = {
    colors: {
      primaryColor: 'rgb(255, 122, 89)',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: 'var(--text1OnPrimaryColor)',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#424242',
      text2OnSurfaceColor: 'rgba(0, 0, 0, 0.54)',

      backgroundColor: '#E3E3E3',
      headerBackground: 'var(--primaryColor)',
      text1OnHeaderColor: 'var(--text1OnPrimaryColor)',
    },
    analytics: [{ name: ThemeAnalytics.FACEBOOK_PIXEL, id: '272459063392853' }],
  };

  public static SimpleCustomThemeDark: Theme = {
    colors: {
      primaryColor: 'green',
      text1OnPrimaryColor: '#000',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: 'var(--text1OnPrimaryColor)',

      surfaceColor: '#424242',
      text1OnSurfaceColor: '#FFF',
      text2OnSurfaceColor: 'rgba(255, 255, 255, 0.84)',

      toolbarBackground: 'var(--primaryColor)',
      text1OnToolbarColor: 'var(--text1OnPrimaryColor)',

      backgroundColor: '#000',
      headerBackground: 'var(--primaryColor)',
      text1OnHeaderColor: 'var(--text1OnPrimaryColor)',
    },
  };

  public static VoestalpineTheme: Theme = {
    colors: {
      primaryColor: '#36567D',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: '#FFF',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#424242',
      text2OnSurfaceColor: 'rgba(0, 0, 0, 0.54)',

      backgroundColor: '#E3E3E3',
      headerBackground: '#fff',
      text1OnHeaderColor: 'var(--text1OnSurfaceColor)',
    },
    fonts: [],
    logoUrl: 'https://www.voestalpine.com/group/static/system/modules/com.voestalpine.common/resources/img/voestalpineLogo_2x.png?v=2019-04-11',
  };

  public static SpaceTheme: Theme = {
    colors: {
      primaryColor: '#36567D',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: '#FFF',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#424242',
      text2OnSurfaceColor: 'rgba(0, 0, 0, 0.54)',

      backgroundColor: "url('https://images.unsplash.com/photo-1517976487492-5750f3195933?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80') center",
    },
    fonts: [new ThemeFont('Halogen', '/assets/test/Halogen.woff2', 'woff2')],
    logoUrl: '/assets/test/hs-logo.png',
  };

  public static EtisalatTheme: Theme = {
    colors: {
      primaryColor: '#749d2c',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: '#749d2c',
      text1OnSecondaryColor: '#FFF',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#424242',
      text2OnSurfaceColor: 'rgba(0, 0, 0, 0.54)',

      backgroundColor: '#000',
      text1OnBackgroundColor: '#FFF',
      text2OnBackgroundColor: 'rgba(255,255,255,0.8)',

      highlightColor: '#B3B3B3',
      dividerColor: 'rgba(255,255,255,0.65)',
    },
    fonts: [new ThemeFont('NeoTech', 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/etisalat/fonts/NeoTechRegular.woff', 'woff')],
    translation: new ThemeLanguageOverride('en', 'https://slidelizarddata.blob.core.windows.net/configuration/custombranding/etisalat/custom-texts.json'),
  };

  public static HeadspaceTheme: Theme = {
    colors: {
      primaryColor: '#F2712B',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: 'var(--text1OnPrimaryColor)',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#575D70',
      text2OnSurfaceColor: '#777F97',

      backgroundColor: '#EDE9E3',
      text1OnBackgroundColor: '#575D70',
      text2OnBackgroundColor: '#777F97',

      highlightColor: '#FFF',
      dividerColor: 'rgba(255,255,255,0.65)',
    },
    fonts: [new ThemeFont('Chilanka', 'https://fonts.gstatic.com/s/chilanka/v5/WWXRlj2DZQiMJYaYRoJXKdAiZQTP.woff2', 'woff2')],
  };

  public static NetflixTheme: Theme = {
    colors: {
      primaryColor: '#E10715',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: 'var(--text1OnPrimaryColor)',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#575D70',
      text2OnSurfaceColor: '#777F97',

      backgroundColor: '#221F21',
      text1OnBackgroundColor: '#FFF',
      text2OnBackgroundColor: 'rgba(255,255,255,0.75)',

      highlightColor: '#FFF',
      dividerColor: 'rgba(255,255,255,0.65)',
    },
    fonts: [new ThemeFont('Oswald', 'https://fonts.gstatic.com/s/oswald/v24/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvsUZiYySUhiCXAA.woff', 'woff')],
    logoUrl: '/assets/test/nf-logo.png',
  };

  public static SpotifyTheme: Theme = {
    colors: {
      primaryColor: '#1DB954',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: 'var(--primaryColor)',
      text1OnSecondaryColor: 'var(--text1OnPrimaryColor)',

      surfaceColor: '#FFF',
      text1OnSurfaceColor: '#575D70',
      text2OnSurfaceColor: '#777F97',

      backgroundColor: '#191414',
      text1OnBackgroundColor: '#FFF',
      text2OnBackgroundColor: 'rgba(255,255,255,0.75)',

      highlightColor: 'rgba(255,255,255,0.85)',
      dividerColor: 'rgba(255,255,255,0.65)',
    },
    fonts: [new ThemeFont('Bree Serif', 'https://fonts.gstatic.com/s/breeserif/v9/4UaHrEJCrhhnVA3DgluA96rp57F2IwM.woff2', 'woff2')],
  };

  public static EasySoftwareTheme: Theme = {
    colors: {
      primaryColor: '#00306A',
      text1OnPrimaryColor: '#FFF',

      secondaryColor: '#00306A',
      text1OnSecondaryColor: '#FFF',
    },
    fonts: [new ThemeFont('Open Sans Regular', 'https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2', 'woff2')],
  };
}
