<div overlayContainer fxLayout="column" fxFlexAlign="center center">
  <mat-toolbar class="background-color-dialogBackground">
    <mat-toolbar-row>
      <div class="clickable-round toolbar-button foreground-text1-color-dialogBackground"
           (click)="close()" inlineSVG="assets/img/ic_close.svg"></div>

      <ng-template
        [ngIf]="currentUser && currentUser.contactInfo && currentUser.contactInfo.id === contactInfoOverview.id">
        <span class="foreground-text1-color-dialogBackground">{{'profile.title' | translate}}</span>
        <span class="toolbar-space"></span>
        <div class="clickable-round toolbar-button foreground-text1-color-dialogBackground"
             style="margin-right: -12px;"
             [matMenuTriggerFor]="profileMenu" inlineSVG="/assets/img/ic_more.svg">
        </div>

        <mat-menu #profileMenu="matMenu" class="foreground-text1-color-dialogBackground">
          <button mat-menu-item (click)="editProfile()">{{'profile.edit-short' | translate}}</button>
          <button mat-menu-item (click)="logout()">{{'auth.logout' | translate}}</button>
        </mat-menu>
      </ng-template>
    </mat-toolbar-row>
  </mat-toolbar>

  <div fxLayout="column"
       fxLayoutAlign="start center"
       class="scroll-container"
       style="padding-top: 16px;">
    <contact-image [contactInfoOverview]="contactInfoOverview" imageSize="112px" fontSize="48px"></contact-image>
    <h4 class="selectable foreground-text1-color-dialogBackground"
        style="text-align: center; margin-top: 8px;">{{(contactInfo ? contactInfo : contactInfoOverview) | fullname}}</h4>

    <mat-progress-bar *ngIf="state == ProgressState.Loading"
                      mode="query"
                      style="margin-top: 12px;"></mat-progress-bar>

    <mat-list *ngIf="state == ProgressState.Content && contactInfo" fxFlexAlign="start"
              style="width: 100%; margin-bottom: 72px; margin-top: 12px;">

      <h6 *ngIf="contactInfo.selfDescription" class="selectable foreground-text2-color-dialogBackground"
          style="margin: 0 16px 4px; text-align: center;">{{contactInfo.selfDescription | multiLang}}</h6>

      <item
        *ngIf="contactInfo.company || contactInfo.position"
        imageSVG="assets/img/ic_domain.svg" imageClass="foreground-text1-color-dialogBackground"
        [firstLine]="contactInfo | job" firstLineTextClass="foreground-text1-color-dialogBackground"
        [secondLine]="'contact-detail.company' | translate"
        [selectable]="true" [clickable]="false" secondLineTextClass="foreground-text2-color-dialogBackground"></item>

      <ng-template [ngIf]="contactInfo.phoneNumbers && contactInfo.phoneNumbers.length > 0">
        <p matSubheader class="foreground-text1-color-dialogBackground"
           style="text-transform: uppercase;">{{'contact-detail.phone-numbers' | translate}}</p>
        <a *ngFor="let number of contactInfo.phoneNumbers"
           mat-button href="tel:{{normalizePhoneNumber(number.phoneNumber)}}" style="width: 100%; padding: 0;">
          <item imageSVG="assets/img/ic_phone.svg" imageClass="foreground-text1-color-dialogBackground"
                [firstLine]="number.phoneNumber" firstLineTextClass="foreground-text1-color-dialogBackground"
                [secondLine]="('contact-detail.type.' + number.type) | translate"
                [selectable]="true" secondLineTextClass="foreground-text2-color-dialogBackground"></item>
        </a>
      </ng-template>

      <ng-template [ngIf]="contactInfo.emailAddresses && contactInfo.emailAddresses.length > 0">
        <p matSubheader class="foreground-text1-color-dialogBackground"
           style="text-transform: uppercase;">{{'contact-detail.email-addresses' | translate}}</p>
        <a *ngFor="let email of contactInfo.emailAddresses"
           mat-button href="mailto:{{email.emailAddress}}" style="width: 100%; padding: 0;">
          <item
            imageSVG="assets/img/ic_email.svg" imageClass="foreground-text1-color-dialogBackground"
            [firstLine]="email.emailAddress" firstLineTextClass="foreground-text1-color-dialogBackground"
            [secondLine]="('contact-detail.type.' + email.type) | translate"
            [selectable]="true" secondLineTextClass="foreground-text2-color-dialogBackground"></item>
        </a>
      </ng-template>

      <ng-template [ngIf]="contactInfo.addresses && contactInfo.addresses.length > 0">
        <p matSubheader class="foreground-text1-color-dialogBackground"
           style="text-transform: uppercase;">{{'contact-detail.addresses' | translate}}</p>
        <location-item *ngFor="let address of contactInfo.addresses"
                       [address]="address"></location-item>
      </ng-template>

      <ng-template [ngIf]="contactInfo.socialNetworks && contactInfo.socialNetworks.length > 0">
        <p matSubheader class="foreground-text1-color-dialogBackground"
           style="text-transform: uppercase;">{{'contact-detail.social-networks' | translate}}</p>
        <a *ngFor="let sn of contactInfo.socialNetworks"
           mat-button href="{{normalizeURL(sn.url)}}" target="_blank" rel="noopener noreferrer"
           style="width: 100%; padding: 0;">
          <item
            [imageSVG]="sn | socialNetworkIcon" imageClass="foreground-text1-color-dialogBackground"
            [firstLine]="sn | socialNetworkName" firstLineTextClass="foreground-text1-color-dialogBackground"
            [secondLine]="sn.url" secondLineTextClass="foreground-text2-color-dialogBackground"></item>
        </a>
      </ng-template>

      <ng-template [ngIf]="contactInfo.website">
        <p matSubheader class="foreground-text1-color-dialogBackground"
           style="text-transform: uppercase;">{{'contact-detail.website' | translate}}</p>
        <a mat-button href="{{normalizeURL(contactInfo.website)}}" target="_blank" rel="noopener noreferrer"
           style="width: 100%; padding: 0;">
          <item
            imageSVG="assets/img/ic_earth.svg" imageClass="foreground-text1-color-dialogBackground"
            [firstLine]="contactInfo.website" firstLineTextClass="foreground-text1-color-dialogBackground"
            [selectable]="true" secondLineTextClass="foreground-text2-color-dialogBackground"></item>
        </a>
      </ng-template>
    </mat-list>
  </div>
</div>
