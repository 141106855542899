import * as Bowser from 'bowser';
import { SLConfig } from '@sl/config/SLConfig';
import { SLLogger } from '@sl/common/utils/SLLogger';

export class BrowserDetect {
  private static browserInfo: Bowser.Parser.Parser;

  public static getBrowser() {
    if (!this.browserInfo) {
      try {
        this.browserInfo = Bowser.getParser(window.navigator.userAgent);
      } catch (e) {
        SLLogger.warn(e);
      }
    }
    return this.browserInfo;
  }

  public static checkIsNotSupported() {
    try {
      return this.getBrowser().satisfies(SLConfig.getEnvironment().notSupportedBrowsers) === true;
    } catch (e) {
      SLLogger.warn(e);
    }
    return false;
  }
}
