<div fxLayout="column"
     fxLayoutAlign="start center"
     class="dialog-container"
     style="padding: 8px 16px 16px;">

  <div fxFlexFill
       fxLayout="row-reverse" fxLayout.lt-md="row" fxLayoutAlign="start center">
    <div class="clickable-round foreground-text1-color-dialogBackground"
         style="margin: 0 -12px; height: 40px; width: 40px; box-sizing: border-box;"
         (click)="dialogRef.close()" inlineSVG="assets/img/ic_close.svg"></div>

    <h4 class="dialog-title foreground-text1-color-dialogBackground">{{'auth.login' | translate}}</h4>
  </div>
  <h6 fxFlexAlign="start" class="foreground-text2-color-dialogBackground">{{'share-connection.description' | translate}}</h6>

  <img src="{{qrCodeURL}}"
       [style.width]="qrCodeSize + 'px'"
       [style.height]="qrCodeSize + 'px'"
       style="margin: 16px 0;"/>

  <h4 class="foreground-text2-color-dialogBackground"
      style="margin: 4px 0; text-align: center;">
    <span class='easy-selectable'>{{participationURL}}</span>
  </h4>
</div>
