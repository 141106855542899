import { ContactInfo, GDPRInfo, PresentationInfo, PresentationInstance, PresentationInstanceState, User } from '@sl/common/model';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { Subject } from 'rxjs';

export class SLUtils {
  public static checkPresentationInstanceState(pi: PresentationInstance | PresentationInfo, state: PresentationInstanceState) {
    if (pi) {
      let piState: string;
      if ((pi as PresentationInfo).presentationInstance) {
        piState = (pi as PresentationInfo).presentationInstance.state;
      } else {
        piState = (pi as PresentationInstance).state;
      }
      return piState === state;
    }
    return false;
  }

  public static isPresentationInstanceFinished(pi: PresentationInstance | PresentationInfo) {
    return this.checkPresentationInstanceState(pi, PresentationInstanceState.Finished) || this.checkPresentationInstanceState(pi, PresentationInstanceState.Error);
  }

  public static checkUserHasEmail(user: User) {
    return user && user.contactInfo && this.checkContactInfoHasEmail(user.contactInfo);
  }

  public static checkContactInfoHasEmail(contactInfo: ContactInfo) {
    return contactInfo && contactInfo.emailAddresses && contactInfo.emailAddresses.length > 0 && !TextUtils.isNullOrEmpty(contactInfo.emailAddresses[0].emailAddress);
  }

  public static checkHasUserData(ci?: ContactInfo) {
    return ci && (!TextUtils.isNullOrEmpty(ci.firstName) || !TextUtils.isNullOrEmpty(ci.lastName) || (ci.emailAddresses != null && ci.emailAddresses.length > 0 && !TextUtils.isNullOrEmpty(ci.emailAddresses[0].emailAddress)));
  }

  public static isGDPREnabled(gdprInfo: GDPRInfo) {
    return gdprInfo && gdprInfo.isActivated && gdprInfo.text;
  }

  public static clone<T>(obj: T): T {
    if (obj != null) {
      return JSON.parse(JSON.stringify(obj));
    }
    return null;
  }

  public static compare(a: any, b: any) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  private static BLOCK_SCROLLING_CLASS = 'block-scrolling';

  public static blockScrolling(element: HTMLElement, block: boolean) {
    if (element) {
      if (block) {
        element.classList.add(this.BLOCK_SCROLLING_CLASS);
      } else {
        element.classList.remove(this.BLOCK_SCROLLING_CLASS);
      }
    }
  }

  public static completeSubjectDelayed<T>(subject: Subject<T>, value: T) {
    // Awkward hack to allow awaiting
    setTimeout(() => {
      subject.next(value);
      subject.complete();
    }, 100);
  }
}
