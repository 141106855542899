import { IErrorTracker } from '@sl/common/services/error-handling/IErrorTracker';
import { Injectable } from '@angular/core';
import { LogLevel } from '@sl/common/services/error-handling/LogLevel';
import { SLConfig } from '@sl/config/SLConfig';

@Injectable({ providedIn: 'root' })
export class ErrorTrackerService implements IErrorTracker {
  private getTrackers(): IErrorTracker[] {
    let tracker = SLConfig.getEnvironment().tracking.error;
    if (!tracker) {
      tracker = [];
    }
    return tracker.filter((t) => !!t);
  }

  private execTrackers(action: (tracker: IErrorTracker) => void) {
    for (const tracker of this.getTrackers()) {
      action(tracker);
    }
  }

  init(): void {
    this.execTrackers((t) => t.init());
  }

  setTag(key: string, value: string): void {
    this.execTrackers((t) => t.setTag(key, value));
  }

  trackError(error: any): void {
    this.execTrackers((t) => t.trackError(error));
  }

  trackLog(message: string, args: Array<any>, level: LogLevel): void {
    if (args && args.length === 0) {
      args = null;
    }

    this.execTrackers((t) => t.trackLog(message, args, level));
  }

  sendLogs(userName: string, email: string): void {
    this.execTrackers((t) => t.sendLogs(userName, email));
  }

  setUserEmail(email: string) {
    if (!SLConfig.getEnvironment().production) {
      this.execTrackers((t) => t.setUserEmail(email));
    }
  }
}
