<div *ngIf="themeService.currentTheme?.availableAuthProviders?.length && !msTeamsService.isInMSTeams()" fxLayout="column" fxLayoutAlign="center center">
  <p *ngIf="displayStyle == 'small'" class="mat-h3 foreground-text2-color-dialogBackground login-title">{{ 'auth.login-with' | translate }}</p>

  <div [fxLayout]="displayStyle == 'small' ? 'row wrap' : 'column'" style="max-width: 100%; justify-content: center;">
    <button
      *ngIf="isAuthProviderVisible(AuthProviderKey.Google) && googleLoginLoadedState !== ProgressState.Empty"
      id="google-login-button"
      class="google-login-button"
      type="button"
      [disabled]="state == ProgressState.Loading || googleLoginLoadedState == ProgressState.Loading"
      mat-raised-button
      (click)="loginGoogle()"
      [matTooltip]="displayStyle == 'small' ? ('auth.login-google' | translate) : ''"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="login-icon" inlineSVG="/assets/img/ic_google.svg"></div>
        <span>{{ 'auth.login-google' + (displayStyle == 'small' ? '-short' : '') | translate }}</span>

        <mat-progress-spinner *ngIf="googleLoginLoadedState == ProgressState.Loading" class="small-progress" style="margin-left: 8px;" diameter="26" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>
        <p *ngIf="googleLoginLoadedState == ProgressState.Error" class="foreground-color-error" style="margin-left: 8px;">{{ 'retry' | translate }}</p>
      </div>
    </button>

    <button
      *ngIf="isAuthProviderVisible(AuthProviderKey.Facebook) && facebookLoginLoadedState !== ProgressState.Empty"
      class="facebook-login-button"
      mat-raised-button
      type="button"
      [disabled]="state == ProgressState.Loading || facebookLoginLoadedState == ProgressState.Loading"
      (click)="loginFacebook()"
      [matTooltip]="displayStyle == 'small' ? ('auth.login-facebook' | translate) : ''"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="login-icon" inlineSVG="/assets/img/ic_fb.svg"></div>
        <span>{{ 'auth.login-facebook' + (displayStyle == 'small' ? '-short' : '') | translate }}</span>
        <mat-progress-spinner *ngIf="facebookLoginLoadedState == ProgressState.Loading" class="small-progress" style="margin-left: 8px;" diameter="26" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>
        <p *ngIf="facebookLoginLoadedState == ProgressState.Error" class="foreground-color-error" style="margin-left: 8px;">{{ 'retry' | translate }}</p>
      </div>
    </button>

    <button
      *ngIf="isAuthProviderVisible(AuthProviderKey.SlideLizard) && slidelizardLoginLoadedState !== ProgressState.Empty"
      class="slidelizard-login-button background-color-primary"
      mat-raised-button
      type="button"
      [disabled]="state == ProgressState.Loading || slidelizardLoginLoadedState == ProgressState.Loading"
      (click)="loginSlideLizard()"
      [matTooltip]="displayStyle == 'small' ? ('auth.login-slidelizard' | translate) : ''"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="login-icon" inlineSVG="/assets/img/ic_email.svg"></div>
        <span>{{ 'auth.login-slidelizard' + (displayStyle == 'small' ? '-short' : '') | translate }}</span>
        <mat-progress-spinner *ngIf="slidelizardLoginLoadedState == ProgressState.Loading" class="small-progress" style="margin-left: 8px;" diameter="26" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>
        <p *ngIf="slidelizardLoginLoadedState == ProgressState.Error" class="foreground-color-error" style="margin-left: 8px;">{{ 'retry' | translate }}</p>
      </div>
    </button>
  </div>

  <div *ngIf="state == ProgressState.Loading" style="margin-top: 8px;" fxLayout="row" fxLayoutAlign="start center">
    <mat-progress-spinner class="small-progress" diameter="26" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>
    <h6 class="foreground-text2-color-dialogBackground" style="margin-left: 16px;">{{ 'auth.login-in-progress' | translate }}</h6>
  </div>

  <div *ngIf="state == ProgressState.Error" style="margin-top: 16px;" fxLayout="row" fxLayoutAlign="start center">
    <div class="foreground-color-error" inlineSVG="assets/img/ic_alert.svg"></div>
    <h6 class="foreground-color-error" style="margin-left: 16px;" [innerHTML]="errorMessage | translate"></h6>
  </div>
</div>
