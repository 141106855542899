export class TextUtils {
  static readonly URL_REGEX = /(http[s]?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-zA-Z0-9()]{2,6}(\/)*\b([-a-zA-Z0-9()@:%_\+.~#?&=]*)/gm;

  static isNullOrEmpty(str: string) {
    return !str || 0 === str.length;
  }

  static isNullOrEmptyTrimmed(str: string) {
    return !str || str.trim().length === 0;
  }

  static joinListToSentence(list: Array<any>) {
    if (list.length === 1) {
      return list.pop();
    } else {
      const last = list.pop();
      return list.join(', ') + ' & ' + last;
    }
  }

  static convertFormattedTextToHTML(text: string) {
    if (text) {
      return text
        .replace(/\n/gm, '<br>')
        .replace(/\*(.*?)\*/gm, '<b>$1</b>')
        .replace(/_(.*?)_/gm, '<i>$1</i>')
        .replace(/```(.*?)```/gm, '<code>$1</code>')
        .replace(/`(.*?)`/gm, '<code>$1</code>')
        .replace(this.URL_REGEX, (match) => {
          if (!match.match(/.*[a-zA-Z].*/gm)) {
            // If match doesn't include at least one letter, we won't say it's a URL (e.g. 10.000)
            return match;
          }

          let url = match;
          if (!match.startsWith('http')) {
            url = 'http://' + match;
          }
          if (match.includes('@')) {
            url = 'mailto:' + match;
          }
          return `<a clicktracking='off' href='${url}'>${match}</a>`;
        });
    }
    return text;
  }

  static convertHTMLToFormattedText(text: string) {
    if (text) {
      return text
        .replace(/<br>/g, '\n')
        .replace(/<b>(.*?)<\/b>/gm, '*$1*')
        .replace(/<i>(.*?)<\/i>/gm, '_$1_')
        .replace(/<code>(.*?)<\/code>/gm, '```$1```')
        .replace(/<code>(.*?)<\/code>/gm, '`$1`')
        .replace(/<a[^>]* href='.*?'*>(.*?)<\/a>/gm, '$1');
    }
    return text;
  }
}
