import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SLAnalyticsService } from '@sl/common/services/analytics';
import { filter, tap } from 'rxjs/operators';
import { SLConfig } from '@sl/config/SLConfig';
import { Injectable } from '@angular/core';

@Injectable()
export class RequestTimeAnalyticsInterceptor implements HttpInterceptor {
  constructor(private analyticsService: SLAnalyticsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (SLConfig.getEnvironment().tracking.enableRequestTimeTracking && !request.url.includes('assets') && !request.url.includes(SLConfig.getEnvironment().webservice.contactGuesserServiceBaseUri)) {
      const startTimestamp = new Date().getTime();

      return next.handle(request).pipe(
        filter((event) => event.type === HttpEventType.Response),
        tap((res) => {
          try {
            const responseTime = new Date().getTime() - startTimestamp;
            const urlWithoutIds = request.url.replace(/\/[0-9a-fA-F]{24}/g, '/{id}');
            // console.log("time " + request.method + " " + urlWithoutIds + ": " + responseTime);
            this.analyticsService.trackEvent('request-time', responseTime, urlWithoutIds);
          } catch (e) {}
        })
      );
    }

    return next.handle(request);
  }
}
