import { AfterViewInit, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { LoginProvider } from '@sl/common/services/auth/LoginProvider';
import { FacebookLoginProvider } from '@sl/common/services/auth/FacebookLoginProvider';
import { GoogleLoginProvider } from '@sl/common/services/auth/GoogleLoginProvider';
import { OAuthService } from 'angular-oauth2-oidc';
import { SlideLizardLoginProvider } from '@sl/common/services/auth/SlideLizardLoginProvider';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { ScriptService } from '@sl/common/services/ScriptService';
import { SessionRole } from '@sl/common/services/endpoint/dto/SessionRole';
import { AuthProviderKey } from '@sl/common/services/endpoint/dto/LoginPayload';
import { ThemeService } from '@sl/common/services/theme/ThemeService';
import { NGUtils } from '@sl/common/utils/NGUtils';
import { MSTeamsService } from '@sl/common/services/ms-teams/MSTeamsService';
import GoogleUser = gapi.auth2.GoogleUser;
import GoogleAuth = gapi.auth2.GoogleAuth;
import StatusResponse = facebook.StatusResponse;

export type LoginDisplayStyle = 'small' | 'large';

@Component({
  selector: 'login',
  styleUrls: ['login.component.scss'],
  templateUrl: 'login.component.html',
})
export class LoginComponent implements AfterViewInit {
  private static readonly GOOGLE_LOGIN_SCRIPTS = ['https://apis.google.com/js/platform.js'];
  private static readonly FACEBOOK_LOGIN_SCRIPTS = ['//connect.facebook.net/en_US/sdk.js'];

  AuthProviderKey = AuthProviderKey;
  ProgressState = ProgressState;

  @Input()
  displayStyle: LoginDisplayStyle = 'small';

  @Input()
  sessionRole: SessionRole = SessionRole.Attendee;

  @Input()
  visibleAuthProviders: AuthProviderKey[] = [AuthProviderKey.Google, AuthProviderKey.Facebook, AuthProviderKey.SlideLizard];

  @Output()
  onLoginSuccessfulEvent = new EventEmitter<boolean>();

  state = ProgressState.Empty;

  googleLoginLoadedState = ProgressState.Empty;
  facebookLoginLoadedState = ProgressState.Empty;
  slidelizardLoginLoadedState = ProgressState.Empty;

  errorMessage: string;

  googleAuth: GoogleAuth;

  constructor(private oauthService: OAuthService, private scriptService: ScriptService, private authService: AuthService, public themeService: ThemeService, public msTeamsService: MSTeamsService, private zone: NgZone) {}

  ngAfterViewInit() {
    NGUtils.deferChange(() => this.loadLoginProviders());
  }

  private loadLoginProviders() {
    if (this.isAuthProviderVisible(AuthProviderKey.Google)) {
      this.loadGoogleLogin();
    }

    if (this.isAuthProviderVisible(AuthProviderKey.Facebook)) {
      this.loadFacebookLogin();
    }

    if (this.isAuthProviderVisible(AuthProviderKey.SlideLizard)) {
      this.loadSlideLizardLogin();
    }
  }

  public isAuthProviderVisible(authProvider: AuthProviderKey) {
    return this.themeService.currentTheme.availableAuthProviders?.includes(authProvider) && this.visibleAuthProviders.includes(authProvider);
  }

  private loadGoogleLogin() {
    this.googleLoginLoadedState = ProgressState.Loading;
    this.scriptService.loadScripts(...LoginComponent.GOOGLE_LOGIN_SCRIPTS).then(
      () => {
        try {
          gapi.load('auth2', () => {
            this.zone.run(() => {
              this.googleAuth = gapi.auth2.init({
                client_id: '668297770997-jpt7132l5rrh7cgsl9vgjn4igevdd22c.apps.googleusercontent.com',
                cookie_policy: 'single_host_origin',
              });

              NGUtils.runAfterNextRender(() => {
                this.googleAuth.attachClickHandler(
                  document.getElementById('google-login-button'),
                  {},
                  (googleUser: GoogleUser) => this.onGoogleLoginSuccess(googleUser),
                  (error) => this.onGoogleLoginFailure(error)
                );
              });

              this.googleLoginLoadedState = ProgressState.Content;
            });
          });
        } catch (e) {
          SLLogger.warn("Couldn't init Google login! %o", e);
          this.googleLoginLoadedState = ProgressState.Error;
        }
      },
      (error) => (this.googleLoginLoadedState = ProgressState.Error)
    );
  }

  private loadFacebookLogin() {
    this.facebookLoginLoadedState = ProgressState.Loading;
    this.scriptService.loadScripts(...LoginComponent.FACEBOOK_LOGIN_SCRIPTS).then(
      (_) => {
        try {
          FB.init({
            appId: '731734036837592',
            xfbml: true,
            version: 'v3.1',
          });

          this.facebookLoginLoadedState = ProgressState.Content;
        } catch (e) {
          SLLogger.warn("Couldn't init Facebook login! %o", e);
          this.facebookLoginLoadedState = ProgressState.Error;
        }
      },
      (error) => (this.facebookLoginLoadedState = ProgressState.Error)
    );
  }

  private loadSlideLizardLogin() {
    this.slidelizardLoginLoadedState = ProgressState.Loading;
    this.oauthService.loadDiscoveryDocument().then(
      () => {
        this.slidelizardLoginLoadedState = ProgressState.Content;
      },
      (error) => {
        this.slidelizardLoginLoadedState = ProgressState.Error;
        SLLogger.warn(error);
      }
    );
  }

  async login(loginProvider: LoginProvider) {
    this.state = ProgressState.Loading;
    if (this.authService.currentUser === null || this.authService.currentUser.isAnonymous) {
      const loginSuccessful = await this.authService.login(loginProvider, this.sessionRole);
      if (loginSuccessful) {
        this.state = ProgressState.Content;
        this.onLoginSuccessfulEvent.emit(true);
      } else {
        this.errorMessage = 'auth.error-login-failed';
        this.state = ProgressState.Error;
        this.onLoginSuccessfulEvent.emit(false);
      }
    } else {
      this.state = ProgressState.Content;
      this.onLoginSuccessfulEvent.emit(true);
    }
  }

  loginGoogle() {
    if (this.googleLoginLoadedState !== ProgressState.Content) {
      this.loadGoogleLogin();
    }
  }

  loginFacebook() {
    if (this.facebookLoginLoadedState === ProgressState.Content) {
      try {
        FB.login(
          (response) => {
            if (response.status === 'connected' && response.authResponse.accessToken) {
              this.onFacebookLoginSuccess(response);
            } else {
              this.onFacebookLoginFailure(response);
            }
          },
          { scope: 'public_profile,email' }
        );
      } catch (e) {
        this.onFacebookLoginFailure(e);
      }
    } else {
      this.loadFacebookLogin();
    }
  }

  loginSlideLizard() {
    if (this.slidelizardLoginLoadedState === ProgressState.Content) {
      this.oauthService
        .initImplicitFlowInPopup()
        .then(() => this.onSlideLizardLoginSuccess())
        .catch((error) => this.onSlideLizardLoginFailure(error));
    } else {
      this.loadSlideLizardLogin();
    }
  }

  onSlideLizardLoginSuccess() {
    this.login(new SlideLizardLoginProvider(this.oauthService));
  }

  onSlideLizardLoginFailure(error: any) {
    SLLogger.log('SlideLizard login error');
    SLLogger.warn(error);

    this.showError('auth.error-login-failed');
  }

  onGoogleLoginSuccess(googleUser: GoogleUser) {
    this.zone.run(() => this.login(new GoogleLoginProvider()));
  }

  onFacebookLoginSuccess(response: StatusResponse) {
    const loginProvider = new FacebookLoginProvider(response.authResponse.accessToken);

    this.zone.run(() => this.login(loginProvider));
  }

  onFacebookLoginFailure(error: any) {
    SLLogger.warn(error);
    this.zone.run(() => {
      this.showError('auth.error-facebook-login');
    });
  }

  onGoogleLoginFailure(error: any) {
    SLLogger.warn(error);
    this.zone.run(() => {
      if (error.error === 'popup_closed_by_user') {
        this.showError('auth.error-google-login-check-thirdparty-cookies');
      } else {
        this.showError('auth.error-google-login');
      }
    });
  }

  private showError(errorMessage: string) {
    this.errorMessage = errorMessage;
    this.state = ProgressState.Error;
  }
}
