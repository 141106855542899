import { Component, OnInit } from '@angular/core';
import { BaseOverlayComponent } from '@sl/common/components/BaseOverlayComponent';
import { SLConfig } from '@sl/config/SLConfig';
import { SLAnalyticsService, TrackingKey } from '@sl/common/services/analytics';
import { ErrorTrackerService } from '@sl/common/services/error-handling/ErrorTrackerService';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { SLUtils } from '@sl/common/utils/SLUtils';
import { ContactInfoFullNamePipe } from '@sl/common/pipes/ContactInfoFullNamePipe';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'about',
  styleUrls: ['about-dialog.component.scss'],
  templateUrl: 'about-dialog.component.html',
})
export class AboutDialogComponent extends BaseOverlayComponent implements OnInit {
  environment = SLConfig.getEnvironment();

  constructor(private trackingService: SLAnalyticsService, private errorTrackerService: ErrorTrackerService, private authService: AuthService, private namePipe: ContactInfoFullNamePipe, dialog: MatDialog) {
    super(dialog);
  }

  ngOnInit(): void {
    this.trackingService.trackPageView(TrackingKey.PageView.ABOUT);
  }

  sendLogs() {
    const currentUser = this.authService.currentUser;
    let userName, email;
    if (SLUtils.checkUserHasEmail(currentUser)) {
      email = currentUser.contactInfo.emailAddresses[0].emailAddress;
    } else {
      email = 'anonymous@slidelizard.at';
    }

    if (currentUser && currentUser.contactInfo) {
      userName = this.namePipe.transform(currentUser.contactInfo);
    } else {
      userName = '-';
    }

    this.errorTrackerService.sendLogs(userName, email);
    this.close();
  }
}
