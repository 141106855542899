import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SLAnalyticsService, TrackingKey } from '@sl/common/services/analytics';
import { BaseOverlayComponent } from '@sl/common/components/BaseOverlayComponent';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { PresentationInstance } from '@sl/common/model';
import { Router } from '@angular/router';
import { PresentationDataService } from '@sl/common/services/endpoint/PresentationDataService';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'access-protection',
  templateUrl: 'access-protection-dialog.component.html',
  styleUrls: ['access-protection-dialog.component.scss'],
})
export class AccessProtectionDialogComponent extends BaseOverlayComponent implements OnInit, AfterContentInit {
  ProgressState = ProgressState;
  state: ProgressState;

  @ViewChild('accessKeyInput')
  accessKeyInput: ElementRef;

  connectionCode: string;
  accessKeyRequired: boolean;
  specificUserRequired: boolean;

  accessKey = '';
  errorMessage = '';

  constructor(private router: Router, private presDataService: PresentationDataService, private authService: AuthService, dialog: MatDialog, private translateService: TranslateService, private trackingService: SLAnalyticsService) {
    super(dialog);
  }

  ngOnInit() {
    this.trackingService.trackPageView(TrackingKey.PageView.ACCESS_PROTECTION);
  }

  ngAfterContentInit() {
    if (this.accessKeyRequired) {
      setTimeout(() => {
        if (this.accessKeyInput) {
          this.accessKeyInput.nativeElement.focus();
        }
      }, 500);
    } else if (this.specificUserRequired && this.authService.currentUser && !this.authService.currentUser.isAnonymous) {
      this.showError('connect.error-wrong-user');
    }
  }

  onConnectClicked() {
    this.connectToPresentation();
  }

  onAccessKeyChanged() {
    this.state = ProgressState.Content;
    this.errorMessage = null;
  }

  private connectToPresentation() {
    this.state = ProgressState.Loading;
    this.presDataService.getPresentationInstanceByCode(this.connectionCode.toLowerCase(), this.accessKey).subscribe(
      (presentationInstance: PresentationInstance) => {
        if (presentationInstance) {
          this.state = ProgressState.Content;
          this.trackingService.trackEvent(TrackingKey.Event.CONNECT_PRESENTATION, { successful: true, type: 'pres' });
          this.close(undefined, true);
          this.router.navigate(['presentation', presentationInstance.id]);
        } else {
          this.onPresentationLoadError(null);
        }
      },
      (error) => this.onPresentationLoadError(error)
    );
  }

  private onPresentationLoadError(error: any) {
    if (error && error.status === 403) {
      if (this.specificUserRequired) {
        this.showError('connect.error-wrong-user');
      } else {
        this.state = ProgressState.Content;
        this.specificUserRequired = true;
      }
    } else if (error && error.status === 412) {
      if (this.accessKeyRequired) {
        this.showError('connect.error-wrong-access-key');
      } else {
        this.state = ProgressState.Content;
        this.accessKeyRequired = true;
      }
      setTimeout(() => this.accessKeyInput.nativeElement.select(), 500);
    } else {
      this.showError('connect.error-generic');
    }
  }

  onLoginSuccessful() {
    if (!this.accessKeyRequired && this.authService.currentUser && !this.authService.currentUser.isAnonymous) {
      this.connectToPresentation();
    }
  }

  onLogoutClicked() {
    this.authService.logout();
    this.state = ProgressState.Content;
    this.errorMessage = null;
  }

  isDesktop(): boolean {
    return DeviceDetect.isDesktop();
  }

  showError(msgId: string) {
    this.errorMessage = this.translateService.instant(msgId);
    this.state = ProgressState.Error;
  }
}
