import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContactInfo } from '@sl/common/model/ContactInfo';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { ContactInfoOverview } from '@sl/common/model/ContactInfoOverview';
import { PresentationDataService } from '@sl/common/services/endpoint/PresentationDataService';
import { Path } from '@sl/common/utils/Path';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { Subscription } from 'rxjs';
import { User } from '@sl/common/model';
import { BaseOverlayComponent } from '@sl/common/components/BaseOverlayComponent';
import { EditContactInfoOverlayService } from '@sl/common/components/edit-contact-info-dialog/EditContactInfoOverlayService';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'view-contact-info',
  styleUrls: ['view-contact-info-dialog.component.scss'],
  templateUrl: 'view-contact-info-dialog.component.html',
})
export class ViewContactInfoDialogComponent extends BaseOverlayComponent implements OnInit, OnDestroy {
  ProgressState = ProgressState;
  state: ProgressState;

  contactInfoOverview: ContactInfoOverview;
  contactInfo: ContactInfo;

  currentUser: User;
  userSubscription: Subscription;

  constructor(private presDataService: PresentationDataService, private userDetailOverlayService: EditContactInfoOverlayService, private authService: AuthService, dialog: MatDialog) {
    super(dialog);
    this.userSubscription = authService.user.subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.loadContactInfo();
  }

  private loadContactInfo() {
    if (this.contactInfoOverview) {
      this.state = ProgressState.Loading;
      this.presDataService.getContactInfo(this.contactInfoOverview.id).subscribe((contactInfo: ContactInfo) => {
        this.contactInfo = contactInfo;
        this.state = ProgressState.Content;
      });
    } else {
      this.state = ProgressState.Content;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  editProfile() {
    this.userDetailOverlayService.open().subscribe((result) => {
      if (result) {
        this.loadContactInfo();
      }
    });
  }

  logout() {
    this.authService.logout();
    this.overlayRef.dispose();
  }

  normalizePhoneNumber(number: string) {
    return number.replace(' ', '').trim();
  }

  normalizeURL(url: string) {
    return new Path(url).makeAbsolute().toString();
  }
}
