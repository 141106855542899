import { PresentationInstanceDependentService } from '@sl/pres/services/PresentationInstanceDependentService';
import { SLConfig } from '@sl/config/SLConfig';
import { Injectable, NgZone } from '@angular/core';
import { BasePushMessageService } from '@sl/common/services/BasePushMessageService';
import { ErrorTrackerService } from '@sl/common/services/error-handling/ErrorTrackerService';
import { PresentationInstance } from '@sl/common/model';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { SLLogger } from '@sl/common/utils/SLLogger';

@Injectable()
export class AttendeePushService extends BasePushMessageService implements PresentationInstanceDependentService {
  constructor(private authService: AuthService, zone: NgZone, errorTrackerService: ErrorTrackerService) {
    super(zone, errorTrackerService);
  }

  presentationInstanceId: string;

  init(presentationInstance: PresentationInstance): void {
    const userId = this.authService.currentUser?.id ?? '';
    if (!userId) {
      SLLogger.warn('No user id for initializing push service!');
    }

    super.connect(SLConfig.getEnvironment().pushMessageServer.attendeeHubName, presentationInstance.id, userId);
  }

  disconnect() {
    this.presentationInstanceId = null;
    super.disconnect();
  }
}
