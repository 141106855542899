<ng-template [ngIf]="!quizResultShown && !tooLateErrorShown">
  <sl-answer-filter *ngIf="activeVoting && (activeVoting.type == VotingType.SingleChoice || activeVoting.type == VotingType.MultipleChoice) && activeVoting.answerOptions.length >= 10" (onSearchChanged)="answerFilter = $event"></sl-answer-filter>

  <choice-answer-container
    *ngIf="activeVoting && (activeVoting.type == VotingType.SingleChoice || activeVoting.type == VotingType.MultipleChoice)"
    #scrollContainer
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    class="answersContainer scroll-container"
    (scroll)="onAnswerContainerScrolled()"
    [activeVoting]="activeVoting"
    [previousVotingAnswer]="previousVotingAnswer"
    [disabled]="!canVote"
    [sendVoteState]="sendVoteState"
    [answerFilter]="answerFilter"
    (save)="sendVote()"
  ></choice-answer-container>

  <open-text-answer-container
    *ngIf="activeVoting && activeVoting.type == VotingType.OpenText"
    #scrollContainer
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    class="answersContainer scroll-container"
    (scroll)="onAnswerContainerScrolled()"
    [activeVoting]="activeVoting"
    [previousVotingAnswer]="previousVotingAnswer"
    [disabled]="!canVote"
    [sendVoteState]="sendVoteState"
    (save)="sendVote()"
  ></open-text-answer-container>

  <photo-answer-container
    *ngIf="activeVoting && activeVoting.type == VotingType.Photo"
    #scrollContainer
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    class="answersContainer scroll-container"
    (scroll)="onAnswerContainerScrolled()"
    [activeVoting]="activeVoting"
    [previousVotingAnswer]="previousVotingAnswer"
    [disabled]="!canVote"
    [sendVoteState]="sendVoteState"
    (save)="sendVote()"
  ></photo-answer-container>

  <ranking-answer-container
    *ngIf="activeVoting && activeVoting.type == VotingType.Ranking"
    #scrollContainer
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    class="answersContainer scroll-container"
    (scroll)="onAnswerContainerScrolled()"
    [activeVoting]="activeVoting"
    [previousVotingAnswer]="previousVotingAnswer"
    [disabled]="!canVote"
    [sendVoteState]="sendVoteState"
    (save)="sendVote()"
  ></ranking-answer-container>

  <points-distribution-answer
    *ngIf="activeVoting && activeVoting.type == VotingType.PointsDistribution"
    #scrollContainer
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    class="answersContainer scroll-container"
    (scroll)="onAnswerContainerScrolled()"
    [activeVoting]="activeVoting"
    [previousVotingAnswer]="previousVotingAnswer"
    [disabled]="!canVote"
    [sendVoteState]="sendVoteState"
    [answerFilter]="answerFilter"
    (save)="sendVote()"
  ></points-distribution-answer>
</ng-template>

<mat-card *ngIf="tooLateErrorShown" fxFlexAlign="center" fxLayout="column" fxLayoutAlign="center center" class="tooLateErrorContainer">
  <div class="foreground-color-error" inlineSVG="/assets/img/ic_clock.svg"></div>
  <p class="foreground-color-error" [innerHTML]="'presentation.votings.error-send-answer-voting-already-finished' | translate"></p>
</mat-card>

<mat-card *ngIf="quizResultShown" fxFlexAlign="center" fxLayout="column" fxLayoutAlign="center center" class="quizCorrectAnswerContainer">
  <ng-container *ngIf="quizResult.calculationFinished; else quizResultCalculationPending">
    <ng-container [ngSwitch]="quizResult.result">
      <ng-container *ngSwitchCase="QuizResult.CORRECT">
        <div style="background-color: #00c853; animation-name: quiz-correct-answer;" inlineSVG="/assets/img/ic_check.svg"></div>
        <p style="color: #00c853;" [innerHTML]="'presentation.votings.quiz-correct-answer' | translate"></p>
      </ng-container>

      <ng-container *ngSwitchCase="QuizResult.WRONG">
        <div class="background-color-error" style="animation-name: quiz-wrong-answer;" inlineSVG="/assets/img/ic_close.svg"></div>
        <p class="foreground-color-error" [innerHTML]="'presentation.votings.quiz-wrong-answer' | translate"></p>
      </ng-container>

      <ng-container *ngSwitchCase="QuizResult.NOT_VOTED">
        <div class="background-color-error" style="animation-name: quiz-wrong-answer;" inlineSVG="/assets/img/ic_close.svg"></div>
        <p class="foreground-color-error" [innerHTML]="'presentation.votings.quiz-no-answer' | translate"></p>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="presentationQuizPointsService?.maxReachableQuizPoints > 0 || (isWorkflow && workflowQuizPointsService?.maxReachablePoints > 0)">
      <h3 *ngIf="quizResult.gainedPoints > 0"><translate-count-string [count]="quizResult.gainedPoints.toFixed(0)" count1Text="presentation.votings.quiz-gained-1-point" countMultipleText="presentation.votings.quiz-gained-x-points"></translate-count-string></h3>
      <h3 *ngIf="quizResult.gainedPoints <= 0">{{ 'presentation.votings.quiz-gained-no-points' | translate }}</h3>

      <p *ngIf="isWorkflow" class="foreground-text2-color-surface">{{ 'presentation.votings.quiz-total-points' | translate: { currentPoints: quizResult.currentQuizPoints.toFixed(0), maxReachablePoints: workflowQuizPointsService.maxReachableQuizPoints } }}</p>
      <p *ngIf="!isWorkflow" class="foreground-text2-color-surface">{{ 'presentation.votings.quiz-total-points' | translate: { currentPoints: quizResult.currentQuizPoints.toFixed(0), maxReachablePoints: presentationQuizPointsService.maxReachableQuizPoints } }}</p>
    </ng-container>
  </ng-container>

  <ng-template #quizResultCalculationPending>
    <div class="background-color-primary" inlineSVG="/assets/img/ic_clock.svg"></div>
    <p>{{ 'presentation.votings.quiz-waiting-for-result' | translate }}</p>
  </ng-template>
</mat-card>

<div [hidden]="!scrollIndicatorVisible" class="scrollIndicator mat-body-2 background-color-secondary" [style.opacity]="scrollIndicatorVisible ? 1 : 0" [style.transform]="'translateY(' + (scrollIndicatorVisible ? '0' : '64') + 'px)'" (click)="onScrollIndicatorClicked()">
  {{ 'presentation.votings.more-answers' | translate }}
  <div inlineSVG="/assets/img/ic_arrow_left.svg"></div>
</div>

<mat-card class="bottom-bar-container" fxLayout="row" fxLayoutAlign="start center">
  <div *ngIf="countdownRunning" class="countdown-progress-container" [ngClass]="{ 'timer-progress-bar-animated-last-seconds': countdownLastSeconds }">
    <div
      #countdownProgressBar
      class="countdown-progress-bar background-color-secondary"
      [ngClass]="{ 'background-color-error': countdownLastSeconds }"
      [ngStyle]="{ width: (countdownInitialLeftSeconds / countdownTotalSeconds) * 100 + '%', 'animation-duration': countdownInitialLeftSeconds + 0.5 + 's' }"
    ></div>
  </div>

  <div class="bottom-bar-text-container" fxLayout="row" fxLayoutAlign="start center">
    <div *ngIf="countdownRunning" class="countdown-text-container">
      <p #countdownText1 class="countdown-text" [ngClass]="countdownLastSeconds ? 'countdown-text-animated-last-seconds foreground-color-error' : 'countdown-text-animated'"></p>
      <p #countdownText2 class="countdown-text" [ngClass]="countdownLastSeconds ? 'countdown-text-animated-last-seconds foreground-color-error' : 'countdown-text-hidden'"></p>
    </div>

    <div *ngIf="!countdownRunning" class="votingTypeText" fxLayout="column" fxLayoutAlign="center start">
      <p class="foreground-text2-color-surface">
        {{ 'presentation.votings.' + (activeVoting.isVotingAnonymous ? 'anonymous-' : '') + (activeVoting.isQuiz ? 'quiz' : 'poll') | translate: { type: 'presentation.votings.type.' + activeVoting.type | translate, points: activeVoting.quizPoints ? activeVoting.quizPoints : 0 } }}
      </p>
    </div>
  </div>

  <div *ngIf="isWorkflow && activeVoting.isQuiz" class="bottom-bar-text-container" fxLayout="column" fxLayoutAlign="center start">
    <p class="foreground-text2-color-surface">
        {{ 'presentation.votings.quiz-points-info' | translate: { currentPoints: workflowQuizPointsService.currentQuizPoints.toFixed(0), maxReachablePoints: workflowQuizPointsService.maxReachableQuizPoints } }}
    </p>
  </div>

  <button
    *ngIf="canVote && (sendVoteState == ProgressState.Error || (sendVoteState == ProgressState.Content && isSendButtonRequired()))"
    mat-button
    class="countdown-send-button send-button"
    [ngClass]="{ 'animated-send-button': hasAnswerToSend(), 'background-color-error': hasAnswerToSend() && countdownLastSeconds }"
    (click)="sendVote()"
    [disabled]="!hasAnswerToSend()"
  >
    {{ 'presentation.votings.send-vote' | translate }}
  </button>

  <mat-progress-spinner *ngIf="sendVoteState == ProgressState.Loading" class="inline-progress sendVoteProgress" color="accent" diameter="26" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>

  <div fxLayout="column" fxLayoutAlign="center end">
    <p *ngIf="sendVoteState == ProgressState.Empty && !tooLateErrorShown && !quizResultShown" class="foreground-text2-color-surface alreadyVotedText">{{ 'presentation.votings.already-voted' | translate }}</p>
    <p *ngIf="!canVote" class="foreground-color-error alreadyVotedText">{{ 'presentation.votings.blocked' | translate }}</p>
  </div>
</mat-card>
