<div
  class="attendance-container">

  <div *ngIf="loadingState == ProgressState.Content"
       fxLayout="row wrap"
       fxLayoutAlign="center start"
       style="width: 100%; margin-top: 24px;">

    <h6 fxFlex="100%"
        style="margin: 0 16px 8px; text-align: center;">{{'attendance-history.title' | translate}}</h6>

    <presentation-entry *ngFor="let presentationEntry of presentationHistoryEntries"
                        [presentationHistoryEntry]="presentationEntry"
                        (click)="onPresentationHistoryEntryClicked(presentationEntry.presentationInstanceId)"
                        fxFlex="100%" fxFlex.gt-xs="50%" fxFlex.gt-sm="33%" fxFlex.gt-md="25%"
                        fxFlex.gt-lg="20%"></presentation-entry>

  </div>

  <div *ngIf="loadingState == ProgressState.Empty || loadingState == ProgressState.Loading"
       fxLayout="column" fxLayoutAlign="center center"
       class="presentation-code-info-image-container">
    <img class="presentation-code-info-image" src="/assets/img/presentation_code_info.svg"/>

    <div *ngIf="loadingState == ProgressState.Loading"
         fxLayout="row" fxLayoutAlign="center center" style="margin-top: 40px;">
      <mat-progress-spinner
        color="accent"
        diameter="26"
        strokeWidth="4"
        mode="indeterminate"></mat-progress-spinner>
      <p style="margin-left: 12px;">{{'attendance-history.loading' | translate}}</p>
    </div>
  </div>

  <div *ngIf="loadingState == ProgressState.Error"
       fxLayout="column" fxLayoutAlign="center center" style="margin: 24px 0;">
    <p class="error-text">{{ 'attendance-history.error' | translate}}</p>
    <button mat-button color="accent" (click)="loadAttendedPresentations()">{{'retry' | translate}}</button>
  </div>
</div>
