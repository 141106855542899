<div fxLayout="column">
  <div *ngIf="initialCodeLoadingState == ProgressState.Loading" class="loading">
    Loading
  </div>

  <div *ngIf="initialCodeLoadingState != ProgressState.Loading">
    <div class="top-background" fxLayout="column" fxLayoutAlign="center center"
         style="height: 30vh; width: 100vw; min-height: 160px;">

      <div fxLayout="row" fxLayoutAlign="center center">
        <img src="/assets/img/app_icon.svg" class="slidelizard-logo"/>
        <h1 class="slidelizard-title foreground-header">
          <b>Slide</b><span style="font-weight: 300">Lizard</span></h1>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center"
         style="position: absolute; top: 0; right: 0; height: 56px; padding-right: 16px;">
      <profile class="foreground-header"></profile>
    </div>

    <mat-card class="connect-card mat-elevation-z16" (click)="codeInput.focus()"
              fxLayout="row" fxLayoutAlign="center start">

      <div fxLayout="column" class="code-input-container">
        <input #codeInput
               matInput
               autofocus
               class="code-input mat-form-field"
               [placeholder]="'connect.enter-presentation-code' | translate"
               [(ngModel)]="code"
               (keypress)="onConnectInput($event)"
               [disabled]="state == ProgressState.Loading"
               (keyup.enter)="onConnectClicked()"
               autocorrect="off"
               autocapitalize="none"
               autocomplete="off"
               spellcheck="false"/>
      </div>

      <mat-progress-spinner fxFlexAlign="center"
                            *ngIf="state == ProgressState.Loading"
                            class="small-progress connect-spinner"
                            diameter="26"
                            strokeWidth="4"
                            (click)="stopLoading()"
                            mode="indeterminate"></mat-progress-spinner>


      <button fxFlex="32" fxFlexAlign="center"
              class="connect-button"
              [ngClass]="{'foreground-color-primary': code.length >= 4}"
              *ngIf="state != ProgressState.Loading"
              mat-button
              [disabled]="code.length < 4"
              (click)="onConnectClicked()">{{'connect.join' | translate}}
      </button>
    </mat-card>

    <p *ngIf="state == ProgressState.Error"
       class="foreground-color-error error-text"
       @fadeAndSlideOnAddOrRemoveAnimation>{{errorMessage | translate}}</p>

    <attendance-history @fadeAndSlideOnAddOrRemoveAnimation
                        [shouldLoadAttendedPresentations]="!hasPreEnteredCode"></attendance-history>

    <footer></footer>
    <debug-settings></debug-settings>
  </div>
</div>
