import { BaseVotingAnswerContainerComponent } from "../BaseVotingAnswerContainerComponent";
import { Voting, VotingAnswerImageStyle, VotingType } from "@sl/common/model/Voting";
import { AfterViewChecked, Component, Input } from "@angular/core";
import { VotingAnswerOption } from "@sl/common/model/VotingAnswerOption";
import { ProgressState } from "@sl/common/utils/ProgressState";
import { VotingAnswerEntry } from "@sl/common/model/local/VotingAnswerEntry";
import { ChoiceAnswerPayload } from "@sl/common/services/endpoint/dto/ChoiceAnswerPayload";
import { ThemeService } from "@sl/common/services/theme/ThemeService";
import { FullscreenImageOverlayService } from "@sl/common/components/voting/answer-types/choice-answer/fullscreen-image-dialog/FullscreenImageOverlayService";

@Component({
  selector: 'choice-answer-container',
  templateUrl: 'choice-answer-container.component.html',
  styleUrls: ['choice-answer-container.component.scss'],
})
export class ChoiceAnswerContainerComponent extends BaseVotingAnswerContainerComponent implements AfterViewChecked {

  @Input()
  set activeVoting(voting: Voting) {
    this._activeVoting = voting;
    this.populateSelectedChoiceOptions();
  }

  get activeVoting() {
    return this._activeVoting;
  }

  @Input()
  set previousVotingAnswer(value: VotingAnswerEntry) {
    this._previousVotingAnswer = value;
    this.populateSelectedChoiceOptions();
  }

  get previousVotingAnswer() {
    return this._previousVotingAnswer;
  }

  @Input()
  answerFilter?: string;

  constructor(private themeService: ThemeService, private fullscreenImageOverlayService: FullscreenImageOverlayService) {
    super();
  }

  populateSelectedChoiceOptions() {
    if (this.activeVoting) {
      if (!this.activeVoting.votesLeft && this.previousVotingAnswer?.answers) {
        let previousVotingAnswers: string[];
        previousVotingAnswers = (this.previousVotingAnswer.answers as ChoiceAnswerPayload).answerOptionIds;
        this.selectedAnswers = this.activeVoting.answerOptions.map((answerOption) => previousVotingAnswers.indexOf(answerOption.id) >= 0);
      } else {
        this.selectedAnswers = new Array<boolean>(this.activeVoting.answerOptions.length);
      }
    }
  }

  selectedAnswers: Array<boolean>;

  ngAfterViewChecked() {}

  canSelectAnswers() {
    var componentEnabled = !this.disabled;
    var isSendVoteStateContentOrError = (this.sendVoteState === ProgressState.Content || this.sendVoteState === ProgressState.Error);
    var areMultipleVotesAllowed = this.activeVoting.votesLeft != null;
    var stillVotesLeft = this.activeVoting.votesLeft > 0;
    var voteAlreadySent = this.previousVotingAnswer == null;

    return componentEnabled &&
           isSendVoteStateContentOrError &&
           areMultipleVotesAllowed
              ? stillVotesLeft
              : voteAlreadySent;
  }

  isAnswerSelected(answer: VotingAnswerOption) {
    if(!!this.activeVoting?.answerOptions && !!this.selectedAnswers) {
      const index = this.activeVoting.answerOptions.indexOf(answer);
      if(index >= 0 && index < this.selectedAnswers.length)
        return this.selectedAnswers[index];
    }

    return false;
  }

  onAnswerSelected(answer: VotingAnswerOption) {
    if (!this.canSelectAnswers()) {
      return;
    }

    const position = this.activeVoting.answerOptions.indexOf(answer);
    if (this.activeVoting.type === VotingType.SingleChoice) {
      this.selectedAnswers.fill(false, 0, this.selectedAnswers.length);
      this.selectedAnswers[position] = true;

      if (this.sendVoteWithoutConfirmation()) {
        this.saveEmitter.emit();
      }
    } else {
      this.selectedAnswers[position] = !this.selectedAnswers[position];
    }
  }

  async getVotePayload(): Promise<ChoiceAnswerPayload> {
    const answers = this.activeVoting.answerOptions.filter((item, index) => this.selectedAnswers[index]).map((item) => item.id);
    return { answerOptionIds: answers };
  }

  hasChosenAnswer(): boolean {
    return this.selectedAnswers.indexOf(true) !== -1;
  }

  shouldShowSendButton(): boolean {
    return this.activeVoting.type === VotingType.MultipleChoice || (this.activeVoting.type === VotingType.SingleChoice && !this.sendVoteWithoutConfirmation());
  }

  getFilteredAnswerOptions() {
    if (this.answerFilter) {
      const normalizedFilter = this.answerFilter.trim().toLowerCase();
      return this.activeVoting.answerOptions.filter((ao) => ao.text.toLowerCase().includes(normalizedFilter));
    }
    return this.activeVoting.answerOptions;
  }

  private sendVoteWithoutConfirmation() {
    return !this.themeService.currentTheme?.sendVoteOnlyAfterConfirmation;
  }

  showAnswerImage(answer: VotingAnswerOption) {
    if(this._activeVoting.answerImageStyle === VotingAnswerImageStyle.Large && answer.imageUrl) {
      this.fullscreenImageOverlayService.open(answer.imageUrl);
      return;
    }
  }

  hideAnswerImage() {
    this.fullscreenImageOverlayService.closeOverlay();
  }
}
