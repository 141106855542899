import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDataService } from '@sl/common/services/endpoint/BaseDataService';
import { ServerInfo } from '@sl/common/services/endpoint/dto/ServerInfo';

@Injectable({ providedIn: 'root' })
export class ServerInfoDataService extends BaseDataService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public getServerInfo() {
    return this.get<ServerInfo>(`info`);
    // return of({currentTimestamp: (new Date()).getTime() - 10 * 1000});
  }
}
