import { SLLogger } from '@sl/common/utils/SLLogger';

export class Utils {
  public static getQueryParam(name: string) {
    try {
      return new URLSearchParams(window.location.search).get(name);
    } catch (e) {
      SLLogger.warn(e);
    }
    return null;
  }

  public static ensureHttps(url: string) {
    return url.startsWith('https://') ? url : `https://${url}`;
  }

  public static removeDuplicates<T>(array?: T[]) {
    return array?.filter((value, index, array) => array.indexOf(value) === index);
  }
}
