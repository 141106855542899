import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ScriptService } from '@sl/common/services/ScriptService';
import Player = amp.Player;
import { BaseVideoPlayerComponent } from '@sl/common/components/video/base-video-player.component';
import { SLLogger } from '@sl/common/utils/SLLogger';

@Component({
  selector: 'sl-azure-video-player',
  templateUrl: './azure-video-player.component.html',
})
export class AzureVideoPlayerComponent extends BaseVideoPlayerComponent implements AfterViewInit, OnDestroy {
  public static readonly AZURE_MEDIA_PLAYER_SCRIPT_URL = 'https://amp.azure.net/libs/amp/2.3.5/azuremediaplayer.min.js'; //http://amp.azure.net/libs/amp/latest/docs/changelog.html
  public static readonly VIDEO_URL_REGEXES = [new RegExp(/https:\/\/.*\.streaming\.media\.azure\.net\/.*\/manifest/), new RegExp(/https:\/\/.*\.streaming\.mediaservices\.windows\.net\/.*\/manifest/)];

  ampPlayer?: Player;
  playerId = `amp-video-${Math.random()}`;

  constructor(private host: ElementRef, private scriptService: ScriptService, private zone: NgZone) {
    super();
  }

  ngAfterViewInit(): void {
    this.scriptService.loadScript(AzureVideoPlayerComponent.AZURE_MEDIA_PLAYER_SCRIPT_URL).then(() => this.setupVideoPlayer());
  }

  public play() {
    this.ampPlayer?.play();
    this.ampPlayer?.autoplay(true);
    SLLogger.log('video: play');
  }

  public pause() {
    this.ampPlayer?.pause();
    this.ampPlayer?.autoplay(false);
    SLLogger.log('video: pause %o', this.ampPlayer);
  }

  isPlaying(): boolean {
    return !this.ampPlayer.paused();
  }

  protected onSourceSet() {
    this.ampPlayer?.autoplay(!this.ampPlayer?.paused());
    this.setPlayerSource();
  }

  private setupVideoPlayer() {
    this.zone.runOutsideAngular(() => {
      this.ampPlayer = amp(this.playerId, {
        controls: true,
        logo: { enabled: false },
        width: '100%',
        height: '100%',
        skinConfig: {
          audioTracksMenu: {
            enabled: false,
          },
        },
      });
      this.ampPlayer.addEventListener(amp.eventName.loadedmetadata, () => {
        this.setupPlayer();

        this.onReady.emit();
      });

      this.setPlayerSource();
    });
  }

  private setupPlayer() {
    // this.selectHighestQualityTrack();

    this.hidePlayerButton('.amp-audiotracks-control');
    this.hidePlayerButton('.vjs-fullscreen-control');
    this.hidePlayerButton('.vjs-captions-button');
  }

  private selectHighestQualityTrack() {
    const stream = this.ampPlayer?.currentVideoStreamList()?.streams[0];
    if (stream) {
      const highestQualityVideoTrack = stream.tracks.filter((track) => track.selectable).reduce((prev, current) => (current.bitrate > prev.bitrate ? current : prev));
      stream.selectTrackByIndex(stream.tracks.indexOf(highestQualityVideoTrack));
    }
  }

  private setPlayerSource() {
    this.ampPlayer?.src([
      {
        src: this.source,
        type: 'application/vnd.ms-sstr+xml',
      },
    ]);
  }

  private hidePlayerButton(selector: string) {
    const button = this.host.nativeElement.querySelector(selector);
    if (button && button instanceof HTMLElement) {
      button.style.display = 'none';
    }
  }

  ngOnDestroy(): void {
    this.ampPlayer.dispose();
  }
}
