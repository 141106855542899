import { Component, Input } from "@angular/core";
import { BaseOverlayComponent } from "@sl/common/components/BaseOverlayComponent";

@Component({
  selector: 'sl-fullscreen-image-dialog',
  templateUrl: './fullscreen-image-dialog.component.html',
  styleUrls: ['./fullscreen-image-dialog.component.scss']
})
export class FullscreenImageDialogComponent extends BaseOverlayComponent {

  @Input()
  imageUrl?: string;

}
