import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FeedbackOptionType } from '@sl/common/model';
import { CustomFormElement, CustomFormElementType } from '@sl/common/model/CustomForm';
import { FeedbackAnswerOption } from '@sl/common/model/FeedbackAnswerOption';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';

type ElementType = FeedbackOptionType | CustomFormElementType;

@Component({
  selector: 'single-choice',
  templateUrl: 'single-choice-rating-input.component.html',
  styleUrls: ['single-choice-rating-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleChoiceRatingInputComponent),
      multi: true,
    },
  ],
})
export class SingleChoiceRatingInputComponent implements OnInit,ControlValueAccessor {
  @Input()
  answerOptions: FeedbackAnswerOption[];
  @Input()
  size = '48px';
  @Input()
  selectedClass = '';
  @Input()
  notSelectedClass = '';
  @Input()
  disabled = false;
  @Input()
  indicatorTextLeft = '';
  @Input()
  indicatorTextRight = '';

  shownAnswer: FeedbackAnswerOption;
  selectedAnswers: Array<boolean>;
  isDesktop = DeviceDetect.isDesktop();

  propagateChanged = (_: any) => {};

  constructor() {}

  writeValue(value: any): void {
    if(value !== undefined){
      const position = this.answerOptions.indexOf(this.answerOptions.find(ao => ao.text === value));
      this.selectedAnswers[position] = true;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  registerOnTouched(fn: any): void {}

  canSelectAnswers() {
    return !this.disabled;
  }

  ngOnInit() {
    this.selectedAnswers = new Array<boolean>(this.answerOptions.length);
  }

  isAnswerSelected(answer: FeedbackAnswerOption) {
    const index = this.answerOptions.indexOf(answer);
    return this.selectedAnswers[index];
  }


  onClick(answer: FeedbackAnswerOption) {
    if (!this.disabled) {
        const position = this.answerOptions.indexOf(answer);
        this.selectedAnswers.fill(false, 0, this.selectedAnswers.length);
        this.selectedAnswers[position] = true;
        this.propagateChanged(answer.text)
    }
  }
}
