<ng-template [ngIf]="activeVoting">
  <div class="pointsLeft" [innerHTML]="'presentation.votings.points-distribution.points-left' | translate: { points: activeVoting.maxVotes - selectedAnswersIds.length }"></div>

  <mat-card *ngFor="let answer of getFilteredAnswerOptions(); let i = index" class="answerContainer" [ngClass]="{ clickable: canSelectAnswers(), selected: getAnswerSelectedCount(answer) > 0 }" (click)="addPointToAnswer(answer)" fxFlex="100%" fxFlex.gt-xs="45%">
    <div fxLayout="column">
      <div *ngIf="(activeVoting.answerImageStyle == 'large' || !answer.text) && answer.imageUrl" class="largeAnswerImage">
        <img [src]="answer.imageUrl" class="imageContent selectedImage" />
        <div class="zoomButton foreground-text1-color-surface" (click)="$event.stopPropagation(); showAnswerImage(answer)" inlineSVG="assets/img/ic_fullscreen.svg"></div>
        <div *ngIf="getAnswerSelectedCount(answer) > 0" class="selectedImage selectedImageOverlay" fxLayout="column" fxLayoutAlign="center center">
          <div class="answerSelectedIndicatorLarge background-color-secondary">{{ getAnswerSelectedCount(answer) }}</div>
          <div class="clickable-round removePointButton" style="fill: rgba(255, 255, 255, 0.7);" (click)="removePoint(answer); $event.stopPropagation()" inlineSVG="/assets/img/ic_trash.svg"></div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="smallAnswerImageAndTextContainer">
        <img *ngIf="activeVoting.answerImageStyle == 'small' && answer.text && answer.imageUrl" [src]="answer.imageUrl" class="smallAnswerImage" />
        <p *ngIf="answer.text" class="answerText foreground-text1-color-surface" [innerHTML]="answer.text | multiLang"></p>
        <ng-container *ngIf="activeVoting.answerImageStyle == 'small' && answer.text && getAnswerSelectedCount(answer) > 0">
          <div class="clickable-round removePointButton" style="fill: var(--text2OnSurfaceColor);" (click)="removePoint(answer); $event.stopPropagation()" inlineSVG="/assets/img/ic_trash.svg"></div>
          <div class="answerSelectedIndicatorSmall background-color-secondary">{{ getAnswerSelectedCount(answer) }}</div>
        </ng-container>
      </div>
    </div>
  </mat-card>
</ng-template>
