import { IAnalyticsTracker } from './IAnalyticsTracker';
import { SLLogger } from '@sl/common/utils/SLLogger';

declare const gtag: Function;

export class GoogleAnalytics4Tracker implements IAnalyticsTracker {
  constructor(private trackingId: string) {}

  init(): void {
    gtag('js', new Date());
    gtag('config', this.trackingId, {
      send_page_view: false,
    });
  }

  trackPageView(page: string): void {
    gtag('event', 'page_view', {
      page_title: page,
      page_location: page,
      page_path: '/' + page,
    });
    SLLogger.log('ga page ' + page);
  }

  trackEvent(event: string, param: string | number | object, label?: string): void {
    gtag('event', event, {
      value: param,
      label: label,
    });
    SLLogger.log('ga event ' + event + '; ' + !!param ? param : '');
  }
}
