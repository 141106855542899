import { Injectable } from '@angular/core';
import { LocalStorageService } from '@sl/common/services/storageaccess/LocalStorageService';
import { VotingAnswerEntry } from '@sl/common/model/local/VotingAnswerEntry';

@Injectable({ providedIn: 'root' })
export class VotingAnswersLocalStorageService extends LocalStorageService<VotingAnswerEntry[]> {
  private static readonly VOTING_ANSWERS_KEY = 'voting-answers';

  saveVote(votingId: String, answers: any, remainingSecondsInQuizAfterVote?: number) {
    let votingAnswers = this.getValue(VotingAnswersLocalStorageService.VOTING_ANSWERS_KEY);
    const votingAnswerEntry = new VotingAnswerEntry(votingId, answers, remainingSecondsInQuizAfterVote);
    if (!votingAnswers) {
      this.setValue(VotingAnswersLocalStorageService.VOTING_ANSWERS_KEY, [votingAnswerEntry]);
      return votingAnswerEntry;
    }

    votingAnswers = votingAnswers.filter((value) => value.votingId !== votingId);
    votingAnswers.push(votingAnswerEntry);

    this.setValue(VotingAnswersLocalStorageService.VOTING_ANSWERS_KEY, votingAnswers);
    return votingAnswerEntry;
  }

  getPreviousVotingAnswer(votingId: String): VotingAnswerEntry {
    const votingVotes = this.getValue(VotingAnswersLocalStorageService.VOTING_ANSWERS_KEY);
    if (!votingVotes) {
      return undefined;
    }
    return votingVotes.find((value) => value.votingId === votingId);
  }
}
