import { BaseModel } from '@sl/common/model';
import { FeedbackAnswerOption } from './FeedbackAnswerOption';

export class CustomForm extends BaseModel<CustomForm> {
  name: string;
  formElements: CustomFormElement[];
}

export class CustomFormElement<T = CustomFormElementType> extends BaseModel<CustomFormElement> {
  public title: string;
  public type: T;
  public order: number;
  public required: boolean;
  public answerOptions: FeedbackAnswerOption[];
}

export class CustomFormElementValue {
  public formElementId: string;
  public value: any;

  constructor(formElementId: string, value: any) {
    this.formElementId = formElementId;
    this.value = value;
  }
}

export enum CustomFormElementType {
  Label = 'label',
  Checkbox = 'checkbox',
}
