import {
  ContactInfo,
  ExternalContent,
  ExternalContentMimeType,
  FeedbackOption,
  FeedbackOptionType,
  FeedbackValue,
  GDPRInfo,
  PresentationInfo,
  PresentationInstance,
  PresentationInstanceSettings,
  PresentationInstanceState,
  AskQuestionData,
  SlideAvailability,
  ContactInfoOverview,
} from '@sl/common/model';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Voting, VotingAnswerImageStyle, VotingType } from '@sl/common/model/Voting';
import { SlideInfo } from '@sl/common/model/SlideInfo';
import { BaseAuthorizedDataService } from '@sl/common/services/endpoint/BaseAuthorizedDataService';
import { SessionService } from '@sl/common/services/auth/SessionService';
import { SLConfig } from '@sl/config/SLConfig';
import { catchError, delay, map } from 'rxjs/operators';
import { PresentationInstanceStateInfo } from '@sl/common/services/endpoint/dto/PresentationInstanceStateInfo';
import { AttendeeMessage, AttendeeMessageDisplayType, AttendeeMessageType } from '@sl/common/model/AttendeeMessage';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { AttendancePayload } from '@sl/common/services/endpoint/dto/AttendancePayload';
import { Observable, of, throwError } from 'rxjs';
import { Theme } from '@sl/common/model/Theme';
import { Accessibility, ExternalContentAvailability } from '@sl/common/model/ContentRestrictions';
import { AttendanceInfo } from '@sl/common/services/endpoint/dto/AttendanceInfo';
import { VotingStatistic } from '@sl/common/model/VotingStatistic';
import { FakeDataProvider } from '@sl/common/services/endpoint/FakeDataProvider';
import { Question } from '@sl/common/model/Question';
import { FeedbackAnswerOption } from '@sl/common/model/FeedbackAnswerOption';

@Injectable({ providedIn: 'root' })
export class PresentationDataService extends BaseAuthorizedDataService {
  constructor(protected httpClient: HttpClient, protected sessionService: SessionService) {
    super(httpClient, sessionService);
  }

  public getPresentationInstanceByCode(code: string, accessKey: string) {
    return this.post<PresentationInstance>(`presentationinstance/code/${code}`, { accessKey: accessKey ?? null }).pipe(
      catchError((err) => {
        // Backward compatibility to backend v2.2
        if (err instanceof HttpErrorResponse && err.status === 404) {
          return this.get<PresentationInstance>(`presentationinstance/code/${code}`, { accessKey: accessKey ? accessKey : '' });
        }
        return throwError(err);
      })
    );
  }

  public getPresentationInfo(presentationInstanceId: string) {
    return this.get<PresentationInfo>(`presentationinstance/${presentationInstanceId}/overview`).pipe(
      map((pi) => {
        if (this.fakePresentationRunning()) {
          pi.presentationInstance.state = PresentationInstanceState.Running;
          // pi.presentationInstance.settings = pi.presentationInstance.settings || new PresentationInstanceSettings();
          // pi.presentationInstance.settings.slideStarringEnabled = true;
          // pi.presentationInstance.settings.slideNotesEnabled = true;
          // pi.presentation.showContactFormAtBeginning = true;
        }

        if (this.isMockingEnabled()) {
          const gdprInfo = new GDPRInfo();
          gdprInfo.isActivated = true;
          gdprInfo.text = 'Allow SlideLizard Software GmbH to regularly (every day) send me cool newsletters about ongoing cat projects';
          const settings = pi.presentationInstance.settings || new PresentationInstanceSettings();
          settings.gdpr = gdprInfo;
          // pi.presentation.imageBlobId  =  null;
          pi.presentationInstance.address = null;
          pi.presentationInstance.settings = settings;
          pi.presentationInstance.settings.slideStarringEnabled = true;
          pi.presentationInstance.settings.slideNotesEnabled = true;
          pi.presentationInstance.isQuestionsEnabled = true;
          pi.presentationInstance.isFeedbackEnabled = true;
          pi.presentation.name = '<Translations default="fr"><Translation lang="de">Jährlicher Unternehmensbericht</Translation><Translation lang="en" >Yearly report</Translation><Translation lang="fr">French</Translation></Translations>';
          pi.presentation.description = '<Translations><Translation lang="en">Facts and figures</Translation><Translation lang="de">Fakten, Zahlen und Entwicklungen von 2019.\r\nUnd eine zweite Zeile</Translation></Translations>';
          // pi.presentationInstance.exportedSlidesDocument.availability = Availability.AFTER_PRESENTATION;
          // pi.presentationInstance.exportedSlidesDocument.accessibility = Accessibility.EMAIL_REQUIRED;
          // pi.presentationInstance.exportedSlidesDocument.url = "https://slidelizarddata.blob.core.windows.net/slideshare/Debug/Presentations/5c12e331cf2adc35659330da/5c12e331cf2adc35659330db/2d766f7b-2329-415e-88d2-de37b24b72c7.pdf";

          // pi.presentation.imageUrl = "https://develop.slidelizard.at/api/v9/blob/5dcd3c43d4bd8d4aa74e2ba6/content/image?apikey=windows";
          // pi.presentation.imageBlobId = null;
        }

        return pi;
      })
    );
  }

  public getPresentationInstances(presentationId: string) {
    return this.get<PresentationInstance[]>(`presentation/${presentationId}/presentationinstance`);
  }

  public getFeedbackOptions(presentationInstanceId: string) {
    if (this.isMockingEnabled()) {
      const feedbackOptions: FeedbackOption[] = [
        new FeedbackOption(
          '1',
          '<Translations><Translation lang="en"><![CDATA[How did you like the slides?<br/><small><i>(1=very much 5=not much)</i></small>]]></Translation><Translation lang="de"><![CDATA[Wie haben Ihnen die Folien gefallen?<br/><small><i>(1=gut 5=nicht gut)</i></small>]]></Translation></Translations>',
          FeedbackOptionType.NumberRating5,
          false
        ),
        new FeedbackOption('12', 'Did you like the location', FeedbackOptionType.YesNo, true),
        new FeedbackOption('2', 'How did you like the content?<br/><small><i>(1=very much 6=not much)</i></small>', FeedbackOptionType.NumberRating6, false),
        new FeedbackOption('3', 'How did you like it overall?', FeedbackOptionType.StarRating5, true),
        new FeedbackOption('4', 'How did you like it overall?', FeedbackOptionType.StarRating6, false),
        new FeedbackOption('5', 'What can we improve?', FeedbackOptionType.Text, true),
        new FeedbackOption('6', 'Frage 1', FeedbackOptionType.SingleChoice, false, 17, [ new FeedbackAnswerOption(null, "1asdf", "Antwort 1", 0), new FeedbackAnswerOption(null, "2aas6sdf", "Antwort 2", 1), new FeedbackAnswerOption(null, "3aas6etsdf", "Antwort 3", 2) ]),
        new FeedbackOption('7', 'Frage 2', FeedbackOptionType.SingleChoice, false, 18, [ new FeedbackAnswerOption(null, "1asdf34", "Antwort 4", 0), new FeedbackAnswerOption(null, "2aas6sdf23", "Antwort 5", 1), new FeedbackAnswerOption(null, "3aas6etsdf346", "Antwort 6", 2) ])
      ];
      return of(feedbackOptions).pipe(delay(1000));
    }

    return this.get<FeedbackOption[]>(`presentationinstance/${presentationInstanceId}/feedbackoptions`, null, { sort: 'order' });
  }

  public getSlideInfo(presentationInstanceId: string) {
    return this.get<SlideInfo>(`presentationinstance/${presentationInstanceId}/slideinfo`).pipe(
      map((si) => {
        if (this.fakePresentationRunning()) {
          si.isLive = true;
        }

        if (this.isMockingEnabled()) {
          // const size = si.slides[si.slides.length - 1].slideNumber + 1;

          // si.slides = si.slides.slice(0, 80);
          // const slides = [];
          // slides.push(...si.slides);
          // for (var i = 0; i < 250; i++) {
          //   slides.push(new Slide(size + i, si.slides[i % si.slides.length].imageUrl));
          // }
          // si.slides = slides;
          // console.log(si.slides);
          // si.slides.forEach(s => s.id = String(s.slideNumber));
          //           si.distractionFreeMode = true;
          //           si.slides = null;
          // si.currentSlideNumber = 39;
          si.availability = SlideAvailability.BEGIN_PRESENTATION;
        }

        return si;
      })
    );
  }

  public getExternalContent(presentationId: string) {
    if (this.isMockingEnabled()) {
      const content = [
        // new ExternalContent(null, "VR in Games", "Video showing what's already possible", "video"),
        new ExternalContent(
          { id: '1' },
          '<Translations><Translation lang="en">Handout</Translation><Translation lang="de">Ausgabe</Translation></Translations>',
          '<Translations><Translation lang="en">Description</Translation><Translation lang="de">Beschreibung</Translation></Translations>',
          'textDoc',
          ExternalContentAvailability.BEFORE_PRESENTATION,
          Accessibility.EMAIL_REQUIRED,
          'https://google.com'
        ),
        new ExternalContent(null, 'Rückblick Veranstaltungen 2019', 'Ergebnisse & Fotos', 'textDoc', ExternalContentAvailability.BEGIN_PRESENTATION, Accessibility.EMAIL_REQUIRED),
        new ExternalContent(null, 'VR Devices List', 'Website listing all available VR headsets', 'website', ExternalContentAvailability.AFTER_PRESENTATION),
        new ExternalContent(null, 'How VR will change the world', 'Book recommendation', 'book'),
        // new ExternalContent(null, 'Livestream', 'Klicken um extern zu öffnen', ExternalContentMimeType.Live.name, ExternalContentAvailability.BEGIN_PRESENTATION, Accessibility.UNRESTRICTED, 'http://video.slidelizard.com?room=sl-test'),
        // new ExternalContent({ id: '12' }, 'Facebook', '', ExternalContentMimeType.Video.name, ExternalContentAvailability.BEGIN_PRESENTATION, Accessibility.UNRESTRICTED, 'https://www.facebook.com/7Hauben/videos/712552599294327/'),
        new ExternalContent({ id: '1' }, 'MP4', '', ExternalContentMimeType.Video.name, ExternalContentAvailability.BEGIN_PRESENTATION, Accessibility.UNRESTRICTED, 'https://slidelizarddata.blob.core.windows.net/homepage-assets/blog/6-morph-transition/1-image-gallery.mp4'),
        new ExternalContent({ id: '2' }, 'YouTube', '', ExternalContentMimeType.Video.name, ExternalContentAvailability.BEGIN_PRESENTATION, Accessibility.UNRESTRICTED, 'https://www.youtube.com/watch?v=G4E2bbAgjXg'),
        // new ExternalContent(
        //   { id: '1234' },
        //   'Azure Media Service',
        //   '',
        //   ExternalContentMimeType.Video.name,
        //   ExternalContentAvailability.BEGIN_PRESENTATION,
        //   Accessibility.UNRESTRICTED,
        //   'https://media1ktm4slidelizard-euwe.streaming.media.azure.net/622fadef-a7c9-4f08-a50b-29d0c61bb2e5/This is KTM.ism/manifest'
        // ),
        // new ExternalContent(
        //   { id: '12345' },
        //   'Azure media service 2',
        //   '',
        //   ExternalContentMimeType.Video.name,
        //   ExternalContentAvailability.BEGIN_PRESENTATION,
        //   Accessibility.UNRESTRICTED,
        //   'https://amssamples.streaming.mediaservices.windows.net/91492735-c523-432b-ba01-faba6c2206a2/AzureMediaServicesPromo.ism/manifest'
        // ),
      ];
      return of(content);
    }

    return this.get<ExternalContent[]>(`presentation/${presentationId}/externalcontent`);
  }

  public getContactInfo(contactInfoId: string) {
    return this.get<ContactInfo>(`contactinfo/${contactInfoId}`);
  }

  public getRunningVoting(presentationInstanceId: string) {
    if (this.isMockingEnabled()) {
      return FakeDataProvider.createDelayedResult(FakeDataProvider.createVoting({ type: VotingType.PointsDistribution, answerImageStyle: VotingAnswerImageStyle.Large, isVotingAnonymous: true, maxVotes: 4 }));
    }

    return this.get<Voting>(`presentationinstance/${presentationInstanceId}/voting/running`);
  }

  public getQuestions(presentationInstanceId: string): Observable<Question[]> {
    return this.get<Question[]>(`presentationinstance/${presentationInstanceId}/question`);
  }

  public upVoteQuestion(presentationInstanceId: string, questionId: string) {
    if (this.isMockingEnabled()) {
      return FakeDataProvider.createDelayedResult(void 0);
    }
    return this.put<any>(`presentationinstance/${presentationInstanceId}/question/${questionId}/upvote`, {});
  }

  public getVotingResult(presentationInstanceId: string, votingId: string) {
    if (this.isMockingEnabled()) {
      return FakeDataProvider.createDelayedResult(FakeDataProvider.createVotingResult(FakeDataProvider.createVoting()));
    }

    return this.get<VotingStatistic>(`/presentationinstance/${presentationInstanceId}/voting/vote/${votingId}/statistic`);
  }

  public getAttendeeMessages(presentationInstanceId: string) {
    if (this.isMockingEnabled()) {
      const msgs = new Array<AttendeeMessage>();

      const msg1 = new AttendeeMessage();
      msg1.id = '5';
      msg1.displayType = AttendeeMessageDisplayType.SlideOverlay;
      msg1.type = AttendeeMessageType.Text;
      msg1.text = 'Ende der Pause: 17:45. Bitte alle Gruppen wieder rechtzeitig zurückkommen!';
      // msg1.text = "https://1bp5yi30zpi36aeiy29wbvdr-wpengine.netdna-ssl.com/wp-content/uploads/2016/01/11-ways-to-stay-motivated-when-youre-getting-out-of-debt-blog-990x566.jpg";
      msgs.push(msg1);

      return of(msgs);
    }

    return this.get<AttendeeMessage[]>(`presentationinstance/${presentationInstanceId}/attendeemessage`);
  }

  public postQuestion(presentationInstanceId: string, question: AskQuestionData) {
    return this.post<any>(`presentationinstance/${presentationInstanceId}/question`, question);
  }

  public postFeedback(presentationInstanceId: string, feedback: FeedbackValue[]) {
    return this.post<any>(`presentationinstance/${presentationInstanceId}/feedback`, feedback);
  }

  public postAttendance(presentationInstanceId: string, deviceInfo: AttendancePayload) {
    if (this.fakePresentationRunning()) {
      // return of(new AttendanceInfo(GDPRState.NotSeen, null));
    }
    return this.post<AttendanceInfo>(`presentationinstance/${presentationInstanceId}/attendance`, deviceInfo);
  }

  public postExternalContentView(presentationInstanceId: string, externalContentId: string) {
    // Changes here should also be made in tracking.html !
    return this.post<any>(`presentationinstance/${presentationInstanceId}/externalcontent/${externalContentId}/viewed`, {});
  }

  public getCurrentState(presentationInstanceId: string) {
    SLLogger.log('Get current state');
    return this.get<PresentationInstanceStateInfo>(`presentationinstance/${presentationInstanceId}/stateinfo`).pipe(
      map((pi) => {
        if (this.fakePresentationRunning()) {
          pi.presentationInstanceState = PresentationInstanceState.Running;

          if (this.isMockingEnabled()) {
            // pi.lastAttendeeMessageId = "5";
            // pi.currentSlideNumber = 3;
            // pi.runningVotingId = "1234";
          }
        }

        return pi;
      })
    );
  }

  public getPresentationTheme(presentationInstanceId: string) {
    if (this.isMockingEnabled()) {
      // console.log(JSON.stringify(FakeThemes.RaiffeisenSumsiTheme, null, 2));
      return of(null);
    }
    return this.get<Theme>(`presentationinstance/${presentationInstanceId}/theme`);
  }

  private fakePresentationRunning() {
    return SLConfig.getDebugConfig().fakePresentationRunning;
  }
}
