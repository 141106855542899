<div [@shake]="shake">
  <mat-checkbox *ngIf="showTermsCheckbox"
                [(ngModel)]="hasAcceptedTerms"
                [ngModelOptions]="{standalone: true}"
                [disabled]="disabled"
                [color]="errorShown ? 'warn' : 'accent'"
                [style.font-weight]="errorShown ? 'bold' : 'normal'"
                [ngClass]="errorShown ? 'foreground-color-error' : textClass"
                style="font-size: 0.875rem; transition: all .3s;">
    <span style="white-space: normal;" [innerHTML]="'legal.accept-terms-active' | translate"></span>
  </mat-checkbox>
</div>
