import { SLConfig } from '@sl/config/SLConfig';
import { Injectable, NgZone } from '@angular/core';
import { BasePushMessageService } from '@sl/common/services/BasePushMessageService';
import { ErrorTrackerService } from '@sl/common/services/error-handling/ErrorTrackerService';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { WorkflowDependentService } from './WorkflowDependentService';
import { Workflow } from '../model/Workflow';

@Injectable()
export class AttendeeWorkflowPushService extends BasePushMessageService implements WorkflowDependentService {
  constructor(private authService: AuthService, zone: NgZone, errorTrackerService: ErrorTrackerService) {
    super(zone, errorTrackerService);
  }

  workflowId: string;

  init(worflow: Workflow): void {
    const userId = this.authService.currentUser?.id ?? '';
    if (!userId) {
      SLLogger.warn('No user id for initializing push service!');
    }

    super.connect(SLConfig.getEnvironment().pushMessageServer.attendeeHubName, worflow.id, userId);
  }

  disconnect() {
    this.workflowId = null;
    super.disconnect();
  }
}
