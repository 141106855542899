import { SLConfig } from '@sl/config/SLConfig';

// TODO make service out of it
// TODO move links into constants/readonly functions
export class DeepLinkUtils {
  static createPresentationInstanceDeepLink(instanceId: string): string {
    return SLConfig.getEnvironment().baseDeepLinkURL + '/presentation/' + instanceId;
  }

  static createPresentationInstanceInAppDeepLink(instanceId: string) {
    return 'https://q8m5a.app.goo.gl/?link=' + this.createPresentationInstanceDeepLink(instanceId) + '&apn=com.amerbauer.slidelizard&ad=0';
  }

  static createConferenceDeepLink(conferenceId: string): string {
    return SLConfig.getEnvironment().baseDeepLinkURL + '/conference/' + conferenceId;
  }

  static createConferenceInAppDeepLink(conferenceId: string) {
    return this.createInAppDeepLink(this.createConferenceDeepLink(conferenceId));
  }

  private static createInAppDeepLink(url: string) {
    return 'https://q8m5a.app.goo.gl/?link=' + url + '&apn=com.amerbauer.slidelizard&ad=0';
  }

  static createAudienceParticipationURL(connectionCode: string): string {
    if (connectionCode) {
      return SLConfig.getEnvironment().audienceParticipationURL + '/' + connectionCode.toLowerCase();
    }
    return '';
  }
}
