export class Path {
  private _path = '';

  constructor(private _basePath?: string) {
    this._path += _basePath;
  }

  public combine(...paths: string[]): Path {
    paths.forEach((path) => {
      path = Path.normalize(path, this._path);
      this._path += path;
    });

    return this;
  }

  public makeAbsolute(): Path {
    if (!this.path) {
      return this;
    }
    const protocol = /[a-zA-z0-9]+:\/\//;
    const regexResults = protocol.exec(this.path);
    if (!regexResults || regexResults.length === 0) {
      this._path = 'http://' + this.path;
    }
    return this;
  }

  public get path(): string {
    return this._path;
  }

  public get basePath(): string {
    return this._basePath;
  }

  public toString(): string {
    return this.path;
  }

  /**
   * Removes or adds a slash of path2 regarding the state of path1 in order to make sure two slashes arent in sequence
   */
  private static normalize(path2: string, path1: string): string {
    if (path1.endsWith('/')) {
      if (path2.startsWith('/')) {
        return path2.substring(1);
      }
    } else if (path2 && !path2.startsWith('/')) {
      return '/' + path2;
    }

    return path2;
  }
}
