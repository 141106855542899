import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { BaseVotingAnswerContainerComponent } from '../BaseVotingAnswerContainerComponent';
import { Voting } from '@sl/common/model/Voting';
import { VotingAnswerEntry } from '@sl/common/model/local/VotingAnswerEntry';
import { NgForm } from '@angular/forms';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { PhotoAnswerPayload } from '@sl/common/services/endpoint/dto/PhotoAnswerPayload';
import { PhotoUploadDataService } from '@sl/common/services/endpoint/PhotoUploadDataService';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { animate, sequence, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'photo-answer-container',
  templateUrl: './photo-answer.component.html',
  styleUrls: ['./photo-answer.component.scss'],
  animations: [
    trigger('anim', [
      transition('void => *', [style({ height: '0', opacity: '0', transform: 'translateX(20px)' }), sequence([animate('.1s ease', style({ height: '*', opacity: '.2', transform: 'translateY(20px)' })), animate('.35s ease', style({ height: '*', opacity: 1, transform: 'translateY(0)' }))])]),
    ]),
  ],
})
export class PhotoAnswerComponent extends BaseVotingAnswerContainerComponent implements OnDestroy {
  private readonly MAX_FILE_SIZE = 4 * 1024 * 1024;

  ProgressState = ProgressState;

  maxAnswers?: number;

  @Input()
  set activeVoting(voting: Voting) {
    this._activeVoting = voting;
    this.maxAnswers = voting.maxVotes; // Should use maxAnswersPerAttendee?

    this.populateAnswers();
  }

  get activeVoting() {
    return this._activeVoting;
  }

  @Input()
  set previousVotingAnswer(value: VotingAnswerEntry) {
    this._previousVotingAnswer = value;

    if (this.newAnswer && value && (value.answers as Array<string>).includes(this.newAnswer.imageUrl)) {
      this.resetAnswer();
    }

    this.populateAnswers();

    setTimeout(() => {
      try {
        this.hostElementRef?.nativeElement.scrollTo({
          top: this.hostElementRef.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      } catch (e) {}
    }, 500);
  }

  get previousVotingAnswer() {
    return this._previousVotingAnswer;
  }

  @ViewChild('form')
  form: NgForm;

  public answers: Array<string>;
  public newAnswer?: { file: File; previewImageUrl: string; imageUrl?: string };
  private unsubscribe$ = new Subject<void>();

  constructor(private hostElementRef: ElementRef, private photoUploadService: PhotoUploadDataService, private translateService: TranslateService) {
    super();
  }

  populateAnswers() {
    if (this.activeVoting) {
      if (this.previousVotingAnswer) {
        this.answers = this.previousVotingAnswer.answers;
      } else {
        this.answers = [];
      }
    }
  }

  save() {
    if (this.newAnswer && this.hasChosenAnswer()) {
      this.saveEmitter.emit();
    }
  }

  uploadPhoto() {
    return this.photoUploadService
      .uploadPhoto(this.newAnswer.file)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((result) => {
          this.newAnswer.imageUrl = result.url;
          // this.newAnswer.imageUrl = this.newAnswer.previewImageUrl;
        })
      )
      .toPromise();
  }

  async getVotePayload(): Promise<PhotoAnswerPayload> {
    if (!this.newAnswer.imageUrl) {
      await this.uploadPhoto();
    }
    return { imageUrl: this.newAnswer.imageUrl };
  }

  hasChosenAnswer(): boolean {
    return this.newAnswer != null && this.newAnswer.file != null;
  }

  canAddAnswer() {
    if (this.activeVoting.votesLeft != null) {
      return this.activeVoting.votesLeft > 0;
    }

    if (this.answers && this.activeVoting && this.activeVoting.maxAnswersPerAttendee != null) {
      return this.answers.length < this.activeVoting.maxAnswersPerAttendee;
    }
    return true;
  }

  onPhotoSelected(files: FileList) {
    this.resetAnswer();

    const file = files[0];
    if (file) {
      if (file.size >= this.MAX_FILE_SIZE) {
        alert(this.translateService.instant('presentation.votings.photo.error-file-too-large', { maxFileSizeMb: Math.round(this.MAX_FILE_SIZE / 1024 / 1024) }));
        return;
      }

      this.newAnswer = { file: file, previewImageUrl: URL.createObjectURL(file) };
    }
  }

  resetAnswer() {
    if (this.newAnswer) {
      // URL.revokeObjectURL(this.newAnswer.previewImageUrl);
    }
    this.newAnswer = null;
  }

  shouldShowScrollIndicator(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.resetAnswer();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
