import { NgZone } from '@angular/core';
import * as SignalR from '@aspnet/signalr';
import { HubConnection } from '@aspnet/signalr';
import { SLConfig } from '@sl/config/SLConfig';
import { BehaviorSubject } from 'rxjs';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { ErrorTrackerService } from '@sl/common/services/error-handling/ErrorTrackerService';
import { NetworkUtils } from '@sl/common/utils/NetworkUtils';

export enum PushConnectionState {
  Disconnected,
  Connecting,
  Connected,
}

export abstract class BasePushMessageService {
  private static readonly MAX_ERROR_RECONNECTS = 2;
  private static readonly ERROR_RECONNECT_TIMEOUT = 3000;

  private static readonly SIGNALR_STATE_DISCONNECTED = 2;
  private static readonly SIGNALR_STATE_CONNECTED = 1;
  private static readonly SIGNALR_STATE_CONNECTING = 0;

  public connectionState: BehaviorSubject<PushConnectionState>;
  private subscribeChannelId: string;
  private subscribeUserId: string;
  private hub: string;

  private signalRConnection: HubConnection;
  private errorReconnectCount = 0;

  private onCheckConnectionListener = () => {
    setTimeout(() => {
      this.reconnect();
    }, 500);
  };

  protected constructor(private zone: NgZone, private errorTrackerService: ErrorTrackerService) {
    this.setupObservables();
  }

  protected connect(hub: string, subscribeChannelId: string, subscribeUserId: string) {
    this.subscribeChannelId = subscribeChannelId;
    this.subscribeUserId = subscribeUserId;
    this.hub = hub;
    this.setConnectionState(PushConnectionState.Connecting);

    const url = SLConfig.getEnvironment().pushMessageServer.baseUri + '/' + this.hub;

    if (!this.signalRConnection) {
      this.signalRConnection = new SignalR.HubConnectionBuilder().withUrl(url).build();
    }

    SLLogger.log('SignalR connecting %s', url);

    try {
      this.signalRConnection
        .start()
        .then((_) => {
          SLLogger.log('Signalr connected');
          this.signalRConnection
            .invoke('subscribeAsUser', this.subscribeChannelId, this.subscribeUserId)
            .then((__) => {
              SLLogger.log('Signalr connected (instance id: %s, user id: %s)', this.subscribeChannelId, this.subscribeUserId);
              this.setConnectionState(PushConnectionState.Connected);
            })
            .catch((err) => {
              SLLogger.warn(err);
              this.reconnectOnError(err);
            });
        })
        .catch((err) => {
          SLLogger.warn(err);
          this.reconnectOnError(err);
        });
    } catch (e) {
      SLLogger.warn(e);
    }

    this.signalRConnection.onclose((error) => {
      if (error) {
        SLLogger.warn(error);
        this.zone.run(() => this.reconnectOnError(error));
      }
    });

    window.addEventListener('focus', this.onCheckConnectionListener);
    NetworkUtils.addOnlineOfflineEventListener(this.onCheckConnectionListener);

    return this.signalRConnection;
  }

  disconnect() {
    this.subscribeChannelId = null;
    this.subscribeUserId = null;
    this.hub = null;

    window.removeEventListener('focus', this.onCheckConnectionListener);
    NetworkUtils.removeOnlineOfflineEventListener(this.onCheckConnectionListener);

    this.setConnectionState(PushConnectionState.Disconnected);

    if (this.signalRConnection) {
      try {
        this.signalRConnection
          .stop()
          .then((_) => SLLogger.log('SignalR disconnected'))
          .catch((err) => SLLogger.warn(err));
      } catch (e) {
        SLLogger.warn(e);
      }
    }

    this.connectionState.complete();
    this.setupObservables();
  }

  private reconnectOnError(err: any) {
    this.setConnectionState(PushConnectionState.Disconnected);
    this.errorReconnectCount += 1;

    if (this.errorReconnectCount <= BasePushMessageService.MAX_ERROR_RECONNECTS) {
      setTimeout(() => this.reconnect(), BasePushMessageService.ERROR_RECONNECT_TIMEOUT);
    } else {
      // this.errorTrackerService.trackError(new Error("SignalR all reconnecting attempts failed"));
      SLLogger.warn('SignalR all reconnecting attempts failed!');
    }
  }

  private reconnect() {
    const isOnline = NetworkUtils.isOnline();
    const signalRConnectionState = (<any>this.signalRConnection).connection.connectionState;
    const pushServiceConnectionStateText = signalRConnectionState === BasePushMessageService.SIGNALR_STATE_CONNECTED ? 'connected' : signalRConnectionState === BasePushMessageService.SIGNALR_STATE_DISCONNECTED ? 'disconnected' : 'connecting';

    SLLogger.log('SignalR check reconnecting, isOnline: %s, signalRConnectionState: %s', isOnline, pushServiceConnectionStateText);

    if (isOnline && signalRConnectionState === BasePushMessageService.SIGNALR_STATE_CONNECTED) {
      this.setConnectionState(PushConnectionState.Connected);
    } else if (isOnline && signalRConnectionState === BasePushMessageService.SIGNALR_STATE_DISCONNECTED && this.subscribeChannelId) {
      SLLogger.log('SignalR reconnecting...');
      this.connect(this.hub, this.subscribeChannelId, this.subscribeUserId);
    } else if (signalRConnectionState === BasePushMessageService.SIGNALR_STATE_CONNECTING) {
      this.setConnectionState(PushConnectionState.Connecting);
    } else if (!isOnline || signalRConnectionState === BasePushMessageService.SIGNALR_STATE_DISCONNECTED) {
      this.setConnectionState(PushConnectionState.Disconnected);
    }
  }

  public listen(action: string, listener: (data: any) => void) {
    if (this.signalRConnection) {
      try {
        this.signalRConnection.on(action, (data: any) => {
          SLLogger.log('SignalR received message: %s %o', action, data);

          this.zone.run(() => {
            listener(data);
          });
        });
      } catch (e) {
        SLLogger.warn(e);
      }
    }
  }

  private setConnectionState(connectionState: PushConnectionState) {
    SLLogger.log('SignalR connected: ' + connectionState);

    if (connectionState === PushConnectionState.Connected) {
      this.errorReconnectCount = 0;
    }

    this.connectionState.next(connectionState);
  }

  private setupObservables() {
    this.connectionState = new BehaviorSubject<PushConnectionState>(PushConnectionState.Disconnected);
  }
}
