import { BaseVotingAnswerContainerComponent } from '../BaseVotingAnswerContainerComponent';
import { Voting } from '@sl/common/model/Voting';
import { AfterViewInit, Component, Input, OnDestroy, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { VotingAnswerEntry } from '@sl/common/model/local/VotingAnswerEntry';
import { CdkDrag, DragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';
import { VotingAnswerOption } from '@sl/common/model/VotingAnswerOption';

export class AnswerOptionPoints {
  constructor(public answerOptionId: string, public points: number) {}
}

export class Answers {
  constructor(public answers: AnswerOptionPoints[] ) {}
}

@Component({
  selector: 'ranking-answer-container',
  templateUrl: 'ranking-answer-container.component.html',
  styleUrls: ['ranking-answer-container.component.scss'],
})
export class RankingAnswerContainerComponent extends BaseVotingAnswerContainerComponent implements AfterViewInit, OnDestroy {
  static readonly CAN_SEND_ANSWER_DELAY = 2000;

  @Input()
  set activeVoting(voting: Voting) {
    this._activeVoting = voting;
    this._activeVoting.answerOptions = this.shuffleAnswerOptions(this._activeVoting.answerOptions);
  }

  get activeVoting() {
    return this._activeVoting;
  }

  @Input()
  set previousVotingAnswer(value: VotingAnswerEntry) {
    this._previousVotingAnswer = value;
    this.populateSelectedChoiceOptions();
  }

  get previousVotingAnswer() {
    return this._previousVotingAnswer;
  }

  @ViewChildren(CdkDrag)
  answerViews: QueryList<CdkDrag>;

  isDesktop = DeviceDetect.isDesktop();
  showUpDownButtons = this.isDesktop;

  canSendAnswer = false;
  canSendAnswerTimeout: any;

  constructor(private dragDrop: DragDrop, private container: ViewContainerRef) {
    super();
  }

  shuffleAnswerOptions(array: VotingAnswerOption[]): VotingAnswerOption[] {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

  ngAfterViewInit(): void {
     const dropListRef = this.dragDrop.createDropList(this.container.element).withItems(this.answerViews.map((av) => av._dragRef));

    dropListRef.lockAxis = 'y';
    dropListRef.dropped.subscribe((e) => {
      moveItemInArray(this.activeVoting.answerOptions, e.previousIndex, e.currentIndex);
    });
    dropListRef.autoScrollDisabled = false;

    // Add delay to avoid Angular crash for change detection & to delay send button animating
    this.canSendAnswerTimeout = setTimeout(() => (this.canSendAnswer = true), RankingAnswerContainerComponent.CAN_SEND_ANSWER_DELAY);
  }

  populateSelectedChoiceOptions() {
    if (this.activeVoting && this.previousVotingAnswer) {
      this.activeVoting.answerOptions = this.previousVotingAnswer.answers.answerOptionIds.map((answerOptionId: string) => this.activeVoting.answerOptions.find((ao) => ao.id === answerOptionId));
    }
  }

  moveAnswer(answerOption: VotingAnswerOption, direction: number) {
    const fromIndex = this.activeVoting.answerOptions.indexOf(answerOption);
    const toIndex = fromIndex + direction;

    if (toIndex < this.activeVoting.answerOptions.length && toIndex >= 0) {
      moveItemInArray(this.activeVoting.answerOptions, fromIndex, toIndex);
    }
  }

  canMoveAnswers() {
    return !this.disabled && (this.sendVoteState === ProgressState.Content || this.sendVoteState === ProgressState.Error) && this.previousVotingAnswer == null;
  }

  async getVotePayload() {
    if(this.activeVoting.isQuiz){
      const answers = this.activeVoting.answerOptions.map((item) => item.id);
      return { answerOptionIds: answers };
    }

    const answerCount = this.activeVoting.answerOptions.length;
    var answersArr : AnswerOptionPoints[] = []
    this.activeVoting.answerOptions.map((item, index) => {
      answersArr.push(new AnswerOptionPoints(item.id, answerCount - index));
    });
    return new Answers(answersArr)
  }

  hasChosenAnswer(): boolean {
    return this.activeVoting !== null && this.canSendAnswer;
  }

  ngOnDestroy(): void {
    if (this.canSendAnswerTimeout) {
      clearTimeout(this.canSendAnswerTimeout);
      this.canSendAnswerTimeout = null;
    }
  }
}
