import { Theme } from '@sl/common/model/Theme';
import { SlideLizardTheme } from '@sl/config/themes/SlideLizardTheme';

export const SlideLizardDarkTheme: Theme = {
  ...SlideLizardTheme,
  colors: {
    primaryColor: '#A1416E',
    text1OnPrimaryColor: '#FFF',

    secondaryColor: 'var(--primaryColor)',
    text1OnSecondaryColor: '#FFF',

    surfaceColor: '#1D1D1D',
    text1OnSurfaceColor: 'white',
    text2OnSurfaceColor: 'rgba(255,255,255, 0.74)',

    backgroundColor: 'black',
    text1OnBackgroundColor: 'var(--text1OnSurfaceColor)',
    text2OnBackgroundColor: 'var(--text2OnSurfaceColor)',

    dialogBackgroundColor: 'var(--backgroundColor)',
    text1OnDialogBackgroundColor: 'var(--text1OnBackgroundColor)',
    text2OnDialogBackgroundColor: 'var(--text2OnBackgroundColor)',

    errorColor: '#f44336',
    text1OnErrorColor: 'white',

    // Other colors
    toolbarBackground: 'linear-gradient(-135deg, #A64269 0%, #8E3F87 100%)',
    text1OnToolbarColor: 'var(--text1OnPrimaryColor)',

    headerBackground: 'linear-gradient(-135deg, #BF454A 0%, #A64269 30%, #8E3F87 60%, #673AB7 100%)',
    text1OnHeaderColor: 'var(--text1OnPrimaryColor)',

    highlightColor: 'rgba(255,255,255, 0.6)',
    dividerColor: 'rgba(255,255,255, 0.2)',
  },
};
