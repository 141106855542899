import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiLine',
})
export class MultiLineStringPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
    return value;
  }
}
