import { Voting } from '@sl/common/model/Voting';
import { VotingStatistic } from '@sl/common/model/VotingStatistic';

export class VotingResult {
  voting: Voting;
  statistic: VotingStatistic;

  constructor(voting: Voting, statistic: VotingStatistic) {
    this.voting = voting;
    this.statistic = statistic;
  }
}
