import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { BaseOverlayService } from '@sl/common/services/BaseOverlayService';
import { AboutDialogComponent } from '@sl/common/components/about-dialog/AboutDialogComponent';

@Injectable()
export class AboutOverlayService extends BaseOverlayService<AboutDialogComponent> {
  constructor(overlay: Overlay) {
    super(overlay);
  }

  protected getComponent(): ComponentType<AboutDialogComponent> {
    return AboutDialogComponent;
  }

  open() {
    const instance = this.createOverlay();
    instance.overlayRef = this.overlayRef;

    return this.overlayRef;
  }
}
