import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sl-photo-grid',
  templateUrl: './photo-grid.component.html',
  styleUrls: ['./photo-grid.component.scss'],
})
export class PhotoGridComponent {
  _chartData: Array<ChartValue>;

  @Input()
  set chartData(value: Array<ChartValue>) {
    // TODO: hardcoded for RLB
    this._chartData = value.sort((a, b) => b.value - a.value || a.name.localeCompare(b.name)).slice(0, 10);
    this._chartData.forEach((d, index) => (d.value = index + 1));
  }

  get chartData() {
    return this._chartData;
  }
}
