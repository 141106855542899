import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Voting } from '@sl/common/model/Voting';
import { VotingAnswerEntry } from '@sl/common/model/local/VotingAnswerEntry';
import { ProgressState } from '@sl/common/utils/ProgressState';

@Component({
  selector: 'base-voting-answer-component',
  template: '',
})
export class BaseVotingAnswerContainerComponent {
  protected _activeVoting: Voting;
  protected _previousVotingAnswer: VotingAnswerEntry;

  @Input()
  public sendVoteState: ProgressState;

  @Input()
  public disabled = false;

  @Output('save')
  protected saveEmitter = new EventEmitter();

  constructor() {}

  public async getVotePayload(): Promise<any> {
    return null;
  }

  hasChosenAnswer(): boolean {
    return false;
  }

  shouldShowScrollIndicator(): boolean {
    return true;
  }

  shouldShowSendButton() {
    return true;
  }
}
