export class NetworkUtils {
  public static isOnline() {
    if (navigator.onLine !== undefined) {
      return navigator.onLine;
    }
    return true;
  }

  public static addOnlineOfflineEventListener(eventListener: EventListenerOrEventListenerObject) {
    this.addOnlineEventListener(eventListener);
    this.addOfflineEventListener(eventListener);
  }

  public static addOnlineEventListener(eventListener: EventListenerOrEventListenerObject) {
    window.addEventListener('online', eventListener);
  }

  public static addOfflineEventListener(eventListener: EventListenerOrEventListenerObject) {
    window.addEventListener('offline', eventListener);
  }

  public static removeOnlineOfflineEventListener(eventListener: EventListenerOrEventListenerObject) {
    this.removeOnlineEventListener(eventListener);
    this.removeOfflineEventListener(eventListener);
  }

  public static removeOnlineEventListener(eventListener: EventListenerOrEventListenerObject) {
    window.removeEventListener('online', eventListener);
  }

  public static removeOfflineEventListener(eventListener: EventListenerOrEventListenerObject) {
    window.removeEventListener('offline', eventListener);
  }
}
