import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnDestroy } from '@angular/core';
import { OverlayCloseHelper } from '@sl/common/utils/OverlayCloseHelper';

@Component({
  selector: 'base-dialog',
  template: '',
})
export class BaseDialogComponent<T> implements OnDestroy {
  private overlayCloseHelper: OverlayCloseHelper;

  constructor(public dialogRef: MatDialogRef<T>) {
    this.overlayCloseHelper = new OverlayCloseHelper(this.constructor.name, () => this.dialogRef.close());
  }

  ngOnDestroy(): void {
    this.overlayCloseHelper.onDestroy();
  }
}
