import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class SLTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public prefix: string = '', public suffix: string = '.json') {}

  public getTranslation(lang: string): Observable<Object> {
    let url: string;
    if (lang.startsWith('http')) {
      url = lang;
    } else {
      url = `${this.prefix}${lang}${this.suffix}`;
    }
    return this.http.get(url);
  }
}
