import { IAnalyticsTracker } from '@sl/common/services/analytics/IAnalyticsTracker';
import { AuthConfig } from 'angular-oauth2-oidc';
import { IErrorTracker } from '@sl/common/services/error-handling/IErrorTracker';
import { Theme, ThemeAnalytics } from '@sl/common/model/Theme';

export enum Environment {
  Local = 'local',
  Develop = 'develop',
  Test = 'test',
  Production = 'production',
}

export interface HelpPageLink {
  [language: string]: string | undefined;
}

export interface IEnvironment {
  name: Environment;
  production: boolean;
  appVersion: string;
  appVersionCode: number;
  buildTimestamp?: number;
  i18n: {
    defaultLanguage: string;
    supportedLanguages: string[]; // ISO 639-1 codes, https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
  };
  theme: {
    default: Theme; // Used when no theme is defined for a presentation or for other modules (connect, activate,...)
    defaultDark: Theme; // Used for Microsoft Teams during a call
    fallback: Theme; // Values of the fallback theme are used when a theme is defined but it's missing some values
    neutral: Theme; // Used while theme is still loading
  };
  webservice: {
    baseUri: string;
    apikey: string;
    contactGuesserServiceBaseUri: string;
    photoUploadBaseUri: string;
    avatarBaseUri: string;
  };
  authServerConfig: AuthConfig;
  homepageURL: string;
  audienceParticipationURL: string;
  baseDeepLinkURL: string;
  tracking: {
    enableRequestTimeTracking: boolean;
    allowedAnalyticsDomains?: string[];
    error: IErrorTracker[];
  };
  pushMessageServer: {
    baseUri: string;
    attendeeHubName: string;
  };
  notSupportedBrowsers: {};
  liveStreaming: {
    jitsi: {
      domain: string;
    };
  };
  helpPages: {
    msTeams: HelpPageLink;
  };
}
