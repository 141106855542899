import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PresentationDataService } from '@sl/common/services/endpoint/PresentationDataService';
import { TrackingKey, SLAnalyticsService } from '@sl/common/services/analytics';
import { UserDataService } from '@sl/common/services/endpoint/UserDataService';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { AttendedPresentationInfo } from '@sl/common/services/endpoint/dto/AttendedPresentationInfo';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { Subscription } from 'rxjs';
import { SessionService } from '@sl/common/services/auth/SessionService';

@Component({
  selector: 'attendance-history',
  templateUrl: 'attendance-history.component.html',
  styleUrls: ['attendance-history.component.scss'],
})
export class AttendanceHistoryComponent implements OnDestroy {
  ProgressState = ProgressState;
  loadingState = ProgressState.Empty;

  @Input()
  set shouldLoadAttendedPresentations(value: boolean) {
    if (value && this.presentationHistoryEntries == null) {
      this.loadAttendedPresentations();

      if (this.sessionSubscription == null) {
        this.sessionSubscription = this.sessionService.sessionToken.subscribe((_) => {
          this.loadAttendedPresentations();
        });
      }
    }
  }

  presentationHistoryEntries: AttendedPresentationInfo[];
  sessionSubscription: Subscription;

  constructor(private router: Router, private presDataService: PresentationDataService, private userDataService: UserDataService, private sessionService: SessionService, private trackingService: SLAnalyticsService) {}

  public loadAttendedPresentations() {
    if (this.loadingState !== ProgressState.Loading) {
      this.loadingState = ProgressState.Loading;

      SLLogger.log('Loading attended presentations...');
      this.userDataService.getAttendedPresentations().subscribe(
        (presentations: AttendedPresentationInfo[]) => {
          if (presentations.length > 0) {
            this.loadingState = ProgressState.Content;
            presentations.sort((a, b) => b.attendedAt - a.attendedAt);
          } else {
            this.loadingState = ProgressState.Empty;
          }

          this.presentationHistoryEntries = presentations;
        },
        (error) => {
          SLLogger.warn("Couldn't get attended presentations %o", error);
          this.loadingState = ProgressState.Error;
        }
      );
    }
  }

  onPresentationHistoryEntryClicked(presentationInstanceId: string) {
    this.trackingService.trackEvent(TrackingKey.Event.HISTORY_PRESENTATION_ITEM_CLICKED);
    this.router.navigate(['presentation', presentationInstanceId]);
  }

  ngOnDestroy(): void {
    if (this.sessionSubscription) {
      this.sessionSubscription.unsubscribe();
    }
  }
}
