import { SLLogger } from '@sl/common/utils/SLLogger';
import { Injectable } from '@angular/core';

declare class Storage {
  setItem(key: String, data: any): void;

  getItem(key: String): any;

  removeItem(key: String): void;
}

@Injectable()
export abstract class BaseStorageService<T> {
  protected static readonly KEY_PREFIX = 'sl_';

  protected isStorageAvailable: boolean;

  constructor() {
    this.isStorageAvailable = this.checkStorageAvailable();
  }

  protected abstract getStorage(): Storage;

  protected checkStorageAvailable(): boolean {
    try {
      if (!this.getStorage()) {
        SLLogger.warn('The local storage does not exist on this specific browser! ' + navigator.userAgent);
        return false;
      } else {
        // On iOS localStorage may be disabled in private browsing mode, see https://www.mattburkedev.com/dom-exception-22-quota-exceeded-on-safari-private-browsing-with-localstorage/
        try {
          this.getStorage().setItem('test', 'test');
          this.getStorage().removeItem('test');
          return true;
        } catch (e) {
          SLLogger.warn('The local storage does not exist on this specific browser! ' + navigator.userAgent);
          return false;
        }
      }
    } catch (e) {
      SLLogger.warn("Couldn't check if storage is available! " + navigator.userAgent);
      return false;
    }
  }

  protected setValue(key: string, value: T): void {
    if (this.isStorageAvailable) {
      if (value === null || value === undefined) {
        this.getStorage().removeItem(this.getKey(key));
      } else {
        let data: string;
        if (typeof value === 'string') {
          data = value;
        } else {
          data = JSON.stringify(value);
        }
        this.getStorage().setItem(this.getKey(key), data);
      }
    } else {
      try {
        if (!(<any>window).slStorage) {
          (<any>window).slStorage = {};
        }
        (<any>window).slStorage[this.getKey(key)] = value;
      } catch (e) {}
    }
  }

  protected getValue(key: string): T {
    if (this.isStorageAvailable) {
      const item = this.getStorage().getItem(this.getKey(key));
      if (!item) {
        return undefined;
      }
      try {
        return <T>JSON.parse(item);
      } catch (e) {
        // Currently nasty hack to make it work for strings!
        return <T>(<any>item);
      }
    } else {
      try {
        return (<any>window).slStorage[this.getKey(key)];
      } catch (e) {
        return null;
      }
    }
  }

  private getKey(key: string) {
    return BaseStorageService.KEY_PREFIX + key;
  }
}
