import { BaseModel } from '@sl/common/model/BaseModel';
import { ContactInfoOverview } from '@sl/common/model/ContactInfoOverview';

export class Question extends BaseModel {
  text: string;
  isAnonymous: boolean;
  answeredAt?: number;
  createdAt?: number;
  sender?: ContactInfoOverview;
  upVotes: number;

  alreadyUpVoted?: boolean; // not returned by API
}
