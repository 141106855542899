<form #form="ngForm" fxLayout="column" class="questions-container scroll-container">
  <ng-container *ngIf="!!elements?.length">
    <div *ngFor="let element of getSortedElements(); index as i" fxLayout="column" class="formElement">
      <h6 *ngIf="element.title && element.type !== ElementType.Checkbox" class="foreground-text1-color-dialogBackground"><span [innerHTML]="element.title | multiLang | capitalize"></span>&nbsp;<span *ngIf="element.required" class="foreground-color-error requiredIndicator">*</span></h6>

      <single-choice
        *ngIf="element.type === ElementType.SingleChoice"
        [answerOptions]="element.answerOptions"
        [ngModel]="getValue(element.id)"
        [name]="element.id"
        [required]="element.required"
        [indicatorTextLeft]="'presentation.feedback.number-rating.indicator-left' | translate"
        [indicatorTextRight]="'presentation.feedback.number-rating.indicator-right' | translate"
        selectedClass="background-color-primary foreground-text1-color-primary border-color-primary"
        notSelectedClass="foreground-text2-color-dialogBackground"
        [disabled]="disabled">
      </single-choice>

      <star-rating
        *ngIf="element.type === ElementType.StarRating5 || element.type === ElementType.StarRating6"
        [answerOptions]="element.answerOptions"
        [maxRating]="element.type === ElementType.StarRating6 ? 6 : 5"
        [ngModel]="getValue(element.id)"
        [name]="element.id"
        [size]="element.type === ElementType.StarRating6 ? '48px' : '56px'"
        [required]="element.required"
        selectedClass="foreground-color-primary"
        notSelectedClass="foreground-text2-color-dialogBackground"
        [disabled]="disabled"
      ></star-rating>

      <number-rating
        *ngIf="element.type === ElementType.NumberRating5 || element.type === ElementType.NumberRating6"
        [answerOptions]="element.answerOptions"
        [maxRating]="element.type === ElementType.NumberRating6 ? 6 : 5"
        [ngModel]="getValue(element.id)"
        [name]="element.id"
        [required]="element.required"
        [indicatorTextLeft]="'presentation.feedback.number-rating.indicator-left' | translate"
        [indicatorTextRight]="'presentation.feedback.number-rating.indicator-right' | translate"
        selectedClass="background-color-primary foreground-text1-color-primary border-color-primary"
        notSelectedClass="foreground-text2-color-dialogBackground"
        [disabled]="disabled"
      ></number-rating>

      <sl-boolean-input *ngIf="element.type === ElementType.YesNo" class="foreground-text1-color-dialogBackground" [ngModel]="getValue(element.id)" [name]="element.id" [required]="element.required" [disabled]="disabled"> </sl-boolean-input>

      <mat-checkbox *ngIf="element.type === ElementType.Checkbox" [ngModel]="getValue(element.id)" [name]="element.id" [required]="element.required" [disabled]="disabled" class="foreground-text1-color-dialogBackground"
        ><h6 class="foreground-text1-color-dialogBackground"><span [innerHTML]="element.title | multiLang | capitalize"></span>&nbsp;<span *ngIf="element.required" class="foreground-color-error requiredIndicator">*</span></h6></mat-checkbox
      >

      <mat-form-field *ngIf="element.type === ElementType.Text" appearance="outline" floatLabel="never" class="foreground-text1-color-dialogBackground" style="width: 100%;">
        <textarea #optionText matInput [ngModel]="getValue(element.id)" [name]="element.id" class="textOptionInput" rows="1" [autofocus]="i === 0" [disabled]="disabled" [required]="element.required" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="4" maxlength="250"></textarea>
        <mat-hint *ngIf="!disabled" class="foreground-text2-color-dialogBackground" align="end"> {{ optionText.value.length }} / 250 </mat-hint>
      </mat-form-field>
    </div>

    <accept-terms #acceptTerms [style.opacity]="hasEnteredText() ? 1 : 0" textClass="foreground-text1-color-dialogBackground" [disabled]="disabled"></accept-terms
  ></ng-container>
</form>
