import { SessionRole } from '@sl/common/services/endpoint/dto/SessionRole';

export enum AuthProviderKey {
  Google = 'google',
  Facebook = 'facebook',
  SlideLizard = 'slidelizard',
}

export class LoginPayload {
  constructor(public token: string, public provider: AuthProviderKey, public role: SessionRole = SessionRole.Attendee) {}
}
