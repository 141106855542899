import { animate, animation, keyframes, style, transition, trigger } from '@angular/animations';

export const fadeOnAddOrRemoveAnimation = trigger('fadeOnAddOrRemoveAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('600ms cubic-bezier(0.4, 0, 0.2, 1)'),
  ]),
  transition(
    ':leave',
    animate(
      '600ms cubic-bezier(0.4, 0, 0.2, 1)',
      style({
        opacity: 0,
      })
    )
  ),
]);

export const fadeAndSlideOnAddOrRemoveAnimation = trigger('fadeAndSlideOnAddOrRemoveAnimation', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateY(5%)',
    }),
    animate('600ms cubic-bezier(0.4, 0, 0.2, 1)'),
  ]),
  transition(
    ':leave',
    animate(
      '600ms cubic-bezier(0.4, 0, 0.2, 1)',
      style({
        opacity: 0,
      })
    )
  ),
]);

export const shake = animation(
  animate(
    '{{ timing }}s {{ delay }}s',
    keyframes([
      style({ transform: 'translate3d(0, 0, 0)', offset: 0 }),
      style({ transform: 'translate3d(-10px, 0, 0)', offset: 0.2 }),
      style({ transform: 'translate3d(10px, 0, 0)', offset: 0.4 }),
      style({ transform: 'translate3d(-10px, 0, 0)', offset: 0.6 }),
      style({ transform: 'translate3d(10px, 0, 0)', offset: 0.8 }),
      style({ transform: 'translate3d(0, 0, 0)', offset: 1 }),
    ])
  ),
  { params: { timing: 0.5, delay: 0 } }
);

export const fadeAndShrinkOnAddOrRemoveAnimation = trigger('fadeAndShrinkOnAddOrRemoveAnimation', [
  transition(':enter', [style({ 'max-height': 0, opacity: 0 }), animate('.3s ease-out', style({ 'max-height': 100, opacity: 1 }))]),
  transition(':leave', [style({ 'max-height': 100, opacity: 1 }), animate('.3s ease-in', style({ 'max-height': 0, opacity: 0 }))]),
]);
