import { Injectable } from '@angular/core';
import { ClientVersionDataService } from '@sl/common/services/endpoint/ClientVersionDataService';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { SLConfig } from '@sl/config/SLConfig';
import { ClientVersionInfo } from '@sl/common/services/endpoint/dto/ClientVersionInfo';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  private updateAvailableSubject: Subject<ClientVersionInfo>;

  get updateAvailable() {
    return this.updateAvailableSubject.asObservable();
  }

  constructor(private clientVersionDataService: ClientVersionDataService) {
    this.updateAvailableSubject = new Subject();
  }

  public checkForUpdates() {
    this.clientVersionDataService.getAvailableClientVersions().subscribe(
      (versionInfos) => {
        const latestVersionCode = Math.max(...versionInfos.map((v) => v.versionCode));
        const currentVersionCode = SLConfig.getEnvironment().appVersionCode;

        if (currentVersionCode < latestVersionCode) {
          // Update available!
          SLLogger.log(`Update available (current version ${currentVersionCode}, latest version ${latestVersionCode})`);
          this.updateAvailableSubject.next(versionInfos.find((v) => v.versionCode === latestVersionCode));
        }
      },
      (e) => SLLogger.warn('Checking for available updates failed, %o', e)
    );
  }
}
