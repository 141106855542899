import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'formatTimestamp' })
export class FormatTimestampPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(timestamp: number, format: string): string {
    const date = new Date(timestamp);
    return this.datePipe.transform(date, format);
  }
}
