import { BaseDataService } from '@sl/common/services/endpoint/BaseDataService';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class ImageDataService extends BaseDataService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  public getImageUrl(imageBlobId: string): string {
    return this.buildUrl(this.defaultVersion, `blob/${imageBlobId}/content/image?`).toString() + this.defaultQueryParams.toString();
  }
}
