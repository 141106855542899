<h2 class="foreground-text1-color-dialogBackground"  mat-dialog-title>{{ 'profile.pick-avatar' | translate }}</h2>

<div fxLayout="row wrap" fxLayoutAlign="space-between">
  <div *ngFor="let item of avartarImages" fxFlex="130px" style="padding: 10px; width: 130px; height: 130px;" [style.backgroundColor]="selectedImageURL == item ? 'lightgray': ''">
    <img fxLayout="" width="110px" height="110px" [src]="item" (click)="setImageUrl(item)"/>
  </div>
</div>

<mat-dialog-actions style="margin-right: -16px; margin-top: 12px;" fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]=""
          class="foreground-text1-color-dialogBackground">{{'cancel' | translate}}</button>
  <button mat-button cdkFocusRegionstart class="foreground-color-secondary"
          [mat-dialog-close]="selectedImageURL">{{'save' | translate}}</button>
</mat-dialog-actions>
