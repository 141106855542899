<!--<ng-container *ngFor="let data of chartData">-->
<!--  <div class="textContainer">-->
<!--    <img *ngIf="data.imageUrl" [src]="data.imageUrl" />-->
<!--    <p class="foreground-text1-color-background">{{ data.name }}</p>-->
<!--  </div>-->
<!--  <div class="bar background-color-primary" [style.width]="data.value + '%'"></div>-->
<!--  <p class="indicator foreground-text1-color-background">{{ data.value }}%</p>-->
<!--</ng-container>-->

<div class="row" *ngFor="let data of chartData">
  <img *ngIf="data.imageUrl" [src]="data.imageUrl" />
  <p class="text foreground-text1-color-dialogBackground">{{ data.name }}</p>
  <div class="barContainer">
    <div class="bar background-color-primary" [style.width]="data.value + '%'"></div>
  </div>
  <div class="indicatorContainer">
    <p class="indicator foreground-text1-color-dialogBackground">{{ data.value }}%</p>

    <ng-container *ngIf="data.isCorrectAnswer != undefined">
      <div *ngIf="!data.isCorrectAnswer" class="quizAnswerResult background-color-error"
           inlineSVG="/assets/img/ic_close.svg"></div>

      <div *ngIf="data.isCorrectAnswer" class="quizAnswerResult"
            style="background-color: #00c853"
           inlineSVG="/assets/img/ic_check.svg"></div>
    </ng-container>
  </div>
</div>
