import { Component, Input } from '@angular/core';
import { AboutOverlayService } from '@sl/common/components/about-dialog/AboutOverlayService';
import { Theme } from '@sl/common/model/Theme';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '@sl/common/services/i18n/I18nService.service';

@Component({
  selector: 'footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
  @Input()
  removeSlideLizardBranding = false;

  constructor(private aboutOverlayService: AboutOverlayService, private i18nService: I18nService) {}

  onAboutClicked() {
    this.aboutOverlayService.open();
  }

  isUsingThemeTranslation() {
    return this.i18nService.isOverrideLanguage(this.i18nService.getCurrentLanguage());
  }
}
