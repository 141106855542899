<h2 *ngIf="data.title || data.titleRes" class="foreground-text1-color-dialogBackground"  mat-dialog-title>{{data.title ? data.title : (data.titleRes | translate)}}</h2>

<div *ngIf="data.imageURL" [style.background-image]="'url(' + data.imageURL + ')'" style="background-size: contain; background-repeat: no-repeat; min-width: 240px; min-height: 150px;"></div>

<p class="foreground-text1-color-dialogBackground" mat-dialog-content>
  {{data.message ? data.message : (data.messageRes | translate)}}
</p>
<mat-dialog-actions style="margin-right: -16px; margin-top: 12px;" fxLayoutAlign="end">
  <button *ngIf="data.cancelRes" mat-button [mat-dialog-close]="false"
          class="foreground-text1-color-dialogBackground">{{data.cancelRes | translate}}</button>
  <button mat-button cdkFocusRegionstart class="foreground-color-secondary"
          [mat-dialog-close]="true">{{data.confirmRes | translate}}</button>
</mat-dialog-actions>
