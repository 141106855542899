import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AcceptedTermsStorageService } from '@sl/common/services/localstorage/AcceptedTermsStorageService';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { shake } from '@sl/common/animations/CommonAnimations';
import { transition, trigger, useAnimation } from '@angular/animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'accept-terms',
  templateUrl: 'accept-terms.component.html',
  animations: [trigger('shake', [transition('* => *', useAnimation(shake))])],
})
export class AcceptTermsComponent implements OnInit, OnDestroy {
  @Output()
  onHasAcceptedTermsChanged = new EventEmitter<Boolean>();

  @Input()
  disabled = false;

  @Input()
  textClass: string;

  errorShown = false;
  shake = false;

  _hasAcceptedTerms = false;
  showTermsCheckbox = true;

  private unsubscribe$ = new Subject<void>();

  constructor(private settingsService: AcceptedTermsStorageService, private authService: AuthService) {}

  ngOnInit() {
    this.authService.user.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.init());
  }

  private init() {
    this._hasAcceptedTerms = (this.authService.currentUser !== null && !this.authService.currentUser.isAnonymous) || this.settingsService.getHasAcceptedTerms();
    this.showTermsCheckbox = !this._hasAcceptedTerms;
    this.onHasAcceptedTermsChanged.emit(this._hasAcceptedTerms);
  }

  public showRequired() {
    this.errorShown = true;
    this.shake = !this.shake;
  }

  public set hasAcceptedTerms(value: boolean) {
    this._hasAcceptedTerms = value;
    this.errorShown = false;
    this.settingsService.setHasAcceptedTerms(value);
    this.onHasAcceptedTermsChanged.emit(this._hasAcceptedTerms);
  }

  public get hasAcceptedTerms() {
    return this._hasAcceptedTerms;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
