import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  template: '',
})
export class BaseVideoPlayerComponent {
  _source: string;

  @Input('src')
  set source(src: string) {
    if (src !== this.source) {
      this._source = src;
      this.onSourceSet();
    }
  }

  get source() {
    return this._source;
  }

  @Input()
  controlsShown = false;

  @Output()
  onError = new EventEmitter<void>();

  @Output()
  onReady = new EventEmitter<void>();

  public play() {}

  public pause() {}

  public isPlaying(): boolean {
    throw new Error('Not implemented');
  }

  protected onSourceSet() {}
}
