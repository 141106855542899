import { Injectable } from '@angular/core';
import { IAnalyticsTracker } from './IAnalyticsTracker';
import { SLConfig } from '@sl/config/SLConfig';
import { BaseAnalyticsService } from '@sl/common/services/analytics/BaseAnalyticsService';

/**
 * Use for INTERNAL analytics reporting
 */
@Injectable({
  providedIn: 'root',
})
export class SLAnalyticsService extends BaseAnalyticsService {
  protected getTrackers(): IAnalyticsTracker[] {
    const trackingConfig = SLConfig.getEnvironment().tracking;
    if (trackingConfig.allowedAnalyticsDomains && !trackingConfig.allowedAnalyticsDomains.includes(location.hostname)) {
      return [];
    }

    // disable default analytics for now -> track only after joining a presentation if tenant allows it
    return [];
  }
}
