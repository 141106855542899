import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConnectComponent } from '@sl/connect/components/connect/ConnectComponent';
import { AttendanceHistoryComponent } from '@sl/connect/components/attendance_history/AttendanceHistoryComponent';
import { PresentationEntryComponent } from '@sl/connect/components/attendance_history/presentation_entry/PresentationEntryComponent';
import { TranslateModule } from '@ngx-translate/core';
import { AccessProtectionDialogComponent } from '@sl/connect/components/access_protection/AccessProtectionDialogComponent';
import { AccessProtectionOverlayService } from '@sl/connect/components/access_protection/AccessProtectionOverlayService';
import { InlineSVGModule } from 'ng-inline-svg';
import { CommonModule } from '@sl/common/common.module';

@NgModule({
  declarations: [ConnectComponent, AttendanceHistoryComponent, PresentationEntryComponent, AccessProtectionDialogComponent],
  imports: [TranslateModule, AngularCommonModule, FormsModule, BrowserAnimationsModule, CommonModule, InlineSVGModule],
  providers: [AccessProtectionOverlayService],
  exports: [ConnectComponent],
})
export class ConnectModule {}
