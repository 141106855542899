import { Injectable } from '@angular/core';
import { PresentationDataService } from '@sl/common/services/endpoint/PresentationDataService';
import { AttendanceInfo, GDPRState } from '@sl/common/services/endpoint/dto/AttendanceInfo';
import { AttendancePayload } from '@sl/common/services/endpoint/dto/AttendancePayload';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { UserDataService } from '@sl/common/services/endpoint/UserDataService';
import { BehaviorSubject } from 'rxjs';
import { NGUtils } from '@sl/common/utils/NGUtils';
import { Workflow, WorkflowState } from '../model/Workflow';
import { WorkflowDataService } from './workflow-data.service';
import { WorkflowStateHub } from './WorkflowStateHub';
import { VotingAnswersLocalStorageService } from '@sl/pres/services/storage/VotingAnswersLocalStorageService';

@Injectable({ providedIn: 'root' })
export class WorkflowAttendanceService implements WorkflowAttendanceService {
  workflowId: string;
  attendanceInfo: BehaviorSubject<AttendanceInfo>;
  newAttendee: boolean = true;;

  constructor(private workflowDataService: WorkflowDataService, private userDataService: UserDataService, private workflowStateHub: WorkflowStateHub, private votingsStorageService: VotingAnswersLocalStorageService) {}

  init(workflow: Workflow): void {
    this.workflowId = workflow.id;
    this.attendanceInfo = new BehaviorSubject<AttendanceInfo>(null);

    workflow.steps.forEach(step => {
      this.newAttendee = this.votingsStorageService.getPreviousVotingAnswer(step.targetId) == null ? true : false;
    });

    if (this.newAttendee) {
      this.attendWorkflow();
    } else {
      this.loadAttendanceInfo();
    }

    this.workflowStateHub.blockingStateChanged.subscribe((event) => {
      if (this.attendanceInfo.getValue()) {
        this.attendanceInfo.getValue().blockingStates = event.blockingStates;
        this.attendanceInfo.next(this.attendanceInfo.getValue());
      }
    });
  }

  public attendWorkflow() {
    this.workflowDataService.postAttendance(this.workflowId, this.getAttendancePayload()).subscribe(
      (attendanceInfo) => {
        if (attendanceInfo) {
          this.attendanceInfo.next(attendanceInfo);
        } else {
          this.loadAttendanceInfo();
        }
      },
      (error) => {
        SLLogger.warn('Attending presentation failed: %o', error);
        this.loadAttendanceInfo();
      }
    );
  }

  private getAttendancePayload() {
    const att = new AttendancePayload();

    att.deviceType = DeviceDetect.getDeviceType();
    att.name = navigator.userAgent;
    att.platform = DeviceDetect.getDevicePlatform();
    att.connectionString = '';

    return att;
  }

  private loadAttendanceInfo() {
    this.userDataService.getWorkflowAttendanceInfo(this.workflowId).subscribe(
      (attendanceInfo) => this.attendanceInfo.next(attendanceInfo),
      () => {
        this.attendanceInfo.next(null);
      }
    );
  }

  public getAttendanceInfo() {
    return this.attendanceInfo;
  }

  public updateGDPRState(updatedGDPRState: GDPRState) {
    const updatedAttendanceInfo = new AttendanceInfo(updatedGDPRState);
    this.userDataService.updateWorkflowAttendanceInfo(this.workflowId, updatedAttendanceInfo).subscribe(
      () => {
        if (this.attendanceInfo.value) {
          this.attendanceInfo.value.gdprState = updatedGDPRState;
          this.attendanceInfo.next(this.attendanceInfo.value);
        } else {
          this.attendanceInfo.next(updatedAttendanceInfo);
        }
      },
      () => SLLogger.warn('Updating of attendance info failed!')
    );
  }

  public updateQuizPoints(quizPoints: number) {
    this.attendanceInfo.value.currentTotalQuizPoints = quizPoints;
    this.userDataService.updateWorkflowAttendanceInfo(this.workflowId, this.attendanceInfo.value).subscribe(
      () => {
        if (this.attendanceInfo.value) {
          this.attendanceInfo.value.currentTotalQuizPoints = quizPoints;
          this.attendanceInfo.next(this.attendanceInfo.value);
        } else {
          this.attendanceInfo.next(this.attendanceInfo.value);
        }
      },
      () => SLLogger.warn('Updating of attendance info failed!')
    );
  }

  disconnect(): void {
    this.workflowId = null;
    NGUtils.unsubscribeAllSubscribers(this.attendanceInfo);
  }
}
