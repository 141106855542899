import { Injectable } from '@angular/core';
import { AuthLocalStorageService } from '@sl/common/services/localstorage';
import { BehaviorSubject } from 'rxjs';
import { SessionRole } from '@sl/common/services/endpoint/dto/SessionRole';

@Injectable()
export class SessionService {
  public sessionToken: BehaviorSubject<string>;

  constructor(private authLocalStorageService: AuthLocalStorageService) {
    this.sessionToken = new BehaviorSubject(this.getSavedSessionToken());
  }

  getSavedSessionToken() {
    return this.authLocalStorageService.getSessionToken();
  }

  setSessionToken(token: string) {
    this.authLocalStorageService.setSessionToken(token);
    this.sessionToken.next(token);
  }

  getSavedSessionRole() {
    return this.authLocalStorageService.getSessionRole();
  }

  setSessionRole(role: SessionRole) {
    this.authLocalStorageService.setSessionRole(role);
  }

  deleteSession() {
    this.setSessionToken(null);
    this.setSessionRole(null);
  }
}
