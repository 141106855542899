<div overlayContainer fxLayout="column" fxFlexAlign="center center">
  <mat-toolbar color="primary" class="toolbar sl-toolbar">
    <mat-toolbar-row>
      <div class="clickable-round toolbar-button"
           (click)="close()" inlineSVG="assets/img/ic_close.svg"></div>
      <span>{{'about.title' | translate}}</span>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="about-container scroll-container" fxLayout="column" fxLayoutAlign="center center">
    <img class="logo" src="/assets/img/app_icon.svg"/>

    <h3 style="color: #673ab7; font-weight: 300;"><b>Slide</b>Lizard</h3>
    <p class="mat-body-1 foreground-text2-color-dialogBackground build-info">v<b>{{environment.appVersion}}</b> - {{environment.name}}</p>
    <p class="mat-body-1 foreground-text2-color-dialogBackground build-info">{{environment.buildTimestamp | date:'short' }}</p>

    <button mat-button class="send-button" (click)="sendLogs()">{{'about.send-logs' | translate}}</button>

    <a href="https://www.facebook.com/slidelizard" target="_blank" class="entry">{{'about.follow-facebook' |
      translate}}</a>
    <a href="https://www.slidelizard.at" target="_blank" class="entry">{{'about.visit-website' | translate}}</a>

    <p class="mat-body-1 foreground-text2-color-dialogBackground entry" style="text-align: center;"
       [innerHTML]="'about.address' | translate"></p>
    <a href="https://slidelizard.com/imprint" target="_blank">{{'about.imprint' | translate}}</a>
  </div>
</div>
