import { Injectable } from '@angular/core';
import { AttendanceInfo } from '@sl/common/services/endpoint/dto/AttendanceInfo';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Voting, VotingType } from '@sl/common/model/Voting';
import { VotingAnswersLocalStorageService } from '@sl/pres/services/storage/VotingAnswersLocalStorageService';
import { QuizResultAnswerState, QuizResultChangedEvent } from '@sl/pres/services/Events';
import { ChoiceAnswerPayload } from '@sl/common/services/endpoint/dto/ChoiceAnswerPayload';
import { QuizResultCalculatedLocalStorageService } from '@sl/pres/services/storage/QuizResultCalculatedLocalStorageService';
import { WorkflowDependentService } from './WorkflowDependentService';
import { WorkflowAttendanceService } from './WorkflowAttendanceService';
import { WorkflowStateHub } from './WorkflowStateHub';
import { Workflow } from '../model/Workflow';

export enum QuizResult {
  NOT_VOTED = 'not-voted',
  CORRECT = 'correct',
  WRONG = 'wrong',
}

export interface QuizCalculationResult {
  calculationFinished: boolean;
  gainedPoints: number;
  result: QuizResult;

  currentQuizPoints: number;
}

@Injectable()
export class WorkflowQuizPointsService implements WorkflowDependentService {
  workflowId: string;

  currentQuizPoints: number;
  maxReachableQuizPoints: number;

  private unsubscribe$ = new Subject<void>();

  constructor(private workflowAttendanceService: WorkflowAttendanceService, private workflowStateHub: WorkflowStateHub, private votingsStorageService: VotingAnswersLocalStorageService, private quizResultCalculatedStorageService: QuizResultCalculatedLocalStorageService) {}

  init(workflow: Workflow): void {
    this.setCurrentQuizPointsFromAttendanceInfo(this.workflowAttendanceService.attendanceInfo.getValue());

    this.workflowAttendanceService.attendanceInfo.pipe(takeUntil(this.unsubscribe$)).subscribe((attendanceInfo) => this.setCurrentQuizPointsFromAttendanceInfo(attendanceInfo));
    this.workflowStateHub.maxReachableQuizPointsChanged.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      this.maxReachableQuizPoints = event.maxReachableQuizPoints ?? 0;
    });
  }

  private setCurrentQuizPointsFromAttendanceInfo(attendanceInfo: AttendanceInfo) {
    this.currentQuizPoints = attendanceInfo?.currentTotalQuizPoints ?? 0;
  }

  disconnect(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  calculateQuizResult(voting: Voting, quizResultChangedEvent?: QuizResultChangedEvent): QuizCalculationResult | null {
    if (voting?.isQuiz) {
      const previousVotingAnswer = this.votingsStorageService.getPreviousVotingAnswer(voting.id);
      const savedQuizResult = this.quizResultCalculatedStorageService.getQuizResult(voting.id);

      const quizPoints = voting.quizPoints ?? 0;
      const hasAnswered = previousVotingAnswer != null;
      let newPoints: number;
      let calculationFinished: boolean;
      let answerCorrect = false;

      if (voting.type === VotingType.SingleChoice || voting.type === VotingType.MultipleChoice) {
        if (previousVotingAnswer) {
          const correctAnswerOptionIds = this.getCorrectQuizAnswers(voting);

          const chosenAnswerOptions = previousVotingAnswer.answers as ChoiceAnswerPayload;
          answerCorrect = correctAnswerOptionIds.length === chosenAnswerOptions.answerOptionIds.length && correctAnswerOptionIds.every((correctAnswerOptionId) => chosenAnswerOptions.answerOptionIds.some((answer) => answer === correctAnswerOptionId));
        }

        newPoints = answerCorrect ? quizPoints : 0;
        calculationFinished = true;
      } else if (voting.type === VotingType.OpenText) {
        if (!hasAnswered) {
          calculationFinished = true;
          newPoints = 0;
        } else if (quizResultChangedEvent) {
          calculationFinished = true;
          answerCorrect = quizResultChangedEvent.answerState === QuizResultAnswerState.Correct;
          newPoints = quizResultChangedEvent.gainedQuizPoints;
        } else {
          calculationFinished = false;
        }
      }

      const quizResult = hasAnswered ? (answerCorrect ? QuizResult.CORRECT : QuizResult.WRONG) : QuizResult.NOT_VOTED;
      if (calculationFinished) {
        if (quizResult !== savedQuizResult?.result) {
          this.currentQuizPoints += newPoints;
        }

        this.quizResultCalculatedStorageService.saveQuizResult(voting.id, hasAnswered ? (answerCorrect ? QuizResult.CORRECT : QuizResult.WRONG) : QuizResult.NOT_VOTED, this.currentQuizPoints);
        this.workflowAttendanceService.updateQuizPoints(this.currentQuizPoints);
      }

      return { calculationFinished, result: quizResult, gainedPoints: newPoints, currentQuizPoints: this.currentQuizPoints };
    }

    return null;
  }

  getCorrectQuizAnswers(voting: Voting) {
    return voting.answerOptions.filter((answer) => answer.isCorrectAnswer).map((answer) => answer.id);
  }
}
