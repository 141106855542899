import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { BaseOverlayService } from '@sl/common/services/BaseOverlayService';
import { FullscreenImageDialogComponent } from './fullscreen-image-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class FullscreenImageOverlayService extends BaseOverlayService<FullscreenImageDialogComponent> {
  constructor(overlay: Overlay) {
    super(overlay);
  }

  protected getComponent(): ComponentType<FullscreenImageDialogComponent> {
    return FullscreenImageDialogComponent;
  }

  open(imageUrl: string) {
    const instance = this.createOverlay();
    instance.imageUrl = imageUrl;
    instance.overlayRef = this.overlayRef;

    return this.resultSubject;
  }
}
