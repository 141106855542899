import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SLConfig } from '@sl/config/SLConfig';
import { BaseDataService } from '@sl/common/services/endpoint/BaseDataService';

@Injectable({
  providedIn: 'root',
})
export class PhotoUploadDataService extends BaseDataService {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected get baseUri(): string {
    return SLConfig.getEnvironment().webservice.photoUploadBaseUri;
  }

  protected get defaultVersion(): string {
    return '';
  }

  public uploadPhoto(photo: File) {
    // if (this.isMockingEnabled()) {
    //   return of({ url: 'https://test-url.com' }).pipe(delay(1000));
    // }

    const formData = new FormData();
    formData.append('file', photo);

    return this.post<{ url: string }>(``, formData, {
      'Content-Type': '',
    });
  }
}
