import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';
import { BetterErrorStateMatcher } from '@sl/common/utils/BetterErrorStateMatcher';
import { Address, ContactInfo, ContactInfoOverview, EmailAddress, PhoneNumber, User } from '@sl/common/model';
import { Subject, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { UserDataService } from '@sl/common/services/endpoint/UserDataService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@sl/common/services/auth/AuthService';
import { ThemeService } from '@sl/common/services/theme/ThemeService';
import { TranslateService } from '@ngx-translate/core';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { SLUtils } from '@sl/common/utils/SLUtils';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { IWorkflowStepComponent } from '@sl/common/components/IWorkflowStepComponent';
import { ThemeContactInfoField, ThemeContactInfoFieldConfiguration } from '@sl/common/model/Theme';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { PhotoUploadDataService } from '@sl/common/services/endpoint/PhotoUploadDataService';
import { takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AvatarGridDialogComponent } from '../avatar-grid-dialog/AvatarGridDialogComponent';

@Component({
  selector: 'sl-edit-contact-info',
  templateUrl: './edit-contact-info.component.html',
  styleUrls: ['./edit-contact-info.component.scss'],
})
export class EditContactInfoComponent implements OnInit, OnDestroy, IWorkflowStepComponent {
  private readonly MAX_FILE_SIZE = 4 * 1024 * 1024;

  ProgressState = ProgressState;
  ContactInfoField = ThemeContactInfoField;
  additionalInformation = [...Array(5).keys()];

  submitState = ProgressState.Content;
  loadingState: ProgressState;
  isDesktop = DeviceDetect.isDesktop();

  matcher = new BetterErrorStateMatcher();

  @Input()
  forceEditForm = false;

  currentUser: User;
  userSubscription: Subscription;
  newCI: ContactInfo = null;
  hasUserData = false;

  private unsavedData = false;

  @ViewChild('form')
  form: NgForm;

  @Output()
  onContactInfoSaved = new EventEmitter<void>();

  @Output()
  onLoggedIn = new EventEmitter<void>();

  contactInfoFields: ThemeContactInfoFieldConfiguration[];
  enableSelectableContactInfo?: boolean = null;
  selectableContactInfos: ContactInfoOverview[] = [];

  private unsubscribe$ = new Subject<void>();

  constructor(private userDataService: UserDataService, private photoUploadService: PhotoUploadDataService, private dialog: MatDialog, private snackBar: MatSnackBar, private authService: AuthService, public themeService: ThemeService, private translateService: TranslateService) {}

  ngOnInit() {
    this.contactInfoFields = this.themeService.currentTheme.contactInfoFields.map((cField) => {
      return { ...cField, required: cField.order === -1 }; // TODO: hack, remove!
    });
    // this.contactInfoFields =
    // [
    //   {name: ThemeContactInfoField.SelectPredefinedContactInfo, order: 0, required: true},
    //   {name: ThemeContactInfoField.Firstname, order: -1, required: true},
    //   {name: ThemeContactInfoField.Lastname, order: -1, required: true}
    // ]
    this.enableSelectableContactInfo = !!this.contactInfoFields.find(field => field.name === ThemeContactInfoField.SelectPredefinedContactInfo);

    this.loadingState = ProgressState.Loading;
    this.userSubscription = this.authService.user.subscribe((user) => {
      this.currentUser = user;
      this.initNewContactInfo(this.currentUser?.contactInfo);
      this.loadingState = ProgressState.Content;
    });

    if(this.enableSelectableContactInfo === true && !!(<any>window).ownerTenantId) {
      this.userDataService.getTenantUsers((<any>window).ownerTenantId).subscribe(
        (ci: ContactInfoOverview[]) => {
          if (ci.length > 0) {
            ci.sort((a, b) => a.lastName.localeCompare(b.lastName));
          }
          this.selectableContactInfos = ci;
        },
        (error) => {
          SLLogger.warn("Couldn't get tenant users %o", error);
        }
      );
    }
  }

  private fullNameChanged(userId: string) {
    var user = this.selectableContactInfos?.find(ci => ci.id === userId);
    if(!!user) {
      this.newCI.id = user?.id;
      this.newCI.firstName = user?.firstName;
      this.newCI.lastName = user?.lastName;
    }
    else {
      this.newCI.id = null;
      this.newCI.firstName = "";
      this.newCI.lastName = "";
    }

    this.form.form.markAllAsTouched();
  }

  private initNewContactInfo(contactInfo: ContactInfo = null) {
    this.newCI = new ContactInfo();
    if (contactInfo != null) {
      this.newCI.firstName = contactInfo.firstName;
      this.newCI.lastName = contactInfo.lastName;
      this.newCI.imageUrl = contactInfo.imageUrl;
      this.newCI.emailAddresses = contactInfo.emailAddresses;
      this.newCI.addresses = contactInfo.addresses;
      this.newCI.phoneNumbers = contactInfo.phoneNumbers;

      this.newCI.additionalInformation1 = contactInfo.additionalInformation1;
      this.newCI.additionalInformation2 = contactInfo.additionalInformation2;
      this.newCI.additionalInformation3 = contactInfo.additionalInformation3;
      this.newCI.additionalInformation4 = contactInfo.additionalInformation4;
      this.newCI.additionalInformation5 = contactInfo.additionalInformation5;
    } else {
      this.newCI.firstName = null;
      this.newCI.lastName = null;
    }

    if (this.newCI.emailAddresses == null) {
      this.newCI.emailAddresses = Array<EmailAddress>();
    }

    if (this.newCI.emailAddresses.length === 0) {
      this.newCI.emailAddresses.push(new EmailAddress('private', null));
    }

    if (this.newCI.addresses == null) {
      this.newCI.addresses = Array<Address>();
    }

    if (this.newCI.addresses.length === 0) {
      this.newCI.addresses.push(new Address({ type: 'home' }));
    }

    if (this.newCI.phoneNumbers == null) {
      this.newCI.phoneNumbers = Array<PhoneNumber>();
    }

    if (this.newCI.phoneNumbers.length === 0) {
      this.newCI.phoneNumbers.push(new PhoneNumber('private', null));
    }

    this.hasUserData = SLUtils.checkHasUserData(this.newCI);
  }

  onLoginSuccessful(successful: boolean) {
    if (successful) {
      this.onLoggedIn.emit();
    }
  }

  onInputFocused(event: any) {
    if (DeviceDetect.isPhone()) {
      // Weird way to ensure input is visible on mobile devices
      event.srcElement.scrollIntoView();
      setTimeout(() => event.srcElement.scrollIntoView(), 300);
    }
  }

  openAvatarGrid() {
    this.dialog.open(AvatarGridDialogComponent, {height: '650px', width: '500px'}).afterClosed().subscribe((selectedImageURL) =>
    {
      if(this.newCI.imageUrl !== selectedImageURL)
        this.unsavedData = true;
      this.newCI.imageUrl = selectedImageURL;
    });
  }

  public hasUnsavedData(): boolean {
    if(this.unsavedData)
      return true;
    if (this.form?.dirty) {
      return !SLUtils.compare(this.newCI, this.currentUser.contactInfo);
    }
    return false;
  }

  public save() {
    if (this.form.valid) {
      this.submitState = ProgressState.Loading;

      const updateCI = SLUtils.clone(this.newCI);
      if (TextUtils.isNullOrEmpty(updateCI.emailAddresses[0].emailAddress)) {
        // Remove email entry if user didn't enter anything
        updateCI.emailAddresses = null;
      }

      if (TextUtils.isNullOrEmpty(updateCI.phoneNumbers[0].phoneNumber)) {
        // Remove phone number entry if user didn't enter anything
        updateCI.phoneNumbers = null;
      }

      if (TextUtils.isNullOrEmpty(updateCI.addresses[0].zip) && TextUtils.isNullOrEmpty(updateCI.addresses[0].locationName) && TextUtils.isNullOrEmpty(updateCI.addresses[0].street)) {
        // Remove address entry if user didn't enter anything
        updateCI.addresses = null;
      }

      this.userDataService.updateUserMeContactInfo(updateCI).subscribe(
        (_) => {
          this.submitState = ProgressState.Content;
          this.authService.loadUserMe();
          this.form.reset();
          this.unsavedData = false;
          this.translateService.get('profile.update-successful').subscribe((text) => {
            this.snackBar.open(text, null, { duration: 2000 });
          });
          this.onContactInfoSaved.emit();
        },
        (_) => (this.submitState = ProgressState.Error)
      );
    }
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }

  hasAdditionalInformation(i: number) {
    return this.hasTranslation(`profile.additionalInformation.${i}`);
  }

  hasAdditionalInformationDescription(i: number) {
    return this.hasTranslation(`profile.additionalInformation.${i}-description`);
  }

  private hasTranslation(translationKey: string) {
    const str = this.translateService.instant(translationKey);
    return str !== translationKey && !TextUtils.isNullOrEmptyTrimmed(str);
  }

  canSkip(): boolean {
    return this.hasUserData;
  }
}
