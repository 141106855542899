import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[trimmedMinLength]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TrimmedMinLengthDirective, multi: true }],
})
export class TrimmedMinLengthDirective implements Validator {
  @Input('trimmedMinLength') minLength = 0;

  validate(control: AbstractControl): ValidationErrors {
    const isValid = (control.value || '').trim().length >= this.minLength;
    return isValid ? null : { trimmedMinLength: true };
  }
}
