import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScriptService {
  private scripts: any = {};

  loadScripts(...srcs: string[]) {
    const promises: any[] = [];
    srcs.forEach((src) => promises.push(this.loadScript(src)));
    return Promise.all(promises);
  }

  loadScript(src: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[src]) {
        this.scripts[src] = {};
      }

      // resolve if already loaded
      if (this.scripts[src].loaded) {
        resolve();
      } else {
        // load script
        const script = document.createElement('script') as HTMLScriptElement;
        script.type = 'text/javascript';
        script.src = src;
        if ((script as any).readyState) {
          // IE
          const ieScript = script as any;
          ieScript.onreadystatechange = () => {
            if (ieScript.readyState === 'loaded' || ieScript.readyState === 'complete') {
              ieScript.onreadystatechange = null;
              this.scripts[src].loaded = true;
              resolve();
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[src].loaded = true;
            resolve();
          };
        }
        script.onerror = (error: any) => reject(error);
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}
