import { Injectable } from '@angular/core';
import { PreferredLanguageStorageService } from '@sl/common/services/i18n/PreferredLanguageStorageService';
import { TranslateService } from '@ngx-translate/core';
import { SLConfig } from '@sl/config/SLConfig';
import { Subject } from 'rxjs';
import { langDE } from '../../../../assets/lang/de';
import { langEN } from '../../../../assets/lang/en';
import { Utils } from '@sl/common/utils/Utils';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';

export interface OverrideLanguage {
  fallbackLang: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  readonly I18N_CONFIG = SLConfig.getEnvironment().i18n;

  private availableLanguages: string[];

  private currentLang: string;
  private defaultLang: string;
  private overrideLangs: OverrideLanguage[] = [];

  private onLangChangedSubject = new Subject();
  onLangChanged$ = this.onLangChangedSubject.asObservable();

  constructor(private preferredLangService: PreferredLanguageStorageService, private translateService: TranslateService) {
    registerLocaleData(localeDE);

    this.translateService.setTranslation('de', langDE);
    this.translateService.setTranslation('en', langEN);

    this.resetAvailableLanguages();
  }

  init() {
    this.resetLanguageOverrides();
    this.resetAvailableLanguages();

    this.setDefaultLanguage(this.I18N_CONFIG.defaultLanguage);
    const initialLanguage = this.getInitialLanguage();
    this.setLanguage(initialLanguage);
  }

  geAvailableLanguages() {
    return this.availableLanguages;
  }

  getCurrentLanguage() {
    return this.currentLang;
  }

  getDefaultLanguage() {
    return this.defaultLang;
  }

  getLanguagePriorities() {
    return [this.getCurrentLanguage(), ...this.getNavigatorLanguages()];
  }

  addLanguage(newLang: string) {
    if (!this.availableLanguages.includes(newLang)) {
      this.availableLanguages.push(newLang);

      if (this.getBetterFittingLanguage(this.translateService.currentLang, newLang) === newLang) {
        this.setLanguage(newLang);
      }
    }
  }

  private getInitialLanguage() {
    const preferredLanguage = this.preferredLangService.getPreferredLanguage();
    const supportedLanguages = this.I18N_CONFIG.supportedLanguages;

    if (preferredLanguage && supportedLanguages.includes(preferredLanguage)) {
      return preferredLanguage;
    }

    let bestLang = this.I18N_CONFIG.defaultLanguage;
    for (let supportedLang of supportedLanguages) {
      bestLang = this.getBetterFittingLanguage(bestLang, supportedLang);
    }
    return bestLang;
  }

  setLanguage(newLang: string) {
    if (!this.translateService.langs.includes(newLang)) {
      this.translateService.addLangs([newLang]);
    }

    this.translateService.use(this.defaultLang); // Required to ensure all texts are switched to the default language
    this.translateService.use(newLang);
    this.currentLang = newLang;

    this.onLangChangedSubject.next();
  }

  setDefaultLanguage(defaultLang: string) {
    this.translateService.setDefaultLang(defaultLang);
    this.defaultLang = defaultLang;
  }

  addLanguageOverride(fallbackLang: string, url: string) {
    if (!this.overrideLangs.some((l) => l.url === url)) {
      this.overrideLangs.push({ fallbackLang, url });
      this.setDefaultLanguage(fallbackLang);
      this.setLanguage(url);
    }
  }

  isOverrideLanguage(lang: string) {
    return this.overrideLangs.some((l) => l.url === lang);
  }

  setPreferredLanguage(newLang?: string | null) {
    this.preferredLangService.setPreferredLanguage(newLang);
  }

  resetAvailableLanguages() {
    this.availableLanguages = [...this.I18N_CONFIG.supportedLanguages];

    // Reset preferred language if it's not available anymore
    const preferredLanguage = this.preferredLangService.getPreferredLanguage();
    if (preferredLanguage && !this.availableLanguages.includes(preferredLanguage)) {
      this.setPreferredLanguage(null);
    }
  }

  resetLanguageOverrides() {
    for (const lang of this.overrideLangs) {
      this.translateService.resetLang(lang.url);
    }

    this.overrideLangs = [];
  }

  private getBetterFittingLanguage(a: string, b: string) {
    const navigatorLanguages = this.getNavigatorLanguages();
    const currentLangPriority = navigatorLanguages.includes(a) ? navigatorLanguages.indexOf(a) : Number.MAX_VALUE;
    const newLangPriority = navigatorLanguages.includes(b) ? navigatorLanguages.indexOf(b) : Number.MAX_VALUE;

    if (newLangPriority < currentLangPriority) {
      return b;
    }
    return a;
  }

  private getNavigatorLanguages() {
    let languages: readonly string[];
    if (navigator.languages) {
      languages = navigator.languages;
    } else if (navigator.language) {
      languages = [navigator.language];
    } else if ((navigator as any).userLanguage) {
      languages = [(navigator as any).userLanguage]; // IE
    }

    return Utils.removeDuplicates(languages.map((l) => l.split('-')[0]));
  }
}
