<video #video [src]="source" autoplay muted></video>

<div [style.opacity]="controlsShown ? 1 : 0" class="controlsOverlayContainer">
  <ng-container *ngIf="getError(); else noError">
    <div class="controlButton" [inlineSVG]="'/assets/img/ic_replay.svg'" (click)="loadVideo($event)"></div>
    <p *ngIf="getError()" class="errorText">{{ 'video-player.error' | translate }}</p>
  </ng-container>

  <ng-template #noError>
    <mat-progress-spinner *ngIf="!isReadyToPlay(); else playPauseButton" diameter="32" strokeWidth="4" mode="indeterminate" [matTooltip]="'video-player.buffer-info' | translate: { bufferPercentage: bufferPercentage }"></mat-progress-spinner>

    <ng-template #playPauseButton>
      <div class="controlButton" [inlineSVG]="'/assets/img/' + (isPlaying() ? 'ic_pause.svg' : isFinished() ? 'ic_replay.svg' : 'ic_play.svg')" (click)="onPlayPauseButtonClicked($event)"></div>
    </ng-template>
  </ng-template>
</div>
