import { ElementRef, HostListener, OnDestroy, Directive } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { KeyboardUtil } from '@sl/common/utils/KeyboardUtil';
import { OverlayCloseHelper } from '@sl/common/utils/OverlayCloseHelper';
import { ConfirmDialogComponent, ConfirmDialogData } from '@sl/common/components/confirm-dialog/ConfirmDialogComponent';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Directive()
export class BaseOverlayComponent implements OnDestroy {
  // Also change in _sl-constants.scss!
  private static readonly bottomBarContainerHeight = 48;

  public result: any;
  protected unsubscribe$ = new Subject<void>();

  private _overlayRef: OverlayRef;
  private readonly beforeUnloadRef: any;
  private overlayCloseHelper: OverlayCloseHelper;

  get overlayRef() {
    return this._overlayRef;
  }

  set overlayRef(value: OverlayRef) {
    this._overlayRef = value;
    this._overlayRef.backdropClick().subscribe(() => this.close());
  }

  constructor(protected dialog: MatDialog) {
    this.overlayCloseHelper = new OverlayCloseHelper(this.constructor.name, () => this.close());

    this.beforeUnloadRef = (event: any) => this.beforeUnload(event); // Weird way to keep 'this' correct in function
    window.addEventListener('beforeunload', this.beforeUnloadRef);

    KeyboardUtil.hideKeyboard();
  }

  private beforeUnload(event: any) {
    if (this.hasUnsavedData()) {
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = 'xyz';
    }
  }

  protected isOpen() {
    return this.overlayRef && this.overlayRef.hasAttached();
  }

  public close(result: any = undefined, blockHistoryBack: boolean = false, checkUnsavedData: boolean = true) {
    if (checkUnsavedData && this.hasUnsavedData()) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: this.getDiscardUnsavedChangesMessage(),
        })
        .afterClosed()
        .subscribe((confirmResult) => {
          if (confirmResult) {
            this.save();
          } else if (confirmResult === false) {
            this.close(result, blockHistoryBack, false);
          }
        });
    } else {
      this.result = result;
      this.overlayCloseHelper.blockHistoryBack = blockHistoryBack;
      this.overlayRef.dispose();
    }
  }

  @HostListener('window:keydown', ['$event'])
  private onKeyPressed(event: KeyboardEvent) {
    if (this.dialog.openDialogs.length === 0 && KeyboardUtil.isEscapeKeyPress(event) && OverlayCloseHelper.isHighestOverlay(this.constructor.name)) {
      this.close();
    }
  }

  protected hasUnsavedData() {
    return false;
  }

  protected save() {}

  protected getDiscardUnsavedChangesMessage(): ConfirmDialogData {
    return {
      confirmRes: 'save',
      cancelRes: 'discard',
    };
  }

  /**
   * Fix for iOS 10 Safari, which can't make the content fill up the remaining space
   */
  protected setScrollContainerHeight(dialogContainer: ElementRef, toolbar: ElementRef, contentContainer: ElementRef) {
    if (dialogContainer && contentContainer) {
      const height = dialogContainer.nativeElement.offsetHeight - (toolbar ? toolbar.nativeElement.offsetHeight : 0) - BaseOverlayComponent.bottomBarContainerHeight;
      contentContainer.nativeElement.style.height = height + 'px';
    }
  }

  ngOnDestroy(): void {
    this.overlayCloseHelper.onDestroy();
    window.removeEventListener('beforeunload', this.beforeUnloadRef);

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
