import { Directive, HostBinding, Input, OnInit } from '@angular/core';
import { ImageDataService } from '@sl/common/services/endpoint/ImageDataService';
import { TextUtils } from '@sl/common/utils/TextUtils';

interface SLImage {
  imageBlobId: string;
  imageUrl: string;
}

@Directive({
  selector: '[sl-image]',
})
export class SlideLizardImageDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('sl-image') image: SLImage;
  // tslint:disable-next-line:no-input-rename
  @Input('fallback-image') fallbackImagePath: string;

  @HostBinding('src') source: string;

  constructor(private imageDataService: ImageDataService) {}

  ngOnInit() {
    this.source = this.getImageUrl();
  }

  getImageUrl(): string {
    if (!TextUtils.isNullOrEmpty(this.image.imageUrl)) {
      return this.image.imageUrl;
    } else if (!TextUtils.isNullOrEmpty(this.image.imageBlobId)) {
      return this.imageDataService.getImageUrl(this.image.imageBlobId);
    } else {
      return this.fallbackImagePath;
    }
  }
}
