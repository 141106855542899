import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FeedbackAnswerOption } from '@sl/common/model/FeedbackAnswerOption';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';

@Component({
  selector: 'star-rating',
  templateUrl: 'star-rating-input.component.html',
  styleUrls: ['star-rating-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingInputComponent),
      multi: true,
    },
  ],
})
export class StarRatingInputComponent implements ControlValueAccessor, OnInit {
  @Input()
  answerOptions: FeedbackAnswerOption[];
  @Input()
  maxRating: number;
  @Input()
  size = '48px';
  @Input()
  selectedClass = '';
  @Input()
  notSelectedClass = '';
  @Input()
  disabled = false;

  _rating: number;
  shownRating: number;
  numbers: number[];
  isDesktop = DeviceDetect.isDesktop();

  propagateChanged = (_: any) => {};

  constructor() {}

  ngOnInit() {
    this.shownRating = this.rating;
    this.numbers = new Array(this.maxRating);
  }

  get rating() {
    return this._rating;
  }

  set rating(val: number) {
    this._rating = val;
    this.shownRating = this._rating;
    this.propagateChanged(this._rating);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.rating = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChanged = fn;
  }

  registerOnTouched(fn: any): void {}

  onHover(position: number) {
    if (!this.disabled && this.isDesktop) {
      this.shownRating = position;
    }
  }

  @HostListener('mouseleave', ['$event'])
  onLeave() {
    if (!this.disabled && this.isDesktop) {
      this.shownRating = this.rating;
    }
  }

  onClick(position: number) {
    if (!this.disabled) {
      this.rating = position;
    }
  }
}
