import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LoginProvider } from '@sl/common/services/auth/LoginProvider';
import { RegisterPayload } from '@sl/common/services/endpoint/dto/RegisterPayload';
import { User } from '@sl/common/model';
import { UserDataService } from '@sl/common/services/endpoint/UserDataService';
import { SLAnalyticsService, TrackingKey } from '@sl/common/services/analytics';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { AuthDataService } from '@sl/common/services/endpoint/AuthDataService';
import { AuthResponse } from '@sl/common/services/endpoint/dto/AuthResponse';
import { SessionService } from '@sl/common/services/auth/SessionService';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { retry } from 'rxjs/operators';
import { RegisterAnonymousPayload } from '@sl/common/services/endpoint/dto/RegisterAnonymousPayload';
import { SessionRole } from '@sl/common/services/endpoint/dto/SessionRole';
import { LoginPayload } from '@sl/common/services/endpoint/dto/LoginPayload';
import { ErrorTrackerService } from '@sl/common/services/error-handling/ErrorTrackerService';
import { SLUtils } from '@sl/common/utils/SLUtils';
import { Utils } from '@sl/common/utils/Utils';
import { QUERY_PARAMS } from '../../../RouteConfig';

@Injectable()
export class AuthService {
  private user$: BehaviorSubject<User>;

  constructor(
    private oauthService: OAuthService,
    private sessionService: SessionService,
    private userDataService: UserDataService,
    private authDataService: AuthDataService,
    private translateService: TranslateService,
    private trackingService: SLAnalyticsService,
    private errorTrackerService: ErrorTrackerService
  ) {
    this.user$ = new BehaviorSubject(null);
  }

  public async ensureLoggedIn() {
    SLLogger.log('Ensure logged in');
    const sessionToken = this.sessionService.sessionToken.value;

    const limitedLifetimeToken = Utils.getQueryParam(QUERY_PARAMS.LIMITED_LIFETIME_TOKEN);
    if (limitedLifetimeToken) {
      this.setSessionToken(null); // Reset token so no other api calls wait until we have a new token
      const successful = await this.exchangeLimitedLifetimeToken(limitedLifetimeToken);
      if (successful) {
        return;
      } else {
        // Exchanging the token failed, so restore old token
        this.setSessionToken(sessionToken);
      }
    }

    if (!TextUtils.isNullOrEmpty(sessionToken)) {
      SLLogger.log('Session token available');
      this.loadUserMe();
    } else {
      SLLogger.log('No session token available');
      await this.registerAnonymous();
    }
  }

  get currentUser() {
    return this.user$.getValue();
  }

  get currentRole() {
    if (this.currentUser) {
      return this.sessionService.getSavedSessionRole();
    }
    return null;
  }

  get user() {
    return this.user$.asObservable();
  }

  async login(loginProvider: LoginProvider, sessionRole: SessionRole): Promise<boolean> {
    const resultSubject = new Subject<boolean>();
    const loginRequestPayload = new LoginPayload(loginProvider.getToken(), loginProvider.getKey(), sessionRole);

    this.authDataService.login(loginRequestPayload).subscribe(
      (authResponse: AuthResponse) => {
        this.updateSession(authResponse, sessionRole);
        this.loadUserMe(resultSubject);
      },
      async (error: any) => {
        const registerResult = await this.registerOrUpgrade(loginProvider, sessionRole);
        resultSubject.next(registerResult);
        resultSubject.complete();
      }
    );

    this.trackingService.trackEvent(TrackingKey.Event.LOGIN);
    return resultSubject.toPromise();
  }

  logout() {
    try {
      FB.logout((response) => SLLogger.log('Facebook User signed out.'));
    } catch (e) {
      SLLogger.warn(e);
    }
    try {
      gapi.auth2
        .getAuthInstance()
        .signOut()
        .then(function () {
          SLLogger.log('Google User signed out.');
        });
    } catch (e) {
      SLLogger.warn(e);
    }

    try {
      this.oauthService.logOut(true);
    } catch (e) {
      SLLogger.warn(e);
    }

    this.sessionService.deleteSession();
    this.user$.next(null);

    this.trackingService.trackEvent(TrackingKey.Event.LOGOUT);

    this.ensureLoggedIn();
  }

  /**
   * Server usually needs a few seconds to return updated user$ data since it's sending it through the contact info guesser service. Therefore wait a bit to get updated user$ data.
   */
  public loadUserMeDelayed(resultSubject: Subject<boolean> = null) {
    setTimeout(() => this.loadUserMe(resultSubject), 4000);
  }

  public loadUserMe(resultSubject: Subject<boolean> = null) {
    this.userDataService.getUserMe().subscribe(
      (user: User) => {
        this.user$.next(user);

        let email = '';
        if (SLUtils.checkUserHasEmail(user)) {
          email = user.contactInfo.emailAddresses[0].emailAddress;
        }
        // Only sent in non-production environments
        this.errorTrackerService.setUserEmail(email);

        if (resultSubject != null) {
          resultSubject.next(true);
          resultSubject.complete();
        }
      },
      (error) => {
        SLLogger.warn("Couldn't get user$/me %o", error);
        this.sessionService.deleteSession();
        if (resultSubject != null) {
          resultSubject.next(false);
          resultSubject.complete();
        }
        this.ensureLoggedIn();
      }
    );
  }

  private async registerAnonymous(): Promise<boolean> {
    SLLogger.log('Registering as anonymous user');

    const registerRequestPayload = new RegisterAnonymousPayload(this.translateService.getBrowserCultureLang());

    return this.handleAuthRequest(this.authDataService.registerAnonymous(registerRequestPayload), SessionRole.Attendee, true);
  }

  private async exchangeLimitedLifetimeToken(token: string) {
    SLLogger.log('Exchanging limited lifetime token query param');

    return this.handleAuthRequest(this.authDataService.exchangeLimitedLifetimeToken(token), SessionRole.Attendee, true);
  }

  private async registerOrUpgrade(loginProvider: LoginProvider, sessionRole: SessionRole): Promise<boolean> {
    let profile;
    try {
      profile = await loginProvider.getProfileData();
    } catch (e) {
      SLLogger.log('Getting user$ profile data failed %o', e);
    }

    const registerRequestPayload: RegisterPayload = new RegisterPayload(loginProvider.getToken(), loginProvider.getKey(), sessionRole, this.translateService.getBrowserCultureLang(), profile);

    let registerObservable;
    if (this.currentUser && this.currentUser.isAnonymous) {
      // Upgrade anonymous users
      registerObservable = this.userDataService.upgradeAnonymous(registerRequestPayload);
    } else {
      registerObservable = this.authDataService.register(registerRequestPayload);
    }

    this.trackingService.trackEvent(TrackingKey.Event.SIGNUP);

    return this.handleAuthRequest(registerObservable, sessionRole);
  }

  private handleAuthRequest(observable: Observable<AuthResponse>, sessionRole: SessionRole, isAnonymous: boolean = false) {
    const resultSubject = new Subject<boolean>();

    observable.pipe(retry(2)).subscribe(
      (authResponse: AuthResponse) => {
        if (authResponse) {
          SLLogger.log('user$ registered');
          this.updateSession(authResponse, sessionRole);
          this.loadUserMe(resultSubject);
        }
      },
      (error: any) => {
        SLLogger.log('user$ register failed %o', error);
        resultSubject.next(false);
        resultSubject.complete();
      }
    );

    return resultSubject.toPromise();
  }

  private updateSession(authResponse: AuthResponse, sessionRole: SessionRole) {
    this.setSessionToken(authResponse.sessionToken);
    this.sessionService.setSessionRole(sessionRole);
  }

  private setSessionToken(sessionToken: string) {
    this.sessionService.setSessionToken(sessionToken);
  }
}
