import { Pipe, PipeTransform } from '@angular/core';
import { PredefinedSocialNetwork, SocialNetwork } from '@sl/common/model';

@Pipe({ name: 'socialNetworkIcon' })
export class SocialNetworkIconPipe implements PipeTransform {
  transform(sn: SocialNetwork): string {
    let iconName = 'other';
    const predefinedSocialNetwork = PredefinedSocialNetwork.getPrefinedSocialNetwork(sn);
    if (predefinedSocialNetwork) {
      iconName = predefinedSocialNetwork.iconName;
    }
    return '/assets/img/network/' + iconName + '.svg';
  }
}
