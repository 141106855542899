export class ContactInfoOverview {
  public id: string;
  public firstName: string;
  public lastName: string;
  public position: string;
  public company: string;
  public selfDescription: string;
  public imageBlobId: string;
  public imageUrl: string;

  constructor (id: string, firstName: string, lastName: string) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
  }
}
