import { Routes } from '@angular/router';
import { ConnectComponent } from '@sl/connect/components/connect/ConnectComponent';

export enum QUERY_PARAMS {
  LANG = 'lang',
  IS_IN_MS_TEAMS = 'msteams',
  LIMITED_LIFETIME_TOKEN = 'token',
}

export const ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'connect' },
  { path: 'connect', component: ConnectComponent },
  { path: 'presentation', loadChildren: () => import('app/presentation/presentation.module').then((m) => m.PresentationModule), data: { preload: true } },
  { path: 'workflow', loadChildren: () => import('app/worflow/workflow.module').then((m) => m.WorkflowModule) },
  { path: 'activate', loadChildren: () => import('app/plan-activation/plan-activation.module').then((m) => m.PlanActivationModule) },
  { path: 'ms-teams', loadChildren: () => import('app/ms-teams/ms-teams.module').then((m) => m.MsTeamsModule) },
  { path: '**', component: ConnectComponent },
];
