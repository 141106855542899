import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PresentationAttendanceService } from '@sl/pres/services/PresentationAttendanceService';
import { GDPRInfo } from '@sl/common/model';
import { AttendanceInfo, GDPRState } from '@sl/common/services/endpoint/dto/AttendanceInfo';
import { SLUtils } from '@sl/common/utils/SLUtils';

@Component({
  selector: 'accept-gdpr',
  templateUrl: 'accept-gdpr.component.html',
})
export class AcceptGDPRComponent {
  @Output()
  onHasAcceptedGDPRChanged = new EventEmitter<Boolean>();

  @Input()
  textClass: string;

  @Input()
  set gdprInfo(value: GDPRInfo) {
    this._gdprInfo = value;

    this.updateUI();
  }

  get gdprInfo() {
    return this._gdprInfo;
  }

  @Input()
  disabled = false;

  private _gdprInfo: GDPRInfo;
  private _hasAcceptedGDPR = false;

  showGDPRCheckbox = true;

  constructor(private presentationAttendanceService: PresentationAttendanceService) {}

  updateUI() {
    if (SLUtils.isGDPREnabled(this._gdprInfo)) {
      const attendanceInfo = this.presentationAttendanceService.getAttendanceInfo();
      this._hasAcceptedGDPR = attendanceInfo && attendanceInfo.value != null && attendanceInfo.value.gdprState === GDPRState.Accepted;
      this.showGDPRCheckbox = !this._hasAcceptedGDPR;
    } else {
      this.hasAcceptedGDPR = null;
      this.showGDPRCheckbox = false;
    }

    this.onHasAcceptedGDPRChanged.emit(this._hasAcceptedGDPR);
  }

  public set hasAcceptedGDPR(value: boolean) {
    this._hasAcceptedGDPR = value;
    this.onHasAcceptedGDPRChanged.emit(this._hasAcceptedGDPR);
  }

  public get hasAcceptedGDPR() {
    return this._hasAcceptedGDPR;
  }

  save() {
    if (SLUtils.isGDPREnabled(this._gdprInfo)) {
      this.presentationAttendanceService.updateGDPRState(this.hasAcceptedGDPR ? GDPRState.Accepted : GDPRState.Unaccepted);
    }
  }
}
