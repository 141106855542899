import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SlideLizardImageDirective } from '@sl/common/directives/SlideLizardImageDirective';
import { LoadingComponent } from '@sl/common/components/loading/LoadingComponent';
import { ItemComponent } from '@sl/common/components/item/ItemComponent';
import { ContactInfoFullNamePipe } from '@sl/common/pipes/ContactInfoFullNamePipe';
import { ContactInfoJobNamePipe } from '@sl/common/pipes/ContactInfoJobPipe';
import { ExternalContentMimeTypeIconPipe } from '@sl/common/pipes/ExternalContentMimeTypeIconPipe';
import { SlideLizardToolbarComponent } from '@sl/common/components/sl-toolbar/SlideLizardToolbarComponent';
import { FromToDatePipe } from '@sl/common/pipes/FromToDatePipe';
import { ProfileComponent } from '@sl/common/components/profile/ProfileComponent';
import { ShareConnectionCodeDialogComponent } from '@sl/common/components/share-connection-code/ShareConnectionCodeDialogComponent';
import { StarRatingInputComponent } from '@sl/common/components/star-rating-input/StarRatingInputComponent';
import { SpeakersPipe } from '@sl/common/pipes/SpeakersPipe';
import { FormatTimestampPipe } from '@sl/common/pipes/FormatTimestampPipe';
import { RouterModule } from '@angular/router';
import { CapitalizePipe } from '@sl/common/pipes/CapitalizePipe';
import { LocationItemComponent } from '@sl/common/components/location-item/LocationItemComponent';
import { SocialNetworkIconPipe } from '@sl/common/pipes/SocialNetworkIconPipe';
import { SocialNetworkNamePipe } from '@sl/common/pipes/SocialNetworkNamePipe';
import { ClickOutsideDirective } from '@sl/common/directives/ClickOutsideDirective';
import { MediaQueryDirective } from '@sl/common/directives/MediaQueryDirective';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { AcceptTermsComponent } from '@sl/common/components/accept-terms/AcceptTermsComponent';
import { LoginComponent } from '@sl/common/components/login/LoginComponent';
import { InlineSVGModule } from 'ng-inline-svg';
import { ContactImageComponent } from '@sl/common/components/contact-image/ContactImageComponent';
import { DebugSettingsComponent } from '@sl/common/components/debug-settings/DebugSettingsComponent';
import { AboutOverlayService } from '@sl/common/components/about-dialog/AboutOverlayService';
import { AboutDialogComponent } from '@sl/common/components/about-dialog/AboutDialogComponent';
import { FooterComponent } from '@sl/common/components/footer/FooterComponent';
import { TrimmedMinLengthDirective } from 'app/common/directives/TrimmedMinLengthDirective';
import { CapitalizeInputDirective } from '@sl/common/directives/CapitalizeInputDirective';
import { ConfirmDialogComponent } from '@sl/common/components/confirm-dialog/ConfirmDialogComponent';
import { AvatarGridDialogComponent } from '@sl/common/components/avatar-grid-dialog/AvatarGridDialogComponent';
import { EMailValidDirective } from '@sl/common/directives/EMailValidDirective';
import { AutofocusDirective } from '@sl/common/directives/AutofocusDirective';
import { EditContactInfoDialogComponent } from '@sl/common/components/edit-contact-info-dialog/EditContactInfoDialogComponent';
import { EditContactInfoOverlayService } from '@sl/common/components/edit-contact-info-dialog/EditContactInfoOverlayService';
import { ViewContactInfoDialogComponent } from '@sl/common/components/view-contact-info-dialog/ViewContactInfoDialogComponent';
import { ViewContactInfoOverlayService } from '@sl/common/components/view-contact-info-dialog/ViewContactInfoOverlayService';
import { AcceptGDPRComponent } from '@sl/common/components/accept-gdpr/AcceptGDPRComponent';
import { OverlayContainerDirective } from '@sl/common/directives/OverlayContainerDirective';
import { LocalizedDatePipe } from '@sl/common/pipes/LocalizedDatePipe';
import { ProgressBarComponent } from '@sl/common/components/progress-bar/ProgressBarComponent';
import { RequestTimeAnalyticsInterceptor } from '@sl/common/services/httpclient/RequestTimeAnalyticsInterceptor';
import { AllowedInputPatternDirective } from '@sl/common/directives/AllowedInputPatternDirective';
import { AspectRatioDirective } from '@sl/common/directives/AspectRatioDirective';
import { NumberRatingInputComponent } from '@sl/common/components/number-rating-input/NumberRatingInputComponent';
import { SingleChoiceRatingInputComponent } from '@sl/common/components/single-choice-rating-input/SingleChoiceRatingInputComponent';
import { TranslateCountStringComponent } from '@sl/common/components/translate-count-string/TranslateCountStringComponent';
import { BaseDialogComponent } from '@sl/common/components/BaseDialogComponent';
import { Html5VideoPlayerComponent } from '@sl/common/components/video/html5-video-player/html5-video-player.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { HorizontalBarChartComponent } from './components/charts/horizontal-bar-chart/horizontal-bar-chart.component';
import { AzureVideoPlayerComponent } from './components/video/azure-video-player/azure-video-player.component';
import { VerticalBarChartComponent } from './components/charts/vertical-bar-chart/vertical-bar-chart.component';
import { VideoPlayerComponent } from './components/video/video-player.component';
import { BaseVideoPlayerComponent } from '@sl/common/components/video/base-video-player.component';
import { YoutubeVideoPlayerComponent } from './components/video/youtube-video-player/youtube-video-player.component';
import { VimeoVideoPlayerComponent } from './components/video/vimeo-video-player/vimeo-video-player.component';
import { MultiLanguageStringPipe } from './pipes/MultiLanguageStringPipe';
import { MultiLineStringPipe } from './pipes/MultiLineStringPipe';
import { LanguageChooserComponent } from './components/language-chooser/language-chooser.component';
import { BooleanInputComponent } from './components/boolean-input/boolean-input.component';
import { MatRadioModule } from '@angular/material/radio';
import { HelpLinkComponent } from './components/help-link/help-link.component';
import { VotingComponent } from './components/voting/voting.component';
import { BaseVotingAnswerContainerComponent } from './components/voting/answer-types/BaseVotingAnswerContainerComponent';
import { OpenTextAnswerContainerComponent } from './components/voting/answer-types/open-text-answer/OpenTextAnswerContainerComponent';
import { RankingAnswerContainerComponent } from './components/voting/answer-types/ranking-answer/RankingAnswerContainerComponent';
import { ChoiceAnswerContainerComponent } from './components/voting/answer-types/choice-answer/ChoiceAnswerContainerComponent';
import { VotingResultComponent } from './components/voting/results/voting-result-component/voting-result.component';
import { EditContactInfoComponent } from './components/edit-contact-info/edit-contact-info.component';
import { WordGridComponent } from './components/charts/word-grid/word-grid.component';
import { PhotoAnswerComponent } from './components/voting/answer-types/photo-answer/photo-answer.component';
import { SafePipe } from '@sl/common/pipes/SafePipe';
import { PhotoGridComponent } from './components/charts/photo-grid/photo-grid.component';
import { CustomFormComponent } from '@sl/common/components/custom-form/custom-form.component';
import { AnswerFilterComponent } from './components/voting/answer-filter/answer-filter.component';
import { FullscreenImageDialogComponent } from './components/voting/answer-types/choice-answer/fullscreen-image-dialog/fullscreen-image-dialog.component';
import { PointsDistributionAnswerComponent } from './components/voting/answer-types/points-distribution-answer/points-distribution-answer.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

const MaterialModules = [
  MatButtonModule,
  MatCardModule,
  MatDialogModule,
  MatRippleModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatListModule,
  MatProgressBarModule,
  MatInputModule,
  MatToolbarModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatMenuModule,
  MatTabsModule,
  MatRadioModule,
];

const components = [
  BaseDialogComponent,
  ItemComponent,
  LoadingComponent,
  SlideLizardToolbarComponent,
  LoginComponent,
  ViewContactInfoDialogComponent,
  ProfileComponent,
  ShareConnectionCodeDialogComponent,
  StarRatingInputComponent,
  NumberRatingInputComponent,
  SingleChoiceRatingInputComponent,
  ContactImageComponent,
  LocationItemComponent,
  AcceptTermsComponent,
  AcceptGDPRComponent,
  DebugSettingsComponent,
  AboutDialogComponent,
  FooterComponent,
  EditContactInfoDialogComponent,
  ConfirmDialogComponent,
  AvatarGridDialogComponent,
  ProgressBarComponent,
  TranslateCountStringComponent,
  Html5VideoPlayerComponent,
  HorizontalBarChartComponent,
  AzureVideoPlayerComponent,
  VerticalBarChartComponent,
  VideoPlayerComponent,
  BaseVideoPlayerComponent,
  YoutubeVideoPlayerComponent,
  VimeoVideoPlayerComponent,
  LanguageChooserComponent,
  BooleanInputComponent,
  HelpLinkComponent,
  BaseVotingAnswerContainerComponent,
  ChoiceAnswerContainerComponent,
  OpenTextAnswerContainerComponent,
  RankingAnswerContainerComponent,
  VotingResultComponent,
  VotingComponent,
  EditContactInfoComponent,
  WordGridComponent,
  PhotoAnswerComponent,
  PhotoGridComponent,
  CustomFormComponent,
  AnswerFilterComponent,
  FullscreenImageDialogComponent,
  PointsDistributionAnswerComponent,
];

const directives = [SlideLizardImageDirective, ClickOutsideDirective, MediaQueryDirective, EMailValidDirective, AutofocusDirective, OverlayContainerDirective, AllowedInputPatternDirective, AspectRatioDirective, TrimmedMinLengthDirective, CapitalizeInputDirective];

const pipes = [ContactInfoFullNamePipe, ContactInfoJobNamePipe, ExternalContentMimeTypeIconPipe, LocalizedDatePipe, FromToDatePipe, SpeakersPipe, CapitalizePipe, FormatTimestampPipe, SocialNetworkIconPipe, SocialNetworkNamePipe, MultiLanguageStringPipe, MultiLineStringPipe, SafePipe];

const services = [ViewContactInfoOverlayService, EditContactInfoOverlayService, AboutOverlayService];

@NgModule({
  declarations: [...components, ...directives, ...pipes, SingleChoiceRatingInputComponent],
  imports: [TranslateModule, AngularCommonModule, FormsModule, RouterModule, FlexLayoutModule, MaterialModules, HttpClientModule, InlineSVGModule, DragDropModule],
  providers: [...services, ...pipes, { provide: HTTP_INTERCEPTORS, useClass: RequestTimeAnalyticsInterceptor, multi: true }],
  exports: [MaterialModules, FlexLayoutModule, ...components, ...directives, ...pipes],
})
export class CommonModule {}
