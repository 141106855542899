import { SLConfig } from '@sl/config/SLConfig';
import { ErrorTrackerService } from '@sl/common/services/error-handling/ErrorTrackerService';
import { LogLevel } from '@sl/common/services/error-handling/LogLevel';

export class SLLogger {
  public static log(message: any, ...params: any[]) {
    this.logInternal(LogLevel.Info, message, params);
  }

  public static warn(message: any, ...params: any[]) {
    this.logInternal(LogLevel.Warning, message, params);
  }

  public static error(message: any, ...params: any[]) {
    this.logInternal(LogLevel.Error, message, params);
  }

  private static logInternal(level: LogLevel, message: any, params: any[]) {
    if (message) {
      let log = false;
      try {
        log = localStorage && localStorage['sl_log'] === 'true';
      } catch (e) {}

      if (!SLConfig.getEnvironment().production || log) {
        try {
          this.getLogFunction(level)(message, ...params);
        } catch (e) {
          // Fix for older iOS versions
          try {
            console.warn(message, ...params);
          } catch (e) {
            console.log(message);
          }
        }
      } else {
        // Very ugly hack, because service can't be injected
        new ErrorTrackerService().trackLog(message, params, level);
      }
    }
  }

  private static getLogFunction(level: LogLevel): (message: any, ...params: any[]) => void {
    if (level === LogLevel.Error) {
      return console.error;
    } else if (level === LogLevel.Warning) {
      return console.warn;
    } else {
      return console.log;
    }
  }
}
