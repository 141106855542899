export let langEN = {
  "site-title": "Join a Presentation",
  "site-description": "Enter the presentation code to participate in live polls, ask questions, view slides and give feedback. No download required.",
  "ok": "OK",
  "cancel": "Cancel",
  "retry": "Retry",
  "send": "Send",
  "save": "Save",
  "yes": "Yes",
  "already-sent": "Already sent",
  "check-again": "Check again",
  "loading": "Loading",
  "discard": "Discard",
  "no-connection": "No internet connection",
  "error-maintenance": "SlideLizard is under scheduled maintenance. The service will be back again soon!",
  "language": "Language",
  "help": "Help",
  "search": "Search",
  "browser-not-supported": {
    "message": "Your browser ({{name}}) is quite old and is therefore not officially supported anymore. Problems may occur, please consider installing a newer browser!"
  },
  "name": {
    "branded": "SlideLizard",
    "neutral": "this App"
  },
  "legal": {
    "accept-terms-active": "I accept the <a href='https://slidelizard.com/en/terms' target='_blank'>Terms</a> and <a href='https://slidelizard.com/en/privacy' target='_blank'>Privacy policy</a>.",
    "accept-terms-passive": "By using {{appName}}, you accept the <a href='https://slidelizard.com/en/terms' target='_blank'>Terms</a> and <a href='https://slidelizard.com/en/privacy' target='_blank'>Privacy policy</a>.",
    "accept-terms-login": "By logging in to {{appName}}, you accept the <a href='https://slidelizard.com/en/terms' target='_blank'>Terms</a> and <a href='https://slidelizard.com/en/privacy' target='_blank'>Privacy policy</a>."
  },
  "auth": {
    "login": "Login",
    "login-with": "Login with",
    "login-google": "Login with Google",
    "login-google-short": "Google",
    "login-facebook": "Login with Facebook",
    "login-facebook-short": "Facebook",
    "login-slidelizard": "Login with SlideLizard",
    "login-slidelizard-short": "SlideLizard",
    "login-in-progress": "Logging you in...",
    "logout": "Logout",
    "error-login-failed": "Sorry, we couldn't log you in!",
    "error-google-login": "Sorry, Google login was not successful!",
    "error-google-login-check-thirdparty-cookies": "Sorry, Google login was not successful! <br>Ensure that third party cookies are <a href='https://www.google.com/search?q=how+to+enable+third+party+cookies' target='_blank'>enabled</a>.",
    "error-facebook-login": "Sorry, Facebook login was not successful!",
  },
  "profile": {
    "edit-short": "Edit",
    "edit-long": "Edit your profile",
    "view": "View your profile",
    "title": "Your Profile",
    "enter-data": "or edit your Data",
    "enter-data-info": "Your data will be saved, but no account will be created.",
    "email": "Your E-Mail",
    "email-invalid": "Invalid address",
    "email-invalid-provider": "E-Mail provider not allowed",
    "firstname": "Your First name",
    "firstname-invalid": "Invalid First name",
    "lastname": "Your Last name",
    "lastname-invalid": "Invalid Last name",
    "phone-number": "Phone number",
    "zip": "Postal code",
    "city": "City",
    "street": "Street",
    "company": "Company",
    "position": "Position",
    "update-successful": "Profile updated",
    "unsaved-data-title": "Do you want to save your profile?",
    "unsaved-data-message": "You haven't saved your data yet.",
    "error-save-failed": "Couldn't save profile!",
    "selectci": "What is your name?",
    "other": "Other",
    "pick-avatar": "Choose an avatar",
  },
  "connect": {
    "join": "Join",
    "enter-presentation-code": "Presentation Code",
    "enter-access-key": "Enter password",
    "private-presentation-info": "This presentation is <b>private</b>.",
    "private-presentation-access-key-info": "Please ask the speaker for the password.",
    "private-presentation-specific-user-info": "Only selected users can join.",
    "error-no-presentation": "Wrong presentation code!",
    "error-wrong-access-key": "Wrong password!",
    "error-wrong-user": "Presentation isn't shared with your user. Please login with a different user!",
    "error-generic": "Something went wrong, please try again!"
  },
  "attendance-history": {
    "title": "Attended presentations & conferences",
    "view-presentation": "View presentation",
    "view-conference": "View conference",
    "loading": "Loading attended presentations...",
    "error": "Couldn't load attended presentations!"
  },
  "contact-detail": {
    "company": "Company",
    "phone-numbers": "Phone numbers",
    "email-addresses": "E-Mail addresses",
    "addresses": "Addresses",
    "social-networks": "Social Networks",
    "website": "Website",
    "profile": "Profile",
    "type": {
      "home": "Home",
      "private": "Private",
      "work_mobile": "Work Mobile",
      "office": "Office",
      "work": "Work",
      "mobile": "Mobile"
    }
  },
  "share-connection": {
    "share": "Share",
    "description": "Share the Participation-Link to let other people connect"
  },
  "conference": {
    "error-not-found": "Sorry we couldn't find the requested conference",
    "connect-with-code": "Connect with Conference Code"
  },
  "presentation": {
    "info": "Info",
    "details": "Details",
    "starting-soon": "Presentation will start soon",
    "main-view": {
      "switch": "Switch {{first}} & {{second}}",
      "fullscreen-show": "Enable Fullscreen",
      "fullscreen-hide": "Disable Fullscreen",
      "small-view-hide": "Hide {{view}}",
      "small-view-show": "Show {{view}}",
      "slides": "Slides"
    },
    "live-stream": {
        "name": "Live Stream",
      "error-zoom-cant-be-shown-mobile": {
        "text": "This presentation uses Zoom for Video calling. </br>Switch between Zoom & SlideLizard to participate in polls.",
        "cta-open-external": "Open Zoom App"
      },
      "error-zoom-cant-be-shown": {
        "text": "This presentation uses Zoom for Video calling. </br><b>Click to open SlideLizard and Zoom side-by-side</b>.",
        "cta-open-external": "Open Zoom",
        "cta-resize-window": "Move SlideLizard beside Zoom",
      }
    },
    "external-content": {
      "title": "Shared content",
      "available-after-presentation": "Available after the presentation",
      "available-after-presentation-info": "This content will be available after the presentation.",
      "now-available": "Now available",
      "receive-by-email": "Get shared content by E-Mail",
      "receive-by-email-afterwards": "Get shared content by E-Mail afterwards",
      "will-be-received-by-email-afterwards": "Shared content will be sent to {{email}} afterwards",
    },
    "email-required": {
      "info": "In order to <b>access</b> '{{title}}' the presenter <b>requires your E-Mail address</b>.",
      "option-enter-email": "Or enter your E-Mail address",
      "unsaved-data-title": "Do you want to save your E-Mail?",
      "unsaved-data-message": "You haven't saved your E-Mail address yet.",
      "open": "Open",
      "error-send-failed": "Couldn't save your E-Mail address!"
    },
    "help-others-connect": "Help others to connect",
    "error-not-found": "Sorry we couldn't find the requested presentation",
    "connect-with-code": "Connect with Presentation Code",
    "speaker": "Speaker",
    "receive-content-via-email": {
      "title-now": "Enter your E-Mail address to receive <b>{{content}}</b>.",
      "cta-now": "Send me now!",
      "title-afterwards": "Enter your E-Mail address to receive <b>{{content}}</b> after the presentation.",
      "cta-afterwards": "Save",
      "slides": "the slides",
      "notes": "your private notes",
      "starred-slides": "starred slides",
      "speaker-contact-info": "Speaker contact info",
      "external-content": "Shared documents"
    },
    "summary-email": {
      "successful": "Your E-Mail is on the way!",
      "error": "An error occurred. Please try again!"
    },
    "votings": {
      "title": "Polls",
      "vote": "Vote in a Poll",
      "active": "Vote now",
      "no-active": "There is currently no active poll",
      "type": {
        "single": "Single Choice",
        "multiple": "Multiple Choice",
        "openText": "Open Text",
        "ranking": "Ranking",
        "photo": "Photo",
        "pointsDistribution": "Points distribution"
      },
      "poll": "{{type}} Poll",
      "quiz": "{{type}} Quiz ({{points}} Points)",
      "anonymous-poll": "Anonymous {{type}} Poll",
      "anonymous-quiz": "Anonymous {{type}} Quiz ({{points}} Points)",
      "quiz-correct-answer": "Congrats, <br/><b>correct answer</b>",
      "quiz-wrong-answer": "Sorry, <br/><b>wrong answer</b>",
      "quiz-no-answer": "<b>No</b> <br/>answer sent",
      "quiz-waiting-for-result": "Your answer is being evaluated...",
      "quiz-gained-no-points":"No Points",
      "quiz-gained-1-point":"+{{count}} Point",
      "quiz-gained-x-points":"+{{count}} Points",
      "quiz-total-points":"Total {{currentPoints}} / {{maxReachablePoints}}",
      "quiz-points-info": "Currently {{currentPoints}} / {{maxReachablePoints}} reachable Quiz-Points",
      "send-vote": "Send Vote",
      "already-voted": "You already voted",
      "more-answers": "More answers",
      "vote-sent-successfully": "Your answer was sent successfully!",
      "unsaved-data-title": "Do you want to send your answer?",
      "unsaved-data-message": "You haven't sent an answer yet.",
      "voting-now-title": "Vote now!",
      "open-text": {
        "add-answer": "Add an answer",
        "x-answers-possible": "{{count}} answers possible",
        "1-answer-possible": "1 answer possible",
        "add-x-more-answers": "{{count}} more answers possible",
        "add-1-more-answer": "1 more answer possible",
        "add-unlimited-answers": "Add as many answers as you want",
        "max-answers-exceeded": "Maximum number of answers reached ({{maxAnswers}})",
        "error-invalid-input": "Invalid input",
      },
      "photo": {
        "add-answer": "Choose a photo",
        "x-answers-possible": "You can send {{count}} photos",
        "1-answer-possible": "",
        "add-x-more-answers": "You can add {{count}} more photos",
        "add-1-more-answer": "You can add 1 more photo",
        "add-unlimited-answers": "Add as many photos as you want",
        "max-answers-exceeded": "Maximum number of photos reached ({{maxAnswers}})",
        "error-file-too-large": "This image is to large. Please an image with a maximum size of {{maxFileSizeMb}}mb."
      },
      "ranking": {
        "move-up": "Move up",
        "move-down": "Move down",
        "guidance": {
          "desktop": "Click and drag entries to sort by priority",
          "mobile": "Drag entries on the right handle to sort by priority"
        }
      },
      "points-distribution": {
        "points-left":"<b>{{points}}</b> points left to distribute"
      },
      "error-send-answer": "Sorry sending your vote failed!",
      "error-send-answer-voting-already-finished": "Sorry, you are too late!<br>Poll has already finished!",
      "error-load-failed": "Sorry, couldn't load polls!",
      "blocked":"You are not allowed to vote"
    },
    "feedback": {
      "title": "Give Feedback",
      "number-rating": {
        "indicator-left": "",
        "indicator-right": ""
      },
      "yes-no-rating": {
        "yes": "Yes",
        "no": "No",
      },
      "anonymous": "Feedback is completely anonymous",
      "already-left": "You already left a feedback",
      "unsaved-data-title": "Do you want to send your feedback?",
      "unsaved-data-message": "You haven't sent your feedback yet.",
      "sent-successfully": "Your feedback was sent successfully!",
      "error-send-failed": "Sorry, sending feedback failed!",
      "error-load-failed": "Sorry, couldn't load feedback!"
    },
    "question": {
      "ask": "Ask a question",
      "send-as": "Send as",
      "anonymous": "Anonymous",
      "identify": "Add name or E-Mail",
      "placeholder": "Question",
      "sent-successfully": "Your question was sent successfully!",
      "unsaved-data-title": "Do you want to send your question?",
      "unsaved-data-message": "You haven't sent your question yet.",
      "error-send-failed": "Sorry, sending your question failed!",
      "error-identification-needed": "You need to enter your name or E-Mail to send a question (anonymous questions are disabled by the host).",
      "anonymous-info": "If you ask a question anonymously, the speaker won't be able to answer it after the presentation!",
      "blocked":"You are not allowed to ask questions",
    },
    "question-ranking": {
  "title":"Q&A"
    },
    "slides": {
      "title": "Slides of the presentation",
      "available-after-presentation": "Slides will be available after the presentation",
      "info-count": "{{count}} slides shared",
      "sent-via-email-after-presentation": "You'll receive the slides via E-Mail after the presentation",
      "receive-via-email-after-presentation": "Enter your E-Mail address to receive the slides after the presentation.",
      "receive-via-email-now": "Enter your E-Mail address to receive the slides.",
      "download-now": "The presenter requires your E-Mail address to download the slides.",
      "choose-download-or-receive-via-email": "You can either get a PDF of the slides via E-Mail, or directly download and view them on this device.",
      "choose-download-or-receive-via-email-short": "Download or get via E-Mail now",
      "click-to-view": "Click to view",
      "download": "Download",
      "receive-via-email": "Get via E-Mail",
      "receive-via-email-afterwards": "Click to receive via E-Mail afterwards",
      "will-be-received-by-email-afterwards": "Slides will be sent to {{email}} afterwards",
      "slide-animation-in-progress": "Slide will be visible when all elements are revealed",
      "slide-shown-on-large-screen": "Slide only shown on projector",
      "slide-not-shared": "Slide not shared",
      "toggle-fullscreen": "Toggle fullscreen",
      "go-live-short": "Back to live",
      "go-live-long": "Go to current slide",
      "rotate-device": "Please rotate your device!"
    },
    "live": {
      "connection-push": "Live",
      "connection-polling": "Bad connection",
      "no-connection": "No live connection",
    },
    "attendee-message": {
      "new": "New Message"
    },
    "notes": {
      "title": "Notes",
      "add": "Add private notes",
      "click-to-add": "Click to add private notes",
      "click-to-add-for-slide": "Click to add private notes for slide {{slideNumber}}",
      "not-editable": "Notes can't be added on this slide",
      "no-notes": "No private notes",
      "receive-by-email": "Get notes by E-Mail",
      "receive-by-email-afterwards": "Get notes by E-Mail afterwards",
      "will-be-received-by-email-afterwards": "Notes will be sent to {{email}} afterwards",
      "not-saved": "Your personal notes aren't saved yet! Do you want to save them now?",
      "error-not-saved": "Your notes & starred slides couldn't be saved!"
    },
    "stars": {
      "star": "Star slide",
      "unstar": "Unstar slide",
      "receive-via-email-afterwards": "Do you want to receive all starred slides per E-Mail afterwards?",
      "will-be-received-by-email-afterwards": "You'll receive all starred slides per E-Mail afterwards."
    }
  },
  "plan-activation": {
    "title": "Manage your Plan",
    "login": "Login",
    "logout": "Logout",
    "confirm": "Confirm",
    "current-plan": "Current plan",
    "change-plan": "Change",
    "cancel-plan": "Cancel",
    "site-title": "Change plan",
    "upgrade-plan": "Upgrade to {{plan}}",
    "next": "Next",
    "active-since": "Active since",
    "invoice-address": "Invoice address",
    "plan": {
      "PLAN_FREE": "FREE",
      "PLAN_TRIAL": "PRO Trial",
      "PLAN_BASIC": "BASIC",
      "PLAN_PRO": "PRO",
      "PLAN_ENTERPRISE": "ENTERPRISE",
      "PLAN_SMALL_BUSINESS": "SMALL BUSINESS",
      "PLAN_BUSINESS": "BUSINESS",
      "PLAN_ENTERPRISE_NEW": "ENTERPRISE",
    },
    "choose-plan": {
      "title": "Choose Plan",
      "period-monthly": "Month",
      "period-yearly": "Year"
    },
    "cancel-email": {
      "subject": "Cancel SlideLizard Plan",
      "body": "Please cancel the plan ({{plan}}) for {{email}}."
    },
    "payment-details": {
      "title": "Add payment details",
      "period": {
        "title": "Payment period",
        "yearly": "Yearly",
        "monthly": "Monthly"
      },
      "christmas-offer":"🎄 Christmas offer 🎄",
      "info": "We will send you an invoice per E-Mail. <br><b>You can cancel anytime.</b>",
      "firstname": "First name",
      "firstname-required": "First name is required",
      "lastname": "Last name",
      "lastname-required": "Last name is required",
      "email": "E-Mail address",
      "email-required": "Please enter a valid E-Mail address",
      "street": "Street",
      "street-required": "Street is required",
      "city": "City",
      "city-required": "City is required",
      "zip": "ZIP",
      "zip-required": "ZIP is required",
      "country": "Country",
      "country-required": "Country is required",
      "company": "Company (optional)",
      "vat-reg-no": "VAT Registration Number (optional)"
    },
    "summary": {
      "title": "Confirm subscription",
      "chosen-plan": "Chosen plan",
      "amount": "Price",
      "exclusive-vat": "excl. VAT",
      "payment-period": "Payment period",
      "name": "Name",
      "company": "Company",
      "vat-reg-no": "VAT Reg. No.",
      "email": "E-Mail",
      "address": "Address",
      "subscribe": "Subscribe",
      "invoice-info": "You will receive an invoice via E-Mail,<br> which you can then pay by credit card.",
      "error": "Sorry, something went wrong!",
      "error-plan-already-activated": "You are already subscribed to this plan!<br>Please choose another plan."
    },
    "finish": {
      "title": "Congrats!",
      "text": "You're joining an elite club of successful presenters who use SlideLizard!",
      "ppt-restart-info": "Please restart PowerPoint in order to activate your new plan."
    }
  },
  "video-player": {
    "buffer-info": "Video is loading {{bufferPercentage}}%",
    "error": "An error occurred. Couldn't load video."
  },
  "ms-teams": {
    "enter-code-or-link": "Enter presentation code or link",
    "error-wrong-code": "No presentation found!",
    "setup-help": "Get the code in the SlideLizard-Tab in PowerPoint",
    "setup-help-info": "<a href='https://help.slidelizard.com/d/installation' target='_blank'>Learn how to get started with SlideLizard</a>",
    "setup-help-image": "/assets/img/ms-teams/tutorial-copy-presentation-code.en.png",
    "setup-finished": "Setup finished",
    "save-info": "Click ‘Save’ to finish the setup of SlideLizard.",
    "meeting-tab-info": "After the meeting has started, participants can access SlideLizard by clicking this button:"
  },
  "workflow": {
    "current-step": "Step {{currentStep}} of {{totalSteps}}",
    "next": "Next",
    "previous": "Back",
    "finished": {
      "title": "The voting has finished",
      "text": ""
    },
    "error-not-found": "Couldn't find the requested voting!"
  },
  "about": {
    "title": "About",
    "copyright": "© 2024 {{appName}}",
    "follow-facebook": "Follow us on Facebook",
    "visit-website": "Visit our Website",
    "address": "<b>SlideLizard Software GmbH</b><br>Semmelweisstraße 34<br>4020 Linz / Austria<br>",
    "imprint": "Imprint",
    "send-logs": "Send Logs"
  },
  "date-time": {
    "today": "Today"
  }
};
