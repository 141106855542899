import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SLConfig } from '@sl/config/SLConfig';
import { IEnvironment } from '@sl/config/environments/IEnvironment';
import { I18nService } from '@sl/common/services/i18n/I18nService.service';

export type HelpPage = keyof IEnvironment['helpPages'];

@Injectable({ providedIn: 'root' })
export class HelpPagesService {
  constructor(private i18nService: I18nService) {}

  public getHelpPage(pageName: HelpPage) {
    const pages = SLConfig.getEnvironment().helpPages[pageName];

    let page = pages[this.i18nService.getCurrentLanguage()] || pages[this.i18nService.getDefaultLanguage()];
    if (page) {
      return page;
    }
    return undefined;
  }
}
