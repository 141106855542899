import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { BaseOverlayService } from '@sl/common/services/BaseOverlayService';
import { AccessProtectionDialogComponent } from '@sl/connect/components/access_protection/AccessProtectionDialogComponent';

@Injectable()
export class AccessProtectionOverlayService extends BaseOverlayService<AccessProtectionDialogComponent> {
  constructor(overlay: Overlay) {
    super(overlay);
  }

  protected getComponent(): ComponentType<AccessProtectionDialogComponent> {
    return AccessProtectionDialogComponent;
  }

  open(connectionCode: string, accessKeyRequired: boolean, specificUserRequired: boolean) {
    const instance = this.createOverlay();
    instance.connectionCode = connectionCode;
    instance.accessKeyRequired = accessKeyRequired;
    instance.specificUserRequired = specificUserRequired;
    instance.overlayRef = this.overlayRef;

    return this.overlayRef;
  }
}
