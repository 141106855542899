import { Injectable } from '@angular/core';
import { Theme, ThemeAnalytics, ThemeFont, ThemeLanguageOverride } from '@sl/common/model/Theme';
import { SLConfig } from '@sl/config/SLConfig';
import cssVars from 'css-vars-ponyfill';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { PresenterAnalyticsService } from '@sl/pres/services/PresenterAnalyticsService';
import { FacebookPixelTracker } from '@sl/common/services/analytics/FacebookPixelTracker';
import { IAnalyticsTracker } from '@sl/common/services/analytics/IAnalyticsTracker';
import { TrackingKey } from '@sl/common/services/analytics';
import { MSTeamsService } from '@sl/common/services/ms-teams/MSTeamsService';
import { I18nService } from '@sl/common/services/i18n/I18nService.service';
import { GoogleAnalyticsTracker } from '../analytics/GoogleAnalyticsTracker';
import { GoogleAnalytics4Tracker } from '../analytics/GoogleAnalytics4Tracker';
import { ScriptService } from '../ScriptService';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  public static readonly CUSTOM_FONT_NAME = 'SLCustomFont';

  private static readonly FONT_FACE_STYLE_ELEMENT_ID = 'sl-font-face-style';
  private static readonly FONT_DEFINITION_STYLE_ELEMENT_ID = 'fonts';
  private static readonly FALLBACK_FONTS = '"Arial", sans-serif';

  private static readonly GOOGLE_SCRIPTS = ['https://www.google-analytics.com/analytics.js', 'https://www.googletagmanager.com/gtag/js'];
  private static readonly FACEBOOK_SCRIPTS = ['https://connect.facebook.net/en_US/fbevents.js'];

  private _currentTheme: Theme;

  public get currentTheme() {
    return this._currentTheme;
  }

  constructor(private presenterAnalyticsService: PresenterAnalyticsService, private scriptService: ScriptService, private i18nService: I18nService, private msTeamsService: MSTeamsService) {}

  public setTheme(theme: Theme) {
    this._currentTheme = theme;

    if (this.isValidTheme(theme)) {
      this._currentTheme = this.overrideEmptyValues(this._currentTheme, SLConfig.getEnvironment().theme.fallback);

      this.applyFonts(this._currentTheme.fonts);
      this.applyColors(this._currentTheme.colors);

      if (this.currentTheme.translation) {
        this.applyLanguageOverride(this._currentTheme.translation);
      } else {
        this.applyDefaultTranslations();
      }

      this.applyAnalytics(this._currentTheme.analytics);

      this.applyExecutableJavascript(this._currentTheme.executableJavaScript);
    } else {
      this.resetTheme();
    }
  }

  private isValidTheme(theme: Theme) {
    return theme && Object.keys(this.currentTheme).length > 0 && (theme.fonts || (theme.colors && Object.keys(theme.colors).length > 0) || theme.logoUrl || theme.translation);
  }

  public resetTheme() {
    this.setTheme(this.msTeamsService.isDarkTheme() ? SLConfig.getEnvironment().theme.defaultDark : SLConfig.getEnvironment().theme.default);
  }

  private applyFonts(fonts: Array<ThemeFont>) {
    const oldFontFaceStyleElement = document.getElementById(ThemeService.FONT_FACE_STYLE_ELEMENT_ID);
    if (oldFontFaceStyleElement) {
      oldFontFaceStyleElement.parentNode.removeChild(oldFontFaceStyleElement);
    }

    if (fonts && fonts.length > 0) {
      const fontFaceStyleElement = document.createElement('style');
      fontFaceStyleElement.id = ThemeService.FONT_FACE_STYLE_ELEMENT_ID;

      const customFamilyName = ThemeService.CUSTOM_FONT_NAME + '_' + this.getHashCode(fonts[0].url);

      for (const font of fonts) {
        fontFaceStyleElement.appendChild(this.createFontFace(customFamilyName, font.name, font.url, font.format, font.weight));
      }

      document.head.appendChild(fontFaceStyleElement);

      const fontDefinitionStyle = document.getElementById(ThemeService.FONT_DEFINITION_STYLE_ELEMENT_ID);
      fontDefinitionStyle.innerText = `* {font-family: '${customFamilyName}', ${ThemeService.FALLBACK_FONTS} !important; }`;
    }
  }

  private applyColors(colors: any) {
    const themeColorMetaElement = document.querySelector('meta[name=theme-color]');
    if (themeColorMetaElement) {
      themeColorMetaElement.setAttribute('content', colors['primaryColor']);
    }

    try {
      cssVars({
        variables: colors,
        watch: true,
        silent: SLConfig.getEnvironment().production,
      });
    } catch (e) {
      SLLogger.warn('Failed to apply css variables %o', e);
    }
  }

  private createFontFace(customFamilyName: string, fontName: string, url: string, format: string, weight: number) {
    let localFonts = '';
    if (fontName) {
      fontName = fontName.trim();
      localFonts += `local(${fontName}),`;

      // Sometimes installed fonts are named either with - or space
      if (fontName.indexOf(' ') > -1) {
        localFonts += `local(${fontName.replace(' ', '-')}),`;
      }
    }

    return document.createTextNode(`
        @font-face {
            font-family: '${customFamilyName}';
            font-weight: ${weight};
            font-display: swap;
            src: ${localFonts} url(${url}) format('${format}');
        }
    `);
  }

  private getHashCode(text: string) {
    let hash = 0,
      i,
      chr;
    if (text.length === 0) {
      return hash;
    }
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  private overrideEmptyValues(target: any, src: any) {
    for (const key of Object.keys(src)) {
      const property = target[key];

      const isArray = Array.isArray(property);

      if (property !== null && typeof property === 'object' && !isArray) {
        target[key] = this.overrideEmptyValues(target[key], src[key]);
      } else if (property == null) {
        target[key] = src[key];
      }
    }

    return target;
  }

  private applyLanguageOverride(languageOverride: ThemeLanguageOverride) {
    this.i18nService.resetLanguageOverrides();
    this.i18nService.addLanguageOverride(languageOverride.fallbackLanguage, languageOverride.url);
  }

  private applyDefaultTranslations() {
    this.i18nService.init();
  }

  private applyAnalytics(analyticsConfig: Array<ThemeAnalytics>) {
    if (analyticsConfig) {
      const trackers = new Array<IAnalyticsTracker>();

      const facebookPixelIds = analyticsConfig.filter((a) => a.name === ThemeAnalytics.FACEBOOK_PIXEL).map((a) => a.id);
      if (facebookPixelIds && facebookPixelIds.length > 0) {
        facebookPixelIds.forEach((fbPixelId) => {
          trackers.push(new FacebookPixelTracker(fbPixelId));
          SLLogger.log('Added Facebook Pixel (%s)', fbPixelId);
        });
      }

      const googleAnalyticsIds = analyticsConfig.filter((a) => a.name === ThemeAnalytics.GOOGLE_ANALYTICS).map((a) => a.id);
      if (googleAnalyticsIds && googleAnalyticsIds.length > 0) {
        googleAnalyticsIds.forEach((googleIds) => {
          trackers.push(new GoogleAnalytics4Tracker(googleIds));
          SLLogger.log('Added Google Analytics (%s)', googleIds);
        });
      }

      if (trackers.length > 0) {
        const requiredScripts = [];

        if (googleAnalyticsIds.length > 0) {
          requiredScripts.push(...ThemeService.GOOGLE_SCRIPTS);
        } else if (facebookPixelIds.length > 0) {
          requiredScripts.push(...ThemeService.FACEBOOK_SCRIPTS);
        }

        if (requiredScripts.length > 0) {
          this.scriptService.loadScripts(...requiredScripts).then(
            () => this.applyTrackers(trackers),
            () => SLLogger.log('Error loading analytics scripts!')
          );
        } else {
          this.applyTrackers(trackers);
        }
        return;
      }
    }

    this.presenterAnalyticsService.reset();
  }

  private applyTrackers(trackers: IAnalyticsTracker[]) {
    this.presenterAnalyticsService.initWithTrackers(trackers);
    this.presenterAnalyticsService.trackPageView(TrackingKey.PageView.CONNECT); // Simulate page view to start tracking
  }

  private applyExecutableJavascript(executableJavaScript?: string) {
    if (executableJavaScript) {
      try {
        const jsFunction = new Function(executableJavaScript);
        jsFunction();
      } catch (exception) {
        SLLogger.warn('Executing tenant javascript failed: ' + exception);
      }
    }
  }
}
