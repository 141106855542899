import { Address } from './Address';
import { ContactInfoOverview } from './ContactInfoOverview';

export class ContactInfo extends ContactInfoOverview {
  public namePrefix: string;
  public namePostfix: string;
  public website: string;
  public imageUrl: string;

  public socialNetworks: SocialNetwork[];
  public addresses: Address[];
  public emailAddresses: EmailAddress[];
  public phoneNumbers: PhoneNumber[];

  public additionalInformation1?: string;
  public additionalInformation2?: string;
  public additionalInformation3?: string;
  public additionalInformation4?: string;
  public additionalInformation5?: string;

  constructor() {
    super(null,"","");
  }
}

export class EmailAddress {
  constructor(public type: string, public emailAddress: string) {}
}

export class PhoneNumber {
  constructor(public type: string, public phoneNumber: string) {}
}

export class SocialNetwork {
  constructor(public name: string, public url: string) {}
}

export class PredefinedSocialNetwork {
  static readonly Facebook = new PredefinedSocialNetwork('Facebook', 'facebook', 'fb');
  static readonly GooglePlus = new PredefinedSocialNetwork('Google Plus', 'google-plus');
  static readonly Twitter = new PredefinedSocialNetwork('Twitter', 'twitter');
  static readonly Skype = new PredefinedSocialNetwork('Skype', 'skype');
  static readonly Instagram = new PredefinedSocialNetwork('Instagram', 'instagram');
  static readonly Xing = new PredefinedSocialNetwork('Xing', 'xing');
  static readonly LinkedIn = new PredefinedSocialNetwork('LinkedIn', 'linkedin');
  static readonly Github = new PredefinedSocialNetwork('Github', 'github');

  static readonly values = [PredefinedSocialNetwork.Facebook, PredefinedSocialNetwork.GooglePlus, PredefinedSocialNetwork.Twitter, PredefinedSocialNetwork.Skype, PredefinedSocialNetwork.Instagram, PredefinedSocialNetwork.Xing, PredefinedSocialNetwork.LinkedIn, PredefinedSocialNetwork.Github];

  constructor(public displayName: string, public name: string, public iconName: string = name) {}

  static getPrefinedSocialNetwork(sn: SocialNetwork): PredefinedSocialNetwork {
    for (const predefinedSocialNetwork of PredefinedSocialNetwork.values) {
      if (predefinedSocialNetwork.name === sn.name.toLowerCase()) {
        return predefinedSocialNetwork;
      }
    }
    return null;
  }

  toString() {
    return this.name;
  }
}
