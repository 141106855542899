import { Injectable } from '@angular/core';
import { ImageDataService } from '@sl/common/services/endpoint/ImageDataService';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SlideLizardMetaService {
  static readonly ALTERNATING_TITLE_DURATION = 2000;
  static readonly TITLE_SUFFIX = ' | SlideLizard';

  private currentTitle: string;

  private alternatingTitleTimer: Subscription;
  private previousTitle: string;

  constructor(private imageDataService: ImageDataService, private metaService: Meta, private translateService: TranslateService) {}

  setMetaData(title: string, description?: string, imageBlobId?: string) {
    this.setTitle(title);
    this.setDescription(description);
    this.setImageUrl(imageBlobId ? this.imageDataService.getImageUrl(imageBlobId) : '');
  }

  setLoading(description?: string) {
    this.translateService.get('loading').subscribe((text) => this.setMetaData(text, description, ''));
  }

  setError(description?: string) {
    this.setMetaData('Error', description, '');
  }

  startAlternatingTitle(title: string) {
    this.previousTitle = this.currentTitle;
    this.alternatingTitleTimer = timer(0, SlideLizardMetaService.ALTERNATING_TITLE_DURATION).subscribe((value) => {
      if (value % 2) {
        this.setTitle(this.previousTitle);
      } else {
        this.setTitle(title);
      }
    });
  }

  stopAlternatingTitle() {
    if (this.alternatingTitleTimer) {
      this.alternatingTitleTimer.unsubscribe();
    }

    if (this.previousTitle) {
      this.setTitle(this.previousTitle);
      this.previousTitle = null;
    }
  }

  private setTitle(title: string) {
    this.currentTitle = title;
    document.title = title + SlideLizardMetaService.TITLE_SUFFIX;
  }

  private setDescription(description: string = '') {
    this.setNameTag('description', description);
    this.setPropertyTag('og:description', description);
  }

  private setImageUrl(imageUrl: string) {
    this.setPropertyTag('og:image', imageUrl);
  }

  private setNameTag(name: string, value: string) {
    this.setTag('name', name, value);
  }

  private setPropertyTag(name: string, value: string) {
    this.setTag('property', name, value);
  }

  private setTag(type: 'name' | 'property', name: string, value: string) {
    const selector = type + '="' + name + '"';
    const metaDefinition: any = {};
    metaDefinition[type] = name;
    metaDefinition.content = value;
    const tag = this.metaService.getTag(selector);

    if (tag) {
      this.metaService.updateTag(metaDefinition, selector);
    } else {
      this.metaService.addTag(metaDefinition);
    }
  }
}
