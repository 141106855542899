import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseVotingAnswerContainerComponent } from '../BaseVotingAnswerContainerComponent';
import { Voting } from '@sl/common/model/Voting';
import { VotingAnswerEntry } from '@sl/common/model/local/VotingAnswerEntry';
import { TextUtils } from '@sl/common/utils/TextUtils';
import { NgForm } from '@angular/forms';
import { animate, sequence, style, transition, trigger } from '@angular/animations';
import { ProgressState } from '@sl/common/utils/ProgressState';
import { BetterErrorStateMatcher } from '@sl/common/utils/BetterErrorStateMatcher';
import { OpenTextAnswerPayload } from '@sl/common/services/endpoint/dto/OpenTextAnswerPayload';

@Component({
  selector: 'open-text-answer-container',
  templateUrl: 'open-text-answer-container.component.html',
  styleUrls: ['open-text-answer-container.component.scss'],
  animations: [
    trigger('anim', [
      transition('void => *', [style({ height: '0', opacity: '0', transform: 'translateX(20px)' }), sequence([animate('.1s ease', style({ height: '*', opacity: '.2', transform: 'translateY(20px)' })), animate('.35s ease', style({ height: '*', opacity: 1, transform: 'translateY(0)' }))])]),
    ]),
  ],
})
export class OpenTextAnswerContainerComponent extends BaseVotingAnswerContainerComponent {
  ProgressState = ProgressState;

  @Input()
  set activeVoting(voting: Voting) {
    this._activeVoting = voting;
    this.populateAnswers();
  }

  get activeVoting() {
    return this._activeVoting;
  }

  @Input()
  set previousVotingAnswer(value: VotingAnswerEntry) {
    this._previousVotingAnswer = value;

    if (this.newAnswer && value && (value.answers as Array<string>).includes(this.newAnswer)) {
      this.newAnswer = null;
    }

    this.populateAnswers();

    setTimeout(() => {
      try {
        this.answerTextInput?.nativeElement.focus();
        this.hostElementRef?.nativeElement.scrollTo?.({
          top: this.hostElementRef.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      } catch (e) {}
    }, 500);
  }

  get previousVotingAnswer() {
    return this._previousVotingAnswer;
  }

  errorMatcher = new BetterErrorStateMatcher();

  @ViewChild('form')
  form: NgForm;

  @ViewChild('answerTextInput', { read: ElementRef })
  answerTextInput: ElementRef;

  public answers: Array<string>;
  public newAnswer: string;

  constructor(private hostElementRef: ElementRef) {
    super();
  }

  populateAnswers() {
    if (this.activeVoting) {
      if (this.previousVotingAnswer) {
        this.answers = this.previousVotingAnswer.answers;
      } else {
        this.answers = new Array<string>();
      }
    }
  }

  save() {
    this.newAnswer = this.newAnswer?.trim();

    if (this.hasChosenAnswer()) {
      this.saveEmitter.emit();
    }
  }

  async getVotePayload(): Promise<OpenTextAnswerPayload> {
    return { answerText: this.newAnswer };
  }

  hasChosenAnswer(): boolean {
    return !TextUtils.isNullOrEmpty(this.newAnswer) && (this.form ? this.form.valid : true);
  }

  canAddAnswer() {
    if (this.answers && this.activeVoting && this.activeVoting.maxAnswersPerAttendee != null) {
      return this.answers.length < this.activeVoting.maxAnswersPerAttendee;
    }
    return true;
  }

  shouldShowScrollIndicator(): boolean {
    return false;
  }
}
