import { LocalStorageService } from '@sl/common/services/storageaccess/LocalStorageService';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ServerTimeStorageService extends LocalStorageService<any> {
  private static readonly SERVER_Time = 'st';

  getServerTimeDifference() {
    return super.getValue(ServerTimeStorageService.SERVER_Time);
  }

  saveServerTimeDifference(value: number) {
    return super.setValue(ServerTimeStorageService.SERVER_Time, value);
  }
}
