import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { SLLogger } from '@sl/common/utils/SLLogger';

@Directive({
  selector: '[aspect-ratio]',
})
export class AspectRatioDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  @Input()
  fixedSide: 'width' | 'height' = 'width';

  @Input('aspect-ratio')
  set aspectRatio(ratioText: string) {
    try {
      if (ratioText === 'fill') {
        this.fill = true;
      } else {
        this.fill = false;
        this.ratios = ratioText.split(':').map((s) => Number.parseInt(s, 0));
      }
    } catch (e) {
      SLLogger.warn("Couldn't parse aspect ratio '" + ratioText + "'");
    }
  }

  fill: boolean;
  ratios: number[];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.recalculate();
  }

  recalculate() {
    if (this.el.nativeElement) {
      if (this.fill) {
        this.setFixedSize('width', '100%');
        this.setFixedSize('height', '100%');
      } else if (this.ratios && this.ratios.length == 2 && this.el) {
        if (this.fixedSide == 'width') {
          this.setFixedSizePx('height', Math.round((this.el.nativeElement.offsetWidth / this.ratios[0]) * this.ratios[1]));
        } else {
          this.setFixedSizePx('width', Math.round((this.el.nativeElement.offsetHeight / this.ratios[1]) * this.ratios[0]));
        }
      } else {
        this.setFixedSize('width', null);
        this.setFixedSize('height', null);
      }
    }
  }

  private setFixedSizePx(property: string, value: number) {
    this.setFixedSize(property, value + 'px');
  }

  private setFixedSize(property: string, value: string) {
    this.el.nativeElement.style['min-' + property] = value;
    this.el.nativeElement.style[property] = value;
    this.el.nativeElement.style['max-' + property] = value;
  }

  ngAfterViewInit(): void {
    this.recalculate();
  }
}
