<div class="content container" fxLayout="column" fxFlexAlign="center center"
     style="background-color: white; height: 100vh;">
  <mat-toolbar style="background-color: transparent;">
    <mat-toolbar-row>
      <div class="clickable-round toolbar-button" style="fill: black;"
           (click)="close()" inlineSVG="assets/img/ic_close.svg"></div>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="scroll-container access-protection-scroll-container" fxLayout="column" fxFlexAlign="center center">
    <img src="/assets/img/ic_locked.svg" class="image"/>

    <div fxLayout="column" fxLayoutAlign="center center" class="infoText">
      <h6 class="foreground-text2-color-surface"
          [innerHTML]="'connect.private-presentation-info'| translate"></h6>
      <h6 *ngIf="specificUserRequired" class="foreground-text2-color-surface"
          [innerHTML]="'connect.private-presentation-specific-user-info'| translate"></h6>
      <h6 *ngIf="accessKeyRequired" class="foreground-text2-color-surface"
          [innerHTML]="'connect.private-presentation-access-key-info'| translate"></h6>
    </div>

    <ng-template [ngIf]="specificUserRequired">
      <login *ngIf="!authService.currentUser || authService.currentUser.isAnonymous; else profile"
             (onLoginSuccessfulEvent)="onLoginSuccessful()"></login>

      <ng-template #profile>
        <div fxLayout="row wrap" fxLayoutAlign="center center" style="margin-bottom: 12px;">
          <contact-image [contactInfoOverview]="authService.currentUser.contactInfo"
                         imageSize="36px" fontSize="18px"></contact-image>
          <h6 style="margin: 0 12px;">{{authService.currentUser.contactInfo | fullname}}</h6>
          <button mat-button class="send-button" (click)="onLogoutClicked()">{{'auth.logout' | translate}}</button>
        </div>
      </ng-template>
    </ng-template>

    <mat-form-field *ngIf="accessKeyRequired" class="foreground-text1-color-background">
      <input matInput #accessKeyInput [placeholder]="'connect.enter-access-key' | translate"
             style="text-align: center;"
             [(ngModel)]="accessKey"
             [disabled]="state == ProgressState.Loading"
             (keyup.enter)="onConnectClicked()"
             (input)="onAccessKeyChanged()"
             autofocus maxlength="30"
             autocorrect="off"
             autocapitalize="none"
             autocomplete="off"
             spellcheck="false"/>
    </mat-form-field>


    <p *ngIf="state == ProgressState.Error" style="text-align: center;"
       class="foreground-color-error">{{errorMessage}}</p>

    <div style="margin: 8px;" fxLayout="column" fxLayoutAlign="center center">
      <mat-progress-spinner *ngIf="state == ProgressState.Loading"
                            class="small-progress connect-spinner"
                            diameter="26"
                            strokeWidth="4"
                            mode="indeterminate"></mat-progress-spinner>

      <button [ngClass]="{'foreground-color-primary': accessKey.length > 2}"
              *ngIf="accessKeyRequired && state != ProgressState.Loading"
              mat-raised-button
              [disabled]="accessKeyRequired && accessKey.length < 3"
              (click)="onConnectClicked()">{{'connect.join' | translate}}
      </button>
    </div>
  </div>
</div>
