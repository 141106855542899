import { Subject } from 'rxjs';

export class NGUtils {
  /**
   * Use to defer changes to avoid ElementChangedAfterChecked error
   * @param change
   */
  public static deferChange(change: () => void) {
    Promise.resolve(null).then(() => change());
  }

  /**
   * Use to run code after changes are rendered (e.g. elements are revealed by ngIf)
   * @param run
   */
  public static runAfterNextRender(run: () => void) {
    setTimeout(() => run());
  }

  public static unsubscribeAllSubscribers(...observables: Subject<any>[]) {
    if (observables) {
      observables.filter((observable) => observable).forEach((observable) => observable.observers.forEach((o) => o.complete()));
    }
  }
}
