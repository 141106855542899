import { Injectable } from '@angular/core';
import { PresentationDataService } from '@sl/common/services/endpoint/PresentationDataService';
import { AttendanceInfo, GDPRState } from '@sl/common/services/endpoint/dto/AttendanceInfo';
import { AttendancePayload } from '@sl/common/services/endpoint/dto/AttendancePayload';
import { DeviceDetect } from '@sl/common/utils/DeviceDetect';
import { PresentationInstanceDependentService } from '@sl/pres/services/PresentationInstanceDependentService';
import { SLLogger } from '@sl/common/utils/SLLogger';
import { UserDataService } from '@sl/common/services/endpoint/UserDataService';
import { BehaviorSubject } from 'rxjs';
import { NGUtils } from '@sl/common/utils/NGUtils';
import { PresentationInstance, PresentationInstanceState } from '@sl/common/model';
import { PresentationStateHub } from '@sl/pres/services/PresentationStateHub';

@Injectable({ providedIn: 'root' })
export class PresentationAttendanceService implements PresentationInstanceDependentService {
  presentationInstanceId: string;
  attendanceInfo: BehaviorSubject<AttendanceInfo>;

  constructor(private presentationDataService: PresentationDataService, private userDataService: UserDataService, private presentationStateHub: PresentationStateHub) {}

  init(presentationInstance: PresentationInstance): void {
    this.presentationInstanceId = presentationInstance.id;
    this.attendanceInfo = new BehaviorSubject<AttendanceInfo>(null);

    if (presentationInstance.state === PresentationInstanceState.Running) {
      this.attendPresentation();
    } else if (presentationInstance.state !== PresentationInstanceState.WorkInProgress) {
      this.loadAttendanceInfo();
    }

    this.presentationStateHub.blockingStateChanged.subscribe((event) => {
      if (this.attendanceInfo.getValue()) {
        this.attendanceInfo.getValue().blockingStates = event.blockingStates;
        this.attendanceInfo.next(this.attendanceInfo.getValue());
      }
    });
  }

  public attendPresentation() {
    this.presentationDataService.postAttendance(this.presentationInstanceId, this.getAttendancePayload()).subscribe(
      (attendanceInfo) => {
        if (attendanceInfo) {
          this.attendanceInfo.next(attendanceInfo);
        } else {
          this.loadAttendanceInfo();
        }
      },
      (error) => {
        SLLogger.warn('Attending presentation failed: %o', error);
        this.loadAttendanceInfo();
      }
    );
  }

  private getAttendancePayload() {
    const att = new AttendancePayload();

    att.deviceType = DeviceDetect.getDeviceType();
    att.name = navigator.userAgent;
    att.platform = DeviceDetect.getDevicePlatform();
    att.connectionString = '';

    return att;
  }

  private loadAttendanceInfo() {
    this.userDataService.getAttendanceInfo(this.presentationInstanceId).subscribe(
      (attendanceInfo) => this.attendanceInfo.next(attendanceInfo),
      () => {
        this.attendanceInfo.next(null);
      }
    );
  }

  public getAttendanceInfo() {
    return this.attendanceInfo;
  }

  public updateGDPRState(updatedGDPRState: GDPRState) {
    const updatedAttendanceInfo = new AttendanceInfo(updatedGDPRState);
    this.userDataService.updateAttendanceInfo(this.presentationInstanceId, updatedAttendanceInfo).subscribe(
      () => {
        if (this.attendanceInfo.value) {
          this.attendanceInfo.value.gdprState = updatedGDPRState;
          this.attendanceInfo.next(this.attendanceInfo.value);
        } else {
          this.attendanceInfo.next(updatedAttendanceInfo);
        }
      },
      () => SLLogger.warn('Updating of attendance info failed!')
    );
  }

  disconnect(): void {
    this.presentationInstanceId = null;
    NGUtils.unsubscribeAllSubscribers(this.attendanceInfo);
  }
}
